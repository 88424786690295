import * as React from 'react';
import { UserInterface } from '../../../profile/user';
import UserActivationDialog from '.';
import useUserActions from '../../../hooks/useUserActions';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

interface Props {
  userProfile: UserInterface;
  userActive: boolean;
  onDismiss: () => void;
}

export const UserActivation: React.FC<Props> = ({ userActive, userProfile, onDismiss }): JSX.Element => {
  const {
    toggleUserActivation,
    userActivationResultDetails: { isLoading, reset, isQueryEnded }
  } = useUserActions();

  return (
    <UserActivationDialog
      userProfile={userProfile}
      userActive={userActive}
      userActivateApiDetails={{ result: { isLoading, isQueryEnded }, toggleUserActivation }}
      onDismiss={() => {
        onDismiss();
        reset();
      }}
    />
  );
};
