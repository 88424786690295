import { DataTable, DataTableColumn } from '@vaisala/rockhopper-data-table';
import { Heading } from '@vaisala/rockhopper-components';
import React from 'react';
import { DeviceModel } from '../../../types';
import { useTranslation } from 'react-i18next';
import { IconFormatter } from '../../Site/SiteTableFormatters';
import { useResizeObserver } from '../../../hooks/useResizeObserver';
import { TEST_IDS } from '../../../tests/testids';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';

interface Props {
  device: DeviceModel;
  onHighlight: (deviceId: string) => boolean;
  highlighted: string;
}

const GatewayStatus: React.FC<Props> = ({ device, onHighlight, highlighted }) => {
  const { t } = useTranslation();
  const ref = React.useRef();
  const highlightRef = React.useRef<HTMLElement>();
  const [, height] = useResizeObserver(ref, 400);
  const loggerData = device.status?.connectedLoggers
    ? [...device.status.connectedLoggers]
        .sort((a, b) => (b < a ? 1 : -1))
        .map(deviceId => {
          const [model, _sn] = deviceId.split('-');
          return {
            deviceId,
            model,
            action: 'treeControl'
          };
        })
    : [];
  const getRows = (rowIdx: number) => {
    return loggerData[rowIdx];
  };
  const handleHighlight = (e, value) => {
    if (onHighlight) {
      const wasSelected = onHighlight(value);
      const currentRow: HTMLElement = e.currentTarget.closest('.react-grid-Row');
      const container: HTMLElement = currentRow.parentElement;
      const allHighlightedRows: HTMLCollection = container.getElementsByClassName('highlight');

      Array.from(allHighlightedRows).map((el: HTMLElement) => el.classList.remove('highlight'));
      // Only add the highlight if it wasn't previously selected
      if (!wasSelected) {
        currentRow?.classList?.add('highlight');
        highlightRef.current = currentRow;
      } else {
        highlightRef.current = undefined;
      }
    }
  };
  const AP10StatusColumns: DataTableColumn[] = [
    { key: 'deviceId', name: t('deviceManager.device') },
    { key: 'model', name: t('deviceManager.hardwareModel') },
    {
      key: 'action',
      name: t('general.locate'),
      width: 56,
      formatter: IconFormatter,
      events: {
        onClick: (ev, args) => {
          handleHighlight(ev, loggerData[args.rowIdx].deviceId);
        }
      }
    }
  ];

  React.useEffect(() => {
    if (!highlighted && highlightRef.current) {
      highlightRef.current.classList.remove('highlight');
    }
  }, [highlighted]);

  return (
    <>
      <Heading htmlId={QA_TEST_IDS.device_manager_current_devices_heading} level={3}>
        {t('deviceManager.currentDevices')}
      </Heading>
      <div id={QA_TEST_IDS.device_manager_ap10_status_blurb} className="grey-medium vai-margin-bottom-l">
        {t('deviceManager.ap10StatusBlurb')}
      </div>
      <div ref={ref}>
        {device?.status?.connectedLoggers?.length > 0 ? (
          <DataTable
            dataTa={TEST_IDS.device_manager.current_devices_datatable}
            className="site-table"
            rowGetter={getRows}
            enableCellSelect={false}
            rowsCount={device.status.connectedLoggers.length}
            minColumnWidth={150}
            minHeight={height}
            hasFocusBorder={false}
            columns={AP10StatusColumns}
          />
        ) : (
          <EmptyState
            htmlId={QA_TEST_IDS.device_status_no_hosted_devices}
            heading="deviceManager.noHostedDevices.heading"
            description="deviceManager.noHostedDevices.description"
            illustration={IllustrationType.nothingFound}
          />
        )}
      </div>
    </>
  );
};

export default GatewayStatus;
