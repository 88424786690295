import React from 'react';
import { Flex, FlexItem, Button, ButtonType, Icon, Separator } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { TEST_IDS } from '../../../tests/testids';

interface Props {
  onDelete: () => void;
  canBeDeleted?: boolean;
  label: string;
  constraintText: string;
  deleteButtonDisabled?: boolean;
}

export const DeleteNodeSection: React.FC<Props> = ({
  constraintText,
  label,
  onDelete,
  canBeDeleted,
  deleteButtonDisabled
}) => {
  return (
    <>
      <Separator id="edit-zone-and-location-delete-separator" />
      <Flex id="edit-zone-and-location-delete-flex-container" justifyContent={'space-between'}>
        <FlexItem id="edit-zone-and-location-delete-flex-first" alignSelf="center" flexBasis="90%">
          <Button
            onClick={onDelete}
            disabled={!canBeDeleted || deleteButtonDisabled}
            id="edit-zone-and-location-delete"
            buttonType={ButtonType.Link}
            data-ta={TEST_IDS.site_tree_form_dialog_delete_button}
            startIcon={<Icon name={VaiIcon.Trash} />}
          >
            {label}
          </Button>
        </FlexItem>
        <FlexItem id="edit-zone-and-location-delete-flex-second" alignSelf="center" flexBasis={null}>
          <span
            id="edit-zone-and-location-delete-message"
            className={canBeDeleted ? 'zone-and-location-visibility-hidden' : 'edit-zone-and-location-delete-text'}
          >
            {constraintText}
          </span>
        </FlexItem>
      </Flex>
    </>
  );
};
