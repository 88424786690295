import { IconProps } from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { MeasurementType, SiteChildrenInterface, SITES_SYMBOL_ID, TreeMenuSiteInterface } from '../siteTree/site';
import { SortOptions } from '../components/SiteTree/SiteTree';
import { ALARM_PRIORITY, LOCATION_STATUS } from '../constants';

type Node = {
  node_id: string;
  children?: Node[];
  [key: string]: any;
};
/**
 * Recursively find node with matching node_id from site children.
 * @param arr
 * @param id
 * @returns Single Node
 */
export const findNode = <T extends Node>(arr: T[], id: string) => {
  const result: T | null = arr.reduce((acc, item) => {
    if (acc) return acc;

    // return item if node_id and id matches
    if (item.node_id === id) return item;

    if (item.children) {
      // if no item found and it has children, look through children the children.
      return findNode<T>(item.children as T[], id);
    }
  }, null);

  return result;
};

export const showMeasurementSymbol = (symbol_id: SITES_SYMBOL_ID) => {
  const symbols: Record<SITES_SYMBOL_ID, string> = {
    celsius: '°C',
    fahrenheit: '°F',
    rh: '%RH',
    ppm: 'PPM',
    percent: '%CO₂'
  };
  return symbols[symbol_id] || '';
};

/**
 * Recursive transformation of SiteChildren hierarchial objects to a flattened array of objects.
 * Used for testing and mocking.
 * @param nodes
 * @returns flattened array of nodes
 */
export const getAllNodes = (nodes: SiteChildrenInterface[] | SiteChildrenInterface): SiteChildrenInterface[] => {
  let children = [];

  if (!Array.isArray(nodes)) {
    nodes = [nodes];
  }
  return nodes
    .map(node => {
      if (node.children && node.children.length) {
        children = [...children, ...node.children];
      }
      return node;
    })
    .concat(children.length ? getAllNodes(children) : children);
};

export type AlertType = 'OK' | 'WARN' | 'UNLINK' | 'ALARM'; // will be defined later when we have more info about alerts
type getIconParams = MeasurementType | AlertType | 'treeControl' | LOCATION_STATUS;

export const getIcon = (value: getIconParams): Pick<IconProps, 'name' | 'color'> => {
  const icons = {
    OK: { name: VaiIcon.AlertOk, color: VaiColor.AlertOk },
    WARN: { name: VaiIcon.AlertWarning, color: VaiColor.AlertWarning },
    UNLINK: { name: VaiIcon.Unlink, color: VaiColor.BlueDark },
    [ALARM_PRIORITY.CRITICAL]: { name: VaiIcon.AlertAlarm, color: VaiColor.Red },
    [ALARM_PRIORITY.INFO]: { name: VaiIcon.AlertInfo, color: VaiColor.AlertInfo },
    [ALARM_PRIORITY.MODERATE]: { name: VaiIcon.Warning, color: VaiColor.Orange },
    ALARM: { name: VaiIcon.AlertAlarm, color: VaiColor.AlertAlarm },
    co2: { name: VaiIcon.Dust, color: VaiColor.BlueDark },
    temperature: { name: VaiIcon.Thermometer, color: VaiColor.BlueDark },
    humidity: { name: VaiIcon.Humidity, color: VaiColor.BlueDark },
    treeControl: { name: VaiIcon.TreeControl, color: VaiColor.BlueDark }
  };
  return icons[value];
};

export enum SiteSortValue {
  byDefault = 'byDefault',
  alphabetically = 'alphabetically',
  reverseAlphabetically = 'reverseAlphabetically'
}

export const sortTree = (nodes: TreeMenuSiteInterface[], sortBy: SortOptions) => {
  if (Array.isArray(nodes) && nodes.length > 1) {
    if (sortBy === SiteSortValue.alphabetically) {
      nodes.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });
    }
    if (sortBy === SiteSortValue.reverseAlphabetically) {
      nodes.sort((a, b) => b.text.localeCompare(a.text));
    }
    return nodes;
  }
  return nodes;
};
