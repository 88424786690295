import React, { useEffect } from 'react';
import { SiteInterface } from '../../../siteTree/site';
import { useUpdateGroupRightsMutation, useGetGroupQuery, RightDetails } from '../../../store/services/userApi';
import { RightsDialogView } from './RightsDialogView';
import { addNotification } from '../../../store/notifications';
import { NotificationType } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';

interface Props {
  customerId: string;
  site: SiteInterface;
  groupDetails: { name: string; id: string };
  onDismiss: () => void;
  onSuccess: () => void;
}

export const RightsDialogAPI: React.FC<Props> = ({ customerId, site, onDismiss, groupDetails, onSuccess }) => {
  const [mutate, saveResults] = useUpdateGroupRightsMutation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isFetching, isLoading, currentData } = useGetGroupQuery(
    {
      group_id: groupDetails.id,
      customer_id: customerId,
      include_rights: true
    },
    { refetchOnMountOrArgChange: true }
  );

  const saveGroupRights = ({ rights, rightsGlobal }: RightDetails) => {
    mutate({ customerId, groupId: groupDetails.id, rights, rights_global: rightsGlobal });
  };

  useEffect(() => {
    if (saveResults.isSuccess) {
      onSuccess();
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: t('profile.notifyGroupEdited')
        })
      );
    }
    if (saveResults.isError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: t('profile.notifyEditGroupFailed')
        })
      );
    }
  });

  return (
    <RightsDialogView
      savingDetails={{
        isSaving: saveResults.isLoading,
        isSaved: saveResults.isSuccess,
        isSaveFailed: saveResults.isError
      }}
      groupDetails={groupDetails}
      rightDetails={{
        rights: currentData?.rights || {},
        rightsGlobal: currentData?.rights_global || 0
      }}
      isLoading={isFetching || isLoading || !site || !currentData}
      site={site}
      onDismiss={onDismiss}
      onSave={({ rights, rightsGlobal }) => saveGroupRights({ rights, rightsGlobal })}
    />
  );
};
