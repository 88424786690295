import { useEffect, useState } from 'react';
import { getUserNameForAPI } from '../utils';

const useGetUserName = () => {
  const [username, setUserName] = useState<string>(null);

  useEffect(() => {
    let mounted = true;
    // getUsername on page load for useGetSiteNodesQuery, this should be fetched from store in RTK Query builder instead.
    const getUsername = async () => {
      const username = await getUserNameForAPI();
      if (mounted) {
        setUserName(username);
      }
    };
    getUsername();
    return () => {
      mounted = false;
    };
  }, []);

  return { username };
};
export default useGetUserName;
