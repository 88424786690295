import React from 'react';
import { DeviceListItemModel } from '../../types';
import cx from 'classnames';
import useHtmlId, { IUseHtmlIDProps } from '../../hooks/useHtmlId';
import { TEST_IDS } from '../../tests/testids';
import { Flex, FlexItem, Icon, ListItem, Size } from '@vaisala/rockhopper-components';
import { DEVICE_STATE_ICON_INFO, DEVICE_STATE_TRANSLATION_KEYS } from '../../constants';
import { getDeviceImage, getDeviceOrHostText } from '../../utils';
import { useTranslation } from 'react-i18next';

interface Props extends IUseHtmlIDProps {
  device: DeviceListItemModel;
  onClick: (deviceId: string) => void;
  highlighted?: string;
  routeId?: string;
}

const DeviceManagerListItem: React.FC<Props> = ({ device, routeId, highlighted, onClick, htmlId }) => {
  const { t } = useTranslation();
  const itemRef = React.useRef<HTMLLIElement | null>(null);
  const isHighlighted = device.device_id === highlighted;
  const isDeviceInRoute = device.device_id === routeId;
  const { getId } = useHtmlId({ htmlId });
  const className = cx('vai-padding-vertical-s device-list-item', {
    'user-selected-row': isDeviceInRoute,
    highlight: isHighlighted
  });
  const getIcon = () => {
    const [icon, color] = DEVICE_STATE_ICON_INFO[device.state];
    return icon ? (
      <Icon
        dataTa={TEST_IDS.device_manager_device_list_item_device_status_icon}
        id={getId(`${device.device_id}-status-icon`)}
        name={icon}
        color={color}
        size={Size.XS}
      />
    ) : null;
  };
  const handleClick = () => {
    onClick && onClick(device.device_id);
  };
  React.useEffect(() => {
    if (highlighted === device.device_id) {
      itemRef && itemRef.current && itemRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [highlighted]);
  return (
    <ListItem
      ref={itemRef}
      id={getId(`${device.device_id}-list-item`)}
      dataTa={TEST_IDS.device_manager_device_list_item}
      className={className}
      onClick={handleClick}
    >
      <FlexItem
        id={getId(`${device.device_id}-device-icon`)}
        dataTa={TEST_IDS.device_manager_device_list_item_img_wrapper}
        flexBasis="40px"
        className="device-icon"
      >
        <img
          id={getId(`${device.device_id}-img`)}
          data-ta={TEST_IDS.device_manager_device_list_item_img}
          src={getDeviceImage(device.product_code)}
          className="icon-width"
        />
      </FlexItem>
      <FlexItem
        id={getId(`${device.device_id}-device-details`)}
        flexBasis="70%"
        alignSelf="center"
        className="vai-margin-left-m"
      >
        <h4
          id={getId(`${device.device_id}-device-nickname`)}
          data-ta={TEST_IDS.device_manager_device_list_item_nickname}
          className="vai-margin-vertical-none"
        >
          {device.device_id}
        </h4>
        <div
          id={getId(`${device.device_id}-device-or-host-text`)}
          data-ta={TEST_IDS.device_manager_device_list_item_device_or_host_text}
          className="vai-font-size-xs grey-medium"
        >
          {getDeviceOrHostText(device, t)}
        </div>
      </FlexItem>
      <Flex className="vai-margin-horizontal-s device-status grey-medium" justifyContent="flex-end">
        <FlexItem
          id={getId(`${device.device_id}-status-label`)}
          dataTa={TEST_IDS.device_manager_device_list_item_status_label}
          className="vai-margin-right-s align-right"
          alignSelf="center"
          flexBasis="95%"
        >
          {t(DEVICE_STATE_TRANSLATION_KEYS[device.state])}
        </FlexItem>
        <FlexItem
          dataTa={TEST_IDS.device_manager_device_list_item_device_status_icon_wrapper}
          id={getId(`${device.device_id}-status-icon-wrapper`)}
          className="device-icon"
          alignSelf="center"
        >
          {getIcon()}
        </FlexItem>
      </Flex>
    </ListItem>
  );
};

export default DeviceManagerListItem;
