import React, { PropsWithChildren, useState } from 'react';
import { Accordion, AccordionProps, Button, ButtonType, Flex } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { FallbackContent } from './FallbackContent';
import AlarmSettingsDialog from '../AlarmSettingsDialog/AlarmSettingsDialog';
import { TranslationKey } from '../../../../react-i18next';

interface Props extends AccordionProps {
  title: JSX.Element;
  editContent: JSX.Element;
  isModalOpen?: boolean;
  openModal: () => void;
  closeModal: () => void;
  fallbackDetails: { isLoading: boolean; noData: boolean; accessRestricted: boolean; retry: () => void };
  editButtonId: string;
  modalTitle: TranslationKey;
}

export function AlarmSettingsAccordion({
  title,
  children,
  editContent,
  isModalOpen,
  openModal,
  closeModal,
  fallbackDetails,
  editButtonId,
  modalTitle,
  ...rest
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const showFallbackContent = fallbackDetails.noData || fallbackDetails.isLoading || fallbackDetails.accessRestricted;

  return (
    <Accordion
      className="threshold-accordion"
      onChangeAccordionExpanded={isExpanded => {
        setIsExpanded(isExpanded);
      }}
      isExpanded={isExpanded}
      title={
        <Flex>
          <Flex.Item flexGrow={1}>{title}</Flex.Item>
          <Flex.Item>
            <Button
              onClick={event => {
                event.stopPropagation();
                openModal();
              }}
              buttonType={ButtonType.Link}
              id={editButtonId}
            >
              {t('general.edit')}
            </Button>
          </Flex.Item>
        </Flex>
      }
      {...rest}
    >
      {children}
      {isModalOpen && (
        <AlarmSettingsDialog
          title={modalTitle}
          isOpen={isModalOpen}
          closeModal={() => closeModal()}
          fallbackContent={
            showFallbackContent && (
              <FallbackContent
                isAccessRestricted={fallbackDetails.accessRestricted}
                unexpectedErrorProps={{ retry: fallbackDetails.retry }}
                isFetching={fallbackDetails.isLoading}
              />
            )
          }
        >
          {editContent}
        </AlarmSettingsDialog>
      )}
    </Accordion>
  );
}
