import { getUnixTime } from 'date-fns';
import {
  LocationCustomProps,
  LocationTreeFormattedNode,
  Threshold_Setting,
  Threshold_Values
} from '../components/Reports/Locations';
import { isEmptyObject, roundHalfToEven } from './common';
import {
  DownloadJsonResponseLazyQuery,
  GetMeasurementLazyQuery,
  GetMeasurementsResponse
} from '../store/services/reportsApi';

/**
 * Recursively find node with matching node_id from formatted location tree.
 * @param nodes Dictionary of tree nodes
 * @param id The tree node to find
 * @returns Single Node; or null
 */
export const findTreeNode = (
  nodes: { [key: string]: LocationTreeFormattedNode },
  nodeId: string
): LocationTreeFormattedNode | null => {
  if (nodes) {
    for (const key in nodes) {
      // iterate through sibling nodes
      if (key == nodeId) {
        // found the node
        return nodes[key];
      } else if (nodes[key].children) {
        // look for a match in the child nodes
        const match = findTreeNode(nodes[key].children, nodeId);
        // return node if found; otherwise, continue through siblings
        if (match) return match;
      }
    }
  }
  return null;
};

export const processData = (data, prevData, id): LocationCustomProps => {
  const selectedLocationsClone = JSON.parse(JSON.stringify(prevData));
  let measurements = [],
    minThreshold = Infinity,
    maxThreshold = -Infinity;
  const threshold: Threshold_Values = {} as Threshold_Values;
  let selectedNode: LocationTreeFormattedNode = null;
  if (data && data.items) {
    data.items.forEach(item => {
      measurements = measurements.concat(
        [...item.measurements].map(meas => {
          if (item.threshold_rules && !isEmptyObject(item.threshold_rules)) {
            meas = { ...meas, threshold_rules: { ...item.threshold_rules } };
          }
          return meas;
        })
      );

      if (item.threshold_rules && !isEmptyObject(item.threshold_rules)) {
        Object.values(item.threshold_rules).forEach((setting: Threshold_Setting) => {
          if (setting.enabled && setting.value < minThreshold) {
            minThreshold = setting.value;
          }

          if (setting.enabled && setting.value > maxThreshold) {
            maxThreshold = setting.value;
          }
        });
      }
    });

    measurements.sort((a, b) => a.t - b.t);

    threshold.min = minThreshold;
    threshold.max = maxThreshold;

    selectedNode = selectedLocationsClone[id];

    // Reset measurement points for each new cycle
    selectedNode.measurement_points = [];
    selectedNode.threshold_values = null;
    selectedNode.minimum = selectedNode.maximum = selectedNode.average = selectedNode.most_recent = null;

    selectedNode.measurement_points = measurements;
    selectedNode.threshold_values = threshold;

    // Statistics
    const decimals = selectedNode.decimal_places;

    selectedNode.average = data.average != null ? roundHalfToEven(data.average, decimals).toString() : null;
    selectedNode.minimum = data.min != null ? roundHalfToEven(data.min, decimals).toString() : null;
    selectedNode.maximum = data.max != null ? roundHalfToEven(data.max, decimals).toString() : null;
    selectedNode.most_recent = data.recent != null ? roundHalfToEven(data.recent, decimals).toString() : null;
  }
  return selectedLocationsClone;
};

export const doGetMeasurementsQuery = async (
  getMeasurements: GetMeasurementLazyQuery,
  downloadJsonData: DownloadJsonResponseLazyQuery,
  params: { customerId: string; from: Date; to: Date; locationId: string; symbolId: any },
  requests: Record<string, any> = {}
): Promise<GetMeasurementsResponse | null> => {
  const { locationId: id, symbolId, customerId, from, to } = params;
  requests[id] = getMeasurements({
    customerId,
    params: {
      from: getUnixTime(from),
      to: getUnixTime(to),
      location_id: id,
      symbol_id: symbolId
    }
  });

  let data;
  try {
    data = await requests[id].unwrap();
    requests && delete requests[id];
  } catch (e) {
    console.error('getting getMeasurements results', { request: requests[id], error: e.response ?? e });
    return null;
  }

  if (data && data.url) {
    try {
      const { url } = data;
      requests[url] = downloadJsonData({ url });
      data = await requests[url].unwrap();

      delete requests[url];
    } catch (e) {
      console.error('getting getMeasurements results from url', { request: requests[id], error: e.response ?? e });
      return null;
    }
  }
  return data;
};
