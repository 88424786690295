import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { LocationNode, SiteChildrenInterface, SiteInterface } from '../siteTree/site';

export interface SiteTreeState {
  selectedNodeId: string | null;
  selectedNode: SiteInterface | SiteChildrenInterface | LocationNode;
  lastRemoved: string;
  currentlyRemoving: string;
  expanded: string[];
  tabError?: any;
  showInactiveNodes: boolean;
}

export const initialState = {
  selectedNodeId: null,
  selectedNode: null,
  lastRemoved: null,
  currentlyRemoving: null,
  showInactiveNodes: null
} as SiteTreeState;

export const siteTreeSlice = createSlice({
  name: 'SiteTree',
  initialState,
  reducers: {
    setSelectedNodeId(state, action) {
      state.selectedNodeId = action.payload;
    },
    setSelectedNode(state, action) {
      state.selectedNode = action.payload;
    },
    setCurrentlyRemoving(state, action) {
      state.currentlyRemoving = action.payload;
    },
    setLastRemoved(state, action) {
      state.lastRemoved = action.payload;
    },
    setTabError(state, action) {
      state.tabError = action.payload;
    },
    setShowInactiveNodes(state, action) {
      state.showInactiveNodes = action.payload;
    }
  }
});

export const selectShowInactiveNodes = (state: RootState) => state.siteTree.showInactiveNodes;
export const selectTabError = (state: RootState) => state.siteTree.tabError;
export const selectSelectedNodeId = (state: RootState) => state.siteTree.selectedNodeId;
export const selectLastRemoved = (state: RootState) => state.siteTree.lastRemoved;
export const selectCurrentlyRemoving = (state: RootState) => state.siteTree.currentlyRemoving;
export const selectSelectedNode = (state: RootState) => state.siteTree.selectedNode;
export const {
  setSelectedNodeId,
  setLastRemoved,
  setCurrentlyRemoving,
  setTabError,
  setShowInactiveNodes,
  setSelectedNode
} = siteTreeSlice.actions;

export default siteTreeSlice.reducer;
