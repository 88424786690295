import qs from 'qs';
import * as ActionType from './actionTypes';
import { Api, Apis, EventApiUris } from '../../utils/Api';
import { GetEventsProp, schemaEventInterface } from '../../events/events';
import { getConstantFromLocalStorage } from '../../utils/common';
import { LS_COMPANY_CUSTOMER_ID } from '../../constants';
import morphism from 'morphism';
import { reportError } from '../../utils/errorHandler';

export enum EventError {
  'networkError',
  'customerIdError'
}

const getEvents = (query: GetEventsProp) => async (dispatch, getState) => {
  dispatch({ type: ActionType.GET_EVENT_LIST_START });
  const state = getState();
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = EventApiUris.eventList.replace('{customer_id}', customerId) + `?${qs.stringify(query)}`;
      const response = await Api.get(Apis.events, uri);
      const body = await response.json();

      const events = morphism(schemaEventInterface, body.events);
      const isNewQuery = query.start === 0;
      // Reset the events in the store if the start value pasesd is 0 or events is passed a falsy value.
      const currEvents = isNewQuery || !state.events.events ? [] : state.events.events;
      const totalCount: number = body.found;
      dispatch({ type: ActionType.GET_EVENT_LIST_SUCCESS, events: currEvents.concat(events), start: query.start });
      dispatch({ type: ActionType.GET_EVENT_LIST_TOTAL_COUNT, totalCount });
    } else {
      dispatch({ type: ActionType.GET_EVENT_LIST_FAIL, error: EventError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error getting events:');
    dispatch({
      type: ActionType.GET_EVENT_LIST_FAIL,
      error: EventError.networkError
    });
  }
};

export const eventsDispatchActions = { getEvents };
