import React from 'react';
import { useTranslation } from 'react-i18next';
import { Size, Grid, Icon } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../../../tests/testids';
import { getIcon } from '../../../../../utils/site';
import { ThresholdLevelsForm } from './ThresholdLevelsForm';
import { ThresholdNotificationsForm } from './ThresholdNotificationsForm';
import { Steps } from '../../Steps';
import { ThresholdModalProvider } from '../ThresholdModalProvider';
import { LocationNode } from '../../../../../siteTree/site';
import { ThresholdAlarmSettings } from '../../../../../store/services/alarmsApi';
import { useAlarmSettingsDialogContext } from '../../../Alarms/AlarmSettingsDialog/AlarmSettingsDialog';
import { Group } from '../../../../../store/services/userApi';

interface Props {
  location: LocationNode;
  onSave: (settings: ThresholdAlarmSettings) => void;
  settingsDetails: { settings: ThresholdAlarmSettings; isFetching: boolean; refetch: () => void };
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
  isSaving?: boolean;
}

export const AlarmThresholdDialogContent: React.FC<Props> = ({
  location,
  notificationGroupsDetails,
  onSave,
  settingsDetails,
  isSaving
}) => {
  const { t } = useTranslation();
  const { showWarning } = useAlarmSettingsDialogContext();
  const stepNumberTitles = {
    1: t('site.alarms.settings.threshold.form.step1.label'),
    2: t('site.alarms.settings.threshold.form.step2.label')
  };

  if (!settingsDetails.settings) {
    return null;
  }

  return (
    <ThresholdModalProvider
      notificationGroupsDetails={notificationGroupsDetails}
      initialSettings={settingsDetails.settings}
      onSave={onSave}
      location={location}
      isSaving={isSaving}
    >
      <div data-ta={TEST_IDS.alarm_settings_threshold_modal}>
        <Steps>
          <Steps.Display>
            {({ currentStepNumber, stepsCount }) => (
              <Grid>
                <Grid.Row className="display-row">
                  <Grid.Col md={2} lg={2} xl={2}>
                    <h3>{t('general.location')}</h3>
                  </Grid.Col>
                  <Grid.Col md={10} lg={10} xl={10}>
                    <h3 className="alarm-threshold-dialog-location-name">
                      <Icon size={Size.M} name={getIcon(location.meas_id).name} /> {location.name}
                    </h3>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="display-row">
                  <Grid.Col md={2} lg={2} xl={2}>
                    <h3>
                      {t('site.alarms.settings.threshold.steps.display', {
                        stepNumber: currentStepNumber,
                        stepsCount: stepsCount
                      })}
                    </h3>
                  </Grid.Col>
                  <Grid.Col md={10} lg={10} xl={10}>
                    <h3 data-ta={TEST_IDS.alarm_threshold_step_count_message}>{stepNumberTitles[currentStepNumber]}</h3>
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            )}
          </Steps.Display>
          <Steps.Step>
            <ThresholdLevelsForm />
          </Steps.Step>
          <Steps.Step>
            <ThresholdNotificationsForm onCancel={showWarning} />
          </Steps.Step>
        </Steps>
      </div>
    </ThresholdModalProvider>
  );
};
