import * as React from 'react';
import { Auth } from 'aws-amplify';
import validator from 'validator';
import {
  InputField,
  Size,
  Button,
  ButtonType,
  Icon,
  Flex,
  FlexItem,
  Table,
  TableStyle,
  TableBody,
  TableRow,
  TableCell,
  Checkbox
} from '@vaisala/rockhopper-components';
import './add-group-members.scss';
import { InvitedUsersInterface, GroupInterface, UserInterface } from '../../../profile/user';
import { INVITE_USER_FROM_GROUP } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { ModalContainer } from '../../Modals/ModalContainer';
import useEmails from '../../../hooks/useEmails';
import Infotip from '../../Utils/Infotip';
import { TEST_IDS } from '../../../tests/testids';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

interface AddGroupMembersDialogProps {
  users: UserInterface[];
  groupDetails: GroupInterface;
  inviteUsers: (users: InvitedUsersInterface, dispatcher: string, name?: string) => string[];
}

const AddGroupMembersDialog = (props: AddGroupMembersDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchCriteria, setSearchCriteria] = React.useState('');
  const [foundUsers, setFoundUsers] = React.useState([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const { validEmails, renderTagInput } = useEmails();
  const [userSelected, isUserSelected] = React.useState(false);
  const onDismiss = () => dispatch(hideDialog());

  const initializeUsers = () => {
    const temp = [];
    const existingusers = [];
    props.groupDetails.users.forEach(user => {
      existingusers.push(user.email);
    });
    props.users.forEach(user => {
      if (!existingusers.includes(user.email)) {
        temp.push({ ...user, checked: false });
      }
    });
    setFoundUsers(temp);
  };

  React.useEffect(() => {
    initializeUsers();
  }, [props.users]);

  const filter = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    let keyword = e.currentTarget.value;
    keyword = validator.ltrim(keyword);
    if (keyword !== '') {
      const results = foundUsers.filter(user => {
        if (user.first_name || user.last_name) {
          return (user.first_name + ' ' + user.last_name).toLowerCase().includes(keyword.toLowerCase());
        } else {
          return user.email.toLowerCase().includes(keyword.toLowerCase());
        }
      });
      setFoundUsers(results);
    } else {
      initializeUsers();
      // If the text field is empty, show all users
    }
    setSearchCriteria(keyword);
  };

  const checkBoxChangeHandler = (index: number) => {
    const temp = [...foundUsers];
    temp[index].checked = !temp[index].checked;
    setFoundUsers(temp);
    isUserSelected(false);
    temp.forEach(user => {
      if (user.checked) {
        isUserSelected(true);
        return;
      }
    });
  };

  const inviteUsers = async () => {
    setSaving(true);
    const invitedUsers = [];
    foundUsers.forEach(user => {
      if (user.checked) {
        const invitedUser = {
          user_name: user.email,
          locale: user.locale,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          group_ids: [props.groupDetails.group_id]
        };
        invitedUsers.push(invitedUser);
      }
    });
    // TODO: This should be passed down from the redux store or localStorage.
    const currentCredentials = await Auth.currentSession();
    const userLocale = currentCredentials.getIdToken().payload.locale;
    validEmails.forEach(email => {
      const invitedUser = {
        user_name: email,
        locale: userLocale,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        group_ids: [props.groupDetails.group_id]
      };
      invitedUsers.push(invitedUser);
    });
    const users = {
      users: invitedUsers
    };
    await props.inviteUsers(users, INVITE_USER_FROM_GROUP);
    // TODO: MAYBE. This call could return a list of rejected users. Should this be handled
    //  with UserRejectionDialog. The only issue is that we don't know if it was an existing
    //  user who would have their own local set, or whether we should just naively use the
    //  locale defined by the current user to send out the request.
    onDismiss();
  };

  let userListContent;
  if (foundUsers.length > 0) {
    userListContent = foundUsers.map((row, index) => (
      <TableRow
        dataTa={TEST_IDS.groups_add_group_member_dialog_users_list_item}
        htmlId={`add-group-members-row-${index}`}
        key={index}
        className={!row.active ? 'deactivate-row-background' : ''}
      >
        <TableCell id={`add-group-members-checkbox-${index}`} className="add-group-members-checkbox aqua-medium">
          <Checkbox
            id="add-group-members"
            checked={row.checked}
            label=""
            onChange={() => checkBoxChangeHandler(index)}
          />
        </TableCell>
        <TableCell
          id={`add-group-members-profile-picture-cell-${index}`}
          className={!row.active ? 'user-and-group-row-deactive' : ''}
        >
          <Flex>
            <FlexItem alignSelf={'flex-start'} flexBasis={'10%'}>
              {row.picture ? (
                <img
                  id={`add-group-members-profile-picture-${index}`}
                  src={row.picture}
                  className="user-profile-picture-icon"
                />
              ) : (
                <Icon id={`add-group-members-profile-icon-${index}`} name={VaiIcon.User} />
              )}
            </FlexItem>
            <FlexItem
              id={`add-group-members-profile-name-${index}`}
              className="vai-margin-bottom-s"
              alignSelf={'flex-end'}
              flexBasis={'40%'}
            >
              {row.first_name || row.last_name ? (row.first_name + ' ' + row.last_name).trim() : row.email.trim()}
              {!row.active ? <em>{` - ${t('profile.inactive')}`}</em> : ''}
            </FlexItem>
          </Flex>
        </TableCell>
      </TableRow>
    ));
  } else {
    userListContent = (
      <div id="add-group-members-empty-message" className="add-group-members-empty-list">
        {t('addGroupMembers.noAvailableUsers')}
      </div>
    );
  }
  const ModalButtons = (
    <>
      <Button
        id="add-group-members-invite-button"
        className="vai-space-inline-s"
        htmlId="add-group-members-invite-button"
        onClick={inviteUsers}
        disabled={(!userSelected && validEmails.length === 0) || saving}
        dataTa={TEST_IDS.groups_add_group_member_dialog_save_button}
      >
        {t('addGroupMembers.saveUsers')}
      </Button>
      <Button
        id="add-group-members-cancel-button"
        htmlId="add-group-members-cancel-button"
        buttonType={ButtonType.Secondary}
        onClick={onDismiss}
        dataTa={TEST_IDS.groups_add_group_member_dialog_cancel_button}
      >
        {t('inviteUsers.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="addgroup-members-dialog"
      isOpen
      htmlId="addgroup-members-dialog"
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.M}
      dismissOnOverlayClick={false}
      title="addGroupMembers.addGroupMembersTitle"
      iconName={VaiIcon.UserGroup}
      // role="alert-dialog"
      buttons={ModalButtons}
      dataTa={TEST_IDS.groups_add_group_member_dialog}
    >
      <div
        id="add-group-members-email-invite-container"
        className="add-group-members-field-container add-group-members-email-invite-container"
      >
        <span id="add-group-members-email-invite-container" className="add-group-members-add-to-msg">
          {t('addGroupMembers.addTo')}
        </span>{' '}
        <span
          data-ta={TEST_IDS.groups_add_group_member_dialog_group_name}
          id="add-group-members-group-name"
          className="add-group-members-add-to-msg add-group-members-group-name"
        >
          {props.groupDetails.group_name}
        </span>
        <Flex alignItems="flex-start" className="vai-margin-top-m">
          <FlexItem flexGrow={1} className="vai-margin-right-xs">
            {renderTagInput({
              htmlId: 'add-group-members-add-email-input',
              placeholder: t('addGroupMembers.enterEmail')
            })}
          </FlexItem>
          <FlexItem>
            <Infotip htmlId="add-group-members-email-infotip" iconProps={{ size: Size.L }}>
              {t('infotip.emailInput')}
            </Infotip>
          </FlexItem>
        </Flex>
      </div>
      <div id="add-group-members-field-search-container" className="add-group-members-field-container vai-margin-top-m">
        <span id="add-group-members-select-existing-user-msg" className="add-group-members-select-existinguser-msg">
          {t('addGroupMembers.selectExistingUserMsg')}
        </span>
        <InputField
          htmlId="add-group-members-search-groups-input"
          name="search"
          width={Size.Container}
          placeholder={t('inviteUsers.searchFieldPlaceholder')}
          value={searchCriteria}
          onChange={filter}
          data-ta={TEST_IDS.groups_add_group_member_dialog_search_field}
          endIcon={<Icon name={VaiIcon.Search} size={Size.M} />}
        />
      </div>
      <br />
      <div id="add-group-members-users-container" className="add-group-members-users-container">
        <Table id="add-group-members-users-list-table" tableStyle={TableStyle.Plain} className="user-and-group-table">
          <TableBody htmlId="add-group-members-users-list-table-body">{userListContent}</TableBody>
        </Table>
      </div>
    </ModalContainer>
  );
};

export default AddGroupMembersDialog;
