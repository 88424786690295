import React from 'react';
import { ThresholdAlarmSettings } from '../../../../../store/services/alarmsApi';
import { Heading } from '@vaisala/rockhopper-components';
import { DisplayRow } from '../../../Alarms/shared/DisplayRow';
import { ALARM_THRESHOLD_TYPE } from '../../../../../constants';
import { Group } from '../../../../../store/services/userApi';
import { SITES_SYMBOL_ID } from '../../../../../siteTree/site';
import { AlarmSettingsDisplayContainer } from '../../../Alarms/shared/AlarmSettingsDisplayContainer';
import { AlarmDisplay } from '../../../Alarms/shared/AlarmDisplay';
import { showMeasurementSymbol } from '../../../../../utils/site';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../../../react-i18next';

export interface Props {
  settingsDetails: { settings: ThresholdAlarmSettings | undefined; isFetching: boolean };
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
  measSymbolId: SITES_SYMBOL_ID;
}

export const VALUE_HEADER_TRANSLATION_KEY: Record<ALARM_THRESHOLD_TYPE, TranslationKey> = {
  [ALARM_THRESHOLD_TYPE.HIHI]: 'site.alarms.threshold.level.display.value.header.HighHigh',
  [ALARM_THRESHOLD_TYPE.HI]: 'site.alarms.threshold.level.display.value.header.High',
  [ALARM_THRESHOLD_TYPE.LO]: 'site.alarms.threshold.level.display.value.header.Low',
  [ALARM_THRESHOLD_TYPE.LOLO]: 'site.alarms.threshold.level.display.value.header.LowLow'
};

export const ThresholdAlarmSettingsDisplay: React.FC<Props> = ({
  settingsDetails,
  notificationGroupsDetails,
  measSymbolId
}) => {
  const { settings, isFetching } = settingsDetails;
  const { groups } = notificationGroupsDetails;
  const { t } = useTranslation();

  const groupDetails =
    groups?.map(group => {
      return { name: group.group_name, numberOfUsers: group.users.length, id: group.group_id };
    }) || [];

  const getLevelGroupDetails = (list: string[] | undefined) => {
    return list ? groupDetails.filter(group => list.includes(group.id)) : [];
  };

  const isLoading = !settings || isFetching || notificationGroupsDetails.isFetching;

  return (
    <AlarmSettingsDisplayContainer isLoading={isLoading}>
      {settings &&
        Object.keys(ALARM_THRESHOLD_TYPE).map((level: ALARM_THRESHOLD_TYPE) => {
          return (
            <DisplayRow
              key={level}
              alarmValueContent={
                <>
                  <Heading className="alarm-level-heading" level={4}>
                    {t(VALUE_HEADER_TRANSLATION_KEY[level])}
                  </Heading>
                  {settings.template.rules[level].enabled ? (
                    <AlarmDisplay
                      label={`${settings.template.rules[level].value} ${showMeasurementSymbol(measSymbolId)}`}
                      priority={settings.template.rules[level].priority}
                      threshold={level}
                    />
                  ) : (
                    t('general.alarmDisabled')
                  )}
                </>
              }
              groupsDetails={getLevelGroupDetails(settings.settings.notifications[level].EMAIL)}
              level={level}
            />
          );
        })}
    </AlarmSettingsDisplayContainer>
  );
};
