import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROOT_LEVEL_NAME } from '../../../constants';
import { Languages } from '../../../i18n';
import { TEST_IDS } from '../../../tests/testids';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';

const LOWERED_EN = Languages.en.toLowerCase();

type SidebarProps = {
  language?: Languages;
};

interface HTML {
  default: string;
}

const Help: React.FC<SidebarProps> = ({ language = Languages.en }) => {
  const [currentExtension, setCurrentExtension] = React.useState<string | null | undefined>('');
  const [content, setContent] = React.useState<HTML | null>(null);
  const location = useLocation();

  /**
   * Load the content for that language and area, falling back to en-us if the translated version is not found.
   * @param language
   * @param area
   * @param path
   *  */

  const loadContent = (language: Languages, area: string, path: string) => {
    const loweredLanguage = language?.toLowerCase() || LOWERED_EN;

    // NOTE: After some deliberation with the docs team, it was decided to split sidebar docs into a language folder, then have
    // a single folder house the files. The tool that is being used, uses this structure to separate the documentation into something
    // indexable when it gets generated. Also, the decision was to use home.html as the base page instead of the standard index.html
    // commonly used. This is janky and a rudimentary implementation that will quickly turn to spaghetti.
    const fullExtension = `${loweredLanguage}/${area}_${path || ROOT_LEVEL_NAME}.html`;
    const homeExtension = `${loweredLanguage}/${area}_${ROOT_LEVEL_NAME}.html`;
    if (fullExtension === currentExtension || homeExtension === currentExtension) {
      return;
    }
    let newContent: HTML | null = null;
    let currExtension: string | undefined | null;
    const routesToTry = Array.from(
      new Set([
        fullExtension,
        homeExtension,
        fullExtension.replace(`${loweredLanguage}/`, `${LOWERED_EN}/`),
        homeExtension.replace(`${loweredLanguage}/`, `${LOWERED_EN}/`)
      ])
    );
    for (const route of routesToTry) {
      try {
        currExtension = route;
        // !!HACK: Instead of using craco to modify the compile-time config, using the html-loader inline. Ideally, we would
        // add the html-loader to the webpack config and avoid this nonsense, but configuring using craco breaks the guarantees
        // that CRA provides and I don't recommend that approach.
        // The prefix for the sidebar folder must be hardcoded or webpack will not add sidebar content to the build and the import will fail.
        newContent = require('html-loader!../../../docs/sidebar/' + route);
        break;
      } catch (error) {
        // Do nothing. This is part of the logic, if the module cannot be found it will fallback to the home.html version if it exists.
        // This allows a single file to be relevant for multiple tabs in an area. eg. reports uses this. It will also fall through when
        // an alternative (eg. other than english) language folder is not present.
      }
    }
    if (!newContent) {
      // We tried our best. There must be no page available for this route.
      currExtension = null;
      console.warn('No sidebar content for this page');
    } else {
      const parsedHTML: Document = new DOMParser().parseFromString(newContent.default, 'text/html');
      newContent = { default: parsedHTML.body.innerHTML };
    }
    setContent(newContent);
    setCurrentExtension(currExtension);
  };

  React.useEffect(() => {
    const [, area, path] = location.pathname.split('/');
    loadContent(language as Languages, area, path);
  }, [location.pathname, language]);

  return (
    <>
      {content?.default ? (
        <div
          className="vai-margin-m inner vai-padding-m"
          data-ta={TEST_IDS.sidebar_help_content_wrapper}
          dangerouslySetInnerHTML={{ __html: content.default }}
        />
      ) : (
        <div className="vai-margin-m inner" data-ta={TEST_IDS.sidebar_help_empty}>
          {!content && <EmptyState heading="noResults" illustration="nothingFound" />}
        </div>
      )}
      {/** This is merely to use to confirm which file is loaded in the test suite. */}
      <div style={{ display: 'none' }} data-ta={TEST_IDS.sidebar_current_route}>
        {currentExtension}
      </div>
    </>
  );
};

export default Help;
