import React from 'react';
import useHtmlId, { IUseHtmlIDProps } from '../hooks/useHtmlId';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LS_COMPANY_CUSTOMER_ID, PARENT_SITE_KEY } from '../constants';
import { useGetDevicePerSiteQuery } from '../store/services/devicesApi';
import { getConstantFromLocalStorage, routes } from '../utils';
import { IllustrationType } from '../components/Utils/EmptyState/Illustration';
import CenteredSpinner from '../components/BaseComponents/CenteredSpinner';
import { EmptyState } from '../components/Utils/EmptyState/EmptyState';
import { QA_TEST_IDS } from '../tests/qaTestIds';
import { TEST_IDS } from '../tests/testids';
import { Button } from '@vaisala/rockhopper-components';

interface ComponentProps {
  hasDevices: boolean;
  onHighlight?: (value: any) => boolean;
}

interface Props {
  displayName?: string;
  route?: string;
}

interface BackendError {
  // TODO: better typing here maybe. The status codes tell us what we need to know for this HOC.
  data?: { message: string; name: string };
  status: 404 | 500 | 403 | 400;
}

const withDevice = ({
  htmlId,
  separator,
  route = routes.deviceManager.properties.url,
  displayName = 'Component'
}: IUseHtmlIDProps & Props) => (Component: any) => {
  const HOC = (props: ComponentProps): React.ReactElement<any, any> => {
    const { hasDevices } = props;
    const { getId } = useHtmlId({ htmlId, separator });
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation();
    // TODO: This will either need lifted out of here or pulled out of a se
    const siteId = PARENT_SITE_KEY;
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    const { data: device, isLoading, isFetching, error, isError, refetch } = useGetDevicePerSiteQuery(
      {
        customerId,
        siteId,
        deviceId: id
      },
      // NOTE: don't call the single device endpoint if we there is no ID in the URL OR there are no devices in the system for this tenant.
      { skip: id == null || !hasDevices, refetchOnFocus: false }
    );
    const componentProps = { ...props, device, getId };
    React.useEffect(() => {
      const backendError = error as BackendError;
      if (backendError?.status === 404) {
        // Go to the list endpoint if 404
        navigate(`${routes.deviceManager.url}/${route}`, { replace: true });
      }
    }, [isError, error]);

    if (!hasDevices) {
      return (
        <EmptyState
          htmlId="device-manager-empty-state"
          heading="deviceManager.noDevices.heading"
          illustration={IllustrationType.emptyFolder}
        />
      );
    }

    if (isLoading || isFetching || (!device && !isError)) {
      return <CenteredSpinner htmlId={QA_TEST_IDS.device_manager_tab_spinner} />;
    }

    const handleRefresh = () => refetch();
    const refreshId = QA_TEST_IDS.device_manager_refresh_device;
    if (isError) {
      return (
        <EmptyState
          htmlId={QA_TEST_IDS.device_manager_fetch_error}
          heading="general.somethingWentWrong"
          illustration={IllustrationType.genericError}
        >
          <Button id={refreshId} dataTa={TEST_IDS.device_manager_refresh_device} onClick={handleRefresh}>
            {t('general.refresh')}
          </Button>
        </EmptyState>
      );
    }
    return <Component {...componentProps} />;
  };
  HOC.displayName = `withDevice(${displayName})`;
  return HOC;
};

export default withDevice;
