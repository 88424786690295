import { Flex, Icon, Size } from '@vaisala/rockhopper-components';
import React, { MouseEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../react-i18next';
import { AlarmSetState, LocationNode, MeasurementType } from '../../siteTree/site';
import { DataSourceListItemModel } from '../../types';
import { areThresholdAlarmsNotSet, buildDeviceId, roundHalfToEven, timestampToTimeString } from '../../utils';
import { AlertType, getIcon, showMeasurementSymbol } from '../../utils/site';
import i18n from '../../i18n';
import { TEST_IDS } from '../../tests/testids';
import {
  ALARM_PRIORITY,
  DEFAULT_NO_MEASUREMENT,
  DEVICE_SYMBOL_AS_UNIT_ID,
  DEVICE_SYMBOL_ID_TO_UNIT_ID,
  DeviceSymbolUnitIds,
  LOCATION_STATUS
} from '../../constants';
import cx from 'classnames';
import { AlarmPriorityIcons, AlarmThresholdIcons } from './Alarms/shared/AlarmIcons';
import { DeviceAlarmRuleIcons } from './Setting/DeviceAlarmAccordion/shared';
import { AlarmSubtype } from './Setting/ThresholdAlarmAccordion/shared';
import Timesince from '../Utils/Timesince';
import { AlarmType } from '../../store/services/alarmsApi';
import { Link } from 'react-router-dom';

export type FormattedSiteTableData = {
  status: LOCATION_STATUS;
  type: MeasurementType;
  location: string;
  key: string;
  node_id: string;
  value: IValueWithUnit;
  time: string;
  device: DataSourceListItemModel | TranslationKey;
  thres_alarm_set: AlarmSetState;
  device_alarm_set: AlarmSetState;
  action: string;
};

/**
 * Prepares data format for DataTable component.
 * @param data
 * @returns array of objects for DataTable
 */
export const formatDataForTable = (data: LocationNode[], showInactiveLocations: boolean): FormattedSiteTableData[] => {
  const tableData = data.map(item => {
    return {
      active: item.active,
      status: item.status,
      type: item.meas_id, // measurement type
      location: `${item.name}${!item.active ? ' - ' + i18n.t('site.inactive') : ''}`,
      key: item.node_id,
      value: {
        roundedValue: item.raw_value ? roundHalfToEven(item.raw_value, item.decimal_places || 0) : NaN,
        unit: DEVICE_SYMBOL_ID_TO_UNIT_ID[item.symbol_id],
        isStale: item.is_stale
      },
      is_stale: item.is_stale,
      node_id: item.node_id,
      time: item.timestamp && timestampToTimeString(item.timestamp),
      device: item.device ? item.device : ('site.settingsLocationNoDataSource' as TranslationKey),
      thres_alarm_set: item.thres_alarm_set,
      device_alarm_set: item.device_alarm_set,
      action: 'treeControl'
    };
  });
  const filteredTableData = showInactiveLocations ? tableData : tableData.filter(item => item.active);
  return filteredTableData as FormattedSiteTableData[];
};

export const LocationFormatter = ({
  value,
  id,
  selectNode
}: {
  value: string;
  id?: string;
  selectNode: (node: string, path?: string) => void;
}): ReactElement => {
  const { t } = useTranslation();
  const inactiveText = t('site.inactive');
  const isInactive = value.includes(` - ${inactiveText}`);
  /* eslint-disable enforce-ids-in-jsx/missing-ids */
  return (
    <div
      className="cursor-pointer location-cell"
      onClick={() => selectNode(id)}
      title={value}
      data-inactive={isInactive}
    >
      <Link data-ta={!isInactive && TEST_IDS.active_row_location_text} to={id}>
        {isInactive ? (
          <i data-inactive data-ta={TEST_IDS.inactive_row_location_text}>
            {value}
          </i>
        ) : (
          value
        )}
      </Link>
    </div>
  );
  /* eslint-enable enforce-ids-in-jsx/missing-ids */
};

interface IconFormatterProps {
  value: AlertType | MeasurementType | 'treeControl';
}

export const IconFormatter: React.FC<IconFormatterProps> = ({ value }) => {
  const classes = cx('cell cell--icon', { 'cursor-pointer': value === 'treeControl' });
  return (
    <div className={classes}>
      <Icon size={Size.L} {...getIcon(value as AlertType | MeasurementType | 'treeControl')} dataTa={TEST_IDS.icon} />
    </div>
  );
};

interface ActionFormatterProps extends IconFormatterProps {
  rowId: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

export const ActionFormatter: React.FC<ActionFormatterProps> = ({ value, onClick, rowId }) => {
  return (
    <div onClick={onClick} data-rowid={rowId} className="cursor-pointer">
      <IconFormatter value={value} />
    </div>
  );
};

export interface IValueWithUnit {
  unit: DeviceSymbolUnitIds | null;
  roundedValue: number | typeof NaN;
  isStale?: boolean | undefined;
}

export const ValueWithUnitFormatter = ({ value }: { value: IValueWithUnit }) => {
  const { roundedValue, unit, isStale } = value;
  if (isStale || isNaN(roundedValue)) {
    return (
      <div data-ta={TEST_IDS.measurement} className="vai-padding-horizontal-s">
        {DEFAULT_NO_MEASUREMENT}
      </div>
    );
  }
  const symbol = showMeasurementSymbol(DEVICE_SYMBOL_AS_UNIT_ID[unit]);
  return (
    <div data-ta={TEST_IDS.measurement} className="vai-padding-horizontal-s" title={`${roundedValue} ${symbol}`}>
      <span data-ta={TEST_IDS.measurement_value}>{roundedValue}</span>
      <span data-ta={TEST_IDS.measurement_symbol}> {symbol}</span>
    </div>
  );
};

export const PriorityIconFormatter = ({ value, row }: { value: ALARM_PRIORITY; row: any }) => {
  const PriorityIcon = AlarmPriorityIcons[value];
  return (
    <Flex data-priority={value} data-is-current={row.isCurrent} justifyContent="center">
      {PriorityIcon ? <PriorityIcon dataTa={TEST_IDS.alarm_row_priority_icon} /> : null}
    </Flex>
  );
};

export const TimesinceFormatter = ({ value }: { value: number | null }) => {
  const { t } = useTranslation();
  return value !== null ? (
    <>
      <Timesince dataTa={TEST_IDS.alarm_row_timesince} timestamp={value} />
    </>
  ) : (
    <>{t('general.ongoing')}</>
  );
};

export const AlarmTypeFormatter = ({ value }: { value: [AlarmType, AlarmSubtype] }) => {
  const { t } = useTranslation();
  const [type, subtype] = value;
  const alarmThresholdIcon = AlarmThresholdIcons[type];
  const deviceAlarmRuleIcon = DeviceAlarmRuleIcons[type];
  const AlarmIcon = alarmThresholdIcon || deviceAlarmRuleIcon;
  return (
    <Flex className="whitespace-normal" alignItems="center">
      <span className="vai-margin-right-s" data-ta={TEST_IDS.alarm_type_icon}>
        {AlarmIcon ? <AlarmIcon title={type} /> : null}
      </span>
      <span title={subtype}>{subtype}</span>
    </Flex>
  );
};
interface Props {
  status: LOCATION_STATUS;
}

export const LocationStatusFormatter: React.FC<Props> = ({ status }) => {
  return (
    <div className="cell cell--icon">
      <Icon size={Size.L} {...getIcon(status)} dataTa={TEST_IDS.location_status_icon} />
    </div>
  );
};

export const ValueFormatter = ({ value }: { value: string }) => {
  return <div>{value ? value : DEFAULT_NO_MEASUREMENT}</div>;
};

export const ThresholdAlarmFormatter = ({ value }: { value: AlarmSetState }) => {
  const { t } = useTranslation();
  if (areThresholdAlarmsNotSet(value)) {
    return <div className="cell cell--deactive">{t('site.statusThresholdNotSet')}</div>;
  }
  if (value === AlarmSetState.PAUSED) {
    return <div>{t('site.settingsAlarmsPaused')}</div>;
  }
  return <div>{t('site.settingsThresholdAlarmsSet')}</div>;
};

export const DeviceAlarmFormatter = ({ value }: { value: AlarmSetState | 'default' }) => {
  const { t } = useTranslation();
  // depends on how this gets implemented in the backend create a separate type for devicealarmsetstate.
  if (value === 'default') {
    return <div>{t('site.settingsDeviceAlarmsDefault')}</div>;
  }
  if (value === AlarmSetState.NOT_SET) {
    return <div className="cell cell--deactive">{t('site.settingsDeviceAlarmsNotSet')}</div>;
  }
  if (value === AlarmSetState.PAUSED) {
    return <div>{t('site.settingsAlarmsPaused')}</div>;
  }
  return <div>{t('site.settingsDeviceAlarmsSet')}</div>;
};

export const DeviceFormatter = ({ value }: { value: DataSourceListItemModel | TranslationKey }) => {
  const { t } = useTranslation();
  if (typeof value === 'string') {
    return <div className="cell cell--deactive">{t(value)}</div>;
  }
  return <div>{buildDeviceId(value.probe_model, value.probe_sn)}</div>;
};
