import { connect } from 'react-redux';
import { StoreState, reportsDispatchActions } from '../../../store';
import Graph from '../../Reports/Graph/Graph';
import React from 'react';
import { LocationNode } from '../../../siteTree/site';
import { useLazyGetMeasurementsQuery, useLazyDownloadJsonResponseQuery } from '../../../store/services/reportsApi';
import { getConstantFromLocalStorage, getInitialAssignedChartColors, routes } from '../../../utils';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { doGetMeasurementsQuery, processData } from '../../../utils/reports';
import { VaiColor } from '@vaisala/rockhopper-design-tokens';
import { subHours } from 'date-fns';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { LocationCustomProps } from '../../Reports/Locations';
import makeTestId, { IMakeTestIdProps } from '../../../utils/makeTestId';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import { TEST_IDS } from '../../../tests/testids';
import Timespan, { TimespanOptions, numHoursForTimespan } from './Timespan';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  location: LocationNode;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IMakeTestIdProps;

export const DEFAULT_TIMESPAN = TimespanOptions.THREE_HOURS;

function getStartFromTimespan(timespan: TimespanOptions): Date {
  const now = new Date();
  const numHours = numHoursForTimespan[timespan] || numHoursForTimespan[DEFAULT_TIMESPAN];
  return subHours(now, numHours);
}

const StatusGraph: React.FC<Props> = ({ location, dataTa, ...props }) => {
  const { getTestId } = makeTestId({ dataTa });
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const [getMeasurements, { isLoading, isFetching: isFetchingMeasurements, isSuccess }] = useLazyGetMeasurementsQuery();
  const [
    downloadJsonData,
    { isFetching: isFetchingJsonData, isSuccess: isSuccessJsonData }
  ] = useLazyDownloadJsonResponseQuery();

  const [data, setData] = React.useState<LocationCustomProps | null>(null);
  const [timespan, setTimespan] = React.useState<TimespanOptions>(TimespanOptions.THREE_HOURS);

  // Check the fetching prop so it shows loader instead of empty table when switching between locations
  const isLoadingData = isLoading || isFetchingJsonData || isFetchingMeasurements;

  const fetchMeasurements = async (timespan: TimespanOptions) => {
    // Short circuit if the data source is not set on the location
    if (!location.device || !location.active) {
      return;
    }
    // Every time we call for new data, we want the very latest time.
    const now = new Date();
    const intervalDate = {
      from: getStartFromTimespan(timespan),
      to: now
    };
    props.setIntervalDate(intervalDate);
    const queryProps = {
      customerId,
      from: intervalDate.from,
      to: intervalDate.to,
      locationId: location.node_id,
      symbolId: location.symbol_id
    };
    let data = await doGetMeasurementsQuery(getMeasurements, downloadJsonData, queryProps);
    if (data) {
      data = { ...data, ...location };
      const processedData: LocationCustomProps = processData(data, { [location.node_id]: data }, location.node_id);
      props.setReportsAssignedChartColors({
        ...getInitialAssignedChartColors(),
        [VaiColor.AquaVaisala]: location.node_id
      });
      props.setReportsVisibleLocations({ [location.node_id]: { visible: true, color: VaiColor.AquaVaisala } });
      props.setReportSelectedLocationsCustomProps({
        [location.node_id]: {
          ...processedData[location.node_id],
          visibleOnGraph: true,
          showThreshold: true,
          color: VaiColor.AquaVaisala
        }
      });
      setData(processedData);
    } else {
      props.setReportSelectedLocationsIDs([]);
      props.setReportSelectedLocationsNum(0);
      props.setReportsVisibleLocations({});
      props.setReportSelectedLocationsCustomProps({});
    }
  };

  React.useEffect(() => {
    setData(null);
    const resetTimespan = DEFAULT_TIMESPAN;
    setTimespan(resetTimespan);
    fetchMeasurements(resetTimespan);
  }, [location.node_id]);

  React.useEffect(() => {
    fetchMeasurements(timespan);
  }, [timespan]);

  React.useEffect(() => {
    if (isSuccess || isSuccessJsonData) {
      props.setReportSelectedLocationsIDs([location.node_id]);
      props.setReportSelectedLocationsNum(1);
    }
  }, [isSuccess, isSuccessJsonData]);

  React.useEffect(() => {
    return () => {
      /** Reset report state on unmount as state is shared with reports.
       * SelectedLocationsNum doesn't otherwise get reset and can show as 1 on reports page when coming from a location page
       */
      props.resetReportsState();
    };
  }, []);

  // When the site changes, the component is not unmounted, there is a split second where the node_id of the current location is not
  // the same as the data we have. To avoid the NoData component flickering, we need to check.
  const dataIsSameAsCurrentLocation = data ? Object.keys(data)[0] === location.node_id : true;
  const showNoData =
    !isLoadingData &&
    dataIsSameAsCurrentLocation &&
    (data === null || !data[location.node_id] || data[location.node_id].measurement_points.length === 0);

  return (
    <div className="vai-margin-top-xl">
      {!location.active ? (
        <LocationDeactivated dataTa={getTestId(TEST_IDS.location_deactivated)} />
      ) : showNoData ? (
        <NoData dataTa={getTestId(TEST_IDS.no_data)} />
      ) : (
        <>
          <div className="vai-margin-bottom-xxl">
            <Timespan
              dataTa={getTestId(TEST_IDS.timespan)}
              selectedTimespan={timespan}
              onClick={timespan => setTimespan(timespan)}
              isLoading={isLoadingData}
            />
          </div>
          {isLoadingData ? (
            <CenteredSpinner dataTa={getTestId(TEST_IDS.spinner)} />
          ) : (
            <Graph dataTa={getTestId(TEST_IDS.graph)} hideTimeNav={true} />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedFormattedLocationsNum: reports.selectedLocationsNum,
  intervalDate: reports.intervalDate,
  selectedLocationsCustomProps: reports.selectedLocationsCustomProps
});

const mapDispatchToProps = (dispatch: any) => ({
  setIntervalDate: interval => dispatch(reportsDispatchActions.setReportIntervalDate(interval)),
  setReportsVisibleLocations: locations => dispatch(reportsDispatchActions.setReportVisibleLocations(locations)),
  setReportsAssignedChartColors: colors => dispatch(reportsDispatchActions.setReportAssignedChartColors(colors)),
  setReportSelectedFormattedLocations: locations =>
    dispatch(reportsDispatchActions.setReportSelectedFormattedLocations(locations)),
  setReportSelectedLocationsCustomProps: locations =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsCustomProps(locations)),
  setReportSelectedLocationsNum: number => dispatch(reportsDispatchActions.setReportSelectedLocationsNum(number)),
  setReportSelectedLocationsIDs: IDs => dispatch(reportsDispatchActions.setReportSelectedLocationsIDs(IDs)),
  resetReportsState: () => dispatch(reportsDispatchActions.resetReportsState())
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusGraph);

export const NoData: React.FC<IMakeTestIdProps> = ({ dataTa }) => {
  return (
    <EmptyState
      dataTa={dataTa}
      heading={'site.statusThresholdNoData'}
      description={'site.noDataDescription'}
      illustration={IllustrationType.emptyTable}
    />
  );
};

export const LocationDeactivated: React.FC<IMakeTestIdProps> = ({ dataTa }) => {
  const { t } = useTranslation();
  return (
    <EmptyState
      dataTa={dataTa}
      heading={'site.inactiveLocationGraphHeading'}
      descriptionObj={
        <Trans
          i18nKey={'site.inactiveLocationGraphDesc'}
          values={{ linkText: t('commonTitles.reports') }}
          components={[
            <Link key="reports-url" target="_blank" to={`${routes.reports.url}/${routes.reports.graph.url}`}>
              Reports
            </Link>
          ]}
        />
      }
      illustration={IllustrationType.emptyTable}
    />
  );
};
