import { Anchor, Flex, Heading, Icon, Size, Stack } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_NO_MEASUREMENT, DeviceState, LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { useGetLocationQuery } from '../../../store/services/siteApi';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { DeviceDataSourceModel, DeviceModel } from '../../../types';
import {
  getConstantFromLocalStorage,
  getDataSourceMeasurementSymbolName,
  roundHalfToEven,
  routes,
  timestampToTimeString
} from '../../../utils';
import { showMeasurementSymbol } from '../../../utils/site';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { TEST_IDS } from '../../../tests/testids';

type DeviceDataSourceCardProps = {
  device: DeviceModel;
  dataSource: DeviceDataSourceModel;
};

export enum DataSourceClass {
  ACTIVE = 'datasource--active',
  OFFLINE = 'datasource--offline',
  STALE = 'datasource--stale'
}

export const DeviceDataSourceCard = ({ device, dataSource }: DeviceDataSourceCardProps) => {
  const { t } = useTranslation();
  // fetch location data for showing linked location name
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const { data: location, isLoading } = useGetLocationQuery(
    { customerId, id: dataSource.location_id },
    { skip: !dataSource.location_id }
  );
  const interval = device.status?.interval;
  const deviceOnline = device.status?.state === DeviceState.Online;
  const { stale, value, timestamp } = dataSource.latest_measurement;
  const getDataSourceStatusClass = (): DataSourceClass => {
    if (!deviceOnline || !dataSource.online) {
      return DataSourceClass.OFFLINE;
    }
    return stale || !timestamp ? DataSourceClass.STALE : DataSourceClass.ACTIVE;
  };
  const dataSourceSymbolName = dataSource.symbol_id && getDataSourceMeasurementSymbolName(dataSource.symbol_id);
  const dataSourceValue = value && !stale ? roundHalfToEven(value, dataSource.decimals) : DEFAULT_NO_MEASUREMENT;
  // FIXME: The BE is returning 240 seconds (4 minutes) for an interval at the moment and this works. However, if a device were to report any
  // interval that was a partial minute it would round it down. So theoretically, you could have an interval that is under a minute and the UI
  // would show `0 minutes`.

  const intervalTranslation = t('deviceManager.minuteInterval', { count: Math.floor(interval / 60) });

  const getLastMeasurementLabel = () => {
    if (stale) {
      return timestamp ? t('deviceManager.status.lastMeasured') : '';
    }
    return typeof interval === 'undefined' ? '' : `${t('deviceManager.status.interval')}: ${intervalTranslation}`;
  };
  const locationRoute = `${routes.site.url}/status/${dataSource.location_id}`;
  return (
    <Flex dataTa={TEST_IDS.data_source_card_container} className={`datasource ${getDataSourceStatusClass()}`}>
      <div className="datasource__content">
        <Stack direction="row" justify="space-between" align="center">
          <div className="datasource__name">
            {dataSource.probe_model}: {dataSource.probe_sn}
          </div>

          <div className="datasource__time">
            <span data-ta={TEST_IDS.data_source_card_interval}>{getLastMeasurementLabel()}</span>
            <span data-ta={TEST_IDS.data_source_card_last_measurement} className="datasource__timestamp">
              {timestamp ? timestampToTimeString(timestamp) : ''}
            </span>
          </div>
        </Stack>
        <Stack direction="row" justify="space-between" align="center">
          <Heading dataTa={TEST_IDS.data_source_card_value} level={2} className="datasource__value">
            {dataSourceValue} {showMeasurementSymbol(dataSourceSymbolName)}
          </Heading>
          <div className="datasource__location-link">
            {isLoading ? (
              <CenteredSpinner dataTa={TEST_IDS.data_source_card_location_spinner} />
            ) : dataSource.location_id && location ? (
              <Anchor
                dataTa={TEST_IDS.data_source_card_location_link}
                htmlId={QA_TEST_IDS.device_status_datasource_location_link}
                to={locationRoute}
                openInNewWindow
              >
                {location.name} <Icon name={VaiIcon.Link} size={Size.M} />
              </Anchor>
            ) : (
              t('deviceManager.status.notLinked')
              /** TODO: Add infotip here when we get text
               * <Infotip htmlId={QA_TEST_IDS.device_location_not_linked_infotip}>{t('deviceManager.infotip.locationNotLinked')</Infotip>
               */
            )}
          </div>
        </Stack>
      </div>
    </Flex>
  );
};
