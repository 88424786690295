import { Flex, NavTabs, Paper, TabProps } from '@vaisala/rockhopper-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import { TranslationKey } from '../../../react-i18next';
import './tabNavigation.scss';
import { TEST_IDS } from '../../../tests/testids';
import { getConstantFromLocalStorage, parseOrDefault, routes, userSettings } from '../../../utils';
import { LS_USERNAME, UserSettings } from '../../../constants';

export type CustomTabProps = TabProps & {
  id?: string;
  translationKey?: TranslationKey;
};

interface TabNavigationProps {
  tabs: CustomTabProps[];
  addRoutes?: string[];
  contentClassName?: string;
  /** Removes the flex container around the TabNavigation */
  noWrapper?: boolean;
  /**
   * Removes the Paper component around the Outlet
   */
  noPaper?: boolean;
  onChange?: () => void;
  shouldSkipParams?: boolean;
}

/**
 * TabNavigation component which renders its nested routes using react-router Outlet.
 * Requires tabs prop to render tabs as they aren't part of the Routes.
 * To get matching routes with active tabs use same path and tabKey value.
 *
 * If any routes are present between the the tabKey and Id list them as an array of strings in the AddRoutes
 *
 *
 * @example
 * <Route path="/" component={TabNavigation} addRoutes={[green-apple]}>
 *   <Route path="/apples">
 *     <Route index component={Apples} />
 *     <Route path="/apples/:id" component={SingleApple}
 *     <Route path="/apples/green-apple/:id" component={GreenApple}>
 *   </Route>
 *   <Route path="/pears" component={Pear} />
 * </Route>
 *
 */
export const TabNavigation = ({
  tabs,
  addRoutes,
  contentClassName,
  noWrapper,
  noPaper,
  onChange,
  shouldSkipParams
}: TabNavigationProps): JSX.Element => {
  const { id, ...rest } = useParams();
  const location = useLocation();
  const username = getConstantFromLocalStorage(LS_USERNAME);
  const lastRouteWasHistorical = parseOrDefault(
    userSettings.get(username, UserSettings.WAS_VIEWING_HISTORICAL_ALARMS, false),
    false
  );
  const { t } = useTranslation();

  const checkAddRoutes = (): CustomTabProps[] => {
    const tabsCopy = tabs.map(tab => {
      const newTab = { ...tab };
      // When there are added routes between tabKey and id parameters e.g. tabkey/anyroute/:id
      if (addRoutes) {
        const currentPath: string[] = location.pathname.split('/').filter(path => path !== '');
        const addRoute: string[] = addRoutes.filter(route => currentPath.includes(route));
        if (addRoute.length > 0 || lastRouteWasHistorical) {
          let routesToAdd: string;
          if (!newTab.tabKey.includes('alarms')) {
            routesToAdd = addRoute.filter(route => route !== routes.site.alarms.historical.url).join('/');
          } else {
            if (lastRouteWasHistorical && !addRoute.includes(routes.site.alarms.historical.url)) {
              addRoute.push(routes.site.alarms.historical.url);
            }
            routesToAdd = addRoute.join('/');
          }
          let finalRoute = tab.tabKey;
          if (routesToAdd) {
            finalRoute += '/';
          }
          newTab.tabKey = `${finalRoute}${routesToAdd}`;
        }
      }
      if (id) {
        newTab.tabKey = !shouldSkipParams ? `${newTab.tabKey}/${id}` : `${newTab.tabKey}`;
      } else {
        newTab.tabKey = `${newTab.tabKey}`;
      }

      return newTab;
    });
    return tabsCopy;
  };
  const [currentTabs, setTabs] = useState(checkAddRoutes());
  const [selectedTab, setSelectedTab] = useState(null);

  const handleChange = (tabKey: string) => {
    if (shouldSkipParams) {
      const [pathname, _param] = tabKey ? tabKey.split('/') : [];
      setSelectedTab(pathname);
    } else {
      setSelectedTab(tabKey);
    }

    onChange && onChange();
  };

  useEffect(() => {
    setTabs(checkAddRoutes());
  }, [tabs]);

  useEffect(() => {
    // Change tabs on location change (using Navigate component)
    setTabs(checkAddRoutes());
    handleChange(rest['*']);
  }, [location]);

  useEffect(() => {
    setTabs(checkAddRoutes());
  }, [id, lastRouteWasHistorical]);

  const renderNavTabs = (): JSX.Element => {
    return (
      <NavTabs htmlId="tab-navigation" selectedTabKey={selectedTab} setSelectedTabKey={handleChange}>
        {currentTabs.map(tab => {
          const { tabKey, translationKey, title, id } = tab;
          return (
            <NavTabs.Tab
              htmlId={id}
              key={tabKey}
              tabKey={tabKey}
              title={
                <NavLink to={tabKey} onClick={() => handleChange(tabKey)}>
                  {translationKey ? t(translationKey, { count: 0 }) : title}
                </NavLink>
              }
            />
          );
        })}
      </NavTabs>
    );
  };

  const renderInner = (): JSX.Element => {
    return (
      <>
        {renderNavTabs()}
        {noPaper ? (
          <Outlet />
        ) : (
          <Paper
            dataTa={TEST_IDS.paper}
            style={{ height: '100%', borderTop: '1px solid #DADADA' }}
            className={contentClassName}
          >
            <Outlet />
          </Paper>
        )}
      </>
    );
  };

  return (
    <>
      {noWrapper ? (
        renderInner()
      ) : (
        <Flex flexDirection="column" alignContent="flex-start" style={{ width: '100%', height: '100%' }}>
          {renderInner()}
        </Flex>
      )}
    </>
  );
};
