import * as React from 'react';
import './user-activation-dialog.scss';
import { Size, Button, ButtonType, BodyText } from '@vaisala/rockhopper-components';
import { UserInterface } from '../../../profile/user';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from '../../Modals/ModalContainer';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { getConstantFromLocalStorage } from '../../../utils';
import { ActivateUserArgs } from '../../../store/services/userApi';
import { ButtonWithLoader } from '../../ButtonWithLoader/ButtonWithLoader';
import { TEST_IDS } from '../../../tests/testids';

interface UserActivationDialog {
  onDismiss: () => void;
  userProfile: UserInterface;
  userActive: boolean;
  userActivateApiDetails: {
    toggleUserActivation: (args: ActivateUserArgs) => void;
    result: { isLoading: boolean; isQueryEnded: boolean };
  };
}

const UserActivationDialog = (props: UserActivationDialog): JSX.Element => {
  const { t } = useTranslation();
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const { result, toggleUserActivation } = props.userActivateApiDetails;
  const fullName =
    props.userProfile.first_name && props.userProfile.last_name
      ? `${props.userProfile.first_name} ${props.userProfile.last_name}`
      : null;

  const confirmAlertModal = () => {
    toggleUserActivation({
      customerId,
      active: props.userActive,
      userName: props.userProfile.email,
      fullName
    });
  };

  React.useEffect(() => {
    if (result.isQueryEnded) {
      props.onDismiss();
    }
  }, [result.isLoading, result.isQueryEnded]);

  const alertModalVisibleMsg = props.userActive
    ? t('profile.activateUserMsg', {
        name:
          props.userProfile.first_name || props.userProfile.last_name
            ? props.userProfile.first_name + ' ' + props.userProfile.last_name
            : props.userProfile.user_name
      })
    : t('profile.deactivateUserMsg', {
        name:
          props.userProfile.first_name || props.userProfile.last_name
            ? props.userProfile.first_name + ' ' + props.userProfile.last_name
            : props.userProfile.user_name
      });

  const modalButtons = (
    <>
      <ButtonWithLoader
        id="user-activation-confirm"
        htmlId="user-activation-confirm"
        onClick={confirmAlertModal}
        buttonType={ButtonType.Secondary}
        loading={result.isLoading}
        dataTa={TEST_IDS.users_and_groups_active_toggle_confirm_button}
      >
        {props.userActive ? t('profile.activateUserBtn') : t('profile.deactivateUserBtn')}
      </ButtonWithLoader>
      <Button id="user-activation-cancel" htmlId="user-activation-cancel" onClick={props.onDismiss}>
        {t('profile.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="user-activation-dialog"
      isOpen
      htmlId="user-activation-dialog"
      showCloseIcon={true}
      onDismiss={props.onDismiss}
      width={Size.S}
      dismissOnOverlayClick={false}
      role="alert-dialog"
      title={props.userActive ? 'profile.activateUserHeader' : 'profile.deactivateUserHeader'}
      buttons={modalButtons}
    >
      <BodyText id="user-activation-text" className="activate-user-body">
        {alertModalVisibleMsg}
      </BodyText>
    </ModalContainer>
  );
};

export default UserActivationDialog;
