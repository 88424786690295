import React from 'react';
import { TEST_IDS } from '../../../../../../tests/testids';
import { useThresholdModalContext } from '../../ThresholdModalProvider';
import NumberField from '../../../../../Utils/NumberField';
import {
  ALARM_THRESHOLD_TYPE,
  decimalInputRegex,
  decimalInputRegexOnlyPositive,
  integerInputRegex
} from '../../../../../../constants';
import { SITES_SYMBOL_ID } from '../../../../../../siteTree/site';
import { ThresholdAlarmTemplateRule } from '../../../../../../store/services/alarmsApi';
import { validateOrder, validateThresholdValue } from '../../validations';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';

interface Props {
  handleChange: (change: { value: number }) => void;
  rule: ThresholdAlarmTemplateRule;
  level: ALARM_THRESHOLD_TYPE;
}

const measSymbolInputRegexMap: Record<SITES_SYMBOL_ID, RegExp> = {
  [SITES_SYMBOL_ID.CELSIUS]: decimalInputRegex,
  [SITES_SYMBOL_ID.FAHRENHEIT]: decimalInputRegex,
  [SITES_SYMBOL_ID.CO2_PERCENT]: decimalInputRegexOnlyPositive,
  [SITES_SYMBOL_ID.CO2_PPM]: integerInputRegex,
  [SITES_SYMBOL_ID.HUMIDITY]: decimalInputRegexOnlyPositive
};

export const ThresholdValueField: React.FC<Props> = ({ handleChange, rule, level }) => {
  const { measSymbolId, errors, rules, saveErrors, unitDetails } = useThresholdModalContext();
  const fieldHasError =
    errors[level].value.cannotBeEmptyValue.hasError ||
    errors[level].value.units.max.hasError ||
    errors[level].value.units.min.hasError ||
    errors.order.hasError;

  return (
    <NumberField
      className={fieldHasError ? 'has-error' : ''}
      id={`${QA_TEST_IDS.alarms_settings_threshold_value_field_$level}${level}`}
      initialValue={rule.value === null ? '' : String(rule.value)}
      handleChange={handleChange}
      onBlur={() => {
        saveErrors(errors => {
          const valueValidation = validateThresholdValue(rule, errors[level], unitDetails);
          const orderValidationHasError = validateOrder(rules).hasError;
          return {
            ...errors,
            order: { ...errors.order, hasError: orderValidationHasError },
            [level]: valueValidation.error
          };
        });
      }}
      regExp={measSymbolInputRegexMap[measSymbolId]}
      dataTa={TEST_IDS.alarm_settings_threshold_form_values.input}
      disabled={!rules[level].enabled}
    />
  );
};
