import React from 'react';
import {
  Button,
  Size,
  Flex,
  Table,
  TableBody,
  TableRow,
  TableStyle,
  TableCell,
  Stack,
  Separator,
  Heading,
  Icon,
  Anchor,
  ButtonType
} from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from '../../Modals/ModalContainer';
import { hideDialog } from '../../../store/dialog';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { TEST_IDS } from '../../../tests/testids';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import {
  ALARM_THRESHOLD_TYPE,
  AlarmTicket,
  CALIBRATION_ALARM_SUBTYPES,
  CalibrationServiceLink,
  DEFAULT_DECIMAL_PLACES,
  LS_COMPANY_CUSTOMER_ID
} from '../../../constants';
import { AlarmThresholdIcons, AlarmPriorityIcons } from '../Alarms/shared/AlarmIcons';
import './alarm-details.scss';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import {
  buildDeviceId,
  convertMeasIdToType,
  convertToMiliSeconds,
  getConstantFromLocalStorage,
  getDataSourceMeasurementSymbolName,
  isLocationNode,
  roundHalfToEven,
  routes,
  timestampToTimeString
} from '../../../utils';
import MeasurementTypeIcon from '../../Utils/MeasurementTypeIcon';
import { showMeasurementSymbol } from '../../../utils/site';
import { PriorityTranslationKeyMap } from '../Alarms/shared/PrioritySelector';
import {
  alarmSubTypeTranslationKeys,
  alarmSubTypeTranslationMessages,
  thresholdLevelTranslationKeys
} from '../Setting/ThresholdAlarmAccordion/shared';
import Timesince from '../../Utils/Timesince';
import { useGetSingleAlarmQuery } from '../../../store/services/alarmsApi';
import { ALARM_RULE_NAME_HEADING_TRANSLATION_KEYS } from '../Setting/DeviceAlarmAccordion/shared';
import AcknowledgeMessage from './AcknowledgeMessage';
import AcknowledgeMessageAlert from './AcknowledgeMessageAlert';
import { IMakeTestIdProps } from '../../../utils/makeTestId';
import AcknowledgeForm from './AcknowledgeForm';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import { Trans } from 'react-i18next';
import { HasRights } from '../../HasRIghts/HasRights';
import { selectSelectedNode } from '../../../store/siteTree';
import { RightNames } from '../../RightsAccordionTree/constants';
export const dataTa = 'alarm-details';

interface Props extends IMakeTestIdProps {
  ticketId: string;
}

export const copyAlarmLink = (ticket_id: string) => {
  const rootURL = window.location.origin;
  const absoluteUrl = `${rootURL}${routes.site.url}/${routes.site.alarms.url}/${routes.site.ticket.url}/${ticket_id}/true`;
  navigator.clipboard.writeText(absoluteUrl);
};

const AlarmDetailsModal: React.FC<Props> = ({ ticketId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedNode = useAppSelector(selectSelectedNode);
  const [displayAcknowledgeFrom, setDisplayAcknowledgeFrom] = React.useState<boolean>(false);
  const onDismiss = () => {
    dispatch(hideDialog());
  };
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);

  const { currentData: data, isFetching, error, isLoading } = useGetSingleAlarmQuery({
    customer_id: customerId,
    ticket_id: ticketId
  });

  const showAcknowledgeForm = () => {
    setDisplayAcknowledgeFrom(!displayAcknowledgeFrom);
  };

  return (
    <ModalContainer
      id={QA_TEST_IDS.alarms_thresholdAlarmDetails_modalContainer}
      htmlId={QA_TEST_IDS.alarms_thresholdAlarmDetails_modalContainer}
      dataTa={TEST_IDS.alarms_thresholdAlarmDetails_modalContainer}
      isOpen
      showCloseIcon={true}
      width={Size.L}
      dismissOnOverlayClick={false}
      title={'alarms.thresholdAlarmDetails.modalTitle'}
      onDismiss={onDismiss}
    >
      {isLoading || isFetching ? (
        <CenteredSpinner dataTa={TEST_IDS.alarms_thresholdAlarmDetails_spinner} className="vai-margin-top-xxl" />
      ) : error ? (
        <div className="alarm-details-empty-state-container">
          <EmptyState heading="alarms.detailsCouldNotBeLoaded" illustration={IllustrationType.genericError} />
        </div>
      ) : (
        <Flex className="threshold-details-container">
          <Flex.Item alignSelf={'flex-start'} flexBasis={'60%'}>
            {data.ticket.category === 'THRESHOLD' ? (
              <ThresholdAlarmDetails ticket={data.ticket} />
            ) : (
              <DeviceAlarmDetails ticket={data.ticket} />
            )}
          </Flex.Item>
          <Flex.Item alignSelf={'center'} flexBasis={'2%'} style={{ height: '100%' }}>
            <hr className="vertical-separator" />
          </Flex.Item>
          <Flex.Item alignSelf={'flex-end'} flexBasis={'38%'} style={{ height: '100%', maxWidth: '38%' }}>
            <HasRights.AcknowledgeAlarms
              nodeId={isLocationNode(selectedNode) ? selectedNode.parent_id : selectedNode.node_id}
            >
              {!displayAcknowledgeFrom && (
                <Button
                  id={QA_TEST_IDS.alarms_thresholdAlarmDetails_acknowledge_button}
                  dataTa={TEST_IDS.alarms_thresholdAlarmDetails_acknowledge_button}
                  startIcon={<Icon name={VaiIcon.Check} />}
                  onClick={showAcknowledgeForm}
                >
                  {t('alarms.thresholdAlarmDetails.acknowledge')}
                </Button>
              )}
            </HasRights.AcknowledgeAlarms>
            <div className="alarms-thresholdAlarmDetails-acknowledgment-container">
              {displayAcknowledgeFrom && (
                <AcknowledgeForm showAcknowledgeForm={showAcknowledgeForm} ticketId={ticketId} dataTa={dataTa} />
              )}
              {data.messages.length === 0 ? (
                <AcknowledgeMessageAlert dataTa={dataTa} acknowledgeRequired={data.ticket.acknowledgement} />
              ) : (
                [...data.messages]
                  .sort((a, b) => b.timestamp - a.timestamp)
                  .map((message, index) => <AcknowledgeMessage dataTa={dataTa} key={index} alarmMessage={message} />)
              )}
            </div>
          </Flex.Item>
        </Flex>
      )}
    </ModalContainer>
  );
};

type AlarmDetailsProps = {
  ticket: AlarmTicket;
};

const ThresholdAlarmDetails: React.FC<AlarmDetailsProps> = ({ ticket }) => {
  const { t } = useTranslation();
  const ThresholdIcon = AlarmThresholdIcons[ticket.type];
  const PriorityIcon = AlarmPriorityIcons[ticket.priority];
  const deviceId = ticket.device ? buildDeviceId(ticket.device.device_model, ticket.device.device_sn) : null;
  const probeId = ticket.device ? buildDeviceId(ticket.device.probe_model, ticket.device.probe_sn) : null;

  const currTime: number = Date.now();

  return (
    <Stack className="threshold-details-stack">
      <Flex>
        <Flex.Item alignSelf={'flex-start'} flexBasis={'75%'}>
          <Heading level={3} className="vai-margin-sm">
            {t('alarms.thresholdAlarmDetails.thresholdAlarm')}
          </Heading>
        </Flex.Item>
        <Flex.Item alignSelf={'center'} flexBasis={'25%'}>
          <Button
            dataTa={TEST_IDS.alarms_thresholdAlarmDetails_copy_link_button}
            buttonType={ButtonType.Link}
            onClick={() => copyAlarmLink(ticket.ticket_id)}
            startIcon={<Icon name={VaiIcon.Circle} />}
          >
            {t('alarms.thresholdAlarmDetails.copyLink')}
          </Button>
        </Flex.Item>
      </Flex>
      <Table className="threshold-details-table borderless" tableStyle={TableStyle.Plain}>
        <TableBody>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_location_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_location_label}
            >
              {t('general.location')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_location_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_location_value}
            >
              <MeasurementTypeIcon
                id={QA_TEST_IDS.alarms_thresholdAlarmDetails_location_icon}
                className="vai-margin-right-s"
                type={convertMeasIdToType(ticket.device.meas_id)}
              />
              {ticket.location}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell className="grey-medium small">{ticket.path}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_value_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_value_label}
            >
              {t('general.value')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_value_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_value_value}
            >
              {roundHalfToEven(ticket?.value, ticket.decimal_places !== null ? ticket.decimal_places : 2)}{' '}
              {showMeasurementSymbol(getDataSourceMeasurementSymbolName(ticket.unit))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_alarmType_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_alarmType_label}
            >
              {t('alarms.thresholdAlarmDetails.alarmType')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_alarmType_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_alarmType_value}
            >
              <ThresholdIcon className="vai-margin-right-s" />
              {t(thresholdLevelTranslationKeys[ticket.type])}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Separator />
      <Table className="threshold-details-table borderless" tableStyle={TableStyle.Plain}>
        <TableBody>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_startTime_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_startTime_label}
            >
              {t('general.startTime')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_startTime_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_startTime_value}
            >
              {ticket.start ? timestampToTimeString(ticket.start) : '--'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_endTime_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_endTime_label}
            >
              {t('general.endTime')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_endTime_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_endTime_value}
            >
              {ticket.end ? timestampToTimeString(ticket.end) : t('alarms.thresholdAlarmDetails.ongoing')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_duration_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_duration_label}
            >
              {t('general.duration')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_duration_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_duration_value}
            >
              {ticket.end ? (
                <Timesince timestamp={ticket.end - ticket.start} />
              ) : (
                <Timesince timestamp={currTime - ticket.start} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Separator />
      <Heading
        id={QA_TEST_IDS.alarms_thresholdAlarmDetails_alarmSettings_title}
        dataTa={TEST_IDS.alarms_thresholdAlarmDetails_alarmSettings_title}
        level={3}
        className="vai-margin-sm"
      >
        {t('alarms.thresholdAlarmDetails.alarmSettings')}
      </Heading>
      <Table className="threshold-details-table borderless" tableStyle={TableStyle.Plain}>
        <TableBody>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_priority_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_priority_label}
            >
              {t('general.priority')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_priority_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_priority_value}
            >
              <PriorityIcon className="vai-margin-right-s" />
              {t(PriorityTranslationKeyMap[ticket.priority])}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_thresholdValue_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_thresholdValue_label}
            >
              {t('alarms.thresholdAlarmDetails.thresholdValue')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_thresholdValue_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_thresholdValue_value}
            >
              {ticket.type === ALARM_THRESHOLD_TYPE.HI || ticket.type === ALARM_THRESHOLD_TYPE.HIHI ? '>' : '<'}{' '}
              {roundHalfToEven(
                ticket.template_value,
                ticket.decimal_places !== null ? ticket.decimal_places : DEFAULT_DECIMAL_PLACES
              )}{' '}
              {ticket.unit ? showMeasurementSymbol(getDataSourceMeasurementSymbolName(ticket.unit)) : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_alarmDelay_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_alarmDelay_label}
            >
              {t('alarms.thresholdAlarmDetails.alarmDelay')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_alarmDelay_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_alarmDelay_value}
            >
              {ticket.delay_sec === 0 || ticket.delay_sec == null ? (
                '--'
              ) : (
                <Timesince timestamp={convertToMiliSeconds(ticket.delay_sec)} />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_dataSource_label}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_dataSource_label}
            >
              {t('alarms.thresholdAlarmDetails.dataSource')}:
            </TableCell>
            <TableCell
              id={QA_TEST_IDS.alarms_thresholdAlarmDetails_dataSource_value}
              dataTa={TEST_IDS.alarms_thresholdAlarmDetails_dataSource_value}
            >
              {ticket.device ? (
                <Anchor
                  to={`${routes.deviceManager.url}/${routes.deviceManager.status.url}/${deviceId}`}
                  openInNewWindow
                >
                  {deviceId && probeId ? `${deviceId} ${probeId}` : deviceId || probeId || ''}
                </Anchor>
              ) : (
                '--'
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

const DeviceAlarmDetails: React.FC<AlarmDetailsProps> = ({ ticket }) => {
  const { t } = useTranslation();
  const PriorityIcon = AlarmPriorityIcons[ticket.priority];
  const deviceId = ticket.device ? buildDeviceId(ticket.device.device_model, ticket.device.device_sn) : null;
  const probeId = ticket.device ? buildDeviceId(ticket.device.probe_model, ticket.device.probe_sn) : null;

  const subtypeKey = alarmSubTypeTranslationMessages[ticket.subtype].message;
  const params = alarmSubTypeTranslationMessages[ticket.subtype].params || {};
  if (
    ticket.subtype === CALIBRATION_ALARM_SUBTYPES['90DAYS'] ||
    ticket.subtype === CALIBRATION_ALARM_SUBTYPES['30DAYS'] ||
    ticket.subtype === CALIBRATION_ALARM_SUBTYPES.CALIBRATION
  ) {
    params.probe = ticket.device.probe_sn;
    params.calibration_date = ticket.calibration_date ? timestampToTimeString(ticket.calibration_date) : '--';
  }

  const currTime: number = Date.now();

  return (
    <Stack className="threshold-details-stack">
      <Flex>
        <Flex.Item alignSelf={'flex-start'} flexBasis={'75%'}>
          <Heading level={3} className="vai-margin-sm">
            {t('alarms.deviceAlarmDetails.deviceAlarm')}
          </Heading>
        </Flex.Item>
        <Flex.Item alignSelf={'center'} flexBasis={'25%'}>
          <Button
            dataTa={TEST_IDS.alarms_deviceAlarmDetails_copy_link_button}
            buttonType={ButtonType.Link}
            onClick={() => copyAlarmLink(ticket.ticket_id)}
            startIcon={<Icon name={VaiIcon.Circle} />}
          >
            {t('alarms.thresholdAlarmDetails.copyLink')}
          </Button>
        </Flex.Item>
      </Flex>
      <Table className="threshold-details-table borderless" tableStyle={TableStyle.Plain}>
        <TableBody>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_location_label}>{t('general.location')}:</TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_location_value}>
              <MeasurementTypeIcon
                dataTa={TEST_IDS.alarms_deviceAlarmDetails_location_icon}
                className="vai-margin-right-s"
                type={convertMeasIdToType(ticket.device.meas_id)}
              />
              {ticket.location}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_path} className="grey-medium small">
              {ticket.path}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarm_type_label}>
              {t('alarms.thresholdAlarmDetails.alarmType')}:
            </TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarm_type_value}>
              {t(ALARM_RULE_NAME_HEADING_TRANSLATION_KEYS[ticket.type])}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarm_subtype_label}>
              {t('general.subtype')}:
            </TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarm_subtype_value}>
              {t(alarmSubTypeTranslationKeys[ticket.subtype])}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarm_subtype_message} className="grey-medium small">
              {t(subtypeKey, params)}
              {params.probe &&
                (ticket.subtype === CALIBRATION_ALARM_SUBTYPES['90DAYS'] ||
                  ticket.subtype === CALIBRATION_ALARM_SUBTYPES['30DAYS'] ||
                  ticket.subtype === CALIBRATION_ALARM_SUBTYPES.CALIBRATION) && (
                  <Trans
                    i18nKey={'calibrationLinkMessage'}
                    values={{ linkText: t('alarm.calibrationServices') }}
                    components={[
                      <Anchor key="calibration-url" to={CalibrationServiceLink} openInNewWindow>
                        Calibration services
                      </Anchor>
                    ]}
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_dataSource_label}>
              {t('alarms.thresholdAlarmDetails.dataSource')}:
            </TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_dataSource_value}>
              {ticket.device ? (
                <Anchor
                  to={`${routes.deviceManager.url}/${routes.deviceManager.status.url}/${deviceId}`}
                  openInNewWindow
                >
                  {deviceId && probeId ? `${deviceId} ${probeId}` : deviceId || probeId || ''}
                </Anchor>
              ) : (
                '--'
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Separator />
      <Table className="threshold-details-table borderless" tableStyle={TableStyle.Plain}>
        <TableBody>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_startTime_label}>{t('general.startTime')}:</TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_startTime_value}>
              {ticket.start ? timestampToTimeString(ticket.start) : '--'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_endTime_label}>{t('general.endTime')}:</TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_endTime_value}>
              {ticket.end ? timestampToTimeString(ticket.end) : t('alarms.thresholdAlarmDetails.ongoing')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_duration_label}>{t('general.duration')}:</TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_duration_value}>
              {ticket.end ? (
                <Timesince timestamp={ticket.end - ticket.start} />
              ) : (
                <Timesince timestamp={currTime - ticket.start} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Separator />
      <Heading dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarmSettings_title} level={3} className="vai-margin-sm">
        {t('alarms.thresholdAlarmDetails.alarmSettings')}
      </Heading>
      <Table className="threshold-details-table borderless" tableStyle={TableStyle.Plain}>
        <TableBody>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_priority_label}>{t('general.priority')}:</TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_priority_value}>
              <PriorityIcon dataTa={TEST_IDS.alarms_deviceAlarmDetails_priority_icon} className="vai-margin-right-s" />
              {t(PriorityTranslationKeyMap[ticket.priority])}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarmDelay_label}>
              {t('alarms.thresholdAlarmDetails.alarmDelay')}:
            </TableCell>
            <TableCell dataTa={TEST_IDS.alarms_deviceAlarmDetails_alarmDelay_value}>
              {ticket.delay_sec === 0 || ticket.delay_sec == null ? (
                '--'
              ) : (
                <Timesince timestamp={convertToMiliSeconds(ticket.delay_sec)} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export default AlarmDetailsModal;
