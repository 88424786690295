import React from 'react';
import { Grid, Flex, FlexItem, Checkbox, Toggle, Heading } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { DEVICE_ALARM_RULE_NAMES } from '../../../../../../constants';
import { PrioritySelector } from '../../../../Alarms/shared/PrioritySelector';
import {
  HoursMinutesFields,
  clearDelayErrors,
  validateHoursMinutes
} from '../../../../Alarms/shared/HoursMinutesFields';
import { AlarmSettingFormRow } from '../../../../Alarms/shared/AlarmSettingFormRow';
import { TEST_IDS } from '../../../../../../tests/testids';
import { DeviceAlarmTemplateRule } from '../../../../../../store/services/alarmsApi';
import { DeviceAlarmRuleIcons } from '../../shared';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';
import { useDeviceAlarmModalContext } from '../DeviceAlarmModalProvider';
import { deviceRuleNameTranslations } from '../shared';

interface DeviceLevelProps {
  ruleName: DEVICE_ALARM_RULE_NAMES;
  rule: DeviceAlarmTemplateRule;
}
export type LevelValues = Pick<DeviceAlarmTemplateRule, 'enabled' | 'delay_sec' | 'priority' | 'ack_required'>;
export const DeviceAlarmRule: React.FC<DeviceLevelProps> = ({ ruleName, rule }) => {
  const { t } = useTranslation();
  const { updateSettings, saveErrors, errors } = useDeviceAlarmModalContext();

  function handleUpdate<T extends keyof LevelValues>(update: Record<T, LevelValues[T]>) {
    updateSettings(settings => {
      const rule = settings.template.rules[ruleName];
      return {
        ...settings,
        settings: settings.settings,
        template: { ...settings.template, rules: { ...settings.template.rules, [ruleName]: { ...rule, ...update } } }
      };
    });
  }

  function handleActivate(checked: boolean) {
    updateSettings(settings => {
      const rule = settings.template.rules[ruleName];
      const updatedSettings = {
        ...settings,
        template: {
          ...settings.template,
          rules: {
            ...settings.template.rules,
            [ruleName]: { ...rule, enabled: checked }
          }
        }
      };
      saveErrors(errors => {
        clearDelayErrors(errors[ruleName]);
        return { ...errors };
      });
      return updatedSettings;
    });
  }

  const RuleIcon = DeviceAlarmRuleIcons[ruleName];

  const hoursHasError = errors[ruleName].delay.cannotBeEmptyValue.hasError || errors[ruleName].delay.hours.max.hasError;
  const minutesHasError =
    errors[ruleName].delay.cannotBeEmptyValue.hasError || errors[ruleName].delay.minutes.max.hasError;

  const validateDelay = (unitDetails: { hours: number; minutes: number }) => {
    saveErrors(errors => {
      const validation = validateHoursMinutes(rule.delay_sec, errors[ruleName], unitDetails);
      return { ...errors, [ruleName]: validation.error };
    });
  };

  return (
    <AlarmSettingFormRow hasError={errors[ruleName].hasError} enabled={rule.enabled}>
      <Grid.Col md={1} lg={1} xl={1}>
        <div className="activate-checkbox">
          <Flex alignItems="center" justifyContent="center">
            <FlexItem>
              <Checkbox
                checked={rule.enabled}
                onChange={event => handleActivate(event.target.checked)}
                id={`${QA_TEST_IDS.alarms_settings_device_activate_checkbox_$ruleName}${ruleName}`}
              />
            </FlexItem>
          </Flex>
        </div>
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        <Flex className="rule-name-field" justifyContent="flex-start" alignItems="center">
          <Flex.Item>
            <RuleIcon />
          </Flex.Item>
          <Flex.Item flexGrow={1}>
            <Heading level={4}>{t(deviceRuleNameTranslations[ruleName])}</Heading>
          </Flex.Item>
        </Flex>
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        <PrioritySelector
          disabled={!rule.enabled}
          value={rule.priority}
          id={`${QA_TEST_IDS.alarms_settings_device_priority_select_$ruleName}${ruleName}`}
          htmlId={`${QA_TEST_IDS.alarms_settings_device_priority_select_$ruleName}${ruleName}`}
          onSelect={option => handleUpdate<'priority'>({ priority: option })}
        />
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        <Toggle
          htmlId={`${QA_TEST_IDS.alarms_settings_device_ack_toggle_$ruleName}${ruleName}`}
          disabled={!rule.enabled}
          checked={rule.ack_required}
          onChange={change => handleUpdate<'ack_required'>({ ack_required: change })}
          uncheckedText={t('site.alarms.settings.threshold.form.notRequired')}
          text={t('site.alarms.settings.threshold.form.required')}
          dataTa={TEST_IDS.alarm_settings_device_form_ack_toggle}
        />
      </Grid.Col>
      <Grid.Col md={2} lg={2} xl={2}>
        <HoursMinutesFields
          level={ruleName}
          disabled={!rule.enabled}
          onEnable={validateDelay}
          onBlur={validateDelay}
          hasErrorState={{ hoursHasError, minutesHasError }}
          value={rule.delay_sec}
          handleUpdate={seconds => handleUpdate<'delay_sec'>({ delay_sec: seconds })}
        />
      </Grid.Col>
    </AlarmSettingFormRow>
  );
};
