import { Button, ButtonProps, Spinner } from '@vaisala/rockhopper-components';
import React from 'react';

interface Props extends ButtonProps {
  loading?: boolean;
}

export const ButtonWithLoader: React.FC<Props> = props => {
  const { loading, ...rest } = props;
  return (
    <Button {...rest} disabled={props.disabled || props.loading}>
      {props.loading ? <Spinner className="spinner-small" /> : props.children}
    </Button>
  );
};
