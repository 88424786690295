import React from 'react';
import { Grid } from '@vaisala/rockhopper-components';
import { ALARM_PRIORITY, ALARM_THRESHOLD_TYPE } from '../../../../../../constants';
import { AlarmDisplay } from '../../../../Alarms/shared/AlarmDisplay';
import { useThresholdModalContext } from '../../ThresholdModalProvider';
import { AlarmSettingFormRow } from '../../../../Alarms/shared/AlarmSettingFormRow';
import { showMeasurementSymbol } from '../../../../../../utils/site';
import { useTranslation } from 'react-i18next';
import GroupsSelect from '../../../../Alarms/shared/GroupsSelect';
import { Group } from '../../../../../../store/services/userApi';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';

interface Props {
  value: number;
  priority: ALARM_PRIORITY;
  threshold: ALARM_THRESHOLD_TYPE;
}

export const ThresholdNotificationRow: React.FC<Props> = ({ value, priority, threshold }) => {
  const {
    measSymbolId,
    notificationSettings,
    warnings,
    updateWarnings,
    checkCanAcknowledgeAlarms,
    notificationGroups,
    isFetchingGroups,
    updateSettings
  } = useThresholdModalContext();

  const { t } = useTranslation();

  const updateNotificationsSettings = (updatedList: Group['group_name'][]) => {
    updateSettings(settings => {
      return {
        ...settings,
        settings: {
          ...settings.settings,
          notifications: {
            ...settings.settings.notifications,
            [threshold as ALARM_THRESHOLD_TYPE]: {
              ...settings.settings.notifications[threshold as ALARM_THRESHOLD_TYPE],
              EMAIL: updatedList
            }
          }
        }
      };
    });
  };

  return (
    <AlarmSettingFormRow hasWarning={warnings[threshold].hasWarning}>
      <Grid.Col lg={4} xl={4}>
        <AlarmDisplay
          label={`${value === null ? '' : value} ${showMeasurementSymbol(measSymbolId)}`}
          priority={priority}
          threshold={threshold}
        />
      </Grid.Col>
      <Grid.Col lg={5} xl={5}>
        <GroupsSelect
          domIds={{
            checkboxId: QA_TEST_IDS.alarms_settings_threshold_notifications_group_select_checkbox_$group_name,
            selectorId: `${QA_TEST_IDS.alarms_settings_threshold_notifications_group_select_selector_$level}${threshold}`
          }}
          onBlur={groupsList => {
            updateWarnings(warnings => {
              const updatedWarnings = { ...warnings };
              const selectedGroups = groupsList.filter(group => group.selected);
              updatedWarnings[threshold].hasWarning =
                selectedGroups.length > 0 ? selectedGroups.every(group => !group.canAcknowledgeAlarms) : false;
              return updatedWarnings;
            });
          }}
          groups={notificationGroups}
          isLoading={isFetchingGroups}
          onSelect={updateNotificationsSettings}
          selectedGroups={notificationSettings[threshold].EMAIL}
          checkCanAcknowledgeAlarms={checkCanAcknowledgeAlarms}
        />
      </Grid.Col>
      <Grid.Col lg={3} xl={3}>
        {notificationSettings[threshold].EMAIL.length > 0 && t('general.email')}
      </Grid.Col>
    </AlarmSettingFormRow>
  );
};
