import * as React from 'react';
import { connect } from 'react-redux';
import uPlot from 'uplot';
import { DEVICES_SYMBOL_ID } from '../../../constants';
import { reportsDispatchActions, StoreState } from '../../../store';
import Chart from '../Chart/Chart';
import { LocationCustomProps, LocationTreeFormattedNode } from '../Locations';
import { TEST_IDS } from '../../../tests/testids';
import makeTestId, { IMakeTestIdProps } from '../../../utils/makeTestId';

type GraphProps = { hideTimeNav?: boolean } & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IMakeTestIdProps;

const Graph: React.FunctionComponent<GraphProps> = ({ dataTa, ...props }) => {
  const { getTestId } = makeTestId({ dataTa });
  const initialChartsData: {
    units: string[];
    locations: LocationTreeFormattedNode[];
  }[] = [
    {
      units: [],
      locations: []
    }
  ];

  const [chartsData, setChartsData] = React.useState([]); // Defaults to 1 empty chart
  const [chartsUnits, setChartsUnits] = React.useState([]);
  const [chartsLocations, setChartsLocations] = React.useState([]);

  const [allLocations, setAllLocations] = React.useState([]);
  const [allUnits, setAllUnits] = React.useState([]);
  const [timestamps, setTimestamps] = React.useState(new Set());
  const [syncKey, setSyncKey] = React.useState(null);

  const formatData = locations => {
    const formattedData: { [key: number]: { [key: string]: number } } = {};

    locations.forEach(
      (
        location: {
          symbol_id: string;
          measurement_points: Array<{ t: number; v: number }>;
        },
        index: number
      ) => {
        // E.g => {"123231231.123": {"C": 25.6, "RH": 44.2}}
        // NOTE: For uPlot to handle DST and calendar logic in general, it needs the timestamps in seconds. The BE is returning
        //  timestamps in MS and since we are looping through measurement points anyway, the conversion is handled here for now.
        // TODO: Rethink this, the BE should provide data structured in the way the FE needs it (including timestamp information in seconds)
        location.measurement_points.forEach(point => {
          if (!formattedData[point.t]) {
            formattedData[point.t] = {
              [index]: point.v
            };
          } else {
            formattedData[point.t][index] = point.v;
          }
        });
      }
    );
    return formattedData;
  };

  const getAllLocationsAndUnits = (data: LocationCustomProps) => {
    const locations: any = [];
    const units: string[] = [];

    for (const key in data) {
      if (data[key].visibleOnGraph && data[key].measurement_points?.length > 0) {
        locations.push({ ...data[key], node_id: key });

        if (!units.includes(data[key].symbol_id)) {
          units.push(data[key].symbol_id);
        }
      }
    }

    return [locations, units];
  };

  const getDataForEachChart = (moreThanOne = true) => {
    // Get all timestamps from all locations for xAxis data
    const timestamps = new Set();
    allLocations.forEach((loc: { symbol_id: string; measurement_points: Array<{ t: number; v: number }> }) => {
      loc.measurement_points.forEach(point => timestamps.add(point.t));
    });

    setTimestamps(timestamps);

    if (moreThanOne) {
      const chartsData = [
        ...JSON.parse(JSON.stringify(initialChartsData)),
        ...JSON.parse(JSON.stringify(initialChartsData))
      ];

      allUnits.map(unit => {
        const locations = allLocations.filter(loc => loc.symbol_id === unit);

        switch (unit) {
          case DEVICES_SYMBOL_ID.HUMIDITY:
            chartsData[0].locations = [...chartsData[0].locations, ...locations];
            chartsData[0].units = [...chartsData[0].units, unit];
            break;
          case DEVICES_SYMBOL_ID.CELSIUS:
            if (chartsData[0].units.length < 2 && !chartsData[0].units.includes(DEVICES_SYMBOL_ID.FAHRENHEIT)) {
              chartsData[0].locations = [...chartsData[0].locations, ...locations];
              chartsData[0].units = [...chartsData[0].units, unit];
            } else {
              chartsData[1].locations = [...chartsData[1].locations, ...locations];
              chartsData[1].units = [...chartsData[1].units, unit];
            }
            break;
          case DEVICES_SYMBOL_ID.FAHRENHEIT:
            if (chartsData[0].units.length < 2 && !chartsData[0].units.includes(DEVICES_SYMBOL_ID.CELSIUS)) {
              chartsData[0].locations = [...chartsData[0].locations, ...locations];
              chartsData[0].units = [...chartsData[0].units, unit];
            } else {
              chartsData[1].locations = [...chartsData[1].locations, ...locations];
              chartsData[1].units = [...chartsData[1].units, unit];
            }
            break;
          case DEVICES_SYMBOL_ID.CO2_PERCENT:
          case DEVICES_SYMBOL_ID.CO2_PPM:
            chartsData[1].locations = [...chartsData[1].locations, ...locations];
            chartsData[1].units = [...chartsData[1].units, unit];
            break;
          default:
            break;
        }
      });

      return chartsData;
    }
  };

  const determineChartsCount = () => {
    let chartsData = [];

    if (
      allUnits.length > 2 ||
      (allUnits.includes(DEVICES_SYMBOL_ID.CELSIUS) && allUnits.includes(DEVICES_SYMBOL_ID.FAHRENHEIT))
    ) {
      props.setReportGraphsNum(2);
      chartsData = getDataForEachChart();
      setChartsUnits([chartsData[0].units, chartsData[1].units]);
      setChartsLocations([chartsData[0].locations, chartsData[1].locations]);
    } else {
      props.setReportGraphsNum(1);
      getDataForEachChart(false);
      setChartsUnits([allUnits]);
      setChartsLocations([allLocations]);
    }
  };

  React.useEffect(() => {
    if (props.selectedLocationsNum > 0) {
      const [locations, units] = getAllLocationsAndUnits(props.selectedLocationsCustomProps);
      setAllLocations(locations);
      setAllUnits(units);
    }
  }, [props.selectedLocationsNum, props.selectedLocationsCustomProps]);

  React.useEffect(() => {
    // If the intervals (from, to, etc.) are changed, or the user selects different locations, reset chart data
    setChartsData([]);
    setChartsLocations([]);
    setChartsUnits([]);
  }, [props.intervalDate, props.selectedLocationsIDs]);

  React.useEffect(() => {
    if (allUnits.length > 0) {
      determineChartsCount();
    } else {
      setChartsData([]);
    }
  }, [allUnits]);

  React.useEffect(() => {
    if (chartsUnits.length > 0) {
      const newChartsData = [];

      chartsLocations.forEach(chart => {
        const formattedData = formatData(chart);

        const sortedData: { [key: number]: { [key: number]: number } } = Object.keys(formattedData)
          .map(key => +key)
          .sort((a: number, b: number) => a - b)
          .reduce((obj: { [key: number]: Record<number, number> }, key) => {
            obj[key] = formattedData[key];
            return obj;
          }, {});

        const xAxisData = Array.from(timestamps).sort((a: number, b: number) => a - b);
        // const xAxisData = Object.keys(sortedData).map(key => +key);
        const seriesData: uPlot.AlignedData | [] = [];

        chart.forEach((location: any, i: number) => {
          seriesData[i.toString()] = Array(xAxisData.length).fill(null);

          // const valuesByLocations = Object.values(sortedData);

          xAxisData.forEach((time: number, index: number) => {
            seriesData[i.toString()][index] = sortedData[time] && sortedData[time][i] ? sortedData[time][i] : null;
            // seriesData[i.toString()][index] =
            //   valuesByLocations[index] && valuesByLocations[index][i] ? valuesByLocations[index][i] : null;
          });
        });

        newChartsData.push([xAxisData, ...seriesData]);
      });

      if (newChartsData.length > 1) {
        setSyncKey(uPlot.sync('syncKey'));
      } else {
        setSyncKey(null);
      }

      setChartsData(newChartsData);
    }
  }, [chartsUnits]);

  return (
    <>
      {chartsData.length > 0 ? (
        chartsData.map((data, ind) => (
          <div id={`chart-${ind}`} key={ind} data-ta={getTestId(TEST_IDS.populated_chart_instance)}>
            <Chart
              id={`chart-${ind}`}
              data={data}
              syncKey={syncKey}
              locations={chartsLocations[ind]}
              allLocations={chartsLocations.flat(1)}
              units={chartsUnits[ind]}
              hideTimeNav={props.hideTimeNav}
            />
          </div>
        ))
      ) : (
        <div id={`chart-empty`} data-ta={getTestId(TEST_IDS.chart_instance)}>
          <Chart
            id={`chart-empty`}
            hideTimeNav={props.hideTimeNav}
            data={[]}
            locations={[]}
            allLocations={[]}
            units={[]}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedLocationsNum: reports.selectedLocationsNum,
  intervalDate: reports.intervalDate,
  selectedLocationsIDs: reports.selectedLocationsIDs,
  selectedLocationsCustomProps: reports.selectedLocationsCustomProps
});

const mapDispatchToProps = dispatch => ({
  setReportGraphsNum: num => dispatch(reportsDispatchActions.setReportGraphsNum(num))
});

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
