import React from 'react';
import { Grid, Flex, FlexItem, Checkbox, Toggle } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { ALARM_THRESHOLD_TYPE } from '../../../../../../constants';
import { showMeasurementSymbol } from '../../../../../../utils/site';
import { AlarmThresholdIcons } from '../../../../Alarms/shared/AlarmIcons';
import { PrioritySelector } from '../../../../Alarms/shared/PrioritySelector';
import { HoursMinutesFields, validateHoursMinutes } from '../../../../Alarms/shared/HoursMinutesFields';
import { useThresholdModalContext } from '../../ThresholdModalProvider';
import { AlarmSettingFormRow } from '../../../../Alarms/shared/AlarmSettingFormRow';
import { TEST_IDS } from '../../../../../../tests/testids';
import { ThresholdValueField } from './ThresholdValueFIeld';
import { ThresholdAlarmTemplateRule } from '../../../../../../store/services/alarmsApi';
import { LevelValues, thresholdLevelTranslationKeys } from '../../shared';
import { validateOrder, clearLevelErrors } from '../../validations';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';

interface ThresholdLevelProps {
  level: ALARM_THRESHOLD_TYPE;
  rule: ThresholdAlarmTemplateRule;
}

export const ThresholdLevel: React.FC<ThresholdLevelProps> = ({ level, rule }) => {
  const { t } = useTranslation();
  const { updateSettings, measSymbolId, errors, saveErrors } = useThresholdModalContext();

  function handleUpdate<T extends keyof LevelValues>(update: Record<T, LevelValues[T]>) {
    updateSettings(settings => {
      const rule = settings.template.rules[level];
      return {
        ...settings,
        settings: settings.settings,
        template: { ...settings.template, rules: { ...settings.template.rules, [level]: { ...rule, ...update } } }
      };
    });
  }

  function handleActivate(checked: boolean) {
    updateSettings(settings => {
      const rule = settings.template.rules[level];
      const updatedSettings = {
        ...settings,
        template: {
          ...settings.template,
          rules: {
            ...settings.template.rules,
            [level]: { ...rule, enabled: checked }
          }
        }
      };
      saveErrors(errors => {
        const validation = validateOrder(updatedSettings.template.rules);
        clearLevelErrors(errors[level]);
        return { ...errors, order: { ...errors.order, hasError: validation.hasError } };
      });
      return updatedSettings;
    });
  }

  const ThresholdIcon = AlarmThresholdIcons[level];

  const hoursHasError = errors[level].delay.cannotBeEmptyValue.hasError || errors[level].delay.hours.max.hasError;
  const minutesHasError = errors[level].delay.cannotBeEmptyValue.hasError || errors[level].delay.minutes.max.hasError;

  const validateDelay = (unitDetails: { hours: number; minutes: number }) => {
    saveErrors(errors => {
      const validation = validateHoursMinutes(rule.delay_sec, errors[level], unitDetails);
      return { ...errors, [level]: validation.error };
    });
  };

  return (
    <AlarmSettingFormRow hasError={errors[level].hasError || errors.order.hasError} enabled={rule.enabled}>
      <Grid.Col md={1} lg={1} xl={1}>
        <div className="activate-checkbox">
          <Flex alignItems="center" justifyContent="center">
            <FlexItem>
              <Checkbox
                checked={rule.enabled}
                onChange={event => handleActivate(event.target.checked)}
                id={`${QA_TEST_IDS.alarms_settings_threshold_activate_checkbox_$level}${level}`}
              />
            </FlexItem>
          </Flex>
        </div>
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        <Flex justifyContent="center" alignItems="center">
          <FlexItem flexGrow={4}>
            <ThresholdIcon /> {t(thresholdLevelTranslationKeys[level])}
          </FlexItem>
          <FlexItem flexGrow={3}>
            <ThresholdValueField rule={rule} level={level} handleChange={change => handleUpdate<'value'>(change)} />
          </FlexItem>
          <FlexItem flexGrow={1}>
            <div className="measurement-unit-box">{showMeasurementSymbol(measSymbolId)}</div>
          </FlexItem>
        </Flex>
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        <PrioritySelector
          disabled={!rule.enabled}
          value={rule.priority}
          id={`${QA_TEST_IDS.alarms_settings_threshold_priority_select_$level}${level}`}
          htmlId={`${QA_TEST_IDS.alarms_settings_threshold_priority_select_$level}${level}`}
          onSelect={option => handleUpdate<'priority'>({ priority: option })}
        />
      </Grid.Col>
      <Grid.Col md={3} lg={3} xl={3}>
        <Toggle
          htmlId={`${QA_TEST_IDS.alarms_settings_threshold_ack_toggle_$level}${level}`}
          disabled={!rule.enabled}
          checked={rule.ack_required}
          onChange={checked => handleUpdate({ ack_required: checked })}
          uncheckedText={t('site.alarms.settings.threshold.form.notRequired')}
          text={t('site.alarms.settings.threshold.form.required')}
          dataTa={TEST_IDS.alarm_settings_threshold_form_ack_toggle}
        />
      </Grid.Col>
      <Grid.Col md={2} lg={2} xl={2}>
        <HoursMinutesFields
          level={level}
          disabled={!rule.enabled}
          hasErrorState={{ hoursHasError, minutesHasError }}
          value={rule.delay_sec}
          onBlur={validateDelay}
          onEnable={validateDelay}
          handleUpdate={value => handleUpdate<'delay_sec'>({ delay_sec: value })}
        />
      </Grid.Col>
    </AlarmSettingFormRow>
  );
};
