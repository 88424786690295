import * as React from 'react';
import {
  MenuButton,
  MenuButtonListItem,
  MenuButtonToggle,
  Icon,
  ButtonType,
  MenuButtonList
} from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

import './timepicker.scss';
import { generateTimeOptions, getNearestEnabledTimeOption, roundToMultipleOfFive } from '../../../utils/common';
import { useRef } from 'react';
import { TEST_IDS } from '../../../tests/testids';
import { format } from 'date-fns';

export interface TimePickerProps {
  id?: string;
  maxTime?: string | null;
  minTime?: string | null;
  value: string;
  onChange: (value: string) => void;
  onInvalidTime?: (value: boolean) => void;
  testId?: string;
}

const TimePicker: React.FunctionComponent<TimePickerProps> = props => {
  const [selectedTime, setSelectedTime] = React.useState(format(new Date(), 'HH:mm'));
  const [timeOptions, setTimeOptions] = React.useState([]);
  const [invalid, setInvalid] = React.useState(false);

  const listRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // Disable all times after maxTime
    setTimeOptions(
      generateTimeOptions(
        getNearestEnabledTimeOption(props.maxTime, { nearestBackwards: false }),
        getNearestEnabledTimeOption(props.minTime)
      )
    );
  }, [props.maxTime, props.minTime]);

  React.useEffect(() => {
    setSelectedTime(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (props.onInvalidTime) {
      if (isInvalidTime(selectedTime)) {
        props.onInvalidTime(true);
        setInvalid(true);
      } else {
        props.onInvalidTime(false);
        setInvalid(false);
      }
    }
  }, [timeOptions, selectedTime]);

  /**
   * Scrolls to the nearest selected time in the dropdown list
   * Just because list contains only multiple of 5 on minutes part, it will round minutes before scrolling
   */
  const scrollToSelectedTime = () => {
    const [hh, mm] = selectedTime.split(':');
    const roundedTime = `${hh}:${roundToMultipleOfFive(+mm)
      .toString()
      .padStart(2, '0')}`;
    const selectedTimeOption = listRef.current?.querySelector(`[data-scrollid="${roundedTime}"]`);
    selectedTimeOption?.scrollIntoView({ block: 'center' });
  };

  const isInvalidTime = (value: string) => {
    return timeOptions.find(r => r.value === value && r.disabled);
  };

  return (
    <div ref={listRef}>
      <MenuButton id="time-picker" className="vai-margin-left-s" dataTa={props.testId}>
        <MenuButtonToggle
          id={props.id}
          className={invalid && 'vai-input-field__input--invalid'}
          buttonType={ButtonType.Secondary}
          endIcon={<Icon name={VaiIcon.Clock} />}
          dataTa={TEST_IDS.time_picker_toggle}
          onPointerUp={() => {
            scrollToSelectedTime();
          }}
        >
          {selectedTime}
        </MenuButtonToggle>
        <MenuButtonList>
          {timeOptions.map(option => (
            <MenuButtonListItem
              key={option.value}
              data-scrollid={option.value}
              disabled={option.disabled}
              onSelect={() => {
                setSelectedTime(option.value);
                props.onChange(option.value);
              }}
            >
              {option.value}
            </MenuButtonListItem>
          ))}
        </MenuButtonList>
      </MenuButton>
    </div>
  );
};

export default TimePicker;
