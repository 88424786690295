import { Header, Avatar, Size } from '@vaisala/rockhopper-components';
import React from 'react';
import { Languages, languageShortCodes } from '../../../i18n';
import enUS from '../../../assets/flags/en-US.svg';
import DE from '../../../assets/flags/de-DE.svg';
import ES from '../../../assets/flags/es-XN.svg';
import FI from '../../../assets/flags/fi-FI.svg';
import FR from '../../../assets/flags/fr-FR.svg';
import IT from '../../../assets/flags/it-IT.svg';
import JP from '../../../assets/flags/ja-JP.svg';
import PT from '../../../assets/flags/pt-BR.svg';
import CN from '../../../assets/flags/zh-CN.svg';
import SE from '../../../assets/flags/sv-SE.svg';
import './select-language-header.scss';
import { LANGUAGE_TEXT } from '../../../utils';
import { TEST_IDS } from '../../../tests/testids';

interface SelectLanguageHeaderProps {
  language: Languages;
  changeLanguage: (language: Languages) => void;
}

export const SelectLanguageHeader = (props: SelectLanguageHeaderProps): JSX.Element => {
  const { language, changeLanguage } = props;
  const getSRC = (src: Languages) => {
    switch (src) {
      case Languages.en:
        return enUS;
      case Languages.dede:
        return DE;
      case Languages.esXN:
        return ES;
      case Languages.fiFI:
        return FI;
      case Languages.frfr:
        return FR;
      case Languages.itIT:
        return IT;
      case Languages.jajp:
        return JP;
      case Languages.ptBR:
        return PT;
      case Languages.svSE:
        return SE;
      case Languages.zhcn:
        return CN;
    }
  };

  const onChangeLanguageClick = (lang: Languages) => () => {
    if (lang !== language) {
      changeLanguage(lang);
    }
  };
  return (
    <Header
      id="select-header-language-container"
      dataTa={TEST_IDS.language_header}
      className="select-header-language-container"
      title=""
    >
      <Header.Menu
        id="select-header-language-title"
        className="select-header-language-title"
        title={language ? languageShortCodes[language] : ''}
        data-ta={TEST_IDS.language_header_menu}
        startIcon={
          <Avatar
            className="select-header-language-title-avatar"
            id="select-header-language-title-avatar"
            size={Size.XS}
            src={getSRC(language)}
            dataTa={TEST_IDS.language_header_menu_avatar}
          />
        }
      >
        {Object.keys(LANGUAGE_TEXT).map((key: Languages) => {
          return (
            <Header.MenuItem
              key={`MenuItem-key-${key}`}
              id={`select-header-language-${key}`}
              data-ta={TEST_IDS.language_header_menu_item}
              startIcon={
                <Avatar
                  id={`select-header-language-avatar-${key}`}
                  size={Size.XS}
                  name={key}
                  src={getSRC(key)}
                  className="select-header-language-list-avatar select-header-language-title-avatar"
                  dataTa={TEST_IDS.language_header_menu_item_avatar}
                />
              }
              onClick={onChangeLanguageClick(key)}
            >
              {LANGUAGE_TEXT[key]}
            </Header.MenuItem>
          );
        })}
      </Header.Menu>
    </Header>
  );
};
