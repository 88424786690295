import React, { useEffect, useRef, useState } from 'react';
import { Flex, FlexItem } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import NumberField from '../../../Utils/NumberField';
import { integerInputRegex } from '../../../../constants';
import { TEST_IDS } from '../../../../tests/testids';
import { QA_TEST_IDS } from '../../../../tests/qaTestIds';
import { cloneDeep } from 'lodash';
import { AlarmType } from '../../../../store/services/alarmsApi';

interface Props {
  value: number;
  handleUpdate: (value: number) => void;
  onBlur: (unitDetails: { hours: number; minutes: number }) => void;
  hasErrorState?: { minutesHasError: boolean; hoursHasError: boolean };
  disabled?: boolean;
  level: AlarmType;
  onEnable?: (unitDetails: { hours: number; minutes: number }) => void;
}

const DEFAULT_STATE: { hours: null | number; minutes: null | number } = {
  hours: null,
  minutes: null
};

export const HoursMinutesFields: React.FC<Props> = ({
  value,
  handleUpdate,
  onBlur,
  hasErrorState,
  disabled,
  level,
  onEnable
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState(value === null ? DEFAULT_STATE : convertToHoursMinutes(value));
  const isInitialMountRef = useRef(true);

  useEffect(() => {
    if (isInitialMountRef.current) {
      isInitialMountRef.current = false;
    } else {
      if (!disabled) {
        onEnable(state);
      }
    }
  }, [disabled]);

  const handleChange = ({ hours, minutes }: typeof DEFAULT_STATE) => {
    if (hours === null && minutes === null) {
      return handleUpdate(null);
    }
    const details = convertToSeconds({ hours, minutes });
    handleUpdate(details.seconds);
  };

  return (
    <div className="minutes-hours-box">
      <Flex alignItems="center">
        <FlexItem flexGrow={1}>
          <NumberField
            disabled={disabled}
            className={hasErrorState.hoursHasError ? 'has-error' : ''}
            handleChange={change => {
              setState(prevState => ({ ...prevState, hours: change.value }));
              handleChange({ hours: change.value, minutes: state.minutes });
            }}
            initialValue={state.hours === null ? '' : state.hours.toString()}
            regExp={integerInputRegex}
            onBlur={() => onBlur(state)}
            id={`${QA_TEST_IDS.alarms_settings_threshold_hours_field_$level}${level}`}
            dataTa={TEST_IDS.hours_minutes_fields.hours}
          />
        </FlexItem>
        <FlexItem>
          <span className="time-unit">{t('site.alarms.settings.threshold.form.hours')}</span>
        </FlexItem>
        <FlexItem flexGrow={1}>
          <NumberField
            disabled={disabled}
            className={hasErrorState.minutesHasError ? 'has-error' : ''}
            handleChange={change => {
              setState(prevState => ({ ...prevState, minutes: change.value }));
              handleChange({ hours: state.hours, minutes: change.value });
            }}
            initialValue={state.minutes === null ? '' : state.minutes.toString()}
            regExp={integerInputRegex}
            onBlur={() => onBlur(state)}
            dataTa={TEST_IDS.hours_minutes_fields.minutes}
            id={`${QA_TEST_IDS.alarms_settings_threshold_minutes_field_$level}${level}`}
          />
        </FlexItem>
        <FlexItem>
          <span className="time-unit">{t('site.alarms.settings.threshold.form.minutes')}</span>
        </FlexItem>
      </Flex>
    </div>
  );
};

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

function convertToHoursMinutes(seconds) {
  const hours = (seconds - (seconds % SECONDS_IN_HOUR)) / SECONDS_IN_HOUR;
  const minutes = (seconds - hours * SECONDS_IN_HOUR) / SECONDS_IN_MINUTE;
  return { hours, minutes: Math.trunc(minutes) };
}

export function convertToSeconds({ hours, minutes }) {
  const seconds = hours * SECONDS_IN_HOUR + minutes * SECONDS_IN_MINUTE;
  return { seconds };
}

export const DELAY_VALIDATION_TRANSLATION_KEYS = {
  cannotBeEmptyValue: 'site.alarms.settings.threshold.validations.delay.cannotBeEmpty',
  hours: { max: 'site.alarms.settings.threshold.validations.delay.hours.max' },
  minutes: { max: 'site.alarms.settings.threshold.validations.delay.minutes.max' }
};

export const DEFAULT_DELAY_ERROR = {
  cannotBeEmptyValue: { label: 'Specify a delay value for all alarms.', hasError: false },
  hours: { max: { label: 'Specify at most a 24-hour delay.', hasError: false } },
  minutes: { max: { label: 'Specify at most 59 minutes for this field.', hasError: false } }
};

export type DelayError = {
  delay: typeof DEFAULT_DELAY_ERROR;
  hasError: boolean;
};

/**
 * It will validate threshold level delay value.
 * It will mutate passed in object in place.
 * @param delay_sec - seconds.
 * @param error - Current level error state. It will mutate object in place with updated errors.
 * @param unitDetails - Time and minutes details that user entered. Used to validate hours and minutes fields separately.
 * @returns hasError value and passed in error state. hasError is used to validate passed in rule if not interested in updating error state.
 */
export const validateHoursMinutes = (
  delay_sec: number,
  error: DelayError,
  unitDetails?: { hours: number; minutes: number }
) => {
  const clonedError = cloneDeep(error);

  clonedError.delay.cannotBeEmptyValue.hasError = delay_sec === null;

  if (unitDetails) {
    clonedError.delay.hours.max.hasError = unitDetails.hours > MAX_HOURS;
    clonedError.delay.minutes.max.hasError = unitDetails.minutes > MAX_MINUTES;
  }
  clonedError.hasError = aggregateDelayHasError(clonedError);

  return { hasError: clonedError.hasError, error: clonedError };
};

/**
 * It will aggregate hasError logic from level error state
 * @param Error - @DelayError
 * @returns - if the level error has any validation error in it. It is used to style row when level has any error in it.
 */
export const aggregateDelayHasError = (error: DelayError): boolean => {
  return error.delay.cannotBeEmptyValue.hasError || error.delay.hours.max.hasError || error.delay.minutes.max.hasError;
};

/**
 * It will set all the validation error state of the passed in error to false.
 * It will mutate the object in place
 * @param error - @DelayError.
 */
export const clearDelayErrors = (error: DelayError) => {
  error.delay.cannotBeEmptyValue.hasError = false;
  error.delay.hours.max.hasError = false;
  error.delay.minutes.max.hasError = false;
  error.hasError = false;
};

export const MAX_HOURS = 24;
export const MAX_MINUTES = 59;
