import React from 'react';
import { Form, Size, TextArea } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../../tests/testids';
import { getErrorClass } from '../../../../utils';
import i18n from '../../../../i18n';
import { FormActionType } from '../../shared';

interface Props {
  onChange: (event: React.ChangeEvent) => void;
  label: string;
  value: string | undefined;
  errorMessage: string;
  action: FormActionType;
}

export const Description: React.FC<Props> = ({ label, onChange, value, errorMessage, action }) => {
  return (
    <Form.Item
      component={TextArea}
      placeholder={i18n.t('general.optional')}
      label={label}
      rows={2}
      required={false}
      id={`${action}-zone-and-location-dialog-description`}
      width={Size.Auto}
      value={value}
      onChange={onChange}
      className={getErrorClass(errorMessage)}
      errors={errorMessage}
      data-ta={TEST_IDS.site_tree_form_dialog_description_field}
    />
  );
};
