import * as React from 'react';
import './group-activation-dialog.scss';
import { Size, Button, ButtonType, BodyText } from '@vaisala/rockhopper-components';
import { GroupInterface } from '../../../profile/user';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from '../../Modals/ModalContainer';
import { ButtonWithLoader } from '../../ButtonWithLoader/ButtonWithLoader';
import { getConstantFromLocalStorage } from '../../../utils';
import { LS_COMPANY_CUSTOMER_ID } from '../../../constants';
import { TEST_IDS } from '../../../tests/testids';

interface GroupActivationDialog {
  groupDetails: GroupInterface;
  onDismiss: () => void;
  groupActivateApiDetails: {
    toggleGroupActivation: (args: { active: boolean; groupId: string; name: string; customerId: string }) => void;
    result: { isLoading: boolean; isQueryEnded: boolean };
  };
}

const GroupActivationDialog = (props: GroupActivationDialog): JSX.Element => {
  const { t } = useTranslation();
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const { result, toggleGroupActivation } = props.groupActivateApiDetails;
  const isActivating = !props.groupDetails.active;

  React.useEffect(() => {
    if (result.isQueryEnded) {
      props.onDismiss();
    }
  }, [result.isQueryEnded]);

  const confirmAlertModal = () => {
    props.groupDetails &&
      toggleGroupActivation({
        active: isActivating,
        groupId: props.groupDetails?.group_id,
        name: props.groupDetails?.group_name,
        customerId
      });
  };
  const alertModalVisibleFirstMsg = isActivating ? t('profile.activateGroupMsg') : t('profile.deactivateGroupFirstMsg');
  const alertModalVisibleSecondMsg = isActivating ? '' : t('profile.deactivateGroupSecondMsg');

  const modalButtons = (
    <>
      <ButtonWithLoader
        id="group-activation-confirm"
        htmlId="group-activation-confirm"
        onClick={confirmAlertModal}
        buttonType={ButtonType.Secondary}
        loading={result.isLoading}
        disabled={result.isLoading}
        dataTa={TEST_IDS.users_and_groups_active_toggle_confirm_button}
      >
        {isActivating ? t('profile.activateGroupBtn') : t('profile.deactivateGroupBtn')}
      </ButtonWithLoader>
      <Button id="group-activation-cancel" htmlId="group-activation-cancel" onClick={props.onDismiss}>
        {t('profile.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id="group-activation-dialog"
      isOpen
      htmlId="group-activation-dialog"
      showCloseIcon={true}
      onDismiss={props.onDismiss}
      width={Size.S}
      dismissOnOverlayClick={false}
      role="alert-dialog"
      title={isActivating ? 'profile.activateGroupHeader' : 'profile.deactivateGroupHeader'}
      buttons={modalButtons}
    >
      <BodyText id="group-activation-text" className="activate-group-body">
        <p>{alertModalVisibleFirstMsg}</p>
        <br />
        <p>{alertModalVisibleSecondMsg}</p>
      </BodyText>
    </ModalContainer>
  );
};

export default GroupActivationDialog;
