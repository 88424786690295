import { useEffect, useRef } from 'react';

export function useOutsideClick<T extends HTMLElement>(callback) {
  const ref = useRef<T>();

  useEffect(() => {
    const handleClick = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.body.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
}
