import { StrictSchema, SCHEMA_OPTIONS_SYMBOL } from 'morphism';
import { timestampToTimeString } from '../utils/common';
import { EventCategoryI18nKeys } from '../constants';
import { IMakeTestIdProps } from '../utils/makeTestId';

export interface EventInterface {
  eventId: string;
  object: string;
  obj_id?: string;
  action: string;
  source: string;
  category: string;
  category_i18n_key: EventCategoryI18nKeys;
  affectedlocation: string;
  affectedlocation_id: string;
  parameters: string[][];
  time: string;
}

export interface EventApiInterface {
  customer_id: string;
  event_id: string;
  timestamp: number;
  user_name: string;
  user_name_idx: string;
  site_id?: string;
  location?: string;
  location_id?: string;
  message: string;
  parameters: string[][];
  category: string;
  category_i18n_key: EventCategoryI18nKeys;
  source?: string;
  action: string;
  obj: string;
  obj_id: string;
  ticket_id?: string;
}

export interface GetEventsProp {
  start: number;
  size: number;
  from: number;
  to: number;
  search?: string;
  category?: string;
  action?: string;
  source_type?: string;
  locale: string;
  location_ids?: string;
}

export interface EventsProps extends IMakeTestIdProps {
  events: EventInterface[];
  eventsLoading: boolean;
  getEvents: (query: GetEventsProp) => void;
  deviceType?: string;
  start?: number;
  language: string;
  totalCount: number;
}

export const schemaEventInterface: StrictSchema<EventInterface, EventApiInterface> = {
  [SCHEMA_OPTIONS_SYMBOL]: { undefinedValues: { strip: true } },
  eventId: 'event_id',
  object: 'obj',
  obj_id: 'obj_id',
  action: 'action',
  source: 'source',
  category: 'category',
  category_i18n_key: 'category_i18n_key',
  affectedlocation: 'location',
  affectedlocation_id: 'location_id',
  parameters: 'parameters',
  time: {
    path: 'timestamp',
    fn: value => timestampToTimeString(parseInt(value))
  }
};
