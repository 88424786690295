import { Checkbox, Table, TableBody, TableCell, TableRow } from '@vaisala/rockhopper-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Infotip, { InfotipProps } from '../../Utils/Infotip';
import { CheckboxState, MultiStateCheckbox } from '../../MultiStateCheckbox';
import './infotip.scss';

interface Props extends InfotipProps {
  readonly?: boolean;
}

export const GroupRightsInfotip = (props: Props) => {
  const { t } = useTranslation();

  const renderRightsTableHelp = (): JSX.Element[] => {
    const values = [
      [
        <div key="dummy-checked" className="group-rights-accordion-checkbox">
          <MultiStateCheckbox
            readOnly={props.readonly}
            state={CheckboxState.CHECKED}
            disabled={true}
            id="dummy-checked"
          />
        </div>,
        t('infotip.rights.checked')
      ],
      [
        <div
          key="dummy-not-checked"
          data-readonly={!!props.readonly}
          className="group-rights-accordion-checkbox unchecked"
        >
          <MultiStateCheckbox
            readOnly={props.readonly}
            state={CheckboxState.UNCHECKED}
            disabled={true}
            id="dummy-not-checked"
          />
        </div>,
        t('infotip.rights.notChecked')
      ],
      [
        <div key="dummy-partially-checked" className="group-rights-accordion-checkbox partially">
          <MultiStateCheckbox
            state={CheckboxState.UNCHECKED_INVERTED}
            id="dummy-partially-checked"
            disabled={true}
            readOnly={props.readonly}
          />
        </div>,
        t('infotip.rights.partial')
      ],
      [
        <div
          key="dummy-kinda-checked"
          data-readonly={!!props.readonly}
          className="group-rights-accordion-checkbox inverted-check"
        >
          <MultiStateCheckbox
            state={CheckboxState.CHECKED_INVERTED}
            readOnly={props.readonly}
            id="dummy-kinda-checked"
            disabled={true}
          />
        </div>,
        t('infotip.rights.inverted')
      ]
    ];
    return values.map((value: [JSX.Element, any], index: number) => {
      const [cb, text] = value;
      return (
        <TableRow key={`rights-checkbox--${index}`}>
          <TableCell>{cb}</TableCell>
          <TableCell className="vai-padding-vertical-s">{text}</TableCell>
        </TableRow>
      );
    });
  };
  return (
    <Infotip {...props}>
      <h4>{t('infotip.rights.heading')}</h4>
      <Table>
        <TableBody>{renderRightsTableHelp()}</TableBody>
      </Table>
      <p className="vai-margin-top-m vai-margin-horizontal-none">{t('infotip.rights.para1')}</p>
    </Infotip>
  );
};
