import React from 'react';
import {
  Button,
  ButtonType,
  Size,
  Slider,
  Form,
  Select,
  Toggle,
  BodyText,
  NotificationType,
  Spinner
} from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { ModalContainer } from '../../Modals/ModalContainer';
import { hideDialog } from '../../../store/dialog';
import { useAppDispatch } from '../../../store/hooks';
import { DeviceConfigModel } from '../../../types';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { TEST_IDS } from '../../../tests/testids';
import { useEditDevicePropertiesMutation } from '../../../store/services/devicesApi';
import { addNotification } from '../../../store/notifications';
import { PRODUCT_NAME } from '../../../constants';

import {
  vaiNetChannelOptions,
  vainetNetChannelOptionsType,
  APSliderOptions,
  PARENT_SITE_KEY
} from '../../../constants';

interface Props {
  config: DeviceConfigModel;
  deviceId: string;
}

const EditGatewayProperties: React.FC<Props> = ({ config, deviceId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [vaiNetChannel, setVaiNetChannel] = React.useState(
    config.reported?.device?.apId ? config.reported.device.apId : 0
  );
  const [APDisplay, setAPDisplay] = React.useState(
    config.reported?.display?.enabled ? config.reported.display.enabled : false
  );
  const [APLED, setAPLED] = React.useState(config.reported?.led?.enabled ? config.reported.led.enabled : false);
  const [APDisplayBrightness, setAPDispalyBrightness] = React.useState(
    config.reported?.display?.brightness ? config.reported.display.brightness : 0
  );
  const [APLEDBrightness, setAPLEDBrightness] = React.useState(
    config.reported?.led?.brightness ? config.reported.led.brightness : 0
  );

  const isDeviceModified =
    config.reported?.display?.enabled !== APDisplay ||
    config.reported?.device?.apId !== vaiNetChannel ||
    config.reported?.led?.enabled !== APLED ||
    config.reported?.display?.brightness !== APDisplayBrightness ||
    config.reported?.led?.brightness !== APLEDBrightness;

  const [editDeviceProperties, editDevicePropertiesResult] = useEditDevicePropertiesMutation();

  const { min, max, marks } = APSliderOptions;

  const onDismiss = () => {
    dispatch(hideDialog());
  };

  const onSavingDeviceProperties = () => {
    editDeviceProperties({
      deviceId: deviceId,
      siteId: PARENT_SITE_KEY,
      config: {
        device: {
          apId: vaiNetChannel
        },
        display: {
          enabled: APDisplay,
          brightness: APDisplayBrightness
        },
        led: {
          enabled: APLED,
          brightness: APLEDBrightness
        }
      }
    });
  };

  const onAPDisplayBrightnessChange = nextValue => {
    setAPDispalyBrightness(nextValue);
  };

  const onAPLEDBrightnessChange = nextValue => {
    setAPLEDBrightness(nextValue);
  };

  React.useEffect(() => {
    if (editDevicePropertiesResult.isSuccess) {
      onDismiss();
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: t('devicemanager.editProperties.toaster.success', { deviceId: deviceId, productName: PRODUCT_NAME })
        })
      );
    } else if (editDevicePropertiesResult.isError) {
      onDismiss();
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: t('devicemanager.editProperties.toaster.error', { deviceId: deviceId })
        })
      );
    }
  }, [editDevicePropertiesResult.isSuccess, editDevicePropertiesResult.isError]);

  const ModalButtons = (
    <>
      <Button
        id={QA_TEST_IDS.edit_ap10_properties_save_button}
        dataTa={TEST_IDS.edit_ap10_properties_save_button}
        className="vai-space-inline-s"
        buttonType={editDevicePropertiesResult.isLoading ? ButtonType.Secondary : ButtonType.Primary}
        onClick={onSavingDeviceProperties}
        disabled={!isDeviceModified || editDevicePropertiesResult.isLoading}
      >
        {editDevicePropertiesResult.isLoading ? <Spinner /> : t('general.save')}
      </Button>
      <Button
        id={QA_TEST_IDS.edit_ap10_properties_cancel_button}
        dataTa={TEST_IDS.edit_ap10_properties_cancel_button}
        buttonType={ButtonType.Secondary}
        onClick={onDismiss}
      >
        {t('general.cancel')}
      </Button>
    </>
  );

  return (
    <ModalContainer
      id={QA_TEST_IDS.edit_ap10_properties_modal_container}
      dataTa={TEST_IDS.edit_ap10_properties_modal_container}
      isOpen
      showCloseIcon={true}
      dismissOnOverlayClick={false}
      title={'devicemanager.editProperties.title'}
      onDismiss={onDismiss}
      buttons={ModalButtons}
    >
      <Form className="device-manages-edit-properties-form-container">
        <Form.Item labelWidth={Size.M} label={t('devicemanager.editProperties.vainetChannel')}>
          <Select
            id={QA_TEST_IDS.edit_ap10_properties_vaiNet_channel_select}
            dataTa={TEST_IDS.edit_ap10_properties_vaiNet_channel_select}
            width={Size.M}
            value={vaiNetChannel}
            onSelect={value => setVaiNetChannel(value)}
          >
            {vaiNetChannelOptions.map(({ label, value }: vainetNetChannelOptionsType, index: number) => (
              <Select.Option key={index} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('deviceManager.display')}>
          <Toggle
            htmlId={QA_TEST_IDS.edit_ap10_properties_ap_display_toggle}
            dataTa={TEST_IDS.edit_ap10_properties_ap_display_toggle}
            text={t('general.on')}
            uncheckedText={t('general.off')}
            checked={APDisplay}
            onChange={APDisplay => setAPDisplay(APDisplay)}
          />
        </Form.Item>
        <Form.Item label={t('deviceManager.displayBrightness')}>
          <Slider
            htmlId={QA_TEST_IDS.edit_ap10_properties_display_brightness_slider}
            dataTa={TEST_IDS.edit_ap10_properties_display_brightness_slider}
            useInput
            value={APDisplayBrightness}
            onChange={onAPDisplayBrightnessChange}
            min={min}
            max={max}
            marks={marks}
            step={25}
            disabled={!APDisplay}
            inputFieldProps={{
              dataTa: TEST_IDS.edit_ap10_properties_display_brightness,
              readOnly: true,
              unitLabel: '%'
            }}
          />
        </Form.Item>
        <Form.Item label={t('deviceManager.LED')}>
          <Toggle
            htmlId={QA_TEST_IDS.edit_ap10_properties_ap_led_toggle}
            dataTa={TEST_IDS.edit_ap10_properties_ap_led_toggle}
            text={t('general.on')}
            uncheckedText={t('general.off')}
            checked={APLED}
            onChange={APLED => setAPLED(APLED)}
          />
        </Form.Item>
        <Form.Item label={t('deviceManager.LEDBrightness')}>
          <Slider
            htmlId={QA_TEST_IDS.edit_ap10_properties_led_brightness_slider}
            dataTa={TEST_IDS.edit_ap10_properties_led_brightness_slider}
            useInput
            value={APLEDBrightness}
            onChange={onAPLEDBrightnessChange}
            min={min}
            max={max}
            marks={marks}
            step={25}
            disabled={!APLED}
            inputFieldProps={{
              dataTa: TEST_IDS.edit_ap10_properties_led_brightness,
              readOnly: true,
              unitLabel: '%'
            }}
          />
        </Form.Item>
        <BodyText
          htmlId={QA_TEST_IDS.edit_ap10_properties_message}
          dataTa={TEST_IDS.edit_ap10_properties_message}
          className="grey-medium"
        >
          {t('devicemanager.editProperties.message')}
        </BodyText>
      </Form>
    </ModalContainer>
  );
};

export default EditGatewayProperties;
