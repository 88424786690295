import * as React from 'react';
import { Tooltip } from '@vaisala/rockhopper-components';

interface TruncatedTextProps {
  text: string;
  className?: string;
  dataTa?: string;
}

const TruncatedText: React.FunctionComponent<TruncatedTextProps> = ({ text, className, dataTa }) => {
  const textNode = React.useRef<HTMLSpanElement>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (textNode.current) {
      textNode.current.addEventListener('mouseover', () => {
        if (textNode.current.scrollHeight > textNode.current.offsetHeight && !showTooltip) {
          setShowTooltip(true);
        } else if (textNode.current.scrollHeight <= textNode.current.offsetHeight && showTooltip) {
          setShowTooltip(false);
        }
      });
    }
  }, [textNode.current]);
  const dataTaProp = dataTa ? { ['data-ta']: dataTa } : {};
  return (
    <>
      <span {...dataTaProp} ref={textNode} className={`truncated-text ${className}`}>
        {text}
      </span>
      {showTooltip && <Tooltip triggerRef={textNode}>{text}</Tooltip>}
    </>
  );
};

export default TruncatedText;
