import React from 'react';
import { Form, Select, Size } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../../tests/testids';
import { getErrorClass } from '../../../../utils';
import decimalPlaces from '../../../../data/decimalPlaces.json';
import { FormActionType } from '../../shared';

const [_one_decimal, default_two_decimal] = decimalPlaces;

interface Props {
  onChange: (event: React.ChangeEvent) => void;
  label: string;
  value: number | string | undefined;
  errorMessage: string;
  action: FormActionType;
}

export const DecimalPlaces: React.FC<Props> = ({ label, onChange, value, errorMessage, action }) => {
  return (
    <Form.Item
      label={label}
      component={Select}
      required={true}
      id={`${action}-zone-and-location-dialog-decimal-places`}
      defaultValue={default_two_decimal}
      value={value}
      width={Size.M}
      onChange={onChange}
      className={getErrorClass(errorMessage)}
      data-ta={TEST_IDS.site_tree_form_dialog_decimal_places_field}
    >
      {decimalPlaces.map(option => (
        <Select.Option
          key={`${option.value}`}
          title={option.label}
          value={option.value}
          id={`${action}-zone-and-location-dialog-${option.value}`}
        >
          {option.label}
        </Select.Option>
      ))}
    </Form.Item>
  );
};
