import React from 'react';
import { getConstantFromLocalStorage } from '../../../../utils';
import { LocationNode } from '../../../../siteTree/site';
import { LS_COMPANY_CUSTOMER_ID } from '../../../../constants';
import {
  DeviceAlarmSettings,
  useGetDeviceAlarmSettingsQuery,
  usePostDeviceAlarmSettingsMutation
} from '../../../../store/services/alarmsApi';
import { Group } from '../../../../store/services/userApi';
import { DeviceAccordionContent } from './DeviceAccordionContent';
import './styles.scss';

interface Props {
  locationDetails: { location: LocationNode | undefined; isLoading: boolean };
  canManageAlarms: boolean;
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
}

export const DeviceAlarmAccordion: React.FC<Props> = ({
  locationDetails,
  canManageAlarms,
  notificationGroupsDetails
}) => {
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const locationId = locationDetails.location?.node_id;
  const {
    currentData: deviceAlarmSettings,
    isFetching: isFetchingDeviceAlarmSettings,
    refetch: refetchSettingDetails
  } = useGetDeviceAlarmSettingsQuery(
    {
      customerId: customerId,
      location_id: locationId
    },
    { refetchOnMountOrArgChange: true, skip: !locationDetails.location && !locationDetails.isLoading }
  );

  const [saveSettings, saveResults] = usePostDeviceAlarmSettingsMutation();

  const onSave = (settings: DeviceAlarmSettings) => {
    saveSettings({
      customerId,
      location_id: locationId,
      body: settings
    });
  };

  const settingsDetails = {
    settings: deviceAlarmSettings,
    isFetching: isFetchingDeviceAlarmSettings,
    refetch: refetchSettingDetails
  };

  const savingDetails = {
    isSaving: saveResults.isLoading,
    isSaved: saveResults.isSuccess,
    isSaveFailed: saveResults.isError
  };

  return (
    <DeviceAccordionContent
      canManageAlarms={canManageAlarms}
      locationDetails={locationDetails}
      notificationGroupsDetails={notificationGroupsDetails}
      settingsDetails={settingsDetails}
      onSave={onSave}
      savingDetails={savingDetails}
    />
  );
};
