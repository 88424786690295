import { Button, ButtonType, Drawer, Icon, NavTabs, Stack } from '@vaisala/rockhopper-components';
import React from 'react';
import { SIDEBAR_ANCHOR, SIDEBAR_WIDTH } from '../../constants';
import { Languages } from '../../i18n';
import { selectContent, SidebarContent, setSideBarContent, setIsOpen } from '../../store/sidebar';
import { TEST_IDS } from '../../tests/testids';
import { EmptyState } from '../Utils/EmptyState/EmptyState';
import Help from './content/Help';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import Profile from './content/Profile';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { useTranslation } from 'react-i18next';

type SidebarProps = {
  isOpen?: boolean;
  activeTab?: 'string';
  language?: Languages;
};

const Sidebar: React.FC<SidebarProps> = ({ isOpen = false, language = Languages.en }) => {
  const selectedContent = useAppSelector(selectContent);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const tabs = [
    {
      key: SidebarContent.SOFTWARE_ASSURANCE,
      title: t('general.csa'),
      content: <p>{t('header.softwareAssurance')}</p>,
      buttonTestId: TEST_IDS.sidebar_software_assurance_hide_button,
      tabTestId: TEST_IDS.sidebar_software_assurance_tab
    },
    {
      key: SidebarContent.HELP,
      title: t('general.help'),
      content: <Help language={language} />,
      buttonTestId: TEST_IDS.sidebar_help_hide_button,
      tabTestId: TEST_IDS.sidebar_help_tab
    },
    {
      key: SidebarContent.TOURS,
      title: t('header.tours'),
      content: <p>{t('header.tours')}</p>,
      buttonTestId: TEST_IDS.sidebar_tour_hide_button,
      tabTestId: TEST_IDS.sidebar_tour_tab
    },
    {
      key: SidebarContent.PROFILE,
      title: t('general.profile'),
      content: <Profile />,
      buttonTestId: TEST_IDS.sidebar_profile_hide_button,
      tabTestId: TEST_IDS.sidebar_profile_tab
    }
  ];

  return (
    <Drawer
      dataTa={TEST_IDS.sidebar_drawer}
      isOpen={isOpen}
      anchor={SIDEBAR_ANCHOR}
      width={SIDEBAR_WIDTH}
      // underNavigation={true}
    >
      <NavTabs
        selectedTabKey={selectedContent}
        onSelectedTabChanged={selectedTabKey => dispatch(setSideBarContent(selectedTabKey))}
      >
        {tabs.map((tab, index) => (
          <NavTabs.Tab dataTa={tab.tabTestId} key={tab.key} tabKey={tab.key} title={tab.title}>
            <Stack>
              <Button
                style={{ marginLeft: 'auto' }}
                buttonType={ButtonType.Link}
                endIcon={<Icon name={VaiIcon.DoubleChevronRight} />}
                onClick={() => dispatch(setIsOpen(false))}
                dataTa={tab.buttonTestId}
              >
                {t('general.hide')}
              </Button>
              {tab.content}
            </Stack>
          </NavTabs.Tab>
        ))}
      </NavTabs>
    </Drawer>
  );
};

export default Sidebar;
