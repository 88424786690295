import { cloneDeep, isEmpty } from 'lodash';
import { isLocationNode } from '../../utils';
import { LOCATION_KEY, SITE_KEY, ZONE_KEY } from '../../constants';
import { AccordionTreeNode } from './AccordionTree';

export interface SelectionOptions {
  nonSelectableNodes: (string | '*')[];
  expandedNodes: (string | '*')[];
  selectedNodes: (string | '*')[];
  forcedSelection: boolean | null;
}
interface VisibilityOptions {
  forcedVisibility?: boolean | null;
  showLocations?: boolean;
}

export const DEFAULT_SELECTION_OPTIONS = {
  nonSelectableNodes: [],
  expandedNodes: [],
  selectedNodes: [],
  forcedSelection: null
};

const DEFAULT_VISIBILITY_OPTIONS: VisibilityOptions = {
  forcedVisibility: null,
  showLocations: true
};

type SelectionResult = {
  isSelected: boolean;
  selectedNodes: { id: string; type: typeof LOCATION_KEY | typeof SITE_KEY | typeof ZONE_KEY; visible: boolean }[];
};

export const applyNodeVisibility = (
  node: AccordionTreeNode,
  match: string,
  options: VisibilityOptions = DEFAULT_VISIBILITY_OPTIONS
) => {
  if (options.forcedVisibility !== null) {
    node.visible = options.forcedVisibility;
  } else {
    if (node.name.includes(match)) {
      node.visible = true;
    } else {
      node.visible = false;
    }
  }

  const hasVisibleChild = !isLocationNode(node)
    ? node.children?.reduce((acc, curr) => {
        const isVisible = applyNodeVisibility(curr, match, options);
        return isVisible || acc;
      }, node.visible)
    : node.visible;

  node.visible = hasVisibleChild || node.visible;

  if (isLocationNode(node) && !options.showLocations) {
    node.visible = false;
  }

  return hasVisibleChild || node.visible || false;
};

export const applyNodeSelection = (
  node: AccordionTreeNode,
  targetId?: string,
  options: SelectionOptions = DEFAULT_SELECTION_OPTIONS
): SelectionResult => {
  const memo = new Map();

  const selectNodes = (
    node: AccordionTreeNode,
    targetId?: string,
    options: SelectionOptions = DEFAULT_SELECTION_OPTIONS
  ) => {
    if (memo.has(node.node_id)) {
      return memo.get(node.node_id);
    }
    const shouldChangeSelection = node.node_id === targetId || options.forcedSelection !== null;
    if (!node.selected) {
      node.selected = { full: false, partially: false };
    }

    if (shouldChangeSelection) {
      if (options.forcedSelection !== null) {
        node.selected.full = options.selectedNodes.includes(node.node_id) || options.forcedSelection;
        node.selected.partially = false;
      } else {
        node.selected.full = !node.selected?.full;
        node.selected.partially = node.selected.full ? false : node.selected.partially;
      }
      !isLocationNode(node) &&
        node.children.forEach(child => {
          selectNodes(child, targetId, { ...options, forcedSelection: node.selected.full });
        });
    }

    if (isLocationNode(node) || (!isLocationNode(node) && isEmpty(node.children))) {
      const result = { isSelected: !node.selected.full, selectedNodes: [] };
      if (!options.nonSelectableNodes.includes(node.node_id) && node.selected.full) {
        result.selectedNodes.push({ id: node.node_id, type: node.type, visible: node.visible });
      }
      memo.set(node.node_id, result);

      return result;
    }

    const { isSelected: hasUnselectedChild, selectedNodes } = node.children.reduce(
      (acc, curr) => {
        const { isSelected, selectedNodes } = selectNodes(curr, targetId, options);
        return { isSelected: isSelected || acc.isSelected, selectedNodes: [...selectedNodes, ...acc.selectedNodes] };
      },
      { isSelected: false, selectedNodes: [] }
    );

    node.selected.partially = selectedNodes.length !== 0 && hasUnselectedChild;
    node.selected.full = !hasUnselectedChild;

    if (
      !options?.nonSelectableNodes?.includes(node.node_id) &&
      !options?.selectedNodes?.includes(node.node_id) &&
      node.selected.full
    ) {
      selectedNodes.push({ id: node.node_id, type: node.type, visible: node.visible });
    }
    memo.set(node.node_id, { isSelected: hasUnselectedChild || !node.selected.full, selectedNodes });
    return { isSelected: hasUnselectedChild || !node.selected.full, selectedNodes };
  };
  return selectNodes(node, targetId, options);
};

export const applyLocationsCount = site => {
  const cloneSite = cloneDeep(site);

  const visitNode = node => {
    if (isLocationNode(node)) {
      return 1;
    }
    node.locationsCount = node.children.reduce((acc, curr) => {
      const count = visitNode(curr);
      return acc + count;
    }, 0);

    return node.locationsCount;
  };

  site && visitNode(cloneSite);
  return cloneSite;
};
