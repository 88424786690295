import * as React from 'react';
import validator from 'validator';
import { Form, Heading, Label, InputField, Size, Button, Spinner } from '@vaisala/rockhopper-components';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../utils/routes';
import { getConstantFromLocalStorage } from '../../utils/common';
import { SendResetCodeError } from '../../store/actions/auth';
import {
  USERNAME_MAX_CHARACTERS_LENGTH,
  COMPANY_NAME_MAX_CHARACTERS_LENGTH,
  COMPANY_NAME_MIN_CHARACTERS_LENGTH,
  LS_COMPANY_ALIAS
} from '../../constants';

import './forgot-password.scss';
import { useTranslation } from 'react-i18next';
import { getErrorClass } from '../../utils';
import { TEST_IDS } from '../../tests/testids';

interface ForgotPasswordProps {
  sendingResetCode: boolean;
  sendingResetCodeError?: string;
  sendResetCode: (email: string, companyName: string) => void;
}

const ForgotPassword = ({
  sendingResetCode,
  sendingResetCodeError,
  sendResetCode
}: ForgotPasswordProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [companyName, setCompanyName] = React.useState(getConstantFromLocalStorage(LS_COMPANY_ALIAS) || '');
  const [requestSent, setRequestSent] = React.useState(false);
  const [inputError, setInputError] = React.useState('');
  const [companyError, setCompanyError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);

  /**
   * Redirect after request sent when successful result has been received.
   */
  React.useEffect(() => {
    if (!requestSent) {
      return;
    }
    if (!sendingResetCode) {
      if (!sendingResetCodeError) {
        // replaces history.push
        const loginURL = '/login/';
        navigate(loginURL + routes.login.resetPasswordConfirmation.url);
      } else {
        // If error, clear sending status.
        setRequestSent(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingResetCode]);

  /**
   * If error has occurred, set input error.
   */
  React.useEffect(() => {
    if (sendingResetCodeError) {
      if (sendingResetCodeError === SendResetCodeError.UserNotFoundException) {
        setInputError(t('auth.userNotFoundWithEmail'));
      } else if (sendingResetCodeError === SendResetCodeError.NetworkError) {
        setInputError(t('general.networkError'));
      } else {
        setInputError(t('auth.couldNotSendEmail'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingResetCodeError]);

  const onSubmit = () => {
    const trimmedEmail = validator.trim(email);
    const trimmedCompanyName = validator.trim(companyName);
    sendResetCode(trimmedEmail, trimmedCompanyName);
    setRequestSent(true);
  };

  const onChange = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget;
    setInputError('');
    if (name === 'email') {
      setEmail(value);
      value.length > USERNAME_MAX_CHARACTERS_LENGTH ? setEmailError(true) : setEmailError(false);
    } else if (name === 'companyName') {
      setCompanyName(value);
      value.length > COMPANY_NAME_MAX_CHARACTERS_LENGTH ? setCompanyError(true) : setCompanyError(false);
    }
  };

  const isButtonDisabled = React.useCallback(() => {
    return (
      emailError ||
      !email ||
      !validator.isEmail(validator.trim(email)) ||
      validator.trim(companyName).length < COMPANY_NAME_MIN_CHARACTERS_LENGTH ||
      companyError
    );
  }, [sendingResetCode, companyName, email, companyError]);

  return (
    <Form htmlId="forgot-password-form" preventDefault={true} onSubmit={onSubmit}>
      <Heading htmlId="forgot-password-heading" level={2} className="forgot-password-title">
        {t('auth.forgotPasswordTitle')}
      </Heading>
      <p id="forgot-password-help-text" className="forgot-help">
        {t('auth.typeUsernameForCode')}
      </p>
      <Label htmlId="company-name-label" className="vai-padding-right-none">
        {t('auth.companyID')}
        <InputField
          htmlId="login-company-name"
          dataTa={TEST_IDS.forgot_password_company_name}
          value={companyName}
          name="companyName"
          onChange={onChange}
          width={Size.Container}
          autoComplete="companyName"
          className={getErrorClass(
            companyError ? t('auth.companyIDCharacterLimit', { length: COMPANY_NAME_MAX_CHARACTERS_LENGTH }) : ''
          )}
        />
        <span id="company-name-error" className="input-error">
          {companyError ? t('auth.companyIDCharacterLimit', { length: COMPANY_NAME_MAX_CHARACTERS_LENGTH }) : ''}
        </span>
      </Label>
      <Label htmlId="email-label" className="vai-padding-right-none">
        {t('auth.email')}
        <InputField
          value={email}
          dataTa={TEST_IDS.forgot_password_email}
          name="email"
          onChange={onChange}
          width={Size.Container}
        />
        <span id="email-error" className="input-error">
          {inputError}
        </span>
        <span id="email-error" className="input-error">
          {emailError ? t('auth.usernameCharacterLimit', { length: USERNAME_MAX_CHARACTERS_LENGTH }) : ''}
        </span>
      </Label>
      <div id="buttons-container" className="app-login-submit vai-margin-top-m">
        <Link id="back-link" to={routes.login.url}>
          {t('general.back')}
        </Link>
        <Button id="forgot-password" disabled={isButtonDisabled()} type={'submit'}>
          {sendingResetCode ? <Spinner htmlId="login-spinner" /> : t('auth.sendEmailButton')}
        </Button>
      </div>
    </Form>
  );
};

export default ForgotPassword;
