import { SITE_ZONES_ACCESS_KEYS } from '../constants';
import { SiteInterface } from '../siteTree/site';
export interface createGroupsInterface {
  group_name: string;
  description: string;
}

export interface EditGroupsInterface {
  group_name?: string;
  description?: string;
  group_id: string;
  rights?: Right;
  rights_global?: number;
}

export interface Right {
  [key: string]: number;
}
export interface RightState {
  key: SITE_ZONES_ACCESS_KEYS;
  value: number;
  id: string;
}
export interface SiteChangeProp {
  level: string;
  nodeId: string;
  right: RightState;
}
export interface SiteRights {
  id: string;
  key: SITE_ZONES_ACCESS_KEYS;
  value: number;
}
export interface SiteRightsInterface extends SiteInterface {
  rights?: RightState[];
}

export enum GroupSortValue {
  byName = 'byName',
  byMostRecentChanges = 'byMostRecentChanges',
  byNumberOfUsersAsc = 'byNumberOfUsersAsc',
  byNumberOfUsersDesc = 'byNumberOfUsersDesc'
}

export type GroupSortValueType = keyof typeof GroupSortValue | null;
