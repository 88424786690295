import * as React from 'react';
import { Size, Modal, Heading } from '@vaisala/rockhopper-components';
import { UserInterface } from '../../../profile/user';
import { SiteInterface } from '../../../siteTree/site';

import './user-effective-rights.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog } from '../../../store/dialog';
import { RightsAccordionTree } from '../../RightsAccordionTree/RightsAccordionTree';

interface userEffectiveRightsDialogProps {
  site: SiteInterface;
  userProfile: UserInterface;
}

const UserEffectiveRightsDialog = (props: userEffectiveRightsDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onDismiss = () => dispatch(hideDialog());

  return (
    <Modal
      id="user-effective-rights-dialog"
      className="user-effective-rights-dialog"
      isOpen
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.L}
      dismissOnOverlayClick={false}
    >
      <Heading id="user-effective-rights-header" className="user-effective-rights-header vai-margin-top-none">
        <span id="user-effective-rights-summary">
          {t('profile.rightSummary', {
            userName:
              props.userProfile.first_name.length > 0
                ? props.userProfile.first_name + ' ' + props.userProfile.last_name
                : props.userProfile.email
          })}
        </span>
      </Heading>
      <p id="user-effective-rights-name" className="user-effective-rights-name">
        {props.userProfile.first_name.length > 0
          ? props.userProfile.first_name + ' ' + props.userProfile.last_name
          : props.userProfile.email}
      </p>
      <p id="user-effective-rights-description" className="user-effective-rights-description">
        {t('profile.rightSummaryMessage')}
      </p>
      <br />
      <RightsAccordionTree
        readonly={true}
        site={props.site}
        expandedNodes={[props.site.node_id]}
        rightDetails={{ rights: props.userProfile.rights, rightsGlobal: props.userProfile.rights_global }}
      />
    </Modal>
  );
};

export default UserEffectiveRightsDialog;
