import { useState, useEffect } from 'react';
import { LOCATION_STATUS, THRESHOLD_STATUS, ALARM_PRIORITY } from '../constants';
import { AlarmSetState, LocationNode } from '../siteTree/site';
import { isAlarmState } from '../utils';

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **No data**
 * * If the location is deactivated
 * * If the location is unlinked
 * * If the location is linked but has not yet received it’s first measurement point. (in the first 3 minutes of linking)
 */
function isThresholdNoData(location: LocationNode): boolean {
  return !location.active || location.status === LOCATION_STATUS.UNLINK || location.raw_value === null;
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Unknown**
 * * If the data is stale (No new measurements after 10 minutes) AND there are no threshold alarms active.
 */
function isThresholdUnknown(location: LocationNode): boolean {
  return location.is_stale && !location.threshold_alarms.count;
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Within tolerance**
 * * When there is a set threshold alarm but it is not alarming at the moment.
 */
function isThresholdWithinTolerance(location: LocationNode): boolean {
  return isThresholdSet(location) && location.threshold_alarms && !location.threshold_alarms.count;
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Within tolerance**
 * * When there is a set threshold alarm but it is not alarming at the moment.
 */
function isThresholdAlarming(location: LocationNode): boolean {
  return isThresholdSet(location) && location.threshold_alarms && !!location.threshold_alarms.count;
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Not set**
 * * If all the thres_alarm_set values are default or not set.
 */
export function isThresholdNotSet(location: LocationNode): boolean {
  return isAlarmState.notSet(location.thres_alarm_set) || isAlarmState.default(location.thres_alarm_set);
}

/**
 *
 * @param location {@link LocationNode}
 * @returns boolean
 *
 *  **Set**
 * If any of the thres_alarm_set values are set.
 */
export function isThresholdSet(location: LocationNode): boolean {
  return isAlarmState.set(location.thres_alarm_set);
}

export const useThresholdStatus = (location: LocationNode) => {
  const [statusDetails, setStatusDetails] = useState<{
    isLoading: boolean;
    status: THRESHOLD_STATUS | ALARM_PRIORITY | undefined;
  }>({
    isLoading: true,
    status: undefined
  });

  useEffect(() => {
    if (location) {
      setStatusDetails(() => {
        const updatedDetails = {
          isLoading: false,
          status: undefined
        };
        switch (true) {
          case !location:
            updatedDetails.isLoading = true;
            break;
          case isThresholdNotSet(location):
            updatedDetails.status = THRESHOLD_STATUS.NOT_SET;
            break;
          case isThresholdNoData(location):
            updatedDetails.status = THRESHOLD_STATUS.NO_DATA;
            break;
          case isThresholdUnknown(location):
            updatedDetails.status = THRESHOLD_STATUS.UNKNOWN;
            break;
          case isThresholdAlarming(location):
            updatedDetails.status = location.threshold_alarms.highest_priority;
            break;
          case isThresholdWithinTolerance(location):
            updatedDetails.status = THRESHOLD_STATUS.WITHIN;
            break;
          default:
            updatedDetails.isLoading = true;
            break;
        }
        return updatedDetails;
      });
    }
  }, [location]);

  return statusDetails;
};
