import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Paper,
  InputField,
  Size,
  Icon,
  Select,
  Stack,
  Separator,
  FlexItem,
  Heading
} from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

import RFL100_no_cable from '../../assets/images/RFL100_no_cable.png';
import CA10_small_antenna from '../../assets/images/CA10_small_antenna.png';
import {
  filterDeviceOptions,
  filterStatusOptions,
  deviceManagerSortOptions,
  DeviceTypeFilter,
  DeviceStatusFilter,
  DeviceSortBy,
  FilterStatusOptionsType,
  FilterDeviceOptionsType,
  DeviceManagerSortOptionsType,
  APP_NAME_FULL,
  LS_USERNAME,
  UserSettings
} from '../../constants';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';
import { DeviceListItemModel } from '../../types';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import useHtmlId from '../../hooks/useHtmlId';
import { useNavigate, useParams } from 'react-router';
import { getConstantFromLocalStorage, sortDevices, userSettings } from '../../utils';
import { TEST_IDS } from '../../tests/testids';
import ListError from '../Error/ListError';
import './device-manager.scss';
import DeviceManagerList from './DeviceManagerList';

interface DeviceManagerProps {
  hasDevices: boolean;
  devices: DeviceListItemModel[];
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  highlighted: string;
  setHighlighted: React.Dispatch<React.SetStateAction<string>>;
  onReady: (device: DeviceListItemModel) => void;
  currentRoute: () => string;
  refetch: () => void;
}

const HTML_ID_PROPS = { htmlId: 'device-manager' };

const DeviceManager = ({
  hasDevices,
  devices,
  isLoading,
  error,
  isFetching,
  isSuccess,
  highlighted,
  setHighlighted,
  onReady,
  currentRoute,
  refetch
}: DeviceManagerProps) => {
  const { t } = useTranslation();
  const { getId } = useHtmlId(HTML_ID_PROPS);
  const params = useParams();
  // NOTE: because we are not currently nested under the individual route, React Router returns a * object with the path.
  //  we need to extract the id by pulling it from that url.
  const id: string | null = params['*'] ? params['*'].split('/').splice(-1)[0] : null;
  const navigate = useNavigate();
  const username = getConstantFromLocalStorage(LS_USERNAME);
  const deviceListSorting: DeviceSortBy | undefined = userSettings.sortValueOrDefault(
    username,
    UserSettings.DEVICE_LIST_SORTING,
    DeviceSortBy.RECENTLY,
    Object.values(DeviceSortBy)
  ) as DeviceSortBy;

  const deviceFilterByType: DeviceTypeFilter[] | undefined = userSettings.getCommaSeparated(
    username,
    UserSettings.DEVICE_FILTER_BY_DEVICE_TYPE,
    Object.values(DeviceTypeFilter)
  ) as DeviceTypeFilter[];

  const deviceFilterByStatus: DeviceStatusFilter[] | undefined = userSettings.getCommaSeparated(
    username,
    UserSettings.DEVICE_FILTER_BY_DEVICE_STATUS,
    Object.values(DeviceStatusFilter)
  ) as DeviceStatusFilter[];

  const [searchCriteria, setSearchCriteria] = React.useState<string>('');
  const [filterByDevice, setFilterByDevice] = React.useState<DeviceTypeFilter[]>(deviceFilterByType);
  const [filterByStatus, setFilterByStatus] = React.useState<DeviceStatusFilter[]>(deviceFilterByStatus);
  const [sortBy, setSortBy] = React.useState<DeviceSortBy>(deviceListSorting);
  // NOTE: Results are filtered and sorted in the UI and not by the endpoint.
  const [filteredResults, setFilteredResults] = React.useState<DeviceListItemModel[]>([]);

  React.useEffect(() => {
    if (!devices) return;
    // Sort and filter when the device list is initially fetched
    const results = sortAndFilter();
    setFilteredResults(results);
    onReady(results?.length > 0 ? results[0] : null);
  }, [devices, params]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setSearchCriteria(keyword);
    setHighlighted('');
  };

  const hasSearch = Boolean(searchCriteria);
  const hasDeviceFilter = filterByDevice.length > 0;
  const hasStatusFilter = filterByStatus.length > 0;
  const hasAnyFilters = hasDeviceFilter || hasStatusFilter || hasSearch;

  const handleFilterByDeviceChange = (value: DeviceTypeFilter[]) => {
    userSettings.set(username, UserSettings.DEVICE_FILTER_BY_DEVICE_TYPE, value);
    setFilterByDevice(value);
    setHighlighted('');
  };
  const handleFilterByStatusChange = (value: DeviceStatusFilter[]) => {
    userSettings.set(username, UserSettings.DEVICE_FILTER_BY_DEVICE_STATUS, value);
    setFilterByStatus(value);
    setHighlighted('');
  };
  const handleSortByChange = (value: DeviceSortBy) => {
    userSettings.set(username, UserSettings.DEVICE_LIST_SORTING, value);
    setSortBy(value);
    setHighlighted('');
  };

  const sort = (results: DeviceListItemModel[]): DeviceListItemModel[] => {
    return sortDevices(results, sortBy);
  };

  const filter = (results: DeviceListItemModel[]): DeviceListItemModel[] => {
    // Process all of the filterting in a single filter call to avoid having to loop over the items more than once.
    const filterResult = (result: DeviceListItemModel): boolean => {
      const filterByDeviceResult = hasDeviceFilter
        ? filterByDevice.filter(deviceFilter =>
            result.product_code.toLocaleLowerCase().includes(deviceFilter.toLocaleLowerCase())
          ).length > 0
        : true;
      const filterByStatusResult = hasStatusFilter
        ? filterByStatus.filter(statusFilter => {
            const lowerCaseFilter = statusFilter.toLocaleLowerCase();
            const stateMatch = result.state.toLocaleLowerCase().includes(lowerCaseFilter);
            const errorStateMatch =
              result.errorStates &&
              Object.entries(result.errorStates).some(
                ([key, value]) => value === true && key.toLocaleLowerCase() === lowerCaseFilter
              );
            return stateMatch || errorStateMatch;
          }).length > 0
        : true;
      const searchFilterResult = hasSearch
        ? result.device_id.toLocaleLowerCase().includes(searchCriteria.toLocaleLowerCase())
        : true;
      return filterByDeviceResult && searchFilterResult && filterByStatusResult;
    };
    return results.filter(filterResult);
  };

  const sortAndFilter = (): DeviceListItemModel[] => {
    const newResults = sort(devices);
    if (!hasAnyFilters) {
      return newResults;
    }
    return filter(newResults);
  };

  React.useEffect(() => {
    if (!devices) return;
    // When any of the filtering, sort, or search criteria are changed, sort and filter the results.
    setFilteredResults(sortAndFilter());
  }, [sortBy, filterByStatus, filterByDevice, searchCriteria]);

  // HACK: eslint doesn't like inline functions to set ids on Select items :(
  const deviceTypeSelectId = getId('device-type-select');
  const deviceStatusSelectId = getId('device-status-select');
  const sortSelectId = getId('sort');

  const handleListItemClick = (deviceId: string): void => {
    let route = currentRoute();
    route = route.split('/')[0];
    id !== deviceId && navigate(`${route}/${deviceId}`);
  };

  const refreshResults = (): void => refetch();

  const renderList = () => {
    if ((hasAnyFilters || hasSearch) && filteredResults.length === 0) {
      return (
        <div id={getId('no-results')}>
          <Heading className="vai-margin-left-s" level={3}>
            {t('noResults')}
          </Heading>
        </div>
      );
    } else {
      return (
        <DeviceManagerList
          devices={filteredResults}
          onListItemClick={handleListItemClick}
          routeId={id}
          highlighted={highlighted}
          {...HTML_ID_PROPS}
        />
      );
    }
  };

  return (
    <Paper id={getId('container')} className="device-manager">
      <div id={getId('top')} className="vai-margin-horizontal-m">
        <h3 id={getId('heading')} className="vai-margin-bottom-m">
          {t('deviceManager.heading')}
        </h3>
        <div
          id={getId('search-container')}
          data-ta={TEST_IDS.device_manager_device_list_search_container}
          className="vai-margin-bottom-m"
        >
          <InputField
            id={getId('search-input')}
            data-ta={TEST_IDS.device_manager_device_list_search_input}
            name="deviceManagerSearchInput"
            width={Size.Container}
            placeholder={t('general.search')}
            value={searchCriteria}
            onChange={handleSearch}
            endIcon={<Icon name={VaiIcon.Search} size={Size.M} />}
          />
        </div>
        <Stack id={getId('filters-container')} direction="row" className="device-filters">
          <Flex.Item id={getId('device-type-select-container')}>
            <div id={getId('device-type-select-label')} className="vai-font-size-s strong vai-margin-bottom-s">
              {t('deviceManager.filter.device')}
            </div>
            <Select<DeviceTypeFilter[]>
              id={deviceTypeSelectId}
              className="filter-select"
              mode="tags"
              dataTa={TEST_IDS.device_manager_filter_by_device}
              value={filterByDevice}
              allowClear
              showSearch={false}
              maxTagCount={1}
              dropdownMatchSelectWidth={false}
              placeholder={t('general.selectEllipsis')}
              onChange={handleFilterByDeviceChange}
            >
              {filterDeviceOptions.map(({ label, value }: FilterDeviceOptionsType, index: number) => (
                <Select.Option id={getId(`device-type-option--${value}`)} key={index} value={value}>
                  {t(label)}
                </Select.Option>
              ))}
            </Select>
          </Flex.Item>
          <Flex.Item id={getId('device-status-container')}>
            <div id={getId('device-status-label')} className="vai-font-size-s strong vai-margin-bottom-s">
              {t('deviceManager.filter.status')}
            </div>
            <Select<DeviceStatusFilter[]>
              id={deviceStatusSelectId}
              className="filter-select"
              width={Size.M}
              mode="tags"
              dataTa={TEST_IDS.device_manager_filter_by_status}
              allowClear
              showSearch={false}
              maxTagCount={1}
              placeholder={t('general.selectEllipsis')}
              value={filterByStatus}
              dropdownMatchSelectWidth={false}
              onChange={handleFilterByStatusChange}
            >
              {filterStatusOptions.map(({ label, value }: FilterStatusOptionsType, index: number) => (
                <Select.Option id={`device-status-option--${value}`} key={index} value={value}>
                  {t(label)}
                </Select.Option>
              ))}
            </Select>
          </Flex.Item>
          <Flex.Item id={getId('sort-container')}>
            <div id={getId('sort-label')} className="vai-font-size-s strong vai-margin-bottom-s">
              {t('deviceManager.filter.sort')}
            </div>
            <Select<DeviceSortBy>
              id={sortSelectId}
              dataTa={TEST_IDS.device_manager_sort_by}
              className="filter-select sort"
              width={Size.M}
              value={sortBy}
              dropdownMatchSelectWidth={false}
              onChange={handleSortByChange}
            >
              {deviceManagerSortOptions.map(({ label, value }: DeviceManagerSortOptionsType, index) => (
                <Select.Option id={getId(`sort-option--${label}`)} key={index} value={value}>
                  {t(label)}
                </Select.Option>
              ))}
            </Select>
          </Flex.Item>
        </Stack>
        <Flex justifyContent="space-between">
          <FlexItem
            id={getId('device-count')}
            flexGrow={1}
            className={'vai-margin-top-l'}
            data-count={devices ? devices.length : 0}
          >
            {t('deviceManager.devicesCount', { count: filteredResults?.length || 0 })}
          </FlexItem>
        </Flex>
      </div>
      <Separator className="vai-margin-m" />
      <div id={getId('bottom')} className="vai-margin-horizontal-m vai-margin-bottom-m device-manager--list">
        {isFetching || isLoading ? (
          <CenteredSpinner htmlId={getId('spinner')} />
        ) : error ? (
          <ListError
            errorMsgTranslationKey="deviceManager.errorLoadingResults"
            {...HTML_ID_PROPS}
            refreshResults={refreshResults}
          />
        ) : !hasDevices ? (
          <Flex id={getId('no-devices-container')} flexDirection="column">
            <Stack id={getId('no-devices-stack')} direction="row">
              <Flex.Item id={getId('no-devices-images')} flexBasis={'100%'} style={{ textAlign: 'center' }}>
                <img
                  id={getId('no-devices-ca10')}
                  src={CA10_small_antenna}
                  className="vai-margin-horizontal-s vai-margin-vertical-m icon-width"
                />
                <img
                  id={getId('no-devices-rfl100')}
                  src={RFL100_no_cable}
                  className="vai-margin-horizontal-s vai-margin-vertical-m icon-width"
                />
              </Flex.Item>
            </Stack>
            <Flex.Item id={getId('no-devices-msg')} className="vai-margin-horizontal-m">
              {t('deviceManager.noDevices.list', { appName: APP_NAME_FULL })}
            </Flex.Item>
          </Flex>
        ) : (
          renderList()
        )}
      </div>
    </Paper>
  );
};

export default DeviceManager;
