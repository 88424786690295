import React, { useEffect, useState } from 'react';
import { Grid, Icon, Heading, Size, Flex } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { DataTable, DataTableColumn } from '@vaisala/rockhopper-data-table';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING } from '../../constants';
import { EventInterface } from '../../events/events';
import { buildLinksForEvent, sortRows, timestampToTimeString } from '../../utils';
import cx from 'classnames';
import './event-detail.scss';
import { TEST_IDS } from '../../tests/testids';
import { Link } from 'react-router-dom';
import makeTestId, { IMakeTestIdProps } from '../../utils/makeTestId';

/**
 * Returns boolean values as a string and handles default rendering case.
 * @returns value string
 */
const BooleanValueFormatter = ({ value }: any, t: (str: string) => string) => {
  if (typeof value === 'boolean') {
    const displayValue = value ? t('general.yes') : t('general.no');
    return displayValue;
  }
  return value;
};

/**
 * Used to match cell.key as Union of specific strings instead of just string so translation types won't break.
 *
 * Always update this list of keys to match headerCells.
 *
 * Const assertion would be better on the headerCells to not need this manual Union. But the datagrid library makes the headerCells mutable and that causes issues with as const as it's read-only.
 */
interface DataTableColumnStrictKey extends DataTableColumn {
  key: 'parameter' | 'before' | 'after' | 'content';
}

interface EventDetailProps extends IMakeTestIdProps {
  onClose: (flag: boolean) => void;
  handleNavBtn: (value: number) => void;
  data: EventInterface;
  activeIndex?: number;
  lastIndex?: number;
  maxHeight?: number;
}

export const EventDetail = ({
  onClose,
  handleNavBtn,
  activeIndex,
  lastIndex,
  data,
  maxHeight,
  dataTa
}: EventDetailProps): JSX.Element => {
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();
  const showAffectedLocation = Boolean(data?.affectedlocation);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState(handleWeirdBackendDataStructure(data.parameters));

  const headerCells: DataTableColumnStrictKey[] = [
    { key: 'parameter', sortable: true, name: 'Parameter' },
    { key: 'before', sortable: true, name: 'Before', formatter: value => BooleanValueFormatter(value, t) },
    { key: 'after', sortable: false, name: 'After', formatter: value => BooleanValueFormatter(value, t) },
    { key: 'content', sortable: false, name: 'Content', formatter: value => BooleanValueFormatter(value, t) }
  ];

  const { objectUrl, affectedLocationUrl } = buildLinksForEvent(data);

  useEffect(() => {
    let cells = headerCells;
    if (tableData[0] && tableData[0].hasBefore) {
      cells = cells.filter(cell => cell.key !== 'content');
    } else {
      cells = cells.filter(cell => cell.key !== 'before' && cell.key !== 'after');
    }

    const tCells = cells.map(cell => {
      return { ...cell, name: t(`events.detail.parameters.${cell.key}`) };
    });

    setColumns(tCells);
  }, [tableData]);

  useEffect(() => {
    setTableData(handleWeirdBackendDataStructure(data.parameters));
  }, [data.parameters]);

  const handleCrossClick = () => {
    onClose(false);
  };

  const rowGetter = (rowId: number, rows: TableData['rows']) => {
    return rows[rowId];
  };

  const canClickBack = activeIndex != null && activeIndex > 0;

  const canClickForward = activeIndex != null && lastIndex != null && activeIndex < lastIndex;

  const renderMeta = (): JSX.Element => {
    return (
      <Grid.Row>
        <Grid.Col>
          <div className="details vai-margin-right-s vai-padding-s">
            <Grid.Row>
              <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                <div>
                  <Heading level={4} className="title">
                    {t('events.detail.date')} &amp; {t('events.detail.time')}
                  </Heading>
                </div>
              </Grid.Col>
              <Grid.Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <div>
                  <p id="events-event-detail-date-time" className="value">
                    {data.time}
                  </p>
                </div>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                <div>
                  <Heading level={4} className="title">
                    {t('events.detail.source')}
                  </Heading>
                </div>
              </Grid.Col>
              <Grid.Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <p id="events-event-detail-source" className="value">
                  {data.source}
                </p>
              </Grid.Col>
            </Grid.Row>
            {data.object && (
              <Grid.Row className="align-items-center">
                <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                  <div>
                    <Heading level={4} className="title">
                      {t('events.detail.object')}
                    </Heading>
                  </div>
                </Grid.Col>
                <Grid.Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  {objectUrl ? (
                    <Link
                      className="vai-button vai-button--link vai-button--s"
                      id="events-event-detail-object"
                      to={objectUrl}
                      target="_blank"
                      title={data.object}
                      data-ta={getTestId(TEST_IDS.link)}
                    >
                      <Flex flexDirection="row" alignItems="center" className="w-100">
                        <Flex.Item flexBasis={'30px'}>
                          <Icon
                            dataTa={getTestId(TEST_IDS.concat(TEST_IDS.object, TEST_IDS.link, TEST_IDS.icon))}
                            className="vai-margin-right-s"
                            name={VaiIcon.LinkNewWindow}
                            size={Size.M}
                          />
                        </Flex.Item>
                        <Flex.Item
                          flexBasis={'100%'}
                          dataTa={getTestId(TEST_IDS.concat(TEST_IDS.object, TEST_IDS.link, TEST_IDS.text))}
                          className="truncated-text--1 word-break--all"
                        >
                          {data.object}
                        </Flex.Item>
                      </Flex>
                    </Link>
                  ) : (
                    <span data-ta={getTestId(TEST_IDS.concat(TEST_IDS.object, TEST_IDS.text))} className="value">
                      {data.object}
                    </span>
                  )}
                </Grid.Col>
              </Grid.Row>
            )}
            {showAffectedLocation && (
              <Grid.Row className="align-items-center">
                <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                  <div>
                    <Heading level={4} className="title">
                      {t('general.location')}
                    </Heading>
                  </div>
                </Grid.Col>
                <Grid.Col xl={8} lg={8} md={8} sm={8} xs={8}>
                  {affectedLocationUrl ? (
                    <Link
                      className="vai-button vai-button--link vai-button--s"
                      id="events-event-detail-location"
                      to={affectedLocationUrl}
                      target="_blank"
                      title={data.affectedlocation}
                      data-ta={getTestId(TEST_IDS.link)}
                    >
                      <Flex flexDirection="row" alignItems="center" className="w-100">
                        <Flex.Item flexBasis={'30px'}>
                          <Icon
                            dataTa={getTestId(TEST_IDS.concat(TEST_IDS.location, TEST_IDS.link, TEST_IDS.icon))}
                            className="vai-margin-right-s"
                            name={VaiIcon.LinkNewWindow}
                            size={Size.M}
                          />
                        </Flex.Item>
                        <Flex.Item
                          flexBasis={'100%'}
                          dataTa={getTestId(TEST_IDS.concat(TEST_IDS.location, TEST_IDS.link, TEST_IDS.text))}
                          className="truncated-text--1 word-break--all"
                        >
                          {data.affectedlocation}
                        </Flex.Item>
                      </Flex>
                    </Link>
                  ) : (
                    <span data-ta={getTestId(TEST_IDS.concat(TEST_IDS.location, TEST_IDS.text))} className="value">
                      {data.affectedlocation}
                    </span>
                  )}
                </Grid.Col>
              </Grid.Row>
            )}
          </div>
        </Grid.Col>
      </Grid.Row>
    );
  };
  const leftArrowClasses = cx('heading left', { 'cursor-pointer': canClickBack });
  const rightArrowClasses = cx('heading right', { 'cursor-pointer': canClickForward });
  return (
    <div id="events-event-detail" style={{ height: maxHeight || 500 }} data-ta={getTestId(TEST_IDS.container)}>
      <div id="events-event-detail-header" data-ta={getTestId(TEST_IDS.header)} className="header">
        <div className="navigation" data-ta={getTestId(TEST_IDS.navigation.value)}>
          <Heading
            id="events-event-detail-header-arrow-left"
            onClick={() => canClickBack && handleNavBtn(-1)}
            level={1}
            className={leftArrowClasses}
            dataTa={getTestId(`${TEST_IDS.arrow_left}--${TEST_IDS.container}`)}
          >
            {canClickBack && <Icon dataTa={getTestId(TEST_IDS.arrow_left)} name={VaiIcon.ArrowLeft} className="icon" />}
          </Heading>
          <Heading
            id="events-event-detail-category-action"
            level={1}
            className="heading"
            dataTa={getTestId(TEST_IDS.heading)}
          >
            {`${data.category} ${data.action}`}
          </Heading>
          <Heading
            id="events-event-detail-header-arrow-right"
            level={1}
            className={rightArrowClasses}
            onClick={() => canClickForward && handleNavBtn(1)}
            dataTa={getTestId(`${TEST_IDS.arrow_right}--${TEST_IDS.container}`)}
          >
            {canClickForward && (
              <Icon name={VaiIcon.ArrowRight} dataTa={getTestId(TEST_IDS.arrow_right)} className="icon grey-medium" />
            )}
          </Heading>
        </div>
        <div
          id="events-event-detail-close"
          className="close cursor-pointer"
          data-ta={getTestId(`${TEST_IDS.close}-${TEST_IDS.container}`)}
          onClick={handleCrossClick}
        >
          <Heading level={1} className="heading">
            <Icon dataTa={getTestId(`${TEST_IDS.close}-${TEST_IDS.icon}`)} name={VaiIcon.Cross} />
          </Heading>
        </div>
      </div>
      <div id="events-event-detail-container" style={{ height: maxHeight - 50 || 500 }} className="container">
        <Grid>
          {renderMeta()}
          <Grid.Row>
            <Grid.Col>
              {tableData &&
                data.parameters &&
                tableData.map((table, index) => {
                  return table.rows.length > 0 ? (
                    <div key={index}>
                      <h4>{table.tableTitle}</h4>
                      <DataTable
                        htmlId="events-event-detail-table"
                        dataTa={TEST_IDS.event_details_table}
                        columns={columns}
                        rowGetter={rowId => rowGetter(rowId, table.rows)}
                        rowsCount={table.rows.length}
                        minColumnWidth={90}
                        onGridSort={(sortColumn, sortDirection) => sortRows(table.rows, sortColumn, sortDirection)}
                      />
                    </div>
                  ) : null;
                })}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

interface TableData {
  tableTitle: string;
  hasBefore: boolean;
  rows: {
    parameter: string;
    before: string;
    after: string;
  }[];
}

const UTC_PREFIX = 'UTC::';

function handleTimestamp(value: string): string {
  return value?.startsWith?.(UTC_PREFIX) ? timestampToTimeString(Number(value.split(UTC_PREFIX)[1])) : value;
}

export function handleWeirdBackendDataStructure(parameters: string[][] | undefined): TableData[] {
  if (!parameters) return [{ tableTitle: null, hasBefore: false, rows: [] }];

  const result: TableData[] = [{ tableTitle: null, hasBefore: false, rows: [] }];

  parameters.forEach(item => {
    const isSubtableHeader = item[1] === EMPTY_STRING && item[2] === EMPTY_STRING;
    const currentIndex = result.length === 0 ? 0 : result.length - 1;

    if (isSubtableHeader) {
      const currentTableTitle = item[0];
      if (!result[currentIndex].tableTitle) {
        result[currentIndex] = { tableTitle: currentTableTitle, hasBefore: false, rows: [] };
      } else {
        result.push({ tableTitle: item[0], hasBefore: false, rows: [] });
      }
    } else {
      const [parameter, before, contentAndAfter] = item;
      const eventDetail = {
        parameter: parameter,
        before: handleTimestamp(before),
        content: handleTimestamp(contentAndAfter),
        after: handleTimestamp(contentAndAfter)
      };
      result[currentIndex].rows.push(eventDetail);
      result[currentIndex].hasBefore = eventDetail.before !== EMPTY_STRING;
    }
  });

  return result;
}
