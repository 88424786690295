import { useEffect } from 'react';
import { NotificationType } from '@vaisala/rockhopper-components';
import { LOCATION_KEY, SITE_KEY, ZONE_KEY } from '../constants';
import i18n from '../i18n';
import { useAppDispatch } from '../store/hooks';
import { addNotification } from '../store/notifications';
import {
  useAddNodeMutation,
  useDeleteNodeMutation,
  useLinkNodeMutation,
  useEditNodeMutation,
  useMoveNodeMutation,
  useUnlinkNodeMutation,
  useToggleNodeActiveMutation
} from '../store/services/siteApi';
import { setCurrentlyRemoving, setSelectedNodeId } from '../store/siteTree';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { TranslationKey } from '../react-i18next';
import { LocationType } from '../siteTree/site';
import { isQueryEnded } from '../utils/common';

const getCreateSuccessNotificationString = (node_type: LocationType, node_name: string) => {
  if (node_type === SITE_KEY) {
    return i18n.t('site.notifySiteCreated', { siteName: node_name });
  } else if (node_type === ZONE_KEY) {
    return i18n.t('site.notifyZoneCreated', { zoneName: node_name });
  } else if (node_type === LOCATION_KEY) {
    return i18n.t('site.notifyLocationCreated', { locationName: node_name });
  }
};

const getCreateErrorNotificationString = (node_type: LocationType, node_name: string) => {
  if (node_type === SITE_KEY) {
    return i18n.t('site.notifySiteCreateFailed', { siteName: node_name });
  } else if (node_type === ZONE_KEY) {
    return i18n.t('site.notifyZoneCreateFailed', { zoneName: node_name });
  } else if (node_type === LOCATION_KEY) {
    return i18n.t('site.notifyLocationCreateFailed', { locationName: node_name });
  }
};
const getDeleteSuccessNotificationString = (node_type: LocationType, node_name: string) => {
  if (node_type === SITE_KEY) {
    return i18n.t('site.notifySiteDeleted', { siteName: node_name });
  } else if (node_type === ZONE_KEY) {
    return i18n.t('site.notifyZoneDeleted', { zoneName: node_name });
  } else if (node_type === LOCATION_KEY) {
    return i18n.t('site.notifyLocationDeleted', { locationName: node_name });
  }
};

const getDeleteErrorNotificationString = (node_type: LocationType, node_name: string) => {
  if (node_type === SITE_KEY) {
    return i18n.t('site.notifySiteDeleteFailed', { siteName: node_name });
  } else if (node_type === ZONE_KEY) {
    return i18n.t('site.notifyZoneDeleteFailed', { zoneName: node_name });
  } else if (node_type === LOCATION_KEY) {
    return i18n.t('site.notifyLocationDeleteFailed', { locationName: node_name });
  }
};

const getEditSuccessNotificationString = (node_type: LocationType, node_name: string) => {
  if (node_type === SITE_KEY) {
    return i18n.t('site.notifySiteEdited', { siteName: node_name });
  } else if (node_type === ZONE_KEY) {
    return i18n.t('site.notifyZoneEdited', { zoneName: node_name });
  } else if (node_type === LOCATION_KEY) {
    return i18n.t('site.notifyLocationEdited', { locationName: node_name });
  }
};

const getEditErrorNotificationString = (node_type: LocationType, node_name: string) => {
  if (node_type === SITE_KEY) {
    return i18n.t('site.notifySiteEditFailed', { siteName: node_name });
  } else if (node_type === ZONE_KEY) {
    return i18n.t('site.notifyZoneEditFailed', { zoneName: node_name });
  } else if (node_type === LOCATION_KEY) {
    return i18n.t('site.notifyLocationEditFailed', { locationName: node_name });
  }
};

const getBackendErrorOrDefault = (defaultString: string, errorKey?: string | null): string => {
  let message = defaultString;

  if (errorKey) {
    const translation = i18n.t(errorKey);
    translation !== errorKey && (message = translation);
  }

  return message;
};

const getMoveErrorNotificationString = (type: LocationType, name: string, errorKey?: string | null) => {
  return getBackendErrorOrDefault(
    i18n.t('site.notifyMoveFailed', { name, type: i18n.t(`commonTitles.${type}`) }),
    errorKey
  );
};

const getToggleNodeActiveErrorNotificationString = (
  locationName: string,
  activating: boolean,
  errorKey?: string | null
) => {
  const translationKey: TranslationKey = activating
    ? 'site.notifyLocationActivationError'
    : 'site.notifyLocationDeactivationError';
  return getBackendErrorOrDefault(i18n.t(translationKey, { locationName }), errorKey);
};

const useSiteTreeActions = () => {
  const dispatch = useAppDispatch();

  const [createTreeNode, addNodeResultDetails] = useAddNodeMutation();

  const [
    linkTreeNode,
    { isError: linkTreeNodeError, originalArgs: linkTreeNodeArgs, status: linkTreeNodeStatus }
  ] = useLinkNodeMutation();

  const [
    unlinkTreeNode,
    { isError: unlinkTreeNodeError, originalArgs: unlinkTreeNodeArgs, status: unlinkTreeNodeStatus }
  ] = useUnlinkNodeMutation();

  const [editTreeNode, editTreeNodeResultDetails] = useEditNodeMutation();
  const [deleteTreeNode, deleteTreeNodeResultDetails] = useDeleteNodeMutation();

  const [
    moveTreeNode,
    { isError: moveTreeNodeError, status: moveTreeNodeStatus, originalArgs: moveNodeArgs, error: moveNodeError }
  ] = useMoveNodeMutation();

  const [
    toggleNodeActive,
    {
      isError: toggleNodeActiveIsError,
      status: toggleNodeActiveStatus,
      originalArgs: toggleNodeActiveArgs,
      error: toggleNodeActiveError
    }
  ] = useToggleNodeActiveMutation();

  useEffect(() => {
    if (unlinkTreeNodeStatus === QueryStatus.fulfilled && !unlinkTreeNodeError) {
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: i18n.t('site.notifyLocationUnlinkSuccess', {
            locationName: unlinkTreeNodeArgs.name,
            dataSource: unlinkTreeNodeArgs.dataSource.device_model + '-' + unlinkTreeNodeArgs.dataSource.device_sn
          })
        })
      );
    }
    if (unlinkTreeNodeStatus === QueryStatus.rejected && unlinkTreeNodeError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: i18n.t('site.notifyLocationUnlinkFailed', {
            locationName: unlinkTreeNodeArgs.name,
            dataSource: unlinkTreeNodeArgs.dataSource.device_model + '-' + unlinkTreeNodeArgs.dataSource.device_sn
          })
        })
      );
    }
  }, [unlinkTreeNodeStatus]);

  useEffect(() => {
    if (linkTreeNodeStatus === QueryStatus.fulfilled && !linkTreeNodeError) {
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: i18n.t('site.notifyLocationLinkSuccess', {
            locationName: linkTreeNodeArgs.name,
            dataSource: linkTreeNodeArgs.linkedNode.probe_model + '-' + linkTreeNodeArgs.linkedNode.probe_sn
          })
        })
      );
    }
    if (linkTreeNodeStatus === QueryStatus.rejected && linkTreeNodeError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: i18n.t('site.notifyLocationLinkFailed', { locationName: linkTreeNodeArgs.name })
        })
      );
    }
  }, [linkTreeNodeStatus]);

  useEffect(() => {
    if (addNodeResultDetails.status === QueryStatus.fulfilled && addNodeResultDetails.data) {
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: getCreateSuccessNotificationString(
            addNodeResultDetails.originalArgs.type,
            addNodeResultDetails.originalArgs.name
          )
        })
      );
      dispatch(setSelectedNodeId(addNodeResultDetails.data.node_id));
    }
    if (addNodeResultDetails.status === QueryStatus.rejected && addNodeResultDetails.isError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: getCreateErrorNotificationString(
            addNodeResultDetails.originalArgs.type,
            addNodeResultDetails.originalArgs.name
          )
        })
      );
    }
  }, [addNodeResultDetails.status]);

  useEffect(() => {
    if (editTreeNodeResultDetails.status === QueryStatus.fulfilled && !editTreeNodeResultDetails.isError) {
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: getEditSuccessNotificationString(
            editTreeNodeResultDetails.originalArgs.node_type,
            editTreeNodeResultDetails.originalArgs.name
          )
        })
      );
    }
    if (editTreeNodeResultDetails.status === QueryStatus.rejected && editTreeNodeResultDetails.isError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: getEditErrorNotificationString(
            editTreeNodeResultDetails.originalArgs.node_type,
            editTreeNodeResultDetails.originalArgs.name
          )
        })
      );
    }
  }, [editTreeNodeResultDetails.status]);

  useEffect(() => {
    if (deleteTreeNodeResultDetails.status === QueryStatus.pending) {
      dispatch(setCurrentlyRemoving(deleteTreeNodeResultDetails.originalArgs.node_id));
    }

    if (deleteTreeNodeResultDetails.status === QueryStatus.fulfilled && !deleteTreeNodeResultDetails.isError) {
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: getDeleteSuccessNotificationString(
            deleteTreeNodeResultDetails.originalArgs.type,
            deleteTreeNodeResultDetails.originalArgs.name
          )
        })
      );
      dispatch(setSelectedNodeId(deleteTreeNodeResultDetails.originalArgs.parent_id));
    }
    if (deleteTreeNodeResultDetails.status === QueryStatus.rejected && deleteTreeNodeResultDetails.isError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: getDeleteErrorNotificationString(
            deleteTreeNodeResultDetails.originalArgs.type,
            deleteTreeNodeResultDetails.originalArgs.name
          )
        })
      );
    }
  }, [deleteTreeNodeResultDetails.status]);

  const backendErrorKey = getBackendErrorKey(moveNodeError as ErrorWithTranslationKey);

  useEffect(() => {
    if (moveTreeNodeStatus === QueryStatus.rejected && moveTreeNodeError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: getMoveErrorNotificationString(moveNodeArgs.type, moveNodeArgs.name, backendErrorKey)
        })
      );
    }
  }, [moveTreeNodeStatus, moveTreeNodeError, backendErrorKey]);

  const toggleNodeActiveBackendErrorKey = getBackendErrorKey(toggleNodeActiveError as ErrorWithTranslationKey);

  // Notifications for toggleNodeActive. Backend will return specific errors so we must handle that.
  useEffect(() => {
    const isActivating: boolean = toggleNodeActiveArgs?.active;
    const locationName: string = toggleNodeActiveArgs?.node?.name;
    if (toggleNodeActiveStatus === QueryStatus.rejected && toggleNodeActiveIsError) {
      dispatch(
        addNotification({
          type: NotificationType.Alarm,
          content: getToggleNodeActiveErrorNotificationString(
            locationName,
            isActivating,
            toggleNodeActiveBackendErrorKey
          )
        })
      );
    } else if (toggleNodeActiveStatus === QueryStatus.fulfilled && !toggleNodeActiveIsError) {
      const translationKey: TranslationKey = isActivating
        ? 'site.notifyLocationActivated'
        : 'site.notifyLocationDeactivated';
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: i18n.t(translationKey, { locationName })
        })
      );
    }
  }, [toggleNodeActiveStatus, toggleNodeActiveIsError, toggleNodeActiveBackendErrorKey]);

  return {
    linkTreeNode,
    unlinkTreeNode,
    moveTreeNode,
    toggleNodeActive,
    createTreeNode,
    addNodeResultDetails: {
      ...addNodeResultDetails,
      isQueryEnded: isQueryEnded(addNodeResultDetails.status, addNodeResultDetails.isUninitialized)
    },
    editTreeNode,
    editTreeNodeResultDetails: {
      ...editTreeNodeResultDetails,
      isQueryEnded: isQueryEnded(editTreeNodeResultDetails.status, editTreeNodeResultDetails.isUninitialized)
    },
    deleteTreeNode,
    deleteTreeNodeResultDetails: {
      ...deleteTreeNodeResultDetails,
      isQueryEnded: isQueryEnded(deleteTreeNodeResultDetails.status, deleteTreeNodeResultDetails.isUninitialized)
    }
  };
};

export default useSiteTreeActions;

export interface ErrorWithTranslationKey {
  data: {
    errKey: string;
  };
}

export function isFetchBaseQueryError(error): error is ErrorWithTranslationKey {
  return Boolean(error && error.data && error.data.errKey);
}

function getBackendErrorKey(error: ErrorWithTranslationKey) {
  return isFetchBaseQueryError(error) ? error.data.errKey : null;
}
