import * as ActionType from '../actions/actionTypes';
import { UserInterface, GroupInterface } from '../../profile/user';
import { UserProfileUpdate } from '../actions/profile';

interface getUserListAction {
  readonly type: string;
  readonly users?: UserInterface[];
  readonly error?: string;
}

interface getUserProfileAction {
  readonly type: string;
  readonly currentUser?: UserInterface;
  readonly userProfile?: UserInterface;
  readonly myProfile?: UserInterface;
  readonly error?: string;
}

interface getCompanyGroupsAction {
  readonly type: string;
  readonly groups?: GroupInterface[];
  readonly error?: any;
}

interface inviteUsersAction {
  readonly type: string;
  readonly acceptedUsers?: string[];
  readonly rejectedUsers?: string[];
  readonly error?: any;
}

interface genericAction {
  readonly type: string;
  readonly error?: any;
}

interface getGroupDetailsAction {
  readonly type: string;
  readonly groupDetails?: GroupInterface;
  readonly error?: string;
}
interface editUserProfile {
  readonly type: string;
  readonly payload: UserProfileUpdate;
}

type ProfileAction =
  | getUserListAction
  | getUserProfileAction
  | genericAction
  | getCompanyGroupsAction
  | getGroupDetailsAction
  | editUserProfile;

export interface ProfileState {
  readonly userListError?: any;
  readonly userListLoading: boolean;
  readonly groupListError?: any;
  readonly groupListLoading: boolean;
  readonly users: UserInterface[];
  readonly userProfileError?: any;
  readonly userProfileLoading: boolean;
  readonly myProfileLoading: boolean;
  readonly initProfileLoading: boolean;
  readonly initProfileError?: string;
  readonly currentUser?: UserInterface;
  readonly userProfile?: UserInterface;
  readonly myProfile?: UserInterface;
  readonly userRefresh?: boolean;
  readonly isEditProfileSucceed?: boolean;
  readonly editProfileError?: any;
  readonly userActivationError?: any;
  readonly groups?: GroupInterface[];
  readonly acceptedUsers?: string[];
  readonly rejectedUsers?: string[];
  readonly inviteUsersError?: any;
  readonly userDeletionError?: any;
  readonly groupRefresh?: boolean;
  readonly createGroupsError?: any;
  readonly groupDetailsError?: any;
  readonly groupDetailsLoading?: any;
  readonly groupDetails?: GroupInterface;
  readonly editGroupError?: any;
  readonly deleteGroupError?: any;
  readonly groupActivationError?: any;
  readonly deleteUserFromGroupError?: any;
}

export const initialState: ProfileState = {
  userListLoading: false,
  groupListLoading: false,
  myProfileLoading: true,
  initProfileLoading: false,
  users: [],
  groups: [],
  userProfileLoading: true
};

export default function profileReducer(state: ProfileState = initialState, action: ProfileAction): ProfileState {
  switch (action.type) {
    case ActionType.LOGOUT:
      return { ...state, currentUser: undefined, userProfile: undefined };
    case ActionType.GET_USER_LIST_START:
      return {
        ...state,
        userListLoading: true,
        userListError: undefined,
        isEditProfileSucceed: undefined,
        editProfileError: undefined
      };
    case ActionType.GET_USER_LIST_SUCCESS:
      return { ...state, users: (action as getUserListAction).users, userListLoading: false };
    case ActionType.GET_USER_LIST_FAIL:
      return { ...state, userListLoading: false, userListError: (action as getUserListAction).error };
    case ActionType.GET_USER_PROFILE_START:
      return { ...state, userProfileLoading: true, userProfileError: undefined };
    case ActionType.GET_USER_PROFILE_SUCCESS:
      return { ...state, userProfile: (action as getUserProfileAction).userProfile, userProfileLoading: false };
    case ActionType.GET_USER_PROFILE_FAIL:
      return { ...state, userProfileLoading: false, userProfileError: (action as getUserProfileAction).error };
    case ActionType.GET_MY_USER_PROFILE_ON_INIT:
      return { ...state, initProfileLoading: true, initProfileError: undefined };
    case ActionType.GET_MY_USER_PROFILE_ON_INIT_SUCCESS:
      return { ...state, currentUser: (action as getUserProfileAction).currentUser, initProfileLoading: false };
    case ActionType.GET_MY_USER_PROFILE_ON_INIT_FAIL:
      return { ...state, initProfileLoading: false, initProfileError: (action as getUserProfileAction).error };
    case ActionType.GET_MY_USER_PROFILE_START:
      return { ...state, myProfileLoading: true, userProfileError: undefined };
    case ActionType.GET_MY_USER_PROFILE_SUCCESS:
      return { ...state, myProfile: (action as getUserProfileAction).myProfile, myProfileLoading: false };
    case ActionType.GET_MY_USER_PROFILE_FAIL:
      return { ...state, myProfileLoading: false, userProfileError: (action as getUserProfileAction).error };
    case ActionType.TOGGLE_USER_ACTIVATION_SUCCESS:
      return { ...state, userRefresh: !state.userRefresh };
    case ActionType.TOGGLE_USER_ACTIVATION_FAIL:
      return { ...state, userActivationError: (action as genericAction).error };
    case ActionType.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        userRefresh: !state.userRefresh,
        isEditProfileSucceed: true,
        currentUser: { ...state.currentUser, ...(action as editUserProfile).payload }
      };
    case ActionType.EDIT_PROFILE_FAIL:
      return { ...state, editProfileError: (action as genericAction).error };
    case ActionType.GET_GROUP_LIST_START:
      return { ...state, groupListLoading: true };
    case ActionType.GET_GROUP_LIST_SUCCESS:
      return { ...state, groupListLoading: false, groups: (action as getCompanyGroupsAction).groups };
    case ActionType.GET_GROUP_LIST_FAIL:
      return { ...state, groupListLoading: false, groupListError: (action as getCompanyGroupsAction).error };
    case ActionType.INVITE_USERS_SUCCESS:
      return {
        ...state,
        userRefresh: !state.userRefresh
      };
    case ActionType.INVITE_USERS_FAIL:
      return { ...state, inviteUsersError: (action as inviteUsersAction).error };
    case ActionType.DELETE_USER_SUCCESS:
      return { ...state, userRefresh: !state.userRefresh, userProfile: undefined, userProfileLoading: true };
    case ActionType.DELETE_USER_FAIL:
      return { ...state, userDeletionError: (action as genericAction).error };
    case ActionType.CREATE_GROUPS_SUCCESS:
      return {
        ...state,
        groupRefresh: !state.groupRefresh
      };
    case ActionType.CREATE_GROUPS_FAIL:
      return { ...state, createGroupsError: (action as genericAction).error };
    case ActionType.GET_GROUP_DETAILS_START:
      return { ...state, groupDetailsLoading: true, groupDetailsError: undefined };
    case ActionType.GET_GROUP_DETAILS_SUCCESS:
      return { ...state, groupDetails: (action as getGroupDetailsAction).groupDetails, groupDetailsLoading: false };
    case ActionType.GET_GROUP_DETAILS_FAIL:
      return { ...state, groupDetailsLoading: false, groupDetailsError: (action as getUserProfileAction).error };
    case ActionType.EDIT_GROUP_SUCCESS:
      return { ...state, groupRefresh: !state.groupRefresh };
    case ActionType.EDIT_GROUP_FAIL:
      return { ...state, editGroupError: (action as genericAction).error };
    case ActionType.DELETE_GROUP_SUCCESS:
      return { ...state, groupRefresh: !state.groupRefresh, groupDetails: undefined, groupDetailsLoading: true };
    case ActionType.DELETE_GROUP_FAIL:
      return { ...state, deleteGroupError: (action as genericAction).error };
    case ActionType.TOGGLE_GROUP_ACTIVATION_SUCCESS:
      return { ...state, groupRefresh: !state.groupRefresh };
    case ActionType.TOGGLE_GROUP_ACTIVATION_FAIL:
      return { ...state, groupActivationError: (action as genericAction).error };
    case ActionType.DELETE_GROUP_USERS_SUCCESS:
      return { ...state, groupRefresh: !state.groupRefresh, groupDetailsLoading: true };
    case ActionType.DELETE_GROUP_USERS_FAIL:
      return { ...state, deleteUserFromGroupError: (action as genericAction).error };
    case ActionType.ADD_GROUP_MEMBERS_SUCCESS:
      return { ...state, groupRefresh: !state.groupRefresh, groupDetailsLoading: true };
    case ActionType.REFRESH_GROUPS:
      return { ...state, groupRefresh: !state.groupRefresh };
    default:
      return state;
  }
}
