import React from 'react';
import makeTestId, { IMakeTestIdProps } from '../../../utils/makeTestId';
import { Flex, Grid, Icon, Size } from '@vaisala/rockhopper-components';
import { TEST_IDS } from '../../../tests/testids';
import { useTranslation } from 'react-i18next';
import { TranslationKey } from '../../../react-i18next';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

import './index.scss';

export const SYSTEM_WIDE_RIGHTS_TRANSLATIONS: TranslationKey[] = [
  'systemRights.viewAllZones',
  'systemRights.manageAllSites',
  'systemRights.manageAllDevices',
  'systemRights.viewEvents',
  'systemRights.manageAllGroupsAndUsers'
];

const SystemWideRights: React.FC<IMakeTestIdProps> = ({ children, dataTa }) => {
  const { getTestId } = makeTestId({ dataTa });
  const { t } = useTranslation();
  return (
    <>
      <Grid padding={Size.L} dataTa={getTestId(TEST_IDS.system_wide_rights)}>
        <Grid.Row>
          {SYSTEM_WIDE_RIGHTS_TRANSLATIONS.map(key => {
            return (
              <Grid.Col
                className="system-right-col"
                dataTa={getTestId(TEST_IDS.col)}
                key={key}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
              >
                <Flex alignItems="center">
                  <Icon dataTa={getTestId(TEST_IDS.icon)} size={Size.L} name={VaiIcon.Check} />
                  <span data-ta={getTestId(TEST_IDS.text)}>{t(key)}</span>
                </Flex>
              </Grid.Col>
            );
          })}
        </Grid.Row>
      </Grid>
      {children}
    </>
  );
};

export default SystemWideRights;
