import React from 'react';
import { TEST_IDS } from '../../../tests/testids';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { DEFAULT_NO_MEASUREMENT } from '../../../constants';
import { roundHalfToEven } from '../../../utils';

type StatusMeasurementProps = {
  id?: string | null | undefined;
  value: number | null | undefined;
  decimals?: number;
  dataTa?: string;
  className?: string | null | undefined;
};

const StatusMeasurement: React.FC<StatusMeasurementProps> = ({
  id = QA_TEST_IDS.location_value,
  value,
  decimals,
  dataTa = TEST_IDS.location_value,
  className,
  children
}) => {
  if (!value)
    return (
      <span id={id} className={className} data-ta={dataTa}>
        {DEFAULT_NO_MEASUREMENT}
      </span>
    );
  return (
    <>
      <span className={className} id={id} data-ta={dataTa}>
        {roundHalfToEven(value, decimals)}
      </span>
      {children}
    </>
  );
};

export default StatusMeasurement;
