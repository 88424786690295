import React, { useEffect, useState } from 'react';
import { Button, ButtonType, Grid, Icon, Size } from '@vaisala/rockhopper-components';
import { DEVICE_ALARM_RULE_NAMES } from '../../../../../../constants';
import { useTranslation } from 'react-i18next';
import { AlarmSettingFormHeaderRow } from '../../../../Alarms/shared/AlarmSettingFormHeaderRow';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { TEST_IDS } from '../../../../../../tests/testids';
import { useStepsContext } from '../../../Steps/StepsContextProvider';
import { FormControls } from './FormControls';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';
import { useDeviceAlarmModalContext } from '../DeviceAlarmModalProvider';
import { DeviceAlarmdNotificationRow } from './DeviceAlarmNotifcationsRow';
import './styles.scss';

export const DeviceAlarmNotificationsForm: React.FC = () => {
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);
  const { goBack } = useStepsContext();
  const { warnings } = useDeviceAlarmModalContext();

  useEffect(() => {
    let showWarning = false;
    for (const ruleName of Object.keys(DEVICE_ALARM_RULE_NAMES)) {
      if (warnings[ruleName as DEVICE_ALARM_RULE_NAMES].hasWarning) {
        showWarning = true;
      }
    }
    setShowWarning(showWarning);
  }, [warnings]);

  const id = QA_TEST_IDS.steps.back;

  return (
    <div className="device-alarm-notifications-form" data-ta={TEST_IDS.alarm_settings_threshold_notifications_form}>
      <Grid padding={Size.S}>
        <AlarmSettingFormHeaderRow>
          <Grid.Col lg={4} xl={4}>
            {t('site.alarms.settings.threshold.form.headers.type')}
          </Grid.Col>
          <Grid.Col lg={5} xl={5}>
            {t('site.alarms.settings.threshold.form.headers.notificationGroup')}
          </Grid.Col>
          <Grid.Col lg={3} xl={3}>
            {t('site.alarms.settings.threshold.form.headers.notificationMethod')}
          </Grid.Col>
        </AlarmSettingFormHeaderRow>
        {Object.keys(DEVICE_ALARM_RULE_NAMES).map((ruleName: DEVICE_ALARM_RULE_NAMES) => {
          return <DeviceAlarmdNotificationRow key={ruleName} ruleName={ruleName} />;
        })}
      </Grid>
      <div>
        {showWarning && (
          <p>
            <Icon name={VaiIcon.Warning} size={Size.M} color={VaiColor.OrangeDark} />{' '}
            <span>{t('site.alarms.settings.threshold.notifications.warning')}</span>
          </p>
        )}
      </div>
      <div>
        <Button
          className="controls-back-button"
          startIcon={<Icon name={VaiIcon.ArrowLeft} />}
          buttonType={ButtonType.Link}
          onClick={goBack}
          dataTa={TEST_IDS.steps.back}
          htmlId={id}
          id={id}
        >
          {t('general.back')}
        </Button>
        <FormControls />
      </div>
    </div>
  );
};
