import React from 'react';
import { Icon, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { SITE_ZONES_ACCESS_KEYS, SiteNodeTypes } from '../../constants';
import { TranslationKey } from '../../react-i18next';
import { SiteInterface } from '../../siteTree/site';
import { CheckboxState } from '../MultiStateCheckbox/constants';

export enum RightNames {
  VIEW_NON_INHERITABLE = 'VIEW_NON_INHERITABLE',
  VIEW = 'VIEW',
  ACKNOWLEDGE_ALARMS = 'ACKNOWLEDGE_ALARMS',
  LINK_ALARMS = 'LINK_ALARMS',
  COMMENT_DATA = 'COMMENT_DATA',
  COMMENT_ALARMS = 'COMMENT_ALARMS',
  PAUSE_ALARMS = 'PAUSE_ALARMS',
  MANAGE_ALARM_TEMPLATES = 'MANAGE_ALARM_TEMPLATES',
  MANAGE_DEVICES = 'MANAGE_DEVICES',
  VIEW_EVENTS = 'VIEW_EVENTS',
  MANAGE_PREFERENCES = 'MANAGE_PREFERENCES',
  MANAGE_REPORTS = 'MANAGE_REPORTS',
  MANAGE_SITES = 'MANAGE_SITES',
  MANAGE_USERS_GROUPS = 'MANAGE_USERS_GROUPS'
}

export interface SiteRightsTree extends Omit<SiteInterface, 'rights'> {
  checkboxRightsStates: CheckboxRightsState;
  parent: SiteRightsTree | null;
  children: SiteRightsTree[];
}

export type GlobalRightNames = RightNames.VIEW_EVENTS;

/**
  * Checkbox state aggregation combination between parent and child OR between two sibling nodes

  * Left side is always parent OR child, right is always child
  
  * Usage - CHECKBOX_STATE_COMBINATIONS[parent.node.rights.VIEW + child.node.rights.VIEW]
*/

export const CHECKBOX_STATE_COMBINATIONS = {
  // Unchecked
  [CheckboxState.UNCHECKED + CheckboxState.UNCHECKED]: CheckboxState.UNCHECKED,
  // Inverted checked
  [CheckboxState.CHECKED + CheckboxState.UNCHECKED]: CheckboxState.CHECKED_INVERTED,
  [CheckboxState.CHECKED_INVERTED + CheckboxState.UNCHECKED]: CheckboxState.CHECKED_INVERTED,
  [CheckboxState.CHECKED + CheckboxState.CHECKED_INVERTED]: CheckboxState.CHECKED_INVERTED,
  [CheckboxState.CHECKED + CheckboxState.UNCHECKED_INVERTED]: CheckboxState.CHECKED_INVERTED,
  [CheckboxState.CHECKED_INVERTED + CheckboxState.UNCHECKED_INVERTED]: CheckboxState.CHECKED_INVERTED,
  // Checked
  [CheckboxState.CHECKED + CheckboxState.CHECKED]: CheckboxState.CHECKED,
  [CheckboxState.CHECKED_INVERTED + CheckboxState.CHECKED]: CheckboxState.CHECKED,
  [CheckboxState.CHECKED_INVERTED + CheckboxState.CHECKED_INVERTED]: CheckboxState.CHECKED,
  // Inverted Unchecked
  [CheckboxState.UNCHECKED + CheckboxState.CHECKED]: CheckboxState.UNCHECKED_INVERTED,
  [CheckboxState.UNCHECKED + CheckboxState.CHECKED_INVERTED]: CheckboxState.UNCHECKED_INVERTED,
  [CheckboxState.UNCHECKED + CheckboxState.UNCHECKED_INVERTED]: CheckboxState.UNCHECKED_INVERTED,
  [CheckboxState.UNCHECKED_INVERTED + CheckboxState.UNCHECKED]: CheckboxState.UNCHECKED_INVERTED,
  [CheckboxState.UNCHECKED_INVERTED + CheckboxState.CHECKED]: CheckboxState.UNCHECKED_INVERTED,
  [CheckboxState.UNCHECKED_INVERTED + CheckboxState.CHECKED_INVERTED]: CheckboxState.UNCHECKED_INVERTED,
  [CheckboxState.UNCHECKED_INVERTED + CheckboxState.UNCHECKED_INVERTED]: CheckboxState.UNCHECKED_INVERTED
};

export const DEFAULT_SITE_RIGHTS_OBJECT = {
  [RightNames.MANAGE_SITES]: CheckboxState.UNCHECKED,
  [RightNames.MANAGE_USERS_GROUPS]: CheckboxState.UNCHECKED,
  [RightNames.MANAGE_DEVICES]: CheckboxState.UNCHECKED,
  [RightNames.VIEW_EVENTS]: CheckboxState.UNCHECKED,
  [RightNames.VIEW]: CheckboxState.UNCHECKED,
  [RightNames.ACKNOWLEDGE_ALARMS]: CheckboxState.UNCHECKED,
  // [RightNames.PAUSE_ALARMS]: CheckboxState.UNCHECKED,
  [RightNames.MANAGE_ALARM_TEMPLATES]: CheckboxState.UNCHECKED
};

export const DEFAULT_ZONE_RIGHTS_OBJECT = {
  [RightNames.VIEW]: CheckboxState.UNCHECKED,
  [RightNames.ACKNOWLEDGE_ALARMS]: CheckboxState.UNCHECKED,
  // [RightNames.PAUSE_ALARMS]: CheckboxState.UNCHECKED,
  [RightNames.MANAGE_ALARM_TEMPLATES]: CheckboxState.UNCHECKED
};

export const ZONE_RIGHTS_LIST = [
  RightNames.VIEW,
  RightNames.ACKNOWLEDGE_ALARMS,
  // RightNames.PAUSE_ALARMS,
  RightNames.MANAGE_ALARM_TEMPLATES
];

export const SITE_RIGHTS_LIST = [
  RightNames.MANAGE_SITES,
  RightNames.MANAGE_USERS_GROUPS,
  RightNames.MANAGE_DEVICES,
  RightNames.VIEW_EVENTS
];

export const GLOBAL_RIGHTS_LIST = [RightNames.VIEW_EVENTS];

export const DEFAULT_RIGHTS_BY_TYPE = {
  [SITE_ZONES_ACCESS_KEYS.SITE]: DEFAULT_SITE_RIGHTS_OBJECT,
  [SITE_ZONES_ACCESS_KEYS.ZONE]: DEFAULT_ZONE_RIGHTS_OBJECT
};

export const HELPER_TEXT_TRANSLATION_KEYS: Record<SiteNodeTypes.SITE | SiteNodeTypes.ZONE, TranslationKey> = {
  [SiteNodeTypes.SITE]: 'groupsRights.siteRightsHelperText',
  [SiteNodeTypes.ZONE]: 'groupsRights.zoneRightsHelperText'
};

export const HAS_RIGHTS_TRANSLATION_KEY: TranslationKey = 'profile.hasRights';
export const HAS_NO_RIGHTS_TRANSLATION_KEY: TranslationKey = 'profile.hasNoRights';

export const RIGHTS_TRANSLATION_KEY = {
  [SITE_ZONES_ACCESS_KEYS.ACKNOWLEDGE_ALARMS]: 'profile.acknowledgeAlarms',
  [SITE_ZONES_ACCESS_KEYS.PAUSE_ALARMS]: 'profile.pauseAlarms',
  [SITE_ZONES_ACCESS_KEYS.VIEW]: 'profile.view',
  [SITE_ZONES_ACCESS_KEYS.MANAGE_DEVICES]: 'profile.manageDevices',
  [SITE_ZONES_ACCESS_KEYS.MANAGE_REPORTS]: 'profile.manageReports',
  [SITE_ZONES_ACCESS_KEYS.MANAGE_SITES]: 'profile.manageSites',
  [SITE_ZONES_ACCESS_KEYS.VIEW_EVENTS]: 'systemRights.viewEvents',
  [SITE_ZONES_ACCESS_KEYS.MANAGE_USERS_GROUPS]: 'profile.manageUsersAndGroups',
  [SITE_ZONES_ACCESS_KEYS.MANAGE_ALARM_TEMPLATES]: 'profile.manageAlarmTemplates'
};

export const ACCORDION_SUMMARY_CHECKBOX_STATE_TRANSLATION_KEYS: Record<CheckboxState, TranslationKey> = {
  [CheckboxState.CHECKED_INVERTED]: HAS_RIGHTS_TRANSLATION_KEY,
  [CheckboxState.CHECKED]: HAS_RIGHTS_TRANSLATION_KEY,
  [CheckboxState.UNCHECKED_INVERTED]: HAS_RIGHTS_TRANSLATION_KEY,
  [CheckboxState.UNCHECKED]: HAS_NO_RIGHTS_TRANSLATION_KEY
};

export const checkIcon = <Icon name={VaiIcon.Check} size={Size.M} />;

export const ACCORDION_SUMMARY_ICON: Record<CheckboxState, JSX.Element | null> = {
  [CheckboxState.CHECKED_INVERTED]: checkIcon,
  [CheckboxState.CHECKED]: checkIcon,
  [CheckboxState.UNCHECKED_INVERTED]: checkIcon,
  [CheckboxState.UNCHECKED]: null
};
// TODO: Remove object type
export type CheckboxRightsState = Record<RightNames, CheckboxState>;

export interface PropsWithRightsTreeNode {
  node: SiteRightsTree;
}

export const NO_RIGHTS = 0b0000000000000000;
