import { getMinutes } from 'date-fns';
import React from 'react';
import { useAppSelector } from '../store/hooks';
import { selectTimeZone } from '../store/profile';
import { getTime, getTodayWithNearestAvailableTime } from '../utils';

const TimeContext = React.createContext(null);

interface TimeContextProps {
  children: React.ReactNode;
}

export function TimeContextProvider({ children }: TimeContextProps): JSX.Element {
  const timeZone = useAppSelector(selectTimeZone);
  const defaultValue = getTodayWithNearestAvailableTime();
  const [today, setToday] = React.useState(defaultValue);
  const timeDetails = React.useRef({ date: null, time: '', zone: '' });
  const mounted = React.useRef(true);

  React.useEffect(() => {
    const getAndSetTimeInfo = async () => {
      const { date, time, zone } = await getTime(timeZone);
      timeDetails.current = { date, time, zone };
      setToday(getTodayWithNearestAvailableTime());
    };
    let interval;
    getAndSetTimeInfo().then(() => {
      // NOTE: This causes some crazy side-effects when running the jest test suite so disable when in test env.
      // TODO: Can this be done better? At some point it would be nice to be able to assert the clock functionality.
      //  We could consider just testing the provider on it's own in a separate unit test.
      if (process.env.NODE_ENV !== 'test') {
        interval = setInterval(async () => {
          // HACK: This is better than using the window to track this variable. In working on this, was unable to determine the
          // root cause, but trying to set a state variable for the interval was not working. The interval variable was being reset
          // by the time the component was cleaned up. Instead of attaching a loose variable on the window, we were able to get it
          // to clear the interval by using a ref and setting it on cleanup while clearing the interval in here.
          if (mounted.current === false) {
            clearInterval(interval);
            return;
          }
          const now = new Date();
          if (timeDetails.current.date === null || getMinutes(timeDetails.current.date) !== getMinutes(now)) {
            await getAndSetTimeInfo();
          }
        }, 1000);
      }
    });
    return function cleanup() {
      mounted.current = false;
      clearInterval(interval);
    };
  }, [timeZone]);

  const value = { today, setToday, timeDetails };
  return <TimeContext.Provider value={value}>{children}</TimeContext.Provider>;
}

export default TimeContext;
