import React from 'react';

import { List } from '@vaisala/rockhopper-components';

import { DataSourceListItemModel } from '../../../types';
import DataSourceListItem from './DataSourceListItem';
import useHtmlId, { IUseHtmlIDProps } from '../../../hooks/useHtmlId';
import { TEST_IDS } from '../../../tests/testids';
import { nanoid } from '@reduxjs/toolkit';
import { SITES_SYMBOL_ID } from '../../../siteTree/site';
import { buildDeviceId } from '../../../utils';
import { DEFAULT_DECIMAL_PLACES, DEVICE_SYMBOL_AS_UNIT_ID, DEVICE_SYMBOL_ID_TO_UNIT_ID } from '../../../constants';

interface Props extends IUseHtmlIDProps {
  dataSources: DataSourceListItemModel[];
  selectedItem: DataSourceListItemModel | null;
  decimalPlaces?: number | null;
  symbolId: SITES_SYMBOL_ID;
  loading?: boolean;
  initialItem?: DataSourceListItemModel | null;
  onSelect?: (selectedItem: DataSourceListItemModel | null) => void;
}

const DataSourceList: React.FC<Props> = ({
  dataSources,
  decimalPlaces = DEFAULT_DECIMAL_PLACES,
  initialItem,
  selectedItem,
  onSelect,
  symbolId,
  htmlId = nanoid()
}) => {
  const { getId } = useHtmlId({ htmlId });
  const isItemSelected = (item: DataSourceListItemModel) => {
    if (selectedItem == null) {
      return false;
    }
    return selectedItem.probe_sn === item.probe_sn && selectedItem.meas_group === item.meas_group;
  };
  const isInitial = (item: DataSourceListItemModel) => {
    if (initialItem == null) {
      return false;
    }
    return initialItem.probe_sn === item.probe_sn && initialItem.meas_group === item.meas_group;
  };

  const arrangedDataSources = React.useMemo(() => {
    // If we have an initial item, filter it out of the list of items so we can place it at the top of the list.
    const items: DataSourceListItemModel[] = initialItem
      ? [initialItem].concat(
          dataSources.filter(dataSource =>
            dataSource.probe_sn === initialItem.probe_sn ? dataSource.meas_group !== initialItem.meas_group : true
          )
        )
      : dataSources;
    return items;
  }, [initialItem, dataSources]);

  return (
    <List id={getId('data-source-list-container')} dataTa={TEST_IDS.data_source_list}>
      {arrangedDataSources.map(dataSource => (
        <DataSourceListItem
          key={`${buildDeviceId(dataSource.probe_model, dataSource.probe_sn)}-${dataSource.meas_group}-${
            dataSource.meas_id
          }`}
          htmlId={htmlId}
          separator="-"
          decimalPlaces={decimalPlaces}
          dataSource={dataSource}
          onSelect={() => {
            let selected = dataSource;
            if (selectedItem && isItemSelected(dataSource)) {
              selected = null;
            }
            // Let the caller consume the selected value so this could be reusable if need be.
            onSelect && onSelect(selected);
          }}
          symbolId={isInitial(dataSource) ? symbolId : DEVICE_SYMBOL_AS_UNIT_ID[dataSource.symbol_id]}
          checked={selectedItem ? isItemSelected(dataSource) : false}
          initial={isInitial(dataSource)}
        />
      ))}
    </List>
  );
};

export default DataSourceList;
