import { ALARM_PRIORITY, ALARM_THRESHOLD_TYPE, CALIBRATION_ALARM_SUBTYPES } from '../../../../constants';
import { TranslationKey } from '../../../../react-i18next';
import { SITES_SYMBOL_ID } from '../../../../siteTree/site';
import {
  ThresholdAlarmTemplateRule,
  ThresholdAlarmTemplateRules,
  ThresholdAlarmSettings
} from '../../../../store/services/alarmsApi';
import { DEFAULT_DELAY_ERROR } from '../../Alarms/shared/HoursMinutesFields';

type ErrorStatus = { label: string; hasError: boolean };

export type Errors = ErrorType & { order: ErrorStatus; collectedErrors: string[] };

export type ErrorType = Record<ALARM_THRESHOLD_TYPE, LevelError>;

export type LevelError = {
  value: {
    cannotBeEmptyValue: ErrorStatus;
    units: {
      min: ErrorStatus;
      max: ErrorStatus;
    };
  };
  delay: {
    cannotBeEmptyValue: ErrorStatus;
    hours: {
      max: ErrorStatus;
    };
    minutes: {
      max: ErrorStatus;
    };
  };
  hasError: boolean;
};

const DEFAULT_RULE: ThresholdAlarmTemplateRule = {
  ack_required: false,
  category: '',
  color: '',
  delay_sec: null,
  enabled: false,
  priority: ALARM_PRIORITY.CRITICAL,
  value: null
};

export const thresholdLevelTranslationKeys: Record<ALARM_THRESHOLD_TYPE, TranslationKey> = {
  [ALARM_THRESHOLD_TYPE.HIHI]: 'site.alarms.threshold.level.HighHigh',
  [ALARM_THRESHOLD_TYPE.HI]: 'site.alarms.threshold.level.High',
  [ALARM_THRESHOLD_TYPE.LO]: 'site.alarms.threshold.level.Low',
  [ALARM_THRESHOLD_TYPE.LOLO]: 'site.alarms.threshold.level.LowLow'
};

export const DEFAULT_RULES: ThresholdAlarmTemplateRules = {
  [ALARM_THRESHOLD_TYPE.HIHI]: { ...DEFAULT_RULE },
  [ALARM_THRESHOLD_TYPE.HI]: { ...DEFAULT_RULE },
  [ALARM_THRESHOLD_TYPE.LO]: { ...DEFAULT_RULE },
  [ALARM_THRESHOLD_TYPE.LOLO]: { ...DEFAULT_RULE }
};
const DEFAULT_WARNING = { hasWarning: false };

export type WarningStateType = Record<ALARM_THRESHOLD_TYPE, { hasWarning: boolean }>;

export const DEFAULT_WARNING_STATE: WarningStateType = {
  [ALARM_THRESHOLD_TYPE.HIHI]: { ...DEFAULT_WARNING },
  [ALARM_THRESHOLD_TYPE.HI]: { ...DEFAULT_WARNING },
  [ALARM_THRESHOLD_TYPE.LO]: { ...DEFAULT_WARNING },
  [ALARM_THRESHOLD_TYPE.LOLO]: { ...DEFAULT_WARNING }
};

const DEAFULT_NOTIFICATION_RULE = {
  EMAIL: [],
  VOICE: [],
  SMS: []
};

export const DEFAULT_NOTIFICATION_SETTINGS: ThresholdAlarmSettings['settings']['notifications'] = {
  [ALARM_THRESHOLD_TYPE.HIHI]: { ...DEAFULT_NOTIFICATION_RULE },
  [ALARM_THRESHOLD_TYPE.HI]: { ...DEAFULT_NOTIFICATION_RULE },
  [ALARM_THRESHOLD_TYPE.LO]: { ...DEAFULT_NOTIFICATION_RULE },
  [ALARM_THRESHOLD_TYPE.LOLO]: { ...DEAFULT_NOTIFICATION_RULE }
};

const DEFAULT_UNIT_ERRORS = {
  celsius: {
    max: { label: 'Specify a temperature below 10,001 oC.', hasError: false },
    min: { label: 'Specify a temperature above -276 oC.', hasError: false }
  },
  fahrenheit: {
    max: { label: 'Specify a temperature below 18,032 oF.', hasError: false },
    min: { label: 'Specify a temperature above -472 oF.', hasError: false }
  },
  rh: {
    max: { label: 'Specify at most 100% humidity.', hasError: false },
    min: { label: 'Specify at least 0% humidity.', hasError: false }
  },
  percent: {
    max: { label: 'Specify at most 100% CO2.', hasError: false },
    min: { label: 'Specify at least 0% CO2.', hasError: false }
  },
  ppm: {
    max: { label: 'Specify at most 1,000,000 PPM limit.', hasError: false },
    min: { label: 'Specify at least 0 PPM limit.', hasError: false }
  }
};

const UNIT_ERRORS_TRANSLATION_KEYS = {
  celsius: {
    max: 'site.alarms.settings.threshold.validations.thresholdValue.c.max',
    min: 'site.alarms.settings.threshold.validations.thresholdValue.c.min'
  },
  fahrenheit: {
    max: 'site.alarms.settings.threshold.validations.thresholdValue.f.max',
    min: 'site.alarms.settings.threshold.validations.thresholdValue.f.min'
  },
  rh: {
    max: 'site.alarms.settings.threshold.validations.thresholdValue.rh.max',
    min: 'site.alarms.settings.threshold.validations.thresholdValue.rh.min'
  },
  percent: {
    max: 'site.alarms.settings.threshold.validations.thresholdValue.co2.max',
    min: 'site.alarms.settings.threshold.validations.thresholdValue.co2.min'
  },
  ppm: {
    max: 'site.alarms.settings.threshold.validations.thresholdValue.ppm.max',
    min: 'site.alarms.settings.threshold.validations.thresholdValue.ppm.min'
  }
};

export const THRESHOLD_VALIDATION_TRANSLATION_KEYS = {
  units: UNIT_ERRORS_TRANSLATION_KEYS,
  order: 'site.alarms.settings.threshold.validations.order',
  delay: {
    cannotBeEmptyValue: 'site.alarms.settings.threshold.validations.delay.cannotBeEmpty',
    hours: { max: 'site.alarms.settings.threshold.validations.delay.hours.max' },
    minutes: { max: 'site.alarms.settings.threshold.validations.delay.minutes.max' }
  },
  value: {
    units: UNIT_ERRORS_TRANSLATION_KEYS,
    cannotBeEmpty: 'site.alarms.settings.threshold.validations.thresholdValue.cannotBeEmpty'
  }
};

const DEFAULT_VALUE_ERROR = {
  cannotBeEmptyValue: {
    label: 'Specify a value for active alarm thresholds',
    hasError: false
  },
  units: DEFAULT_UNIT_ERRORS
};

export const DEFAULT_THRESHOLD_ERROR = {
  value: DEFAULT_VALUE_ERROR,
  delay: DEFAULT_DELAY_ERROR,
  hasError: false
};

/**
 *
 * @param translate - For translating error labels
 * @param symbol_id - For selecting correct label for min and max validation limits
 * @param unit - For injecting unit min and max limits received from backend into the translations
 * @returns
 */
export const getDefaultErrorsState = (
  translate,
  symbol_id: SITES_SYMBOL_ID,
  unit: ThresholdAlarmSettings['unit']
): Errors => {
  const DEFAULT_DELAY_ERROR = {
    cannotBeEmptyValue: {
      label: translate(THRESHOLD_VALIDATION_TRANSLATION_KEYS.delay.cannotBeEmptyValue),
      hasError: false
    },
    hours: { max: { label: translate(THRESHOLD_VALIDATION_TRANSLATION_KEYS.delay.hours.max), hasError: false } },
    minutes: { max: { label: translate(THRESHOLD_VALIDATION_TRANSLATION_KEYS.delay.minutes.max), hasError: false } }
  };

  const DEFAULT_VALUE_ERROR = {
    cannotBeEmptyValue: {
      label: translate(THRESHOLD_VALIDATION_TRANSLATION_KEYS.value.cannotBeEmpty),
      hasError: false
    },
    units: {
      max: {
        label: translate(THRESHOLD_VALIDATION_TRANSLATION_KEYS.value.units[symbol_id].max, { value: unit.max }),
        hasError: false
      },
      min: {
        label: translate(THRESHOLD_VALIDATION_TRANSLATION_KEYS.value.units[symbol_id].min, { value: unit.min }),
        hasError: false
      }
    }
  };

  const DEFAULT_THRESHOLD_ERROR = {
    value: DEFAULT_VALUE_ERROR,
    delay: DEFAULT_DELAY_ERROR,
    hasError: false
  };

  return {
    order: { label: translate(THRESHOLD_VALIDATION_TRANSLATION_KEYS.order), hasError: false },
    [ALARM_THRESHOLD_TYPE.HIHI]: { ...DEFAULT_THRESHOLD_ERROR },
    [ALARM_THRESHOLD_TYPE.HI]: { ...DEFAULT_THRESHOLD_ERROR },
    [ALARM_THRESHOLD_TYPE.LO]: { ...DEFAULT_THRESHOLD_ERROR },
    [ALARM_THRESHOLD_TYPE.LOLO]: { ...DEFAULT_THRESHOLD_ERROR },
    collectedErrors: []
  };
};

export type LevelValues = Pick<
  ThresholdAlarmTemplateRule,
  'enabled' | 'value' | 'delay_sec' | 'priority' | 'ack_required'
>;

export const alarmSubTypeTranslationMessages: Record<
  CALIBRATION_ALARM_SUBTYPES,
  { message: TranslationKey; params?: Record<string, any> }
> = {
  '90DAYS': { message: 'alarms.subtype.calibration.message' },
  '30DAYS': { message: 'alarms.subtype.calibration.message' },
  CALIBRATION: { message: 'alarms.subtype.calibration.message' },
  HOST: { message: 'alarms.subtype.lostConnectionToHost.message' },
  LOGGER: { message: 'alarms.subtype.lostConnectionToLogger.message' },
  DATA_UNRECOVERABLE: { message: 'alarms.subtype.dataUnrecoverable.message' },
  DATA_DELAYED: { message: 'alarms.subtype.dataDelayed.message' },
  SENSOR_MALFUNCTION: { message: 'alarms.subtype.sensorMalfunction.message' },
  BATT_LOW: { message: 'alarm.subtype.battery.message' },
  RTC_BATT_LOW: { message: 'alarm.subtype.rtcbattery.message' },
  MISSING_DATASOURCE: { message: 'alarm.subtype.missingDatasource.message' }
};

export const alarmSubTypeTranslationKeys: Record<string, TranslationKey> = {
  '90DAYS': 'alarms.subtype.ninetyDayReminder',
  '30DAYS': 'alarms.subtype.thirtyDayReminder',
  CALIBRATION: 'site.alarms.device.rule.names.calibration',
  HOST: 'alarms.subtype.lostConnectionToHost',
  LOGGER: 'alarms.subtype.lostConnectionToLogger',
  DATA_UNRECOVERABLE: 'alarms.subtype.dataUnrecoverable',
  DATA_DELAYED: 'alarms.subtype.dataDelayed',
  SENSOR_MALFUNCTION: 'alarms.subtype.sensorMalfunction',
  BATT_LOW: 'site.alarms.device.rule.names.battery',
  RTC_BATT_LOW: 'deviceManager.status.rtcBatteryLow',
  MISSING_DATASOURCE: 'deviceManager.status.missingDatasource'
};

export type AlarmSubtype = keyof typeof alarmSubTypeTranslationKeys;
