import { Auth } from 'aws-amplify';
import { Buffer } from 'buffer';
import * as AuthActionType from '../actions/actionTypes';
import { GeneralActionType } from '../general';
import { Api, Apis, UserApiUris } from '../../utils/Api';
import { UserInterface } from '../../profile/user';
import i18n, { isValidLanguage, Languages } from '../../i18n';
import {
  LS_COMPANY_ACCOUNT_ID,
  LS_COMPANY_ADDRESS,
  LS_COMPANY_ALIAS,
  LS_COMPANY_NAME,
  LS_COMPANY_ONBOARD,
  LS_IS_COMPANY_ACTIVE,
  LS_REGION,
  LS_USER_POOL_ID,
  LS_USER_POOL_WEB_CLIENT_ID,
  LS_COMPANY_CUSTOMER_ID,
  NETWORK_ERROR,
  API_PROFILE_INCLUDE_GROUP_PARAM,
  LS_COMPANY_PROFILE_COMPLETION_FLAG,
  LS_TIME_ZONE,
  LS_LOCALE,
  LS_USERNAME,
  DEFAULT_LANGUAGE,
  LS_DATA_RETENTION_MONTHS,
  UserSettings
} from '../../constants';
import { CognitoChallengeName, getConstantFromLocalStorage, userSettings } from '../../utils/common';
import { reportError } from '../../utils/errorHandler';
import { addNotification, clearNotifications } from '../notifications';
import { NotificationType } from '@vaisala/rockhopper-components';
import { hideDialog } from '../dialog';
import { initProfile } from './profile';

export enum AuthError {
  'invalidUserCredentialError',
  'forgotPasswordError',
  'networkError',
  'firstLoginInvalidCredentialError',
  'getUserProfileError',
  'customerIdError',
  'InactivityError',
  'SessionExpiredError'
}

/**
 * @link https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ChangePassword.html
 */
export enum ChangingPasswordError {
  // 400
  NotAuthorizedException = 'NotAuthorizedException',
  LimitExceededException = 'LimitExceededException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidPasswordException = 'InvalidPasswordException',
  // Currently unused but nice for reference.
  ForbiddenException = 'ForbiddenException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  ResourceNotFoundException = 'ResourceNotFoundException',
  TooManyRequestsException = 'TooManyRequestsException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException',
  // 500
  InternalErrorException = 'InternalErrorException'
}

export enum SendResetCodeError {
  UserNotFoundException = 'UserNotFoundException',
  NetworkError = 'NetworkError'
}

export enum PasswordResetError {
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  InvalidPasswordException = 'InvalidPasswordException',
  CouldNotResetPasswordException = 'CouldNotResetPasswordException'
}

export enum MFAError {
  ResendException = 'ResendException',
  CodeMismatchException = 'CodeMismatchException',
  NotAuthorizedException = 'NotAuthorizedException'
}

const convertEmail = (email: string) => email.toLowerCase();
const localStorageCleanUp = () => {
  const username = localStorage.getItem(LS_USERNAME);
  userSettings.clear(username, UserSettings.EVENTS_FILTERS);
  userSettings.clear(username, UserSettings.WAS_VIEWING_HISTORICAL_ALARMS);
  userSettings.clear(username, UserSettings.ALARMS_FROM_DATE);
  userSettings.clear(username, UserSettings.ALARMS_TO_DATE);
  userSettings.clear(username, UserSettings.ALARMS_STATUS);
  userSettings.clear(username, UserSettings.ALARMS_PRIORITY);
  userSettings.clear(username, UserSettings.ALARMS_TYPE);
  userSettings.clear(username, UserSettings.LOCATIONS_SHOW_INACTIVE);
  localStorage.removeItem(LS_REGION);
  localStorage.removeItem(LS_USER_POOL_ID);
  localStorage.removeItem(LS_USER_POOL_WEB_CLIENT_ID);
  localStorage.removeItem(LS_IS_COMPANY_ACTIVE);
  localStorage.removeItem(LS_COMPANY_ONBOARD);
  localStorage.removeItem(LS_COMPANY_ADDRESS);
  localStorage.removeItem(LS_COMPANY_NAME);
  localStorage.removeItem(LS_COMPANY_ACCOUNT_ID);
  localStorage.removeItem(LS_COMPANY_CUSTOMER_ID);
  localStorage.removeItem(LS_COMPANY_PROFILE_COMPLETION_FLAG);
  localStorage.removeItem(LS_TIME_ZONE);
  localStorage.removeItem(LS_USERNAME);
  localStorage.removeItem(LS_DATA_RETENTION_MONTHS);
};

const getUserPoolConfig = (companyAlias: string) => async () => {
  const url = UserApiUris.tenantPool.replace(`{${LS_COMPANY_ALIAS}}`, companyAlias);
  const response = await Api.getUnauthorized(Apis.user, url);
  if (!response.ok) {
    throw new Error(`Could not validate user pool for ${companyAlias}`);
  }
  const config = await response.json();
  localStorage.setItem(LS_REGION, config.region);
  localStorage.setItem(LS_USER_POOL_ID, config.pool_id);
  localStorage.setItem(LS_USER_POOL_WEB_CLIENT_ID, config.pool_client_id);
  localStorage.setItem(LS_COMPANY_ALIAS, companyAlias);
  await Api.setupAmplify(config);
};

export const getUserProfile = (userName: string) => async dispatch => {
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri =
        UserApiUris.userProfile.replace('{customer_id}', customerId).replace('{user_name}', btoa(userName)) +
        '?' +
        API_PROFILE_INCLUDE_GROUP_PARAM;
      const response = await Api.get(Apis.user, uri);
      if (response.ok) {
        return response.json();
      }
      // TODO: dispatch fetch error
      return null;
    } else {
      dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.customerIdError });
    }
  } catch (error) {
    reportError(error, 'Error when trying to get user profile:');
    //  All the other error cases.
    dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.getUserProfileError });
  }
};

/**
 * Try to get Cognito user pool with company id and authenticate user with username and password.
 * @param username Username for login
 * @param password Password for login
 * @param companyAlias companyAlias for login
 */
const login = (username: string, password: string, companyAlias: string, isFirstLogin: boolean) => async dispatch => {
  dispatch({ type: AuthActionType.LOGIN });
  try {
    await dispatch(getUserPoolConfig(companyAlias));
    return dispatch(loginAuthentication(username, password, isFirstLogin, companyAlias));
  } catch (error) {
    reportError(error, 'Error in catch block - auth (94)');
    localStorageCleanUp();
    if (error.code === NETWORK_ERROR) {
      // Distinquish network error from other erros.
      dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.networkError });
    } else {
      if (isFirstLogin) {
        dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.firstLoginInvalidCredentialError });
      } else {
        // All the pther error cases are currently shown as invalid username or password.
        dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.invalidUserCredentialError });
      }
    }
  }
};

/**
 * Try to login using Cognito user pool with given username and password.
 * @param userName Username for login
 * @param password Password for login
 * @param isFirstLogin boolean for whether this is the first login
 * @param companyAlias companyAlias for calling tenantInfo API
 */
const loginAuthentication = (
  userName: string,
  password: string,
  isFirstLogin: boolean,
  companyAlias: string
) => async dispatch => {
  try {
    const user = await Auth.signIn(userName, password);
    // Temporary code to change user NEW_PASSWORD_REQUIRED to CONFIRMED
    if (user.challengeName === CognitoChallengeName.NEW_PASSWORD_REQUIRED) {
      dispatch({ type: AuthActionType.SIGNUP_START });
    } else if (user.challengeName === CognitoChallengeName.SMS_MFA) {
      dispatch({
        type: AuthActionType.MFA_START,
        mfaUsername: userName,
        mfaPassword: Buffer.from(password).toString('base64'),
        mfaUser: user
      });
    } else {
      localStorage.setItem(LS_COMPANY_ALIAS, companyAlias);
      await dispatch(getCompanyInfo(companyAlias));
      if (
        typeof user.attributes.phone_number_verified !== 'undefined' &&
        user.attributes.phone_number_verified === true
      ) {
        dispatch({ type: AuthActionType.LOGIN_SUCCESS });
      } else {
        localStorage.setItem(LS_COMPANY_PROFILE_COMPLETION_FLAG, 'false');
        dispatch({ type: AuthActionType.USER_PROFILE_SETUP, userName: userName });
      }
    }
  } catch (error) {
    reportError(error, 'Error in catch block - auth (136)');
    localStorageCleanUp();
    if (error.code === NETWORK_ERROR) {
      // Distinquish network error from other erros.
      dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.networkError });
    } else {
      if (isFirstLogin) {
        dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.firstLoginInvalidCredentialError });
      } else {
        // All the pther error cases are currently shown as invalid username or password.
        dispatch({ type: AuthActionType.LOGIN_ERROR, error: AuthError.invalidUserCredentialError });
      }
    }
  }
};

/**
 * Logs out the user by clear aws-amplify authentication session and setting isAuthenticated to false
 * which causes redirect.
 */
export const logout = (globalSignout = false, error = null) => async dispatch => {
  try {
    localStorageCleanUp();
    if (globalSignout) {
      await Auth.signOut({ global: true });
    } else {
      await Auth.signOut();
    }
  } catch (error) {
    reportError(error, 'Error when trying to logout:');
    // Should not ever happen.
  }
  dispatch(hideDialog());
  dispatch(clearNotifications());
  dispatch({ type: AuthActionType.LOGOUT, error });
};

const getLanguageInfo = () => async dispatch => {
  let locale = getConstantFromLocalStorage(LS_LOCALE);
  if (typeof locale === 'undefined') {
    locale = DEFAULT_LANGUAGE;
    localStorage.setItem(LS_LOCALE, locale);
  }
  // Only change the language if it's not the same as what has been loaded already;
  if (i18n.language && i18n.language !== locale) {
    if (isValidLanguage(locale as Languages)) {
      // TODO: This should update the user profile if a different language was selected.
      //   This function is only called when the user is logged in. So if the user chose a
      //   different language on the login page, the user profile needs updated otherwise the
      //   language on the screen would not match that in the user's profile.
      await i18n.changeLanguage(locale as Languages);
    } else {
      locale = DEFAULT_LANGUAGE;
    }
  }
  dispatch({ type: GeneralActionType.CHANGE_LANGUAGE, payload: locale as Languages });
};

export const getAuthInfo = () => async dispatch => {
  try {
    const user = await Auth.currentSession();
    const username = user.getIdToken().payload.email;
    localStorage.setItem(LS_USERNAME, username);
    const isSetUpProfileCompleted = getConstantFromLocalStorage(LS_COMPANY_PROFILE_COMPLETION_FLAG);
    // Fetch the user's profile so we have access to their rights immediately
    await dispatch(initProfile());
    if (typeof isSetUpProfileCompleted !== 'undefined' && JSON.parse(isSetUpProfileCompleted) === false) {
      dispatch({ type: AuthActionType.USER_PROFILE_SETUP, userName: user.getAccessToken().payload.username });
    } else {
      dispatch({ type: AuthActionType.LOGIN_SUCCESS });
    }
  } catch (error) {
    // NOTE: this will trigger a logout if the session is expired because of the call the Auth.currentSession.
    reportError(error, 'Error when trying to get auth info:');
  }
};

/**
 * Starts password change by clearing the part of the state used in password change.
 */
const startChangePassword = () => dispatch => dispatch({ type: AuthActionType.CHANGING_PASSWORD_START });

/**
 * Sends password change request and displays a notification if success, error notification otherwise.
 * @param oldPassword Current user's password
 * @param newPassword New password
 */

const changePassword = (oldPassword: string, newPassword: string) => async dispatch => {
  dispatch({ type: AuthActionType.CHANGING_PASSWORD });
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    dispatch({ type: AuthActionType.CHANGING_PASSWORD_SUCCESS });
    const customerId = localStorage.getItem(LS_COMPANY_CUSTOMER_ID);
    const currentCredentials = await Auth.currentSession();
    const userName = currentCredentials.getIdToken().payload.email;
    dispatch(sendChangePasswordConfirmation(customerId, userName));
    await dispatch(logout(true));
  } catch (error) {
    reportError(error, 'Error when changing password: ');
    dispatch({ type: AuthActionType.CHANGING_PASSWORD_FAIL, error: error.code });
  }
};

const sendChangePasswordConfirmation = (customerId: string, userName: string) => async dispatch => {
  try {
    const body = { attribute: 'password' };
    if (customerId !== null && typeof customerId !== 'undefined' && userName !== null) {
      const uri = UserApiUris.sendEmail
        .replace('{user_name}', Buffer.from(userName).toString('base64'))
        .replace('{customer_id}', customerId);
      await Api.put(Apis.user, uri, body);
      dispatch({ type: AuthActionType.SEND_RESET_PASSWORD_EMAIL_SUCCESS });
    } else {
      dispatch({ type: AuthActionType.SEND_RESET_PASSWORD_EMAIL_FAIL, error: 'Missing customer id or username' });
    }
  } catch (error) {
    reportError(error, 'Error sending "Change Password" confirmation:');
    dispatch({ type: AuthActionType.SEND_RESET_PASSWORD_EMAIL_FAIL, error: error.code });
  }
};

/**
 * Completes password change process by clearing the part of the state used in password change.
 */
const changePasswordCompleted = () => dispatch => dispatch({ type: AuthActionType.CHANGING_PASSWORD_COMPLETED });

/**
 * Clear send reset code state.
 */
const sendResetCodeInit = () => dispatch => dispatch({ type: AuthActionType.SENDING_RESET_CODE_INIT });

/**
 * Sends password reset code to the user by email.
 * @param userName User's email address.
 * @param companyAlias User's company alias.
 */
const sendResetCode = (userName: string, companyAlias: string) => async dispatch => {
  dispatch({ type: AuthActionType.SENDING_RESET_CODE });
  try {
    await dispatch(getUserPoolConfig(companyAlias));
    await Auth.forgotPassword(convertEmail(userName));
    dispatch({
      type: AuthActionType.SENDING_RESET_CODE_SUCCESS
    });
  } catch (error) {
    reportError(error, 'Error sending reset code:');
    localStorageCleanUp();
    dispatch({ type: AuthActionType.SENDING_RESET_CODE_FAIL, error: error.code });
  }
};

/**
 * Reset user password to a new password using reset password code.
 * @param resetCode Reset password code sent to the user by email.
 * @param userName userName
 * @param newPassword New password
 */
const resetPassword = (userName: string, resetCode: string, newPassword: string) => async dispatch => {
  try {
    await dispatch(getUserPoolConfig(getConstantFromLocalStorage(LS_COMPANY_ALIAS)));
  } catch (error) {
    // If we cannot fetch the user pool, throw an error an tell the user the link is invalid.
    reportError(error, 'Error resetting password:');
    return dispatch({ type: AuthActionType.RESETTING_PASSWORD_FAIL, error: PasswordResetError.CodeMismatchException });
  }
  if (userName && newPassword && resetCode) {
    dispatch({ type: AuthActionType.RESETTING_PASSWORD });
    try {
      await Auth.forgotPasswordSubmit(convertEmail(userName), resetCode, newPassword);
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: i18n.t('auth.passwordChanged')
        })
      );
      dispatch({ type: AuthActionType.RESETTING_PASSWORD_SUCCESS });
    } catch (error) {
      reportError(error, 'Error resetting password:');
      dispatch({ type: AuthActionType.RESETTING_PASSWORD_FAIL, error: error.code });
    }
  }
};

const setUpPasswordPolicy = () => async dispatch => {
  dispatch({ type: AuthActionType.PASSWORD_POLICY_START });
  try {
    const companyAlias = getConstantFromLocalStorage(LS_COMPANY_ALIAS);
    if (!companyAlias) {
      throw new Error('Company alias is not available in local storage.');
    }
    const url = UserApiUris.tenantPool.replace(`{${LS_COMPANY_ALIAS}}`, companyAlias);
    const response = await Api.getUnauthorized(Apis.user, url);
    if (!response.ok) {
      throw new Error(`Status ${response.status} was returned from user pool for ${companyAlias}`);
    }
    const config = await response.json();
    if (typeof config !== 'undefined') {
      dispatch({
        type: AuthActionType.PASSWORD_POLICY_SUCCESS,
        passwordLength: config.pool_min_length,
        LowerCaseRequired: config.pool_require_lowercase,
        NumberRequired: config.pool_require_numbers,
        SpecialCharRequired: config.pool_require_symbols,
        UpperCaseRequired: config.pool_require_uppercase
      });
    }
  } catch (error) {
    reportError(error, 'Error setting up password policy:');
    dispatch({ type: AuthActionType.PASSWORD_POLICY_FAIL, error: error.code });
  }
};

/**
 * Setup user profile during sign on process.
 * @param userProfile
 */
const setUpProfile = (userProfile: UserInterface) => async dispatch => {
  const body = {
    pin: userProfile.pin,
    phone: userProfile.phone,
    tz: userProfile.tz,
    picture: userProfile.picture,
    last_name: userProfile.last_name,
    locale: userProfile.locale,
    first_name: userProfile.first_name,
    email: userProfile.user_name
  };
  try {
    const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
    if (customerId) {
      const uri = UserApiUris.setUpProfile
        .replace('{user_name}', Buffer.from(userProfile.user_name).toString('base64'))
        .replace('{customer_id}', customerId);
      await Api.put(Apis.user, uri, body);
      localStorage.removeItem(LS_COMPANY_PROFILE_COMPLETION_FLAG);
      dispatch({ type: AuthActionType.SETUP_PROFILE_SUCCESS });
    } else {
      logout(false);
    }
  } catch (error) {
    reportError(error, 'Error setting up profile:');
    logout(false);
  }
};

/**
 * Setup permanent password during sign on process using Cognito.
 * @param username Username for login
 * @param oldPassword Password for login
 * @param newPassword New Password
 */
const setUpPassword = (userName: string, oldPassword: string, newPassword: string) => async dispatch => {
  dispatch({ type: AuthActionType.SETUP_PASSWORD_START });
  let phoneNumVerifies;
  let user;
  try {
    user = await Auth.signIn(userName, oldPassword);
    phoneNumVerifies = user.challengeParam.userAttributes.phone_number_verified;
    // Temporary code to change user NEW_PASSWORD_REQUIRED to CONFIRMED
    if (user.challengeName === CognitoChallengeName.NEW_PASSWORD_REQUIRED) {
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword // the new password
        // OPTIONAL, the required attributes
        // Commented out because it causes the exception {"__type":"NotAuthorizedException","message":"Cannot modify an already provided email"} on dev
        // { email: userName }
      );
      if (typeof phoneNumVerifies !== 'undefined' && phoneNumVerifies === 'true') {
        if (user.challengeName === CognitoChallengeName.SMS_MFA) {
          await dispatch({
            type: AuthActionType.MFA_START,
            mfaUsername: userName,
            mfaPassword: Buffer.from(newPassword).toString('base64'),
            mfaUser: user
          });
        } else {
          await dispatch({ type: AuthActionType.SETUP_PASSWORD_SUCCESS_WITH_PROFILE_COMPLETED });
        }
      } else {
        await dispatch(getCompanyInfo(localStorage.getItem(LS_COMPANY_ALIAS)));
        localStorage.setItem(LS_COMPANY_PROFILE_COMPLETION_FLAG, 'false');
        await dispatch({ type: AuthActionType.SETUP_PASSWORD_SUCCESS_WITHOUT_PROFILE_COMPLETED, userName: userName });
      }
    }
  } catch (error) {
    // HACK! This is grossssss! Cognito must have pushed some breaking changes to their backend code. Device verification on completing password just doesn't work at all.
    if (error?.code === 'InvalidParameterException' && error?.message === 'Invalid device key given.') {
      // Must cancel the current signin attempt and use the password that was set before the error occurred
      try {
        console.log('Cancel current login');
        await Auth.signOut();
      } catch (e) {
        console.error(e);
        console.log('Swallowing auth signout as it will probably fail');
      }
      try {
        user = await Auth.signIn(userName, newPassword);
        localStorage.setItem(LS_COMPANY_PROFILE_COMPLETION_FLAG, 'false');
        await dispatch(getCompanyInfo(localStorage.getItem(LS_COMPANY_ALIAS)));
        await dispatch({ type: AuthActionType.SETUP_PASSWORD_SUCCESS_WITHOUT_PROFILE_COMPLETED, userName: userName });
      } catch (e) {
        reportError(error, 'Error setting up a password:');
        // All the other error cases are currently shown as invalid username or password.
        dispatch({ type: AuthActionType.SETUP_PASSWORD_FAIL, error: error.code });
      }
    } else {
      reportError(error, 'Error setting up a password:');
      // All the other error cases are currently shown as invalid username or password.
      dispatch({ type: AuthActionType.SETUP_PASSWORD_FAIL, error: error.code });
    }
  }
};

/**
 * Get tenant information.
 * @param companyAlias
 */
const getCompanyInfo = (companyAlias: string) => async dispatch => {
  try {
    const uri = UserApiUris.tenantInfo.replace(`{${LS_COMPANY_ALIAS}}`, companyAlias);
    const response = await Api.get(Apis.tenant, uri);
    const tenantInfo = await response.json();
    localStorage.setItem(LS_IS_COMPANY_ACTIVE, tenantInfo.active);
    localStorage.setItem(LS_COMPANY_ONBOARD, tenantInfo.onboard);
    localStorage.setItem(LS_COMPANY_ADDRESS, tenantInfo.address);
    localStorage.setItem(LS_COMPANY_NAME, tenantInfo.name);
    localStorage.setItem(LS_COMPANY_ACCOUNT_ID, tenantInfo.account_id);
    localStorage.setItem(LS_COMPANY_CUSTOMER_ID, tenantInfo.customer_id);
    localStorage.setItem(LS_DATA_RETENTION_MONTHS, tenantInfo.data_retention_months);
    dispatch({ type: AuthActionType.LOADING_TENANT_INFO_SUCCESS });
  } catch (error) {
    reportError(error, 'Error getting company info:');
    // All the pther error cases are currently shown as invalid username or password.
    dispatch({ type: AuthActionType.LOADING_TENANT_INFO_FAIL });
  }
};

/**
 * Setup MFA in login process if it is required by Cognito.
 * @param mfa
 */
const setUpMFA = (mfa: string) => async (dispatch, getState) => {
  dispatch({ type: AuthActionType.MFA_PREP });
  const cognitoUser = getState().auth.mfaUser;
  try {
    if (!cognitoUser) {
      throw new Error('The page may have been reloaded or the MFA requirements are otherwise misconfigured');
    }
    await Auth.confirmSignIn(cognitoUser, mfa);
    await dispatch(getCompanyInfo(localStorage.getItem(LS_COMPANY_ALIAS)));
    return dispatch({ type: AuthActionType.MFA_SUCCESS });
  } catch (error) {
    reportError(error, 'Error setting up MFA:');
    dispatch({ type: AuthActionType.MFA_FAIL, error: error.code || MFAError.CodeMismatchException });
  }
};

/**
 * Resend MFA using Cognito user pool with given username and password.
 */
const resendMFA = () => async (dispatch, getState) => {
  const { mfaUsername, mfaPassword } = getState().auth;
  try {
    // NOTE: if the page was refreshed mfaUsername and mfaPassword will be undefined and this next line will throw an exception.
    const password = Buffer.from(mfaPassword, 'base64').toString('ascii');
    const user = await Auth.signIn(mfaUsername, password);
    if (user.challengeName === 'SMS_MFA') {
      dispatch({
        type: AuthActionType.MFA_START,
        mfaUsername,
        mfaPassword: Buffer.from(password).toString('base64'),
        mfaUser: user
      });
    } else {
      await dispatch({ type: AuthActionType.LOGIN_SUCCESS });
    }
  } catch (error) {
    reportError(error, 'Error resending MFA:');
    dispatch({ type: AuthActionType.RESEND_MFA_FAIL });
  }
};

/**
 * Back to login page from MFA page
 */
const MFABackToLogin = () => async dispatch => {
  dispatch({ type: AuthActionType.MFA_END });
  localStorageCleanUp();
};

const setAuthError = (error: MFAError | AuthError) => async dispatch => {
  dispatch({ type: AuthActionType.SET_AUTH_ERROR, error });
};

const setResettingPasswordError = (error: PasswordResetError) => async dispatch => {
  dispatch({ type: AuthActionType.SET_RESETTING_PASSWORD_ERROR, error });
};

export const authDispatchActions = {
  login,
  logout,
  changePassword,
  getAuthInfo,
  getLanguageInfo,
  changePasswordCompleted,
  startChangePassword,
  sendResetCodeInit,
  sendResetCode,
  resetPassword,
  setUpPasswordPolicy,
  setUpProfile,
  setUpPassword,
  getCompanyInfo,
  setUpMFA,
  resendMFA,
  MFABackToLogin,
  setAuthError,
  setResettingPasswordError
};
