import { Flex, FlexItem, Heading, Icon, Size, Stack, Anchor, Toggle } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceState, DEVICE_ERROR_STATES, DEVICE_STATUS_ICON_TYPE } from '../../../constants';
import { getEntries, DeviceModel, DeviceStatus } from '../../../types';
import CA10_small_antenna from '../../../assets/images/CA10_small_antenna.png';
import { getDeviceStatusTranslations } from '../../../utils';
import DeviceStatusIcon from './DeviceStatusIcon';
import { TEST_IDS } from '../../../tests/testids';

type DeviceManagerStatusTitlebarProps = {
  device: DeviceModel;
};

const DeviceManagerStatusTitlebar = ({ device }: DeviceManagerStatusTitlebarProps) => {
  const { t } = useTranslation();
  const { attributes } = device.properties;
  const { device: deviceHealth } = device.status;
  const isNewDevice: boolean = device.status.state === DeviceState.NewDevice;
  const deviceIsOffline: boolean = device.status.state === DeviceState.Offline;

  // create array of deviceErrorStates with sortKeys, translationKey and type.
  const deviceErrorStates: DeviceStatus[] = getEntries(device.status.errorStates).map(([key, value]) => {
    if (value === true) {
      return DEVICE_ERROR_STATES[key];
    } else return null;
  });

  // combine online with errorStates as there can be multiple states visible when device is online
  const deviceStates: DeviceStatus[] = [
    { translationKey: 'deviceManager.status.online', sortKey: 5 },
    ...deviceErrorStates
  ];

  // get translation and type for styling. Sorts states or return singular statuses offline/new device.
  const deviceStatusesTranslations = getDeviceStatusTranslations(deviceStates, isNewDevice, deviceIsOffline);

  return (
    <>
      <Stack direction="row" justify="space-between">
        <Heading dataTa={TEST_IDS.device_status_heading} id="device-status-title">
          {device.deviceId}
        </Heading>
        {attributes.isLogger && (
          <Flex className="device-status__icons">
            <FlexItem>
              <DeviceStatusIcon type={DEVICE_STATUS_ICON_TYPE.BATTERY} level={deviceHealth.batteryLevel} />
            </FlexItem>
            <FlexItem>
              <DeviceStatusIcon type={DEVICE_STATUS_ICON_TYPE.SIGNAL} level={deviceHealth.signalQuality} />
            </FlexItem>
          </Flex>
        )}
        {/* Temporary removing active toggle */}
        {/* <Toggle
          className="device-status__active-toggle"
          disabled
          checked
          onChange={() => console.log('toggle clicked')}
          text={t('site.active')}
        /> */}
      </Stack>

      {attributes.isLogger && (
        <Flex className="device-status-row">
          <div className={'device-status-column device-status-column--first'}>
            <Heading id="device-status-status-label" className="device-status-column__title" level={3}>
              {t('deviceManager.status.device')}
            </Heading>
            {deviceStatusesTranslations.map(item => {
              return (
                <FlexItem
                  key={item.translationKey}
                  id={`device-status-status-value--${item.translationKey}`}
                  className={
                    item.type === 'alert'
                      ? 'device-status-value device-status-value--alert'
                      : item.type === 'warning'
                      ? 'device-status-value device-status-value--warning'
                      : 'device-status-value'
                  }
                >
                  {item.type === 'alert' && <Icon name={VaiIcon.AlertAlarm} size={Size.S} />}
                  {t(item.translationKey)}
                </FlexItem>
              );
            })}
          </div>

          {isNewDevice ? null : (
            <div className="device-status-column">
              <Heading className="device-status-column__title" level={3}>
                {t('deviceManager.status.currentHost') + ':'}
              </Heading>
              <FlexItem>
                {device.status.gateway ? (
                  <Anchor
                    htmlId="device-status-host-name"
                    className="device-anchor"
                    to={device.status.gateway}
                    openInNewWindow
                  >
                    <Flex alignItems="center" justifyContent="center">
                      <img
                        className="vai-margin-horizontal-s vai-margin-bottom-s icon-width"
                        src={CA10_small_antenna}
                      />
                      {device.status.gateway}
                    </Flex>
                  </Anchor>
                ) : (
                  t('deviceManager.status.none')
                )}
              </FlexItem>
            </div>
          )}
        </Flex>
      )}
    </>
  );
};
export default DeviceManagerStatusTitlebar;
