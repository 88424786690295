import { VALIDATIONS_TYPE, FIELD_NAME_TYPE, FIELD_VALIDATIONS_CONFIG } from '../constants';

const checkValidationErrorFn = (validationType, fieldName, fieldValue) => {
  switch (validationType) {
    case VALIDATIONS_TYPE.MAX_LENGTH:
      return fieldValue?.length > FIELD_VALIDATIONS_CONFIG[fieldName][validationType];
    default:
      return false;
  }
};

interface IValidationResult {
  hasError: boolean;
  errors: VALIDATIONS_TYPE[];
}

export const validation = (
  fieldName: FIELD_NAME_TYPE,
  fieldValue: string,
  validationType: VALIDATIONS_TYPE | VALIDATIONS_TYPE[]
): IValidationResult => {
  const validationResult = { hasError: false, errors: [] } as IValidationResult;

  if (!Array.isArray(validationType)) {
    const hasError = checkValidationErrorFn(validationType, fieldName, fieldValue);

    if (hasError) {
      validationResult.hasError = hasError;
      validationResult.errors = [validationType];
    }
    return validationResult;
  } else {
    return validationType.reduce((mem, type) => {
      const hasError = checkValidationErrorFn(type, fieldName, fieldValue);

      if (hasError) {
        mem.hasError = true;
        mem.errors = [...mem.errors, type];
      }

      return mem;
    }, validationResult);
  }
};
