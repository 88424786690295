import React from 'react';
import { GroupInterface, InvitedUsersInterface, UserInterface } from '../../../profile/user';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import useHtmlId, { IUseHtmlIDProps } from '../../../hooks/useHtmlId';
import { getConstantFromLocalStorage, isAdminGroup } from '../../../utils';
import { LS_USERNAME } from '../../../constants';
import {
  Button,
  ButtonType,
  Checkbox,
  Flex,
  FlexItem,
  Icon,
  Size,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableStyle
} from '@vaisala/rockhopper-components';
import Infotip from '../../Utils/Infotip';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../../../tests/testids';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { showDialog } from '../../../store/dialog';
import { useAppDispatch } from '../../../store/hooks';
import AddGroupsToUserDialog from '../AddGroupsToUserDialog';
import DeleteUserGroupsDialog from '../DeleteUserGroupsDialog';
import { useNavigate } from 'react-router';
import { buildLinkToGroupPage } from '../Group';

interface Props extends IUseHtmlIDProps {
  isLoading: boolean;
  allGroups: GroupInterface[];
  userProfile: UserInterface;
  inviteUsers: (users: InvitedUsersInterface, dispatcher: string, name?: string) => string[];
  onSelect?: (groups: GroupInterface[]) => void;
  tableHeight?: number;
}

const _htmlId = 'user-group-list';
const { remove_user_from_groups_button } = QA_TEST_IDS;

const UserGroupList = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { isLoading, allGroups, userProfile, inviteUsers, onSelect, htmlId = _htmlId, tableHeight } = props;
  const { getId } = useHtmlId({ htmlId });
  const dispatch = useAppDispatch();
  const username = getConstantFromLocalStorage(LS_USERNAME);
  const { t } = useTranslation();
  const [selectedGroups, setSelectedGroups] = React.useState<GroupInterface[]>([]);
  const isCurrentUser = username ? username === userProfile?.user_name : false;
  const isRemoveGroupsButtonDisabled = selectedGroups.length === 0;
  const navigate = useNavigate();

  React.useEffect(() => {
    // Don't leak selected groups when you select a different user from the list.
    selectedGroups.length && setSelectedGroups([]);
    onSelect?.([]);
  }, [userProfile?.user_name]);

  const showDeleteUserGroupsDialog = () => {
    dispatch(showDialog(<DeleteUserGroupsDialog user={userProfile} groups={selectedGroups} />));
  };

  const showAddGroupsToUserDialog = (): void => {
    dispatch(
      showDialog(<AddGroupsToUserDialog groups={allGroups} userProfile={userProfile} inviteUsers={inviteUsers} />)
    );
  };

  if (!isLoading && userProfile) {
    const handleGroupCheckboxChange = (isSelected: boolean, row: GroupInterface) => () => {
      const newGroups = isSelected
        ? selectedGroups.filter(group => group.group_id !== row.group_id)
        : [...selectedGroups, row];
      setSelectedGroups(newGroups);
      onSelect?.(newGroups);
    };
    return (
      <>
        <div className="vai-padding-vertical-m" ref={ref}>
          <Flex alignItems="center" className="vai-padding-bottom-s">
            <FlexItem>
              <p id="user-groups-heading" className="user-header">
                <Icon
                  id={QA_TEST_IDS.user_group_list_heading_icon}
                  dataTa={TEST_IDS.user_group_list_heading_icon}
                  size={Size.L}
                  name={VaiIcon.UserGroup}
                  className="vai-margin-right-s"
                />
                {t('profile.groups')}
              </p>
            </FlexItem>
            <FlexItem className="user-top-right-button-container">
              <Button
                id="user-add-to-group-button"
                data-ta={TEST_IDS.users_add_to_group}
                buttonType={ButtonType.Link}
                onClick={showAddGroupsToUserDialog}
              >
                {t('general.add')}
              </Button>
            </FlexItem>
            <FlexItem>
              <Button
                id={remove_user_from_groups_button}
                dataTa={TEST_IDS.remove_user_from_groups_button}
                buttonType={ButtonType.Link}
                buttonSize={Size.S}
                disabled={isRemoveGroupsButtonDisabled}
                onClick={isRemoveGroupsButtonDisabled ? null : showDeleteUserGroupsDialog}
              >
                <Icon id="remove-user-from-groups-icon" name={VaiIcon.Trash} />
              </Button>
            </FlexItem>
          </Flex>
          <p id="user-group-rights-blurb" className="grey-medium vai-margin-top-m">
            {t('profile.accessRight')}
          </p>
        </div>
        <div className={'user-group-list-table-container'} style={{ maxHeight: tableHeight || 'auto' }}>
          <Table
            id={getId(TEST_IDS.table)}
            dataTa={TEST_IDS.user_group_list_table}
            tableStyle={TableStyle.Plain}
            className="user-and-group-table vai-margin-left-s"
          >
            <TableBody>
              {userProfile.groups.map((row: GroupInterface, index: number) => {
                const selectedItem = selectedGroups.find(group => group.group_id === row.group_id);
                const isSelected = selectedItem != null;
                const isRowAdmin = isAdminGroup(row);
                const checkboxId = getId(`group-checkbox__${row.group_id}`);
                return (
                  <TableRow dataTa={TEST_IDS.user_group_list_table_row} key={`user-group__${row.group_id}--${index}`}>
                    <TableCell className="groups-table-checkbox-cell">
                      {isRowAdmin && isCurrentUser ? (
                        <Infotip
                          htmlId={getId('cannot-remove-self-from-admin')}
                          iconProps={{
                            style: { position: 'relative', left: '-3px' },
                            dataTa: TEST_IDS.user_group_list_infotip
                          }}
                        >
                          {/* NOTE: Popovers don't seem to honour /r/n or HTML in translation tags so the paragraphs are split */}
                          {t('infotip.cannotRemoveSelfFromAdminPara1')}
                          <br />
                          <br />
                          {t('infotip.cannotRemoveSelfFromAdminPara2')}
                        </Infotip>
                      ) : (
                        <Checkbox
                          id={checkboxId}
                          checked={isSelected}
                          dataTa={TEST_IDS.user_group_list_checkbox}
                          onChange={handleGroupCheckboxChange(isSelected, row)}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      id={getId(`group-name__${row.group_id}`)}
                      dataTa={TEST_IDS.user_group_list_group_name}
                      className="aqua-medium cursor-pointer user-and-group-table__groupname"
                      onClick={() => navigate(buildLinkToGroupPage(row.group_id))}
                    >
                      {row.group_name}
                    </TableCell>
                    <TableCell dataTa={TEST_IDS.user_group_list_user_count}>
                      {row.users ? row.users.length : '0'}{' '}
                      {row.users
                        ? row.users.length > 1 || row.users.length === 0
                          ? t('profile.users')
                          : t('profile.user')
                        : t('profile.users')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }
  return <CenteredSpinner htmlId={getId('spinner')} />;
});

UserGroupList.displayName = 'UserGroupList';
export default UserGroupList;
