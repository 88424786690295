import { Icon } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { DEVICE_ALARM_STATUS, ALARM_PRIORITY } from '../../../constants';
import { TEST_IDS } from '../../../tests/testids';
import './locationIcon.scss';

const LocationIcon = ({ status }: { status: DEVICE_ALARM_STATUS | ALARM_PRIORITY }) => {
  return (
    <div className="location-icon" data-ta={TEST_IDS.location_icon}>
      {status === DEVICE_ALARM_STATUS.UNLINKED ? (
        <Icon
          className="location-icon__link"
          dataTa={TEST_IDS.location_icon_unlink}
          style={{ fontSize: '24px' }}
          name={VaiIcon.Unlink}
        />
      ) : (
        <Icon
          className="location-icon__link"
          dataTa={TEST_IDS.location_icon_link}
          style={{ fontSize: '24px' }}
          name={VaiIcon.Link}
        />
      )}
      <Icon className="location-icon__map" style={{ fontSize: '50px' }} name={VaiIcon.MapMarker} />
    </div>
  );
};
export default LocationIcon;
