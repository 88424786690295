import * as React from 'react';
import {
  Form,
  Heading,
  InputField,
  PasswordInput,
  Size,
  Button,
  Select,
  Label,
  RequiredAsterisk,
  Flex,
  FlexItem
} from '@vaisala/rockhopper-components';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PictureUploader from '../Utils/PictureUploader/index';
import timeZones from '../../data/timeZones.json';
import { Languages } from '../../i18n';
import { UserInterface } from '../../profile/user';
import { NAME_MAX_LENGTH, PIN_MIN_LENGTH, PIN_MAX_LENGTH, DEFAULTCOUNTRYCODE } from '../../constants';
import { checkPlusForPhoneNumber, getErrorClass, LANGUAGE_TEXT } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import Infotip from '../Utils/Infotip';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';

import './setup-profile.scss';

interface SetUpProfileProps {
  setUpProfile: (userProfile: UserInterface) => void;
  language: Languages;
  changeLanguage: (language: Languages) => void;
  userName: string;
}

const SetUpProfile = (props: SetUpProfileProps): JSX.Element => {
  const { t } = useTranslation();
  const [saving, setSaving] = React.useState<boolean>(false);
  const { setUpProfile, changeLanguage, userName } = props;
  const getTimeZoneFromBrowser = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const [firstName, setFirstName] = React.useState('');
  const [firstNameEmptyError, setFirstNameEmptyError] = React.useState(false);
  const [firstNameMaxLengthError, setFirstNameMaxLengthError] = React.useState(false);
  const [lastName, setLastName] = React.useState('');
  const [lastNameEmptyError, setLastNameEmptyError] = React.useState(false);
  const [lastNameMaxLengthError, setLastNameMaxLengthError] = React.useState(false);
  const [language, setLanguage] = React.useState(Languages.en);
  const [languageError, setLanguageError] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [pin, setPin] = React.useState('');
  const [pinError, setPinError] = React.useState(false);
  const [timeZone, setTimeZone] = React.useState(getTimeZoneFromBrowser());
  const [timeZoneError, setTimeZoneError] = React.useState(false);
  const [profilePictureFile, setProfilePictureFile] = React.useState(null);

  React.useEffect(() => {
    const setLanguageFromSession = async () => {
      try {
        const session = await Auth.currentSession();
        const { locale } = session.getIdToken().payload;
        setLanguage(locale);
      } catch (exception) {
        // If we have no session. The user may have navigated to this page from a bookmark or maybe a link in an email.
        // No action needed here as the user should be redirected to the login page because of the failed call to Auth.currentSession
      }
    };
    setLanguageFromSession();
  }, []);

  const onFirstNameChange = event => {
    setFirstNameEmptyError(false);
    setFirstNameMaxLengthError(false);
    setFirstName(event.target.value);
    const trimmedFirstName = validator.trim(event.target.value);
    if (!trimmedFirstName) {
      setFirstNameEmptyError(true);
    }
    if (trimmedFirstName && trimmedFirstName.length > NAME_MAX_LENGTH) {
      setFirstNameMaxLengthError(true);
    }
  };

  const onLastNameChange = event => {
    setLastNameEmptyError(false);
    setLastNameMaxLengthError(false);
    setLastName(event.target.value);
    const trimmedLastName = validator.trim(event.target.value);
    if (!trimmedLastName) {
      setLastNameEmptyError(true);
    }
    if (trimmedLastName && trimmedLastName.length > NAME_MAX_LENGTH) {
      setLastNameMaxLengthError(true);
    }
  };

  const onPhoneNumberChange = event => {
    setPhoneNumberError(false);
    setPhoneNumber(event);
  };

  const onTimeZoneChange = event => {
    setTimeZoneError(false);
    setTimeZone(event);
    if (!timeZone) {
      setTimeZoneError(true);
    }
  };

  const onLanguageChange = event => {
    setLanguageError(false);
    setLanguage(event);
    if (props.language !== event) {
      changeLanguage(event);
    }
  };

  const onPinChange = event => {
    setPinError(false);
    setPin(event.target.value);
    if (
      event.target.value &&
      (!/^\d+$/.test(event.target.value) ||
        event.target.value.length < PIN_MIN_LENGTH ||
        event.target.value.length > PIN_MAX_LENGTH)
    ) {
      setPinError(true);
    }
  };

  const onSetUpProfile = async () => {
    setSaving(true);
    let errorExist = false;
    const trimmedFirstName = validator.trim(firstName);
    const trimmedLastName = validator.trim(lastName);
    if (!trimmedFirstName) {
      setFirstNameEmptyError(true);
      errorExist = true;
    }
    if (trimmedFirstName && trimmedFirstName.length > NAME_MAX_LENGTH) {
      setFirstNameMaxLengthError(true);
      errorExist = true;
    }
    if (!trimmedLastName) {
      setLastNameEmptyError(true);
      errorExist = true;
    }
    if (trimmedLastName && trimmedLastName.length > NAME_MAX_LENGTH) {
      setLastNameMaxLengthError(true);
      errorExist = true;
    }
    // eslint-disable-next-line no-useless-escape
    const phone = checkPlusForPhoneNumber(phoneNumber);
    if (!phoneNumber || (phoneNumber && !validator.isMobilePhone(phone, 'any'))) {
      setPhoneNumberError(true);
      errorExist = true;
    }
    if (!language) {
      setLanguageError(true);
      errorExist = true;
    }
    if (!timeZone) {
      setTimeZoneError(true);
      errorExist = true;
    }
    if (pin && (!/^\d+$/.test(pin) || pin.length < PIN_MIN_LENGTH || pin.length > PIN_MAX_LENGTH)) {
      setPinError(true);
      errorExist = true;
    }
    if (!errorExist) {
      const userProfile: UserInterface = {
        first_name: trimmedFirstName,
        last_name: trimmedLastName,
        locale: language,
        tz: timeZone,
        phone: checkPlusForPhoneNumber(phoneNumber),
        pin: pin,
        user_name: userName,
        picture: profilePictureFile
      };
      await setUpProfile(userProfile);
    }
    setSaving(false);
  };

  const isDisabled = saving || !phoneNumber || !firstName || !lastName || !language || !timeZone;

  const getFirstNameError = () => {
    if (firstNameEmptyError) {
      return t('signup.firstNameCannotBeEmpty');
    } else if (firstNameMaxLengthError) {
      return t('signup.characterLengthError');
    } else {
      return '';
    }
  };

  const getLastNameError = () => {
    if (lastNameEmptyError) {
      return t('signup.lastNameCannotBeEmpty');
    } else if (lastNameMaxLengthError) {
      return t('signup.characterLengthError');
    } else {
      return '';
    }
  };

  return (
    <Form mode="wide" htmlId="signup-setup-profile-form">
      <Heading level={2}>{t('signup.setupccounttitle')}</Heading>
      <PictureUploader
        title={t('signup.addProfilePicture')}
        disabled={false}
        htmlId="signup-image-uploader"
        pictureUrl=""
        onChange={file => {
          setProfilePictureFile(file);
        }}
      />
      <Form.Item
        component={InputField}
        label={t('signup.firstName')}
        required={true}
        htmlId="signup-first-name"
        width={Size.M}
        value={firstName}
        onChange={onFirstNameChange}
        className={getErrorClass(getFirstNameError())}
        errors={getFirstNameError()}
      />
      <Form.Item
        label={t('signup.lastName')}
        component={InputField}
        required={true}
        htmlId="signup-last-name"
        width={Size.M}
        value={lastName}
        onChange={onLastNameChange}
        className={getErrorClass(getLastNameError())}
        errors={getLastNameError()}
      />
      <Form.Item
        label={t('signup.language')}
        component={Select}
        showSearch
        filterOption={true}
        optionFilterProp="title"
        required={true}
        htmlId="signup-language"
        defaultValue={t('general.selectEllipsis')}
        value={language}
        width={Size.M}
        onChange={onLanguageChange}
        className={getErrorClass(languageError ? t('signup.languageCannotBeEmpty') : '')}
      >
        {Object.values(Languages).map((value: Languages) => {
          const langText = LANGUAGE_TEXT[value];
          return (
            <Select.Option
              id={`signup-language-list-${value}`}
              key={value}
              title={langText}
              value={value}
              dataTa="language-option"
            >
              {langText}
            </Select.Option>
          );
        })}
      </Form.Item>
      <span id="signup-language-error" className="input-error">
        {languageError ? t('signup.languageCannotBeEmpty') : ''}
      </span>
      <Form.Item
        label={t('signup.timeZone')}
        component={Select}
        showSearch
        required={true}
        htmlId="signup-time-zone"
        defaultValue={t('general.selectEllipsis')}
        value={timeZone}
        width={Size.M}
        onChange={onTimeZoneChange}
        className={getErrorClass(timeZoneError ? t('signup.timeZoneCannotBeEmpty') : '')}
      >
        {timeZones.map(option => (
          <Select.Option
            id={`signup-time-zone-list-${option.value}`}
            key={`${option.value}`}
            title={option.label}
            value={option.value}
          >
            {option.label}
          </Select.Option>
        ))}
      </Form.Item>
      <span id="signup-time-zone-error" className="input-error">
        {timeZoneError ? t('signup.timeZoneCannotBeEmpty') : ''}
      </span>
      <Label id="phone-input-label" className="vai-padding-right-none edit-prfile-flex">
        <span className="edit-profile-asterisk">
          <RequiredAsterisk />
        </span>
        <span id="signup-phone-number-label" className="edit-profile-phone-number-label">
          {t('signup.phoneNumber')}
        </span>
        <PhoneInput
          containerClass="signup-phone-input-container"
          country={DEFAULTCOUNTRYCODE}
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          disableDropdown={true}
          inputClass="signup-phone-input"
        />
      </Label>
      <div id="signup-phone-number-container" className="signup-setup-profile-phone-error vai-form-error">
        {phoneNumberError ? t('signup.phoneNumberIsNotValid') : ''}
      </div>
      <Form.Item
        label={
          <Flex>
            <FlexItem id="signup-pin-label" flexGrow={1}>
              {t('signup.pin')}
            </FlexItem>
            <FlexItem>
              <Infotip htmlId="signup-setup-profile-pin-infotip" iconProps={{ className: 'vai-margin-horizontal-xs' }}>
                {t('infotip.pin')}
              </Infotip>
            </FlexItem>
          </Flex>
        }
        htmlId={'signup-pin'}
        errors={pinError ? t('signup.invalidPin', { minLength: PIN_MIN_LENGTH, maxLength: PIN_MAX_LENGTH }) : ''}
      >
        <PasswordInput
          htmlId="signup-pin-input"
          value={pin}
          name="pin"
          autoComplete="new-password"
          onChange={onPinChange}
          width={Size.M}
          className={getErrorClass(
            pinError ? t('signup.invalidPin', { minLength: PIN_MIN_LENGTH, maxLength: PIN_MAX_LENGTH }) : ''
          )}
        />
      </Form.Item>
      <div className="vai-margin-top-m flex-end">
        <Button
          id="signup-finish-button"
          className="vai-space-inline-s"
          htmlId="signup-finish-button"
          type="submit"
          onClick={onSetUpProfile}
          disabled={isDisabled}
        >
          {saving ? <CenteredSpinner htmlId="signup-finish-button-spinner" className="" /> : t('signup.finishBtn')}
        </Button>
      </div>
    </Form>
  );
};

export default SetUpProfile;
