import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonType, Flex, FlexItem, Grid, Icon, Size } from '@vaisala/rockhopper-components';
import { ALARM_THRESHOLD_TYPE } from '../../../../../../constants';
import { FormHeaders } from './FormHeaders';
import { GreenZone } from './GreenZone';
import { ThresholdLevel } from './ThresholdLevel';
import { useThresholdModalContext } from '../../ThresholdModalProvider';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { TEST_IDS } from '../../../../../../tests/testids';
import { useStepsContext } from '../../../Steps/StepsContextProvider';
import { useTranslation } from 'react-i18next';
import { ErrorsDisplay } from '../../../../Alarms/shared/ErrorsDisplay';
import { collectSetOfErrors, validateThresholdValue } from '../../validations';
import { QA_TEST_IDS } from '../../../../../../tests/qaTestIds';
import { ContextualHelp } from './ContextualHelp';
import { validateHoursMinutes } from '../../../../Alarms/shared/HoursMinutesFields';
import './threshold.scss';

export const ThresholdLevelsForm = () => {
  const { rules, errors, unitDetails, saveErrors } = useThresholdModalContext();
  const { goNext } = useStepsContext();
  const { t } = useTranslation();
  const [helpContextVisible, setHelpContextVisible] = useState(false);

  const isNextDisabled = errors.collectedErrors.length > 0;
  const { next: nextButtonId } = QA_TEST_IDS.steps;
  const showHelpContext = () => {
    setHelpContextVisible(!helpContextVisible);
  };

  const modalContentRef = useRef(null);

  useEffect(() => {
    if (helpContextVisible && modalContentRef.current) {
      modalContentRef.current.scrollTop = modalContentRef.current.scrollHeight;
    }
  }, [helpContextVisible]);

  const validate = (callback: () => void) => {
    saveErrors(errors => {
      let isInvalid = false;

      const updatedErrors = { ...errors };
      for (const level of Object.keys(ALARM_THRESHOLD_TYPE)) {
        const rule = rules[level as ALARM_THRESHOLD_TYPE];
        if (rule.enabled) {
          const hoursMinutesValidation = validateHoursMinutes(rule.delay_sec, errors[level]);
          const thresholdValueValitaion = validateThresholdValue(rule, errors[level], unitDetails);
          updatedErrors[level as ALARM_THRESHOLD_TYPE] = {
            delay: hoursMinutesValidation.error.delay,
            value: thresholdValueValitaion.error.value,
            hasError: hoursMinutesValidation.hasError || thresholdValueValitaion.hasError
          };
        }
      }
      updatedErrors.collectedErrors = collectSetOfErrors(updatedErrors);
      isInvalid = updatedErrors.collectedErrors.length > 0;
      if (!isInvalid) callback();
      return updatedErrors;
    });
  };

  const { help_link_button } = QA_TEST_IDS;

  return (
    <div ref={modalContentRef} className={`threshold-levels-form${helpContextVisible ? ' context-open' : ''}`}>
      <div>
        <Grid padding={Size.S}>
          <FormHeaders />
          <ThresholdLevel rule={rules.HIHI} level={ALARM_THRESHOLD_TYPE.HIHI} />
          <ThresholdLevel rule={rules.HI} level={ALARM_THRESHOLD_TYPE.HI} />
          <GreenZone />
          <ThresholdLevel rule={rules.LO} level={ALARM_THRESHOLD_TYPE.LO} />
          <ThresholdLevel rule={rules.LOLO} level={ALARM_THRESHOLD_TYPE.LOLO} />
        </Grid>
        <ErrorsDisplay collectedErrors={errors.collectedErrors} />
        <Flex justifyContent="space-between">
          <FlexItem flexGrow={1}>
            <Button
              onClick={showHelpContext}
              disabled={helpContextVisible}
              id={help_link_button}
              dataTa={TEST_IDS.help_link_button}
              buttonType={ButtonType.Link}
            >
              <Flex alignItems="center">
                <FlexItem>
                  <Icon name={VaiIcon.HelpO} />
                </FlexItem>
                <FlexItem flexGrow={1}>{t('site.alarms.settings.threshold.form.help.linkText')}</FlexItem>
              </Flex>
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              disabled={isNextDisabled}
              dataTa={TEST_IDS.steps.next}
              id={nextButtonId}
              htmlId={nextButtonId}
              onClick={() => {
                validate(goNext);
              }}
            >
              {t('general.next')}
            </Button>
          </FlexItem>
        </Flex>
        {helpContextVisible && <ContextualHelp showHelpContext={showHelpContext} />}
      </div>
    </div>
  );
};
