import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BodyText,
  Flex,
  FlexItem,
  Heading,
  Icon,
  Separator,
  Button,
  ButtonType,
  AccordionList,
  Size
} from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import RFLImage from '../../../assets/images/RFL100_no_cable.png';
import {
  LS_COMPANY_CUSTOMER_ID,
  PARENT_SITE_KEY,
  DEVICE_STATUS_TYPE_NAMES,
  DEVICE_ALARM_STATUS
} from '../../../constants';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import LocationHeader from '../Location/LocationHeader';
import { useGetLocationQuery } from '../../../store/services/siteApi';
import { useGetDevicePerSiteQuery } from '../../../store/services/devicesApi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectCurrentlyRemoving, selectSelectedNode } from '../../../store/siteTree';
import { buildDeviceId, getConstantFromLocalStorage, isLocationNode } from '../../../utils';
import { selectCurrentUser } from '../../../store/profile';
import { showDialog } from '../../../store/dialog';
import LinkDataSourceDialog from '../LinkDataSourceDialog';
import useSiteTreeActions from '../../../hooks/useSiteTreeActions';
import ToggleActivationDialog from '../ToggleActivationDialog';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { TEST_IDS } from '../../../tests/testids';
import { ThresholdAlarmAccordion } from './ThresholdAlarmAccordion';
import { DeviceAlarmAccordion } from './DeviceAlarmAccordion/DeviceAlarmAccordion';
import { useGetGroupsQuery } from '../../../store/services/userApi';
import { DeviceStatus } from '../../../types';
import { getLocationDeviceStatusTranslations } from '../../../utils';
import { useDeviceStatus } from '../../../hooks/useDeviceStatus';
import { HasRights, RightCheckScope, useHasRights } from '../../HasRIghts/HasRights';
import { RightNames } from '../../RightsAccordionTree/constants';
import AccessRestricted from '../../AccessRestricted/AccessRestricted';
import { SiteTabKeys } from '../../../containers/Site';
import './settings.scss';

type Props = {
  onError?: (error: any) => void;
};

export const Settings: React.FC<Props> = ({ onError }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const selectedNode = useAppSelector(selectSelectedNode);
  const nodeRightId = isLocationNode(selectedNode) ? selectedNode?.parent_id : selectedNode?.node_id;
  const canManageAlarms = useHasRights([{ nodeId: nodeRightId, rightName: RightNames.MANAGE_ALARM_TEMPLATES }]);
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const currentlyRemovingNodeId = useAppSelector(selectCurrentlyRemoving);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    currentData: location,
    error,
    isFetching: isFetchingLocation,
    isLoading: isLoadingLocation
  } = useGetLocationQuery({ customerId, id }, { skip: id === currentlyRemovingNodeId });
  const siteId = PARENT_SITE_KEY;

  React.useEffect(() => {
    if (onError && error) {
      onError(error);
    }
  }, [error]);

  const { currentData: getGroupResponse, isFetching: isFetchingGroups } = useGetGroupsQuery(
    {
      parent_id: location?.parent_id,
      include_rights: true,
      customerId
    },
    { refetchOnMountOrArgChange: true, skip: !location }
  );

  const { data: device } = useGetDevicePerSiteQuery(
    {
      customerId,
      siteId,
      deviceId: location?.device?.device_model + '-' + location?.device?.device_sn
    },
    { skip: !location || !location.device, refetchOnMountOrArgChange: true }
  );

  let deviceStatusesTranslations: DeviceStatus[];

  if (location && location.device && device) {
    // get translation and type for styling. Sorts states or return singular statuses offline/new device.
    deviceStatusesTranslations = getLocationDeviceStatusTranslations(
      device.status,
      location.node_id,
      location.device.probe_sn
    );
  }

  const notificationGroupsDetails = {
    groups: getGroupResponse?.groups,
    isFetching: isFetchingGroups
  };

  const { linkTreeNode, unlinkTreeNode, toggleNodeActive } = useSiteTreeActions();

  const { status: deviceStatus } = useDeviceStatus(location);

  const [openAccordion, setOpenAccordion] = React.useState('');

  const handleAccordionChange = (_isExpanded, accordionKey) => {
    setOpenAccordion(accordionKey);
  };

  const renderLinkDataSourceDialog = () => {
    // Do this check to guard against manual DOM manipulation
    currentUser?.canManageSites &&
      dispatch(
        showDialog(
          <LinkDataSourceDialog linkTreeNode={linkTreeNode} unlinkTreeNode={unlinkTreeNode} location={location} />
        )
      );
  };

  const toggleActiveDisplay = () => {
    dispatch(showDialog(<ToggleActivationDialog location={location} toggleNodeActive={toggleNodeActive} />));
  };

  if (!location) {
    return (
      <>
        <CenteredSpinner />
      </>
    );
  }

  const { site_settings_link_button } = QA_TEST_IDS;
  const locationDetails = { location, isLoading: isLoadingLocation || isFetchingLocation };

  // TODO: For checking site level rights we need site id in each site node so we don't use hardcoded PARENT_SITE_KEY
  const canSeeSettingsNodeRights = [
    { nodeId: nodeRightId, rightName: RightNames.MANAGE_ALARM_TEMPLATES },
    { nodeId: nodeRightId, rightName: RightNames.PAUSE_ALARMS },
    { nodeId: PARENT_SITE_KEY, rightName: RightNames.MANAGE_SITES }
  ];

  const navigateToStatus = () => {
    const statusTabUrl = pathname.replace(SiteTabKeys.settings, SiteTabKeys.status);
    navigate(statusTabUrl);
  };

  return (
    <HasRights
      rightCheckScope={RightCheckScope.Any}
      nodeRights={canSeeSettingsNodeRights}
      fallback={
        <AccessRestricted actionButton={<Button onClick={navigateToStatus}>{t('general.backToStatus')}</Button>} />
      }
    >
      <div className="settings-details">
        <LocationHeader
          location={location}
          toggle={currentUser ? currentUser.canManageSites : false}
          toggleActiveDisplay={toggleActiveDisplay}
          deviceStatus={deviceStatus}
          measurementType={location.meas_id}
        />
        <Separator />
        <Flex flexDirection="row" flexWrap="nowrap" className="settings-data-source">
          <FlexItem alignSelf="center" flexBasis="" flexGrow={0}>
            <Heading
              level={3}
              htmlId={QA_TEST_IDS.link_data_source_heading}
              dataTa={TEST_IDS.link_data_source_heading}
              className="settings-data-source__title"
            >
              {t('site.linkedDataSource')}
            </Heading>
          </FlexItem>
          {currentUser?.canManageSites && (
            <FlexItem>
              <Button
                id={site_settings_link_button}
                dataTa={TEST_IDS.site_settings_link_button}
                buttonType={ButtonType.Link}
                onClick={renderLinkDataSourceDialog}
                buttonSize={Size.S}
              >
                {t('general.edit')}
              </Button>
            </FlexItem>
          )}
        </Flex>
        {deviceStatus !== DEVICE_ALARM_STATUS.UNLINKED ? (
          <Flex flexDirection="row" flexWrap="nowrap" alignItems="center">
            <FlexItem>
              <div className="setting-icon">
                <img
                  id={QA_TEST_IDS.link_data_source_device_img}
                  data-ta={TEST_IDS.link_data_source_device_img}
                  src={RFLImage}
                  width={50}
                />
              </div>
            </FlexItem>
            <Flex flexDirection="column">
              <Flex flexDirection="row" flexWrap="nowrap" alignItems="center">
                <Heading
                  htmlId={QA_TEST_IDS.link_data_source_device_name}
                  dataTa={TEST_IDS.link_data_source_device_name}
                  level={4}
                  className="setting-title"
                >
                  {buildDeviceId(location.device?.device_model, location.device?.device_sn)}
                </Heading>
              </Flex>
              <FlexItem>
                <BodyText
                  htmlId={QA_TEST_IDS.link_data_source_probe_name}
                  dataTa={TEST_IDS.link_data_source_probe_name}
                  className="grey-medium"
                >
                  {buildDeviceId(location.device?.probe_model, location.device?.probe_sn)}
                </BodyText>
              </FlexItem>
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              className="vai-margin-left-m  setting-location-device-status-container"
            >
              {deviceStatusesTranslations?.length > 0 &&
                deviceStatusesTranslations.map(item => {
                  return (
                    <FlexItem
                      dataTa={TEST_IDS.site_setting_device_status_value}
                      key={item.translationKey}
                      id={`device-status-status-value--${item.translationKey}`}
                    >
                      {t(item.translationKey)}
                      {item.type === DEVICE_STATUS_TYPE_NAMES.ALERT && (
                        <Icon className="setting-alert-icon" name={VaiIcon.AlertAlarm} size={Size.S} />
                      )}
                      {item.type === DEVICE_STATUS_TYPE_NAMES.OK && deviceStatusesTranslations.length === 1 && (
                        <Icon className="setting-ok-icon" name={VaiIcon.AlertOk} size={Size.S} />
                      )}
                    </FlexItem>
                  );
                })}
            </Flex>
          </Flex>
        ) : (
          <div>
            <BodyText
              htmlId={QA_TEST_IDS.link_data_source_no_linked_source_msg}
              dataTa={TEST_IDS.link_data_source_no_linked_source_msg}
              className="setting-alert-message"
            >
              {t('site.noLinkedSource')}
            </BodyText>
            <BodyText
              htmlId={QA_TEST_IDS.link_data_source_no_linked_source_blurb}
              dataTa={TEST_IDS.link_data_source_no_linked_source_blurb}
              className="grey-medium"
            >
              {t('site.noLinkedSourceMsg')}
            </BodyText>
          </div>
        )}
        <Separator />
        <Flex flexDirection="row" flexWrap="nowrap" className="settings-alarms">
          <FlexItem alignSelf="center" flexGrow={1}>
            <Heading id={QA_TEST_IDS.site_settings_alarm_settings_heading} level={3} className="settings-alarms__title">
              {t('site.alarmSettingsHeading')}
            </Heading>
          </FlexItem>
        </Flex>
        <div
          id={QA_TEST_IDS.site_settings_threshold_settings_blurb}
          data-ta={TEST_IDS.site_settings_threshold_settings_blurb}
          className="vai-margin-bottom-m grey-medium"
        >
          {t('site.setThresholdLimitsBlurb')}
        </div>
        {!location.active && (
          <div
            className="alert-red vai-margin-bottom-m"
            id={QA_TEST_IDS.location_deactivated_alarm_blurb}
            data-ta={TEST_IDS.location_deactivated_alarm_blurb}
          >
            {t('site.deactivatedLocationAlarmBlurb')}
          </div>
        )}
        <AccordionList
          htmlId={QA_TEST_IDS.site_settings_accordion_list}
          dataTa={TEST_IDS.site_settings_accordion_list}
          onChange={handleAccordionChange}
          defaultKeys={openAccordion}
          className={'dm-accordion-list'}
        >
          <ThresholdAlarmAccordion
            notificationGroupsDetails={notificationGroupsDetails}
            canManageAlarms={canManageAlarms}
            locationDetails={locationDetails}
          />
          <DeviceAlarmAccordion
            notificationGroupsDetails={notificationGroupsDetails}
            canManageAlarms={canManageAlarms}
            locationDetails={locationDetails}
          />
        </AccordionList>
      </div>
    </HasRights>
  );
};
