import React from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { useAppDispatch } from '../store/hooks';

type QueryError = FetchBaseQueryError | SerializedError | undefined;

export interface UseInfiniteQueryResult<T> {
  // data: { notes: [], next: the latest cursor },
  // where data.notes is used to collect and cache the 1st and next fetched notes,
  // the field `next` is updated to follow the latest cursor returned from backend (e.g. DynamoDB in my case).
  data: T;

  // for the very first fetching
  error?: QueryError;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;

  // for the first fetching and next fetching
  isFetching: boolean;

  // for next fetches
  errorNext: QueryError;
  isErrorNext: boolean;
  isFetchingNext: boolean;
  hasNext: boolean;
  fetchNext: () => Promise<void>;
  refetch: () => Promise<void>;
}

export function useInfiniteQuery<T>(
  params: T,
  api: any,
  endpointName: string,
  options?: any, // NOTE: It's impossible to infer the necessary type checking here if requires knowing the type of the endpoint being used
  fetchAll = false, // if `true`: auto do next fetches to get all notes at once
  dataParam = 'tickets'
) {
  const dispatch = useAppDispatch();

  // baseResult is for example GET from https://example.com/johndoe/notes
  const baseResult = api.endpoints[endpointName].useQuery(params, options);

  // nextResults are for example GET from https://example.com/johndoe/notes?last_key=latest-cursor-value
  // trigger may be fired many times
  const [trigger, nextResult] = api.endpoints[endpointName].useLazyQuery();

  const isBaseReady = React.useRef(false);
  const isNextDone = React.useRef(true);
  // next: starts with a null, fetching ended with an undefined cursor
  const next = React.useRef<null | string | undefined>(null);

  // Base result
  React.useEffect(() => {
    next.current = baseResult.data?.last_key;
    if (baseResult.data) {
      isBaseReady.current = true;
      fetchAll && fetchNext();
    }
  }, [baseResult]);

  // When there comes a next fetched result
  React.useEffect(() => {
    if (!nextResult.isSuccess) return;

    if (isBaseReady.current && nextResult.data && nextResult.data.last_key !== next.current) {
      next.current = nextResult.data.last_key; // undefined if no data further

      // Put next fetched notes into the first queried collection (as a base collection)
      // This can help us do optimistic/pessimistic updates against the base collection
      const newItems = nextResult.data[dataParam];
      dispatch(
        api.util.updateQueryData(endpointName, params, drafts => {
          drafts.last_key = nextResult.data.last_key;
          if (newItems && newItems.length > 0) {
            // depends on the use case,
            // maybe we can do deduplication, removal of some old entries here, if required
            // ...

            // adding new data to the cache
            drafts[dataParam].push(...newItems);
          }
        })
      );
    }
  }, [nextResult]);

  const fetchNext = async () => {
    if (!isBaseReady.current || !isNextDone.current || next.current === undefined || next.current === null) return;

    try {
      isNextDone.current = false;
      await trigger({
        ...params,
        last_key: next.current
      });
    } catch (e) {
      console.error(e);
    } finally {
      isNextDone.current = true;
      fetchAll && fetchNext();
    }
  };

  const refetch = async () => {
    isBaseReady.current = false;
    next.current = null; // restart
    await baseResult.refetch(); // restart with a whole new refetching
  };

  return {
    data: baseResult.data,
    error: baseResult.error,
    isError: baseResult.isError,
    isSuccess: baseResult.isSuccess,
    isLoading: baseResult.isLoading,
    isFetching: baseResult.isFetching || nextResult.isFetching,
    errorNext: nextResult.error,
    isErrorNext: nextResult.isError,
    isFetchingNext: nextResult.isFetching,
    hasNext: baseResult.data?.last_key !== undefined,
    fetchNext,
    refetch
  };
}
