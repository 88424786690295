import {
  ALARM_PRIORITY,
  ALARM_THRESHOLD_TYPE,
  ALARM_UX_STATUS,
  API_ALARMS_BASE_URI,
  AlarmMessage,
  AlarmTicket,
  DEFAULT_LANGUAGE,
  DEVICE_ALARM_RULE_NAMES,
  LS_COMPANY_CUSTOMER_ID,
  LS_LOCALE,
  maxNumberOfAPICallRetries
} from '../../constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AlarmsApiUris, getConstantFromLocalStorage, replaceTemplateValues } from '../../utils';
import { updateHeaders } from './utils';
import { SITES_SYMBOL_ID } from '../../siteTree/site';

type GetAlarmSettingsApiArgs = {
  customerId: string;
  location_id: string;
};

interface GetThresholdAlarmSettingsApiArgs extends GetAlarmSettingsApiArgs {
  unit_name: SITES_SYMBOL_ID;
}

type PostAlarmSettingsApiArgs<T> = {
  customerId: string;
  location_id: string;
  body: T;
};

type PostDeviceSettingsApiArgs = PostAlarmSettingsApiArgs<DeviceAlarmSettings>;
export interface PostThresholdSettingsApiArgs extends PostAlarmSettingsApiArgs<Omit<ThresholdAlarmSettings, 'unit'>> {
  unit_name: SITES_SYMBOL_ID;
}

const normalizeArray = (values: string[] | null): string | null => {
  let normalized = null;
  if (values?.length > 0) {
    normalized = values.join(',');
  }
  return normalized;
};

export enum Tags {
  THRESHOLD_SETTINGS = 'THRESHOLD_SETTINGS',
  DEVICE_SETTINGS = 'DEVICE_SETTINGS',
  CURRENT_ALARMS = 'CURRENT_ALARMS',
  HISTORICAL_ALARMS = 'HISTORICAL_ALARMS',
  SINGLE_ALARM = 'SINGLE_ALARM'
}

export const alarmsApi = createApi({
  reducerPath: 'alarmsApi',
  tagTypes: [
    Tags.THRESHOLD_SETTINGS,
    Tags.DEVICE_SETTINGS,
    Tags.CURRENT_ALARMS,
    Tags.HISTORICAL_ALARMS,
    Tags.SINGLE_ALARM
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: API_ALARMS_BASE_URI,
    prepareHeaders: async headers => {
      headers = await updateHeaders(headers);
      return headers;
    }
  }),
  endpoints: builder => ({
    getCurrentAlarms: builder.query<IAlarmResponse, ICurrentAlarmsArgs>({
      query: ({ customer_id, node_id, last_key = null }) => {
        return {
          url: replaceTemplateValues(AlarmsApiUris.currentAlarms, { customer_id }),
          params: { node_id, last_key }
        };
      },
      providesTags: (_result, error, _args) => {
        if (!error) {
          return [Tags.CURRENT_ALARMS];
        }
        return [];
      }
    }),
    getHistoricalAlarms: builder.query<IAlarmResponse, IHistoricalAlarmsArgs>({
      keepUnusedDataFor: 60, // Alarms could update so keep the cache for a shorter length
      query: ({ customer_id, node_id, from, to, last_key = null, priority, status, type }) => {
        const normalizedStatus = normalizeArray(status);
        const normalizedPriority = normalizeArray(priority);
        const normalizedType = normalizeArray(type);
        return {
          url: replaceTemplateValues(AlarmsApiUris.historicalAlarms, { customer_id }),
          params: {
            node_id,
            from,
            to,
            last_key,
            priority: normalizedPriority,
            status: normalizedStatus,
            type: normalizedType
          }
        };
      },
      providesTags: (_result, error, args) => {
        if (!error) {
          return [
            {
              type: Tags.HISTORICAL_ALARMS,
              id: `${args.customer_id}__${args.node_id}__${args.from}__${args.to}__${args.status}__${args.priority}__${args.type}`
            }
          ];
        }
        return [];
      }
    }),
    getSingleAlarm: builder.query<ISingleAlarmResponse, ISingleAlarmsArgs>({
      query: ({ customer_id, ticket_id }) => {
        const locale = getConstantFromLocalStorage(LS_LOCALE, DEFAULT_LANGUAGE);
        return {
          url: replaceTemplateValues(AlarmsApiUris.singleAlarm, { customer_id }),
          params: { ticket_id, locale }
        };
      },
      extraOptions: { maxRetries: maxNumberOfAPICallRetries },
      providesTags: [Tags.SINGLE_ALARM]
    }),
    putAcknowledgeAlarm: builder.mutation<any, IAcknowledgeAlarmArgs>({
      query({ ticket_id, ...body }) {
        const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);

        return {
          url: `${AlarmsApiUris.acknowledgeAlarms
            .replace('{customer_id}', customerId)
            .replace('{ticket_id}', ticket_id)}`,
          method: 'PUT',
          body: body
        };
      },
      invalidatesTags: [Tags.SINGLE_ALARM, Tags.CURRENT_ALARMS]
    }),
    getThresholdAlarmSetting: builder.query<ThresholdAlarmSettings, GetThresholdAlarmSettingsApiArgs>({
      query: ({ customerId, location_id, unit_name }) => {
        return {
          url: `${AlarmsApiUris.thresholdSettingsFacade.replace('{customer_id}', customerId)}`,
          params: {
            node_id: location_id,
            unit: unit_name
          }
        };
      },
      extraOptions: { maxRetries: maxNumberOfAPICallRetries },
      providesTags: [Tags.THRESHOLD_SETTINGS]
    }),
    postThresholdAlarmSettings: builder.mutation<ThresholdAlarmSettings, PostThresholdSettingsApiArgs>({
      query({ location_id, customerId, unit_name, body }) {
        return {
          url: `${AlarmsApiUris.thresholdSettingsFacade.replace('{customer_id}', customerId)}`,
          params: { node_id: location_id, unit: unit_name },
          body,
          method: 'POST'
        };
      },
      invalidatesTags: (_result, error, _args) => {
        if (!error) {
          return [Tags.THRESHOLD_SETTINGS];
        }
        return [];
      }
    }),
    getDeviceAlarmSettings: builder.query<DeviceAlarmSettings, GetAlarmSettingsApiArgs>({
      query: ({ customerId, location_id }) => {
        return {
          url: `${AlarmsApiUris.deviceSettingsFacade.replace('{customer_id}', customerId)}`,
          params: {
            node_id: location_id
          }
        };
      },
      extraOptions: { maxRetries: maxNumberOfAPICallRetries },
      providesTags: [Tags.DEVICE_SETTINGS]
    }),
    postDeviceAlarmSettings: builder.mutation<DeviceAlarmSettings, PostDeviceSettingsApiArgs>({
      query({ location_id, customerId, body }) {
        return {
          url: `${AlarmsApiUris.deviceSettingsFacade.replace('{customer_id}', customerId)}`,
          params: { node_id: location_id },
          body,
          method: 'POST'
        };
      },
      invalidatesTags: (_result, error, _args) => {
        if (!error) {
          return [Tags.DEVICE_SETTINGS];
        }
        return [];
      }
    })
  })
});

export const {
  useGetCurrentAlarmsQuery,
  useGetSingleAlarmQuery,
  usePutAcknowledgeAlarmMutation,
  useLazyGetCurrentAlarmsQuery,
  useGetThresholdAlarmSettingQuery,
  usePostThresholdAlarmSettingsMutation,
  useGetDeviceAlarmSettingsQuery,
  usePostDeviceAlarmSettingsMutation
} = alarmsApi;

export interface ThresholdAlarmTemplateRule {
  ack_required: boolean;
  category: string;
  color: string;
  delay_sec: number | null;
  enabled: boolean;
  priority: ALARM_PRIORITY;
  value: number | null;
}

export type ThresholdAlarmTemplateRules = Record<ALARM_THRESHOLD_TYPE, ThresholdAlarmTemplateRule>;
export type ThresholdAlarmNotificationRules = Record<ALARM_THRESHOLD_TYPE, AlarmNotificationRule>;

export interface ThresholdAlarmSettings {
  template: {
    name: string;
    site_id: string;
    description: string;
    template_id: string;
    rules: ThresholdAlarmTemplateRules;
    meas_id: number;
    version: number;
  };
  settings: {
    active: true;
    template_id: string;
    version: number;
    enabled: true;
    notifications: ThresholdAlarmNotificationRules;
    paused: boolean;
    node_id: string;
  };
  unit: {
    id: number;
    min: number;
    max: number;
    type: string;
  };
}
export type DeviceAlarmTemplateRules = Record<DEVICE_ALARM_RULE_NAMES, DeviceAlarmTemplateRule>;
export type DeviceAlarmTNotificationRules = Record<DEVICE_ALARM_RULE_NAMES, AlarmNotificationRule>;
export type AlarmType = DEVICE_ALARM_RULE_NAMES | ALARM_THRESHOLD_TYPE;

export interface ICurrentAlarmsArgs {
  customer_id: string;
  node_id: string;
  last_key?: string | null | undefined;
}

export interface IAcknowledgeAlarmArgs {
  ticket_id: string;
  message: string;
  ack: boolean;
}

export interface ISingleAlarmsArgs {
  customer_id: string;
  ticket_id: string;
}
export interface IHistoricalAlarmsArgs {
  customer_id: string;
  node_id: string;
  last_key?: string | null | undefined;
  from: number;
  to: number;
  priority?: ALARM_PRIORITY[];
  type?: AlarmType[];
  status?: ALARM_UX_STATUS[]; // TODO: Implement this when doing the dropdowns in the UI for priority, status, and type.
}

export interface IAlarmResponse {
  last_key: string | null;
  tickets: AlarmTicket[];
}

export interface ISingleAlarmResponse {
  ticket: AlarmTicket;
  messages: AlarmMessage[];
}

export interface DeviceAlarmTemplateRule {
  ack_required: boolean;
  category: string;
  color: string;
  delay_sec: number | null;
  enabled: boolean;
  priority: ALARM_PRIORITY;
}
export interface AlarmNotificationRule {
  VOICE: string[];
  SMS: string[];
  EMAIL: string[];
}

export interface DeviceAlarmSettings {
  template: {
    name: string;
    site_id: string;
    start: number;
    description: string;
    template_id: string;
    end: number;
    rules: DeviceAlarmTemplateRules;
    version: number;
  };
  settings: {
    start: number;
    active: true;
    template_id: string;
    end: number;
    version: number;
    enabled: true;
    notifications: DeviceAlarmTNotificationRules;
    node_id: string;
  };
}
