import React, { useState, useEffect } from 'react';
import { Flex, FlexItem, NotificationType, Spinner } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LocationNode } from '../../../../siteTree/site';
import { addNotification } from '../../../../store/notifications';
import { DeviceAlarmSettings } from '../../../../store/services/alarmsApi';
import { QA_TEST_IDS } from '../../../../tests/qaTestIds';
import { TEST_IDS } from '../../../../tests/testids';
import { AlarmSettingsAccordion } from '../../Alarms/shared/AlarmSettingsAccordion';
import { DeviceAlarmSettingsDisplay } from './DeviceAlarmSettingsDisplay';
import i18n from '../../../../i18n';
import { DeviceAlarmDialogContent } from './DeviceAlarmSettingsDialog';
import { Group } from '../../../../store/services/userApi';
import { siteApi, Tags as siteApiTags } from '../../../../store/services/siteApi';
import { isAlarmState } from '../../../../utils';

export interface ContentProps {
  locationDetails: { location: LocationNode | undefined; isLoading: boolean };
  onSave: (settings: DeviceAlarmSettings) => void;
  settingsDetails: { settings: DeviceAlarmSettings; isFetching: boolean; refetch: () => void };
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
  canManageAlarms: boolean;
  savingDetails: { isSaving: boolean; isSaved: boolean; isSaveFailed: boolean };
}

export const DeviceAccordionContent: React.FC<ContentProps> = ({
  settingsDetails,
  locationDetails,
  canManageAlarms,
  notificationGroupsDetails,
  savingDetails,
  onSave
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setisModalOpen] = useState(false);
  const alarmSetState = locationDetails.location?.device_alarm_set;
  useEffect(() => {
    if (savingDetails.isSaved) {
      setisModalOpen(false);
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: t('site.alarms.device.toaster.success', { locationName: locationDetails.location?.name })
        })
      );
      dispatch(siteApi.util.invalidateTags([siteApiTags.Nodes]));
    }
    if (savingDetails.isSaveFailed) {
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: t('site.alarms.device.toaster.fail', { locationName: locationDetails.location?.name })
        })
      );
    }
  }, [savingDetails.isSaved, savingDetails.isSaveFailed]);

  const showAlarmDisplay = isAlarmState.set(alarmSetState) || isAlarmState.default(alarmSetState);
  const showDeviceAlarmNotSet = isAlarmState.notSet(alarmSetState) && !locationDetails.isLoading;

  return (
    <AlarmSettingsAccordion
      modalTitle={'alarm.settings.modal.device.header'}
      editButtonId={QA_TEST_IDS.alarms_settings_device_accordion_edit_button}
      isModalOpen={isModalOpen}
      closeModal={() => setisModalOpen(false)}
      openModal={() => setisModalOpen(true)}
      htmlId={QA_TEST_IDS.site_settings_device_alarms_accordion_item}
      dataTa={TEST_IDS.site_settings_device_alarms_accordion_item}
      title={
        <DeviceAccordionTitle
          isActive={locationDetails.location?.active}
          alarmSetState={locationDetails.location?.device_alarm_set}
          isLoading={locationDetails.isLoading}
        />
      }
      fallbackDetails={{
        accessRestricted: !canManageAlarms,
        isLoading: settingsDetails.isFetching || locationDetails.isLoading,
        noData: !settingsDetails.settings,
        retry: settingsDetails.refetch
      }}
      editContent={
        <DeviceAlarmDialogContent
          settingsDetails={settingsDetails}
          onSave={onSave}
          location={locationDetails.location}
          isSaving={savingDetails.isSaving}
          notificationGroupsDetails={notificationGroupsDetails}
        />
      }
    >
      {showDeviceAlarmNotSet && <div className="grey-medium">{t('site.deviceAlarmNotSetAndWillNotAlarm')}</div>}
      {showAlarmDisplay && (
        <DeviceAlarmSettingsDisplay
          notificationGroupsDetails={notificationGroupsDetails}
          settingsDetails={settingsDetails}
        />
      )}
    </AlarmSettingsAccordion>
  );
};

interface DeviceAccordionTitleProps {
  alarmSetState: LocationNode['device_alarm_set'];
  isActive: boolean;
  isLoading: boolean;
}

const DeviceAccordionTitle: React.FC<DeviceAccordionTitleProps> = ({ alarmSetState, isActive, isLoading }) => {
  const { t } = useTranslation();

  const getAlarmSetStatus = () => {
    switch (true) {
      case isLoading || isAlarmState.none(alarmSetState):
        return <Spinner className="spinner-small" />;
      case isAlarmState.default(alarmSetState):
        return (
          <div
            id={QA_TEST_IDS.site_settings_threshold_alarms_not_set}
            data-ta={TEST_IDS.site_settings_threshold_alarms_not_set}
            className="vai-margin-top-s"
          >
            {t('site.settingsDeviceAlarmsSystemDefault')}
          </div>
        );
      case isAlarmState.notSet(alarmSetState):
        return <div className="vai-margin-top-s alert-red">{t('site.statusDeviceNoSet')}</div>;
      case isAlarmState.set(alarmSetState):
        return <div className="vai-margin-top-s">{t('site.settingsDeviceAlarmsSystemConfigured')}</div>;
      default:
        return null;
    }
  };

  return (
    <Flex
      data-ta={TEST_IDS.alarm_settings_device_accordion_title}
      className="alarm-accordion-subtitle"
      alignItems="center"
      justifyContent="space-between"
    >
      <FlexItem alignSelf="flex-start" flexBasis="200px" className="alarms-accordion-title">
        <div id={QA_TEST_IDS.site_settings_threshold_alarms_accordion_title}>{t('site.deviceAlarmSettings')}</div>
        {getAlarmSetStatus()}
      </FlexItem>
      {!isActive && (
        <FlexItem flexGrow={1} className="vai-margin-left-s black strong">
          {i18n.t('general.deactivated')}
        </FlexItem>
      )}
      <div>{/* placeholder for infotip */}</div>
    </Flex>
  );
};
