import {
  API_ALARMS_BASE_URI,
  API_SITE_MANAGEMENT_BASE_URI,
  API_REPORTS_BASE_URI,
  ALARM_THRESHOLD_TYPE
} from '../../constants';

import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import { SITES_SYMBOL_ID, SiteInterface } from '../../siteTree/site';
import { ReportsApiUris, SitesApiUris, AlarmsApiUris } from '../../utils';
import { INCLUDED_REPORT_SECTIONS, Measurement_Points, Threshold_Setting } from '../../components/Reports/Locations';
import { updateHeaders } from './utils';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

type IncludedSections = {
  [key in INCLUDED_REPORT_SECTIONS]: boolean;
};

type GenerateReportBody = {
  startDateTime: number;
  endDateTime: number;
  nodes: string[];
  format: string;
  tz: string;
  reportTitle: string;
  emailNotification: boolean;
  dataTableInterval?: number;
} & IncludedSections;

type LocationStatistics = {
  average: number;
  min: number;
  max: number;
  recent: number;
  recentTime: number;
};

type ThresholdRules = {
  [key in ALARM_THRESHOLD_TYPE]: Threshold_Setting;
};

export type LocationItem = {
  threshold_template_id: string;
  meas_id?: number; // TODO: figure out if this was removed from BE
  symbol_id?: SITES_SYMBOL_ID;
  measurements: Measurement_Points[];
  threshold_rules?: ThresholdRules;
} & LocationStatistics;

export type GetMeasurementsResponse = {
  items: (LocationItem & LocationStatistics)[];
} & LocationStatistics;

export type GetMeasurementsArgs = {
  customerId: string;
  params: { from: number; to: number; location_id: string; symbol_id: string };
};

export type GetMeasurementLazyQuery = LazyQueryTrigger<
  QueryDefinition<
    GetMeasurementsArgs,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    'SiteNodes' | 'Measurements',
    GetMeasurementsResponse,
    'reportsApi'
  >
>;

export type DownloadJsonResponseLazyQuery = LazyQueryTrigger<
  QueryDefinition<
    {
      url: string;
    },
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    'SiteNodes' | 'Measurements',
    any,
    'reportsApi'
  >
>;

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({
    // baseUrl: API_REPORTS_MANAGEMENT_BASE_URI,
    prepareHeaders: async (headers, { endpoint }) => {
      if (endpoint !== 'downloadReport' && endpoint !== 'downloadJsonResponse') {
        headers = await updateHeaders(headers);
      }

      return headers;
    }
  }),
  tagTypes: ['SiteNodes', 'Measurements'],
  endpoints: builder => ({
    // TODO: Remove in favor of using siteApi. Why is this duplicated here?
    getSiteNodes: builder.query<SiteInterface[], { customerId: string; username: string; siteId: string }>({
      query: ({ customerId, siteId, username }) => {
        return `${API_SITE_MANAGEMENT_BASE_URI}${SitesApiUris.list
          .replace('{customer_id}', customerId)
          .replace('{user_name}', username)
          .replace('{site_id}', siteId)}`;
      },
      providesTags: () => [{ type: 'SiteNodes', id: 'LIST' }],
      transformResponse: (site: SiteInterface) => [site]
    }),
    getMeasurements: builder.query<GetMeasurementsResponse, GetMeasurementsArgs>({
      query: ({ customerId, params }) => ({
        url: `${API_ALARMS_BASE_URI}${AlarmsApiUris.measurements.replace('{customer_id}', customerId)}`,
        params
      }),
      providesTags: (_result, _error, args) => [
        {
          type: 'Measurements',
          id: `${args.params.from}-${args.params.to}-${args.params.location_id}-${args.params.symbol_id}`
        }
      ]
    }),
    getDownloadUrl: builder.query<
      { success: boolean; message: string | null },
      { customerId: string; reportInstanceId: string }
    >({
      query: ({ customerId, reportInstanceId }) => {
        return `${API_REPORTS_BASE_URI}${ReportsApiUris.getDownloadUrl
          .replace('{customer_id}', customerId)
          .replace('{report_id}', reportInstanceId)}`;
      }
      // providesTags: () => [{ type: 'SiteNodes', id: 'LIST' }],
      // transformResponse: (site: SiteInterface) => [site]
    }),
    downloadReport: builder.query<any, { url: string }>({
      query: ({ url }) => ({
        url,
        responseHandler: response => response.blob()
      })
    }),
    downloadJsonResponse: builder.query<any, { url: string }>({
      query: ({ url }) => ({
        url
      })
    }),
    generateReport: builder.mutation<
      any,
      {
        customerId: string;
        body: GenerateReportBody;
      }
    >({
      query: ({ customerId, body }) => ({
        url: `${API_REPORTS_BASE_URI}${ReportsApiUris.generateReport.replace('{customer_id}', customerId)}`,
        method: 'POST',
        body
      })
    })
  })
});

export const {
  useGetSiteNodesQuery,
  useGetMeasurementsQuery,
  useLazyGetMeasurementsQuery,
  useLazyGetDownloadUrlQuery,
  useLazyDownloadReportQuery,
  useLazyDownloadJsonResponseQuery,
  useGenerateReportMutation
} = reportsApi;
