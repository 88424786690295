import { NodeModel } from '@vaisala/community-tree-menu';
import {
  ALARM_PRIORITY,
  CO2_ID,
  HUMIDITY_ID,
  LOCATION_KEY,
  LOCATION_STATUS,
  SITE_KEY,
  SITE_ZONES_ACCESS_KEYS,
  TEMP_ID,
  ZONE_KEY
} from '../constants';
import { SiteRights } from '../profile/group';
import { DataSourceListItemModel, DeviceListItemModel } from '../types';

export interface SiteInterface {
  active?: boolean;
  children: SiteChildrenInterface[];
  parent_id: string;
  name: string;
  position: number;
  type: SITE_ZONES_ACCESS_KEYS;
  node_id: string;
  created?: number;
  changed?: number;
  description?: string;
}

export type TreeMenuSiteInterface = SiteInterface & NodeModel & { status: LOCATION_STATUS };

export type MeasurementType = typeof CO2_ID | typeof TEMP_ID | typeof HUMIDITY_ID;
export type LocationType = typeof SITE_KEY | typeof ZONE_KEY | typeof LOCATION_KEY;

export enum AlarmLevel {
  HI = 'HI',
  HIHI = 'HIHI',
  LO = 'LO',
  LOLO = 'LOLO'
}

export enum AlarmStatus {
  OK = 'OK',
  LOW_PRIORITY = 'LOW',
  MED_PRIORITY = 'MEDIUM',
  HIGH_PRIORITY = 'HIGH'
}

export enum AlarmSetState {
  NOT_SET = 'NOT_SET',
  DEFAULT = 'DEFAULT',
  NONE = 'NONE',
  SET = 'SET',
  // TODO: It would appear that this is going to be removed from the BE, we need to refactor the places this is used
  PAUSED = 'PAUSED'
}

export enum DeviceAlarm {
  COMMUNICATION = 'COMMUNICATION',
  CONFIGURATION = 'CONFIGURATION',
  CALIBRATION = 'CALIBRATION',
  ERROR = 'ERROR',
  BATTERY = 'BATTERY'
}

// TODO: I would like to use the values defined for reports, but there is a typo in celsius and
//  I don't want them to be coupled too tightly
export enum SITES_SYMBOL_ID {
  CELSIUS = 'celsius',
  FAHRENHEIT = 'fahrenheit',
  HUMIDITY = 'rh',
  CO2_PERCENT = 'percent',
  CO2_PPM = 'ppm'
}

export interface SiteChildrenInterface {
  children: SiteChildrenInterface[];
  parent_id: string;
  name: string;
  position: number;
  type: LocationType | SITE_ZONES_ACCESS_KEYS;
  is_stale?: boolean;
  meas_id?: MeasurementType;
  symbol_id?: SITES_SYMBOL_ID; // TODO: Merge DEVICES_SYMBOL_ID with this at some point
  thres_alarm_set?: AlarmSetState;
  device_alarm_set?: AlarmSetState;
  threshold_alarms?: LocationAlarms;
  device_alarms?: LocationAlarms;
  device?: DataSourceListItemModel | null | undefined;
  node_id: string;
  status?: LOCATION_STATUS;
  active?: boolean;
  raw_value?: number | null;
  timestamp?: number | null;
  decimal_places?: number;
  created?: number;
  changed?: number;
  description?: string;
  rights?: SiteRights[];
  parentName?: string;
  alarms_count?: number;
}
export interface CreateTreeNodeInterface extends BaseLocationTreeNodeInterface {
  parent_id: string;
  position: number;
  type: LocationType;
}
export type EditTreeNodeInterface = BaseLocationTreeNodeInterface;

export interface BaseLocationTreeNodeInterface extends BaseNodeInterface {
  meas_id?: string;
  symbol_id?: string;
  decimal_places?: number;
}
export interface LinkTreeNodeInterface {
  device_model: string;
  device_sn: string;
  probe_model: string;
  probe_sn: string;
  meas_group: number;
  meas_id: number;
}
export interface BaseNodeInterface {
  name: string;
  description?: string;
}

export type ZoneNodeInterface = BaseNodeInterface;
export type SiteNodeInterface = BaseNodeInterface;
export interface DeleteTreeNodeInterface {
  node_id: string;
  parent_id: string;
  name: string;
  type: LocationType;
}
export type LocationAlarms = {
  count: number;
  highest_priority: ALARM_PRIORITY | null;
};
export interface LocationNode extends Omit<SiteChildrenInterface, 'children' | 'rights'> {
  active: boolean;
  timestamp?: number;
  device_alarm_set?: AlarmSetState;
  thres_alarm_set?: AlarmSetState;
  threshold_alarms?: LocationAlarms;
  device_alarms?: LocationAlarms;
  alarms_count?: number;
}
