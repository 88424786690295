import React, { useEffect, useRef, useState } from 'react';

interface Props {
  limits: {
    width: {
      max?: number;
      min: number;
    };
  };
}

export const ResizableBox: React.FC<Props> = props => {
  const [width, setWidth] = useState(props.limits.width.min);

  useEffect(() => {
    return () => onMouseUp();
  }, []);
  function onMouseDown() {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
  }
  // Function to be called when the mouse button is released
  function onMouseUp() {
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('mousemove', onMouseMove);
  }

  function onMouseMove(event) {
    event.preventDefault();
    setWidth(oldWidth => {
      const newWidth = oldWidth + event.movementX;
      if (newWidth < props.limits.width.max && newWidth > props.limits.width.min) {
        return newWidth;
      } else {
        return oldWidth;
      }
    });
  }

  return (
    <div style={{ position: 'relative', width: `${width}px` }}>
      {props.children}
      <div onMouseDown={onMouseDown} className={`custom-handle`}>
        <span />
      </div>
    </div>
  );
};
