import React, { useContext, useEffect, useState } from 'react';
import { LocationNode, SITES_SYMBOL_ID } from '../../../../siteTree/site';
import { ThresholdAlarmTemplateRules, ThresholdAlarmSettings } from '../../../../store/services/alarmsApi';
import {
  DEFAULT_RULES,
  DEFAULT_NOTIFICATION_SETTINGS,
  Errors,
  getDefaultErrorsState,
  DEFAULT_WARNING_STATE,
  WarningStateType
} from './shared';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GetGroupsResponse, Group } from '../../../../store/services/userApi';
import { collectSetOfErrors } from './validations';
import { checkCanAcknowledgeAlarmsCurried } from '../../Alarms/shared/GroupsSelect';

interface ThresholdModalContext {
  rules: ThresholdAlarmTemplateRules;
  notificationSettings: ThresholdAlarmSettings['settings']['notifications'];
  errors: Errors;
  warnings: WarningStateType;
  settingsHasChanged: boolean;
  measSymbolId: SITES_SYMBOL_ID;
  unitDetails: ThresholdAlarmSettings['unit'];
  notificationGroups?: GetGroupsResponse['groups'];
  isFetchingGroups: boolean;
  isSaving: boolean;
  updateSettings: (handler: (settings: ThresholdAlarmSettings) => ThresholdAlarmSettings) => void;
  updateWarnings: (handler: (warnings: WarningStateType) => WarningStateType) => void;
  handleSave: () => void;
  saveErrors: (validator: (errors: Errors) => Errors) => void;
  checkCanAcknowledgeAlarms: (group: Group) => boolean;
}

const DEFAULT_ERROR_STATE = getDefaultErrorsState(
  () => {
    return;
  },
  SITES_SYMBOL_ID.CELSIUS,
  { id: 0, max: 100, min: 0, type: '' }
);

const Context = React.createContext<ThresholdModalContext>({
  rules: DEFAULT_RULES,
  measSymbolId: SITES_SYMBOL_ID.CELSIUS,
  errors: DEFAULT_ERROR_STATE,
  warnings: DEFAULT_WARNING_STATE,
  unitDetails: { id: 0, max: 0, min: 0, type: '' },
  settingsHasChanged: false,
  isFetchingGroups: false,
  isSaving: false,
  notificationSettings: DEFAULT_NOTIFICATION_SETTINGS,
  updateSettings: () => {
    return;
  },
  updateWarnings: () => {
    return;
  },
  handleSave: () => {
    return;
  },
  saveErrors: () => DEFAULT_ERROR_STATE,
  checkCanAcknowledgeAlarms: () => false
});

interface Props {
  initialSettings: ThresholdAlarmSettings;
  location: LocationNode;
  onSave: (settings: ThresholdAlarmSettings) => void;
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
  isSaving: boolean;
}

export const ThresholdModalProvider: React.FC<Props> = ({
  children,
  initialSettings,
  location,
  onSave,
  notificationGroupsDetails,
  isSaving
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState(getDefaultErrorsState(t, location.symbol_id, initialSettings.unit));
  const [warnings, setWarnings] = useState<ThresholdModalContext['warnings']>(DEFAULT_WARNING_STATE);
  const [settings, setSettings] = useState<ThresholdAlarmSettings>(initialSettings);
  const [settingsHasChanged, setSettingsHasChanged] = useState(false);

  const checkCanAcknowledgeAlarms: ThresholdModalContext['checkCanAcknowledgeAlarms'] = checkCanAcknowledgeAlarmsCurried(
    location.parent_id
  );

  useEffect(() => {
    setSettingsHasChanged(!isEqual(initialSettings, settings));
  }, [settings]);

  useEffect(() => {
    setSettings(initialSettings);
  }, [initialSettings]);

  const updateSettings: ThresholdModalContext['updateSettings'] = handleUpdate => {
    setSettings(handleUpdate);
  };
  const updateWarnings: ThresholdModalContext['updateWarnings'] = handleUpdate => {
    setWarnings(handleUpdate);
  };
  const handleSave = () => {
    onSave(settings);
  };

  const saveErrors: ThresholdModalContext['saveErrors'] = validator => {
    setErrors(errors => {
      const updatedErrors = validator(errors);
      updatedErrors.collectedErrors = collectSetOfErrors(updatedErrors);
      return updatedErrors;
    });
  };

  return (
    <Context.Provider
      value={{
        notificationGroups: notificationGroupsDetails.groups,
        notificationSettings: settings.settings.notifications,
        isFetchingGroups: notificationGroupsDetails.isFetching,
        isSaving,
        settingsHasChanged,
        rules: settings.template.rules,
        unitDetails: settings.unit,
        measSymbolId: location.symbol_id,
        errors,
        warnings,
        checkCanAcknowledgeAlarms,
        saveErrors,
        updateSettings,
        updateWarnings,
        handleSave
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useThresholdModalContext = () => useContext(Context);
