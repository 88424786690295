import * as React from 'react';
import { connect } from 'react-redux';
import { reportsDispatchActions, StoreState } from '../../../store';
import uPlot, { AlignedData } from 'uplot';
import { format, fromUnixTime, getUnixTime } from 'date-fns';
import {
  CHART_AXES_LABELS,
  DEVICES_SYMBOL_ID,
  MAX_LOCATIONS_SELECTED,
  CHART_TOOLTIP_GROUPED_TYPE_TEXT,
  CHART_TOOLTIP_UNITS,
  THRESHOLD_TYPE,
  THRESHOLD_COLOR,
  ALARM_PRIORITY,
  ALARM_THRESHOLD_TYPE,
  unselectedChartLineAlpha
} from '../../../constants';
import 'uplot/dist/uPlot.min.css';
import './chart.scss';

import UPlotReact from 'uplot-react';
import { Flex } from '@vaisala/rockhopper-components';
import { VaiColor } from '@vaisala/rockhopper-design-tokens';
import AlertAlarmIcon from '../../../assets/images/AlertAlarm.svg';
import AlertWarningIcon from '../../../assets/images/AlertWarning.svg';
import HighhighLightIcon from '../../../assets/images/HighHighLight.svg';
import LowlowLightIcon from '../../../assets/images/LowLowLight.svg';
import HighDarkIcon from '../../../assets/images/HighDark.svg';
import LowDarkIcon from '../../../assets/images/LowDark.svg';
import { CustomProps, Threshold_Values } from '../Locations';
import { getAssignedChartColor, getVisibleLocationsNumber, roundHalfToEven } from '../../../utils';
import { useTranslation } from 'react-i18next';
import ChartIntervalArrows from '../ChartIntervalArrows/ChartIntervalArrows';

type CachedSeries = typeof uPlot.Series & { _paths: { stroke: Path2D } };

type ChartProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    id: string;
    data: uPlot.AlignedData | [];
    locations: CustomProps[];
    allLocations: CustomProps[];
    units: string[];
    syncKey?: uPlot.SyncPubSub;
    hideTimeNav?: boolean;
  };

type TooltipPluginProps = {
  onclick: (u: uPlot, seriesIdx: number | null, dataIdx: number | string, threshold_values: Threshold_Values) => void;
  shiftX?: number;
  shiftY?: number;
};

type TooltipData = { value: number | null; name: string; unit: string; color: VaiColor };

const Chart: React.FunctionComponent<ChartProps> = ({
  reports,
  id,
  syncKey,
  locations,
  allLocations,
  data,
  units,
  setReportsSelectedLocationsCustomProps,
  hideTimeNav
}) => {
  const { t } = useTranslation();

  const [stepInterval, setStepInterval] = React.useState({
    quarterStep: 21600,
    fullStep: 86400
  });

  // let isThresholdShown = false;
  let locationClicked = null;

  function loadIcon(src): Promise<CanvasImageSource> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;

      img.onload = () => {
        resolve(img);
      };

      img.onerror = reject;
    });
  }

  const getThresholdIcon = (thresholdType: string) => {
    switch (thresholdType) {
      case ALARM_THRESHOLD_TYPE.HIHI:
        return HighhighLightIcon;
      case ALARM_THRESHOLD_TYPE.HI:
        return HighDarkIcon;
      case ALARM_THRESHOLD_TYPE.LOLO:
        return LowlowLightIcon;
      case ALARM_THRESHOLD_TYPE.LO:
        return LowDarkIcon;
      default:
        return null;
    }
  };

  async function drawThreshold(u: uPlot, si: number) {
    const ctx = u.ctx;
    const measurement_points = locations[si - 1].measurement_points;

    const isLabelDrawn = {
      [ALARM_THRESHOLD_TYPE.HIHI]: false,
      [ALARM_THRESHOLD_TYPE.HI]: false,
      [ALARM_THRESHOLD_TYPE.LOLO]: false,
      [ALARM_THRESHOLD_TYPE.LO]: false
    };

    const labels = {};

    async function drawLabel(label) {
      ctx.beginPath();
      ctx.setLineDash([]);
      ctx.strokeStyle = label.lineColorProps.text;
      ctx.fillStyle = label.lineColorProps.background;
      const text = `${label.value} ${CHART_AXES_LABELS[label.scale]}`;
      const textWidth = ctx.measureText(text).width;
      const iconWidth = 25;
      const totalWidth = iconWidth + textWidth + 50;
      const rectWidth = totalWidth > 120 ? totalWidth : 120;
      const rectHeight = 30;
      let rectX = label.x0 + 20;
      const rectY = label.y0 - rectHeight / 2;
      // Calculate the right edge of the label
      const rectRight = rectX + rectWidth;
      // Get the width of the chart
      const chartWidth = u.bbox.width;
      // If the label is too close to the right edge of the chart, shift it to the left
      if (rectRight > chartWidth) {
        rectX = chartWidth - rectWidth - 20;
      }
      const firstIconXPOS = rectX + 5;
      const secondconXPOS = rectX + rectWidth - 35;
      ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = label.lineColorProps.text;
      ctx.font = 'bold 16px Arial';
      ctx.fillText(text, firstIconXPOS + iconWidth + 5, rectY + rectHeight / 2);
      ctx.font = '16px Arial';

      const ThresholdImg: CanvasImageSource = await loadIcon(label.lineColorProps.thresholdIcon);
      const img: CanvasImageSource = await loadIcon(label.lineColorProps.icon);

      ctx.drawImage(img, secondconXPOS, rectY + 2, iconWidth, 25);
      ctx.drawImage(ThresholdImg, firstIconXPOS, rectY + 2, iconWidth, 25);
    }

    for (let i = 0; i < measurement_points.length; i++) {
      const s = u.series[si];
      // const xd = u.data[0];
      // const yd = u.data[si];
      // const [i0, i1] = s.idxs as uPlot.Series.MinMaxIdxs;
      // const object = Object.values(measurement_points[i].threshold_rules)[0];
      const object = measurement_points[i].threshold_rules;

      for (const option in object) {
        if (option.toLowerCase() !== THRESHOLD_TYPE.ROC && object[option].enabled) {
          let lineColorProps: { text: string; background: string; icon: string };
          const x0 = u.valToPos(measurement_points[i].t, 'x', true);
          const y0 = u.valToPos(object[option].value, s.scale as string, true);
          const x1 = i < measurement_points.length - 1 ? u.valToPos(measurement_points[i + 1].t, 'x', true) : null;
          const colorMap: Record<
            THRESHOLD_COLOR,
            { text: VaiColor; background: VaiColor; icon: string; thresholdIcon: string }
          > = {
            [THRESHOLD_COLOR.RED]: { text: VaiColor.White, background: VaiColor.Red, icon: null, thresholdIcon: null },
            [THRESHOLD_COLOR.ORANGE]: {
              text: VaiColor.GreyDark,
              background: VaiColor.OrangeDark,
              icon: null,
              thresholdIcon: null
            },
            [THRESHOLD_COLOR.BLUE]: {
              text: VaiColor.GreyDark,
              background: VaiColor.AquaMedium,
              icon: null,
              thresholdIcon: null
            }
          };
          const priorityMap = {
            [ALARM_PRIORITY.CRITICAL]: {
              text: VaiColor.White,
              background: VaiColor.Red,
              icon: AlertAlarmIcon,
              thresholdIcon: getThresholdIcon(option)
            },
            [ALARM_PRIORITY.MODERATE]: {
              text: VaiColor.GreyDark,
              background: VaiColor.OrangeDark,
              icon: AlertWarningIcon,
              thresholdIcon: getThresholdIcon(option)
            },
            [ALARM_PRIORITY.INFO]: {
              text: VaiColor.GreyDark,
              background: VaiColor.AquaMedium,
              icon: AlertWarningIcon,
              thresholdIcon: getThresholdIcon(option)
            }
          };
          const defaultColorProps = {
            text: VaiColor.GreyDark,
            background: VaiColor.OrangeDark,
            icon: AlertWarningIcon,
            thresholdIcon: null
          };
          lineColorProps =
            object[option].color !== null ? colorMap[object[option].color] : priorityMap[object[option].priority];
          lineColorProps = lineColorProps || defaultColorProps;
          ctx.beginPath();
          ctx.strokeStyle = lineColorProps.background;
          ctx.lineWidth = 2;
          ctx.setLineDash([5, 5]);
          ctx.moveTo(x0, y0);
          ctx.lineTo(x1 || x0, y0);
          ctx.stroke();

          // Draw the rectangle
          if (!isLabelDrawn[option]) {
            labels[option] = { value: object[option].value, scale: s.scale, lineColorProps, x0, y0 };
            isLabelDrawn[option] = true;
          }
        }
      }
    }

    for (const type in labels) {
      await drawLabel(labels[type]);
    }
  }

  function addAxesLabels(u: uPlot) {
    const axesArr = document.querySelectorAll(`#${id} .u-axis`);

    u.axes.forEach((axis, i) => {
      if (axis.scale !== 'x') {
        const axisLabel = document.createElement('div');
        axisLabel.className = 'axis-label';
        axisLabel.textContent = CHART_AXES_LABELS[axis.scale] || '';
        axesArr[i].appendChild(axisLabel);
      }
    });
  }

  const changeThresholdValue = node => {
    const selectedLocationsClone = JSON.parse(JSON.stringify(reports.selectedLocationsCustomProps));

    if (selectedLocationsClone[node.node_id]) {
      selectedLocationsClone[node.node_id].showThreshold = !selectedLocationsClone[node.node_id].showThreshold;

      setReportsSelectedLocationsCustomProps(selectedLocationsClone);
    }
  };

  const tooltipPlugin = ({ shiftX = 10, shiftY = 10, onclick }: TooltipPluginProps): uPlot.Plugin => {
    let tooltipLeftOffset = 0;
    let tooltipTopOffset = 0;

    const tooltip = document.createElement('div');
    tooltip.className = 'u-tooltip';

    const threshold = document.createElement('div');
    threshold.className = 'threshold';

    let seriesIdx: number;
    let dataIdx: number;

    let over: any;

    let tooltipVisible = false;
    let clicked = false;
    let rescaled = false;
    let chartIndex;

    function showTooltip() {
      if (!tooltipVisible) {
        tooltip.style.display = 'block';
        over.style.cursor = 'pointer';
        tooltipVisible = true;
      }
    }

    function hideTooltip() {
      if (tooltipVisible) {
        tooltip.style.display = 'none';
        over.style.cursor = null;
        tooltipVisible = false;
      }
    }

    function generateTooltipSortedData(u: uPlot) {
      const tooltipData = {};

      for (let i = 1; i < u.data.length; i++) {
        if (!tooltipData[u.series[i].scale]) {
          tooltipData[u.series[i].scale] = [];
        }
        tooltipData[u.series[i].scale].push({
          value: u.data[i][dataIdx] ? roundHalfToEven(u.data[i][dataIdx], locations[i - 1].decimal_places) : null,
          name: locations[i - 1].name,
          unit: CHART_TOOLTIP_UNITS[u.series[i].scale],
          color: getAssignedChartColor(locations[i - 1].node_id, reports.assignedChartColors)
        });
      }

      const contentHTML = document.createElement('div');
      contentHTML.classList.add('content-values');

      // Sort scalesData in descending order and add data values
      Object.entries(tooltipData).forEach(([key, value]: [string, TooltipData[]]) => {
        tooltipData[key] = value.sort((a, b) => b.value - a.value);

        const groupHeader = document.createElement('div');
        groupHeader.classList.add('row', 'group-header');

        const groupHeaderSpan = document.createElement('span');
        groupHeaderSpan.textContent = t(CHART_TOOLTIP_GROUPED_TYPE_TEXT[key]);

        groupHeader.appendChild(groupHeaderSpan);
        contentHTML.appendChild(groupHeader);

        value.forEach(data => {
          const row = document.createElement('div');
          row.classList.add('row');

          const nodeName = document.createElement('div');
          nodeName.classList.add('col', 'truncated-text');
          nodeName.textContent = data.name;

          const valueCol = document.createElement('div');
          valueCol.classList.add('col');

          const valueWrapper = document.createElement('div');
          valueWrapper.classList.add('value-wrapper');

          const coloredDot = document.createElement('span');
          coloredDot.classList.add('colored-dot');
          coloredDot.style.backgroundColor = data.color;

          const valueSpan = document.createElement('span');
          valueSpan.textContent = data.value ? `${data.value}` : '-';

          valueWrapper.appendChild(coloredDot);
          valueWrapper.appendChild(valueSpan);

          const unitSpan = document.createElement('span');
          unitSpan.classList.add('data-unit');
          unitSpan.textContent = data.unit;

          valueCol.appendChild(valueWrapper);
          valueCol.appendChild(unitSpan);

          row.appendChild(nodeName);
          row.appendChild(valueCol);

          contentHTML.appendChild(row);
        });
      });

      return contentHTML;
    }

    function generateTooltipContent(u: uPlot) {
      const tooltipWrapper = document.createElement('div');
      tooltipWrapper.classList.add('tooltip-content');

      const timestampPara = document.createElement('p');
      timestampPara.textContent = format(fromUnixTime(u.data[0][dataIdx]), 'HH:mm yyyy-MM-dd');
      timestampPara.classList.add('timestamp');

      const contentHTML = generateTooltipSortedData(u);

      tooltipWrapper.appendChild(timestampPara);
      tooltipWrapper.appendChild(contentHTML);

      return tooltipWrapper;
    }

    function setTooltip(u: uPlot) {
      showTooltip();

      const lft = u.cursor.left;
      let top = u.cursor.top;

      const otherChartIndex = chartIndex ? chartIndex - 1 : chartIndex + 1;

      // Sync two charts top cursor position
      if (syncKey) {
        if (uPlot.sync(syncKey?.key).plots[otherChartIndex].cursor.top !== top) {
          if (top === -10) {
            top = uPlot.sync(syncKey?.key).plots[otherChartIndex].cursor.top;
          } else if (uPlot.sync(syncKey?.key).plots[otherChartIndex].cursor.top === -10) {
            uPlot.sync(syncKey?.key).plots[otherChartIndex].setCursor({ left: lft, top });
          }
        }
      }

      // Calculate tooltip dimensions and container dimensions
      const tooltipHeight = tooltip.clientHeight;
      const viewportHeight = over.clientHeight;
      const scrollTop = over.scrollTop;

      // Calculate the potential top and bottom positions of the tooltip
      const tooltipTopPosition = tooltipTopOffset + top + shiftX;
      const tooltipBottomPosition = tooltipTopPosition + tooltipHeight;
      const buffer = 20;

      // Adjust vertical position to avoid overflow
      if (tooltipBottomPosition + buffer > viewportHeight + scrollTop) {
        tooltip.style.top = Math.max(scrollTop, tooltipTopOffset + top - tooltipHeight - shiftX - buffer) + 'px';
      } else {
        tooltip.style.top = tooltipTopOffset + top + shiftX + 'px';
      }

      if (tooltipLeftOffset + lft + shiftY > over.clientWidth - tooltip.clientWidth) {
        tooltip.style.left = tooltipLeftOffset + lft - buffer + shiftY - tooltip.clientWidth + 'px';
      } else {
        tooltip.style.left = tooltipLeftOffset + lft + shiftY + 'px';
      }

      const tooltipContentContainer = document.querySelector(`#${id} .tooltip-content`);
      if (tooltipContentContainer && tooltip.contains(tooltipContentContainer)) {
        tooltip.removeChild(tooltipContentContainer);
      }

      const tooltipContent = generateTooltipContent(u);

      tooltip.appendChild(tooltipContent);
    }

    return {
      hooks: {
        ready: [
          u => {
            over = u.over;
            tooltipLeftOffset = parseFloat(over.style.left);
            tooltipTopOffset = parseFloat(over.style.top);
            u.root.querySelector(`#${id} .u-wrap`)?.appendChild(tooltip);

            if (units.length > 0) {
              addAxesLabels(u);
            }
            if (locations[seriesIdx - 1] && locations[seriesIdx - 1].threshold_values) {
              // const values = locations[seriesIdx - 1].threshold_values.map(obj => obj.value);
              const maxValue = locations[seriesIdx - 1].threshold_values.max;
              const minValue = locations[seriesIdx - 1].threshold_values.min;

              setTimeout(() => {
                if (
                  (minValue !== null && u.scales[u.series[seriesIdx].scale].min > minValue) ||
                  (maxValue !== null && u.scales[u.series[seriesIdx].scale].max < maxValue)
                ) {
                  u.setScale(u.series[seriesIdx].scale, {
                    min:
                      u.scales[u.series[seriesIdx].scale].min < minValue
                        ? u.scales[u.series[seriesIdx].scale].min
                        : minValue - 5,
                    max:
                      u.scales[u.series[seriesIdx].scale].max < maxValue
                        ? maxValue + 5
                        : u.scales[u.series[seriesIdx].scale].max
                  });

                  rescaled = true;
                } else {
                  onclick(u, seriesIdx, dataIdx, locations[seriesIdx - 1].threshold_values);
                }
              }, 0);
            }

            over.addEventListener('mouseup', () => {
              const locationIndInAllCharts = allLocations.findIndex(loc => loc.showThreshold);
              if (locationIndInAllCharts === -1) {
                hideTooltip();
                clicked = true;
                // rescaled = false;

                const clickedLocation = locations[seriesIdx - 1];

                clickedLocation && changeThresholdValue(clickedLocation);
              } else {
                changeThresholdValue(allLocations[locationIndInAllCharts]);
              }

              locationClicked = null;
            });
          }
        ],
        init: [
          (u: uPlot) => {
            const locationInd = locations.findIndex(loc => loc.showThreshold);
            chartIndex = +id.split('-')[1];
            const otherChartIndex = chartIndex ? chartIndex - 1 : chartIndex + 1;

            if (locationInd >= 0) {
              seriesIdx = locationInd + 1;

              locationClicked = locations[locationInd];
              rescaled = false;

              setTimeout(() => {
                // Unfocus all series lines in graph
                u.setSeries(null, { focus: false });

                // Unfocus all series lines in the other graph
                if (syncKey) {
                  uPlot.sync(syncKey?.key).plots[otherChartIndex].setSeries(1, { focus: false });
                  uPlot.sync(syncKey.key).plots[otherChartIndex].series.map((s, i) => {
                    if (i > 0) {
                      uPlot.sync(syncKey?.key).plots[otherChartIndex].series[i].stroke = focusColorSwitch(
                        VaiColor.GreyLight3,
                        VaiColor.GreyLight3
                      );
                    }
                  });
                }

                locations.forEach((_, index) => {
                  if (index === locationInd) {
                    // Focus only the selected line
                    u.setSeries(index + 1, { focus: true });
                  }
                });
              }, 0);
            }

            u.over.addEventListener('mousemove', e => {
              for (let i = 1; i < u.series.length; i++) {
                let res;

                if ((u.series[i] as CachedSeries)._paths) {
                  res = u.ctx.isPointInStroke(
                    (u.series[i] as CachedSeries)._paths.stroke,
                    u.bbox.left + e.offsetX * uPlot.pxRatio,
                    u.bbox.top + e.offsetY * uPlot.pxRatio
                  );
                }

                if (res) {
                  seriesIdx = i;
                }

                if (res || tooltipVisible) {
                  u.over.style.cursor = 'pointer';
                  break;
                } else {
                  u.over.style.cursor = 'auto';
                }
              }
            });
          }
        ],
        setCursor: [
          u => {
            const c = u.cursor;

            if (dataIdx !== c.idx && !locationClicked) {
              dataIdx = c.idx as number;
              if (dataIdx === null) hideTooltip();
              else setTooltip(u);
            }

            u.cursor.show = !locationClicked;
          }
        ],
        setScale: [
          u => {
            if (((seriesIdx && clicked && locations[seriesIdx - 1].threshold_values) || locationClicked) && rescaled) {
              setTimeout(() => {
                onclick(u, seriesIdx, dataIdx, locations[seriesIdx - 1].threshold_values);
                rescaled = false;
              }, 0);
            }

            clicked = false;
          }
        ],
        setSeries: [
          (u, sidx) => {
            if (seriesIdx !== sidx && !locationClicked) {
              seriesIdx = sidx as number;
              if (sidx == null) hideTooltip();
              // else if (dataIdx != null) setTooltip(u);
            }
          }
        ]
      }
    };
  };

  const xAxisOptions = {
    splits(_self: uPlot, _axisIdx: number, _scaleMin: number, _scaleMax: number) {
      const fullStep = getUnixTime(reports.intervalDate.to) - getUnixTime(reports.intervalDate.from);
      const quarterStep = fullStep / 4;
      const startDate = getUnixTime(reports.intervalDate.from);

      setStepInterval({ quarterStep, fullStep });

      return [
        startDate,
        startDate + quarterStep,
        startDate + quarterStep * 2,
        startDate + quarterStep * 3,
        getUnixTime(reports.intervalDate.to)
      ];
    },
    values: [
      // tick incr          default           year                             month    day                        hour     min                sec       mode
      [3600 * 24 * 365, '{YYYY}', null, null, null, null, null, null, 1],
      [3600 * 24 * 28, '{MMM}', '\n{YYYY}', null, null, null, null, null, 1],
      [3600 * 24, '{M}-{D}', null, null, null, null, null, null, 1],
      [3600, '{HH}:{mm}', '\n{YY}-{M}-{D}', null, '\n{M}-{D}', null, null, null, 1],
      [60, '{HH}:{mm}', '\n{YY}-{M}-{D}', null, '\n{M}-{D}', null, null, null, 1],
      [1, '{HH}:{mm}', '\n{YY}-{M}-{D}', null, '\n{M}-{D} {HH}:{mm}', null, null, null, 1],
      [0.001, ':{ss}.{fff}', '\n{YY}-{M}-{D} {HH}:{mm}', null, '\n{M}-{D} {HH}:{mm}', null, '\n{HH}:{mm}', null, 1]
    ]
  };

  const yAxisOptions = {
    splits(self: uPlot, axisIdx: number, scaleMin: number, scaleMax: number) {
      const increment = (scaleMax - scaleMin) / 4;

      let min = scaleMin;
      const array = [];

      while (min < scaleMax) {
        array.push(min);
        min += increment;
      }

      array.push(scaleMax);

      return array;
    }
  };

  const [options, setOptions] = React.useState<uPlot.Options>(
    React.useMemo(() => {
      return {
        width: 700,
        height: 350,
        focus: {
          alpha: 1.1 // setting alpha: 1.1 bypasses the internal (alpha == 1) check that skips redraw() on focus changes
        },
        cursor: {
          show: true,
          focus: {
            prox: 5
          },
          lock: true,
          sync: {
            key: syncKey?.key || '',
            setSeries: true
          },
          y: false, // Show only the cursor dashed indicator for x axis
          drag: {
            x: false // No zooming
          },
          points: {
            size: 7
          },
          dataIdx: (self: uPlot, seriesIdx: number, _closestIdx: number, _xValue: number) => {
            return seriesIdx;
          }
        },
        legend: {
          show: false
        },
        scales: {
          x: {
            range: [getUnixTime(reports.intervalDate.from), getUnixTime(reports.intervalDate.to)]
          },
          y: {
            range: [0, 100]
          }
        },
        axes: [{ ...xAxisOptions }, { ...yAxisOptions }],
        series: [{}, {}]
      };
    }, [])
  );

  const [chartData, setChartData] = React.useState<uPlot.AlignedData>([[], []]);

  // Show line in unfocused color if not focus
  const focusColorSwitch = (focused, unfocused) => (u, idx) => {
    const series = u.series[idx];
    return series._focus === false ? unfocused : focused;
  };

  const getScalesOptions = () => {
    const options: { [key: string]: uPlot.Scale } = {};

    if (units?.length > 0) {
      units.forEach((unit: string) => {
        options[unit] = {
          range(u: uPlot, dataMin: number, _dataMax: number) {
            const maxs = [];
            const mins = [];
            // Optimization. We already calculated minimum and max, so let's just use the values from the location to produce ranges
            locations
              .filter(location => location.symbol_id === unit)
              .forEach(location => {
                maxs.push(location.maximum);
                mins.push(location.minimum);
              });
            if (maxs.length === 0 || dataMin == null) return [0, 100];
            const min = Math.min(...mins);
            const max = Math.max(...maxs);
            return uPlot.rangeNum(min, max, 0.1, true);
          }
        };
      });
    } else {
      // To show the y axis when no data is received
      options.y = {
        range(u: uPlot, dataMin: number, dataMax: number) {
          if (dataMin == null) return [0, 100];

          return uPlot.rangeNum(dataMin, dataMax, 0.1, true);
        }
      };
    }

    return options;
  };

  React.useEffect(() => {
    setOptions(oldOptions => ({
      ...oldOptions,
      width: document.getElementById(id).getBoundingClientRect().width,
      scales: {
        x: {
          range: [getUnixTime(reports.intervalDate.from), getUnixTime(reports.intervalDate.to)]
        },
        ...getScalesOptions()
      },
      axes: [{ ...xAxisOptions }, { ...yAxisOptions }]
    }));
  }, [reports.intervalDate]);

  const bindHandler = (u, targ, handler) => {
    return e => {
      const locationIndInAllCharts = allLocations.findIndex(loc => loc.showThreshold);

      if (locationIndInAllCharts === -1) {
        handler(e);
      }
    };
  };

  const uRef = React.useRef<uPlot | undefined>(undefined);
  const seriesIdxRef = React.useRef<number | undefined>(undefined);

  React.useEffect(() => {
    const newOptions: uPlot.Options = {
      ...options,
      width: document.getElementById(id).getBoundingClientRect().width,
      ...(syncKey
        ? {
            cursor: {
              ...options.cursor,
              sync: {
                key: syncKey?.key,
                setSeries: true
              },
              bind: {
                mousemove: bindHandler,
                mouseleave: bindHandler
              } as uPlot.Cursor.Bind
            }
          }
        : {
            cursor: {
              ...options.cursor,
              bind: {
                mousemove: bindHandler,
                mouseleave: bindHandler
              } as uPlot.Cursor.Bind
            }
          }),
      plugins: [
        tooltipPlugin({
          onclick(u, seriesIdx, _dataIdx, _threshold_values) {
            if (seriesIdx) {
              drawThreshold(u, seriesIdx);
              uRef.current = u;
              seriesIdxRef.current = seriesIdx;
            }
          }
        })
      ],
      scales: {
        x: {
          range: [getUnixTime(reports.intervalDate.from), getUnixTime(reports.intervalDate.to)]
        },
        ...getScalesOptions()
      },
      axes: [
        { ...xAxisOptions },
        ...(units.length === 0
          ? [{ ...yAxisOptions }]
          : units.map((unit: any, i: number) => ({
              ...yAxisOptions,
              side:
                unit === DEVICES_SYMBOL_ID.CELSIUS ||
                unit === DEVICES_SYMBOL_ID.FAHRENHEIT ||
                ((unit === DEVICES_SYMBOL_ID.CO2_PERCENT || unit === DEVICES_SYMBOL_ID.CO2_PPM) &&
                  units.includes(DEVICES_SYMBOL_ID.HUMIDITY)) ||
                (unit === DEVICES_SYMBOL_ID.CO2_PERCENT && units.includes(DEVICES_SYMBOL_ID.CO2_PPM)) ||
                units.length === 1
                  ? 3
                  : 1,
              grid: {
                show: i === 0 ? true : false
              },
              scale: unit
            })))
      ],
      series: [
        {},
        ...(locations.length === 0
          ? [{}]
          : locations.map((location: CustomProps) => {
              return {
                stroke: focusColorSwitch(
                  getAssignedChartColor(location.node_id, reports.assignedChartColors),
                  VaiColor.GreyLight3 + unselectedChartLineAlpha // alpha value for the color (50%) to make it lighter and show highlighted trend line
                ),
                // Minor optimization, don't bother making uPlot get the min/max when we already have it
                max: Number(location.maximum),
                min: Number(location.minimum),
                scale: location.symbol_id,
                width: 2.5,
                spanGaps: false,
                points: {
                  show: false
                }
              };
            }))
      ]
    };
    setChartData(data as AlignedData);
    setOptions(newOptions as uPlot.Options);
  }, [data]);

  React.useEffect(() => {
    let resizeTimeout;
    let drawTimeout;
    function resizeChart() {
      if (document.getElementById(id)?.getBoundingClientRect()) {
        setOptions(options => ({ ...options, width: document.getElementById(id).getBoundingClientRect().width }));
      }
    }

    function draw() {
      if (uRef.current && seriesIdxRef.current) {
        drawThreshold(uRef.current, seriesIdxRef.current);
      }
    }

    window.addEventListener('resize', _e => {
      if (resizeTimeout) clearTimeout(resizeTimeout);
      if (drawTimeout) clearTimeout(drawTimeout);

      resizeTimeout = setTimeout(resizeChart, 100);
      drawTimeout = setTimeout(draw, 200);
    });

    return () => {
      setOptions(null);
      setChartData(null);
      if (resizeTimeout) clearTimeout(resizeTimeout);
      if (drawTimeout) clearTimeout(drawTimeout);
    };
  }, []);

  return (
    <Flex flexDirection="column" className="vai-margin-bottom-xl" id="chart-wrapper">
      {hideTimeNav === true ? null : <ChartIntervalArrows stepInterval={stepInterval} />}

      {/* Show this text only on the first chart */}
      {reports.selectedLocationsNum > MAX_LOCATIONS_SELECTED && id.includes('0') && (
        <Flex justifyContent="center">
          <span className="visible-locations-num">
            {t('reports.showingNumLocations', {
              visible: getVisibleLocationsNumber(reports.visibleLocations),
              total: reports.selectedLocationsNum
            })}
          </span>
        </Flex>
      )}

      <UPlotReact key={id} options={options} data={chartData} />
    </Flex>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  reports
});

const mapDispatchToProps = dispatch => ({
  setReportsSelectedLocationsCustomProps: locations =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsCustomProps(locations))
});

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
