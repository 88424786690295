import React, { useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LS_COMPANY_CUSTOMER_ID, PARENT_SITE_KEY } from '../../../constants';
import useGetUserName from '../../../hooks/useGetUsername';
import { useResizeObserver } from '../../../hooks/useResizeObserver';
import useZoneorSiteDescription from '../../../hooks/useZoneorSiteDescription';
import { SiteInterface } from '../../../siteTree/site';
import { useGetNodeLocationsQuery } from '../../../store/services/siteApi';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { getConstantFromLocalStorage, isLocationNode } from '../../../utils';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import { IllustrationType } from '../../Utils/EmptyState/Illustration';
import { SiteTable } from '../SiteTable';
import { selectSelectedNode } from '../../../store/siteTree';
import { useAppSelector } from '../../../store/hooks';
import { RightNames } from '../../RightsAccordionTree/constants';
import { HasRights, RightCheckScope } from '../../HasRIghts/HasRights';
import AccessRestricted from '../../AccessRestricted/AccessRestricted';
import { Button } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import { SiteTabKeys } from '../../../containers/Site';

interface Props {
  siteId: string;
  site: SiteInterface;
  onError?: (error: any) => void;
  toggleHighlight?: (node_id?: string) => void;
}

export const SettingsList: React.FC<Props> = ({ siteId, onError, toggleHighlight, site }) => {
  const ref = useRef();
  const [, descHeight] = useResizeObserver(ref, 0);
  const selectedNode = useAppSelector(selectSelectedNode);
  const nodeRightId = isLocationNode(selectedNode) ? selectedNode?.parent_id : selectedNode?.node_id;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);
  const { username } = useGetUserName();
  const { id } = useParams();

  const { currentData: nodes, isFetching, error } = useGetNodeLocationsQuery(
    { customerId, username, nodeId: id ? id : siteId },
    { skip: username === undefined || username === 'undefined' || username === null }
  );

  const { description } = useZoneorSiteDescription(site, id);

  React.useEffect(() => {
    if (onError && error) {
      onError(error);
    }
  }, [error]);

  if (!nodes || isFetching) {
    return (
      <>
        <CenteredSpinner />
      </>
    );
  }

  if (nodes.length <= 0 && !id) {
    return (
      <EmptyState
        illustration={IllustrationType.positive}
        heading="site.emptyHeader"
        description="site.emptyBody"
        link={{ to: '/', desc: 'site.emptyTourLink' }}
      />
    );
  }

  if (nodes.length <= 0) {
    return <EmptyState illustration={IllustrationType.emptyFolder} heading="site.emptyZoneHeading" />;
  }
  // TODO: For checking site level rights we need site id in each site childen node so we don't use hardcoded PARENT_SITE_KEY
  const canSeeSettingsNodeRights = [
    { nodeId: nodeRightId, rightName: RightNames.MANAGE_ALARM_TEMPLATES },
    { nodeId: nodeRightId, rightName: RightNames.PAUSE_ALARMS },
    { nodeId: PARENT_SITE_KEY, rightName: RightNames.MANAGE_SITES }
  ];

  const navigateToStatus = () => {
    const statusTabUrl = pathname.replace(SiteTabKeys.settings, SiteTabKeys.status);
    navigate(statusTabUrl);
  };

  return (
    <HasRights
      rightCheckScope={RightCheckScope.Any}
      nodeRights={canSeeSettingsNodeRights}
      fallback={
        <AccessRestricted actionButton={<Button onClick={navigateToStatus}>{t('general.backToStatus')}</Button>} />
      }
    >
      <div className="statuslist__container">
        {description && (
          <div className="statuslist__description" data-ta={QA_TEST_IDS.site_settings_list_description} ref={ref}>
            {description}
          </div>
        )}
        <SiteTable
          descTextHeight={description && descHeight}
          toggleHighlight={toggleHighlight}
          key={id}
          data={nodes}
          type="settings"
        />
      </div>
    </HasRights>
  );
};
