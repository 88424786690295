import React, { useEffect, useState } from 'react';
import { Flex, FlexItem, NotificationType, Spinner } from '@vaisala/rockhopper-components';
import { QA_TEST_IDS } from '../../../../tests/qaTestIds';
import { TEST_IDS } from '../../../../tests/testids';
import { getConstantFromLocalStorage, isAlarmState } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { LocationNode } from '../../../../siteTree/site';
import { LS_COMPANY_CUSTOMER_ID } from '../../../../constants';
import {
  useGetThresholdAlarmSettingQuery,
  usePostThresholdAlarmSettingsMutation,
  ThresholdAlarmSettings
} from '../../../../store/services/alarmsApi';
import { Group } from '../../../../store/services/userApi';
import { ThresholdAlarmSettingsDisplay } from './ThresholdAlarmSettingsDisplay';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../../store/notifications';
import { AlarmThresholdDialogContent } from './AlarmThresholdSettingsDialog/AlarmThresholdDialogContent';
import { AlarmSettingsAccordion } from '../../Alarms/shared/AlarmSettingsAccordion';
import { Tags as siteApiTags, siteApi } from '../../../../store/services/siteApi';

interface Props {
  locationDetails: { location: LocationNode | undefined; isLoading: boolean };
  canManageAlarms: boolean;
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
}

export const ThresholdAlarmAccordion: React.FC<Props> = ({
  locationDetails,
  canManageAlarms,
  notificationGroupsDetails
}) => {
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID);

  const {
    currentData: thresholdAlarmSettings,
    isFetching: isFetchingThresholdSettings,
    refetch: refetchSettingDetails
  } = useGetThresholdAlarmSettingQuery(
    {
      customerId: customerId,
      location_id: locationDetails.location?.node_id,
      unit_name: locationDetails.location?.symbol_id
    },
    { refetchOnMountOrArgChange: true, skip: !locationDetails.location || locationDetails.isLoading }
  );

  const [saveSettings, saveResults] = usePostThresholdAlarmSettingsMutation();

  const onSettingsSave = (settings: ThresholdAlarmSettings) => {
    saveSettings({
      customerId,
      location_id: locationDetails.location?.node_id,
      unit_name: locationDetails.location?.symbol_id,
      body: { settings: settings.settings, template: settings.template }
    });
  };

  const settingsDetails = {
    settings: thresholdAlarmSettings,
    isFetching: isFetchingThresholdSettings,
    refetch: refetchSettingDetails
  };

  return (
    <ThresholdAccordionContent
      canManageAlarms={canManageAlarms}
      locationDetails={locationDetails}
      notificationGroupsDetails={notificationGroupsDetails}
      settingsDetails={settingsDetails}
      onSave={onSettingsSave}
      savingDetails={{
        isSaving: saveResults.isLoading,
        isSaved: saveResults.isSuccess,
        isSaveFailed: saveResults.isError
      }}
    />
  );
};

export interface ContentProps {
  locationDetails: { location: LocationNode | undefined; isLoading: boolean };
  onSave: (settings: ThresholdAlarmSettings) => void;
  settingsDetails: { settings: ThresholdAlarmSettings; isFetching: boolean; refetch: () => void };
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
  canManageAlarms: boolean;
  savingDetails: { isSaving: boolean; isSaved: boolean; isSaveFailed: boolean };
}

export const ThresholdAccordionContent: React.FC<ContentProps> = ({
  settingsDetails,
  locationDetails,
  canManageAlarms,
  notificationGroupsDetails,
  savingDetails,
  onSave
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setisModalOpen] = useState(false);
  const alarmSetState = locationDetails.location?.thres_alarm_set;
  useEffect(() => {
    if (savingDetails.isSaved) {
      setisModalOpen(false);
      dispatch(
        addNotification({
          type: NotificationType.Ok,
          content: t('site.alarms.threshold.toaster.success', { locationName: locationDetails.location?.name })
        })
      );
      dispatch(siteApi.util.invalidateTags([siteApiTags.Nodes]));
    }
    if (savingDetails.isSaveFailed) {
      dispatch(
        addNotification({
          type: NotificationType.Error,
          content: t('site.alarms.threshold.toaster.fail', { locationName: locationDetails.location?.name })
        })
      );
    }
  }, [savingDetails.isSaved, savingDetails.isSaveFailed]);

  const showAlarmDisplay = isAlarmState.set(alarmSetState);
  const showThresholdNotSet =
    (isAlarmState.notSet(alarmSetState) || isAlarmState.default(alarmSetState)) && !locationDetails.isLoading;

  return (
    <AlarmSettingsAccordion
      modalTitle={'alarm.settings.modal.threshold.header'}
      editButtonId={QA_TEST_IDS.alarms_settings_threshold_accordion_edit_button}
      isModalOpen={isModalOpen}
      closeModal={() => setisModalOpen(false)}
      openModal={() => setisModalOpen(true)}
      htmlId={QA_TEST_IDS.site_settings_threshold_alarms_accordion_item}
      dataTa={TEST_IDS.site_settings_threshold_alarms_accordion_item}
      title={
        <ThresholdAccordionTitle
          alarmSetState={locationDetails.location?.thres_alarm_set}
          isActive={locationDetails.location?.active}
          isLoading={locationDetails.isLoading}
        />
      }
      fallbackDetails={{
        accessRestricted: !canManageAlarms,
        isLoading: settingsDetails.isFetching || locationDetails.isLoading,
        noData: !settingsDetails.settings,
        retry: settingsDetails.refetch
      }}
      editContent={
        <AlarmThresholdDialogContent
          settingsDetails={settingsDetails}
          onSave={onSave}
          location={locationDetails.location}
          isSaving={savingDetails.isSaving}
          notificationGroupsDetails={notificationGroupsDetails}
        />
      }
    >
      {showThresholdNotSet && (
        <span
          id={QA_TEST_IDS.site_settings_threshold_alarms_no_thresholds_will_not_alarm}
          data-ta={TEST_IDS.site_settings_threshold_alarms_no_thresholds_will_not_alarm}
          className="grey-medium"
        >
          {t('site.thresholdNotSetAndWillNotAlarm')}
        </span>
      )}
      {showAlarmDisplay && (
        <ThresholdAlarmSettingsDisplay
          notificationGroupsDetails={notificationGroupsDetails}
          settingsDetails={settingsDetails}
          measSymbolId={locationDetails.location?.symbol_id}
        />
      )}
    </AlarmSettingsAccordion>
  );
};

interface ThresholdAccordionTitleProps {
  isLoading: boolean;
  isActive: boolean;
  alarmSetState: LocationNode['thres_alarm_set'];
}

const ThresholdAccordionTitle: React.FC<ThresholdAccordionTitleProps> = ({ isActive, alarmSetState, isLoading }) => {
  const { t } = useTranslation();
  const isDefault = isAlarmState.default(alarmSetState);
  const isThresholdNotSet = isAlarmState.notSet(alarmSetState);
  const showThresholdNotSet = (isDefault || isThresholdNotSet) && !isLoading;
  const showSpinner = isLoading || isAlarmState.none(alarmSetState);
  return (
    <Flex dataTa={TEST_IDS.alarm_settings_threshold_accordion_title} alignItems="center" justifyContent="space-between">
      <FlexItem alignSelf="flex-start" flexBasis="200px" className="alarms-accordion-title">
        <div id={QA_TEST_IDS.site_settings_threshold_alarms_accordion_title}>{t('site.ThreshholdAlaramSettings')}</div>
        {showThresholdNotSet && (
          <div
            id={QA_TEST_IDS.site_settings_threshold_alarms_not_set}
            data-ta={TEST_IDS.site_settings_threshold_alarms_not_set}
            className="vai-margin-top-s alert-red"
          >
            {t('site.statusThresholdNotSet', { count: 0 })}
          </div>
        )}
        {showSpinner && <Spinner className="spinner-small" />}
      </FlexItem>
      {!isActive && (
        <FlexItem flexGrow={1} className="vai-margin-left-s black strong">
          {i18n.t('general.deactivated')}
        </FlexItem>
      )}

      <div>{/* placeholder for infotip */}</div>
    </Flex>
  );
};
