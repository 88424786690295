import { RefObject, useLayoutEffect, useState } from 'react';

export interface ResizeObserverEntry {
  target: Element;
  contentRect: DOMRectReadOnly;
}

export const useResizeObserver = (ref: RefObject<HTMLElement>, defaultHeight?: number, calculatePadding = true) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>(defaultHeight);

  function getVerticalPadding(total: number, element: Element) {
    total = total || 0;

    const style = window.getComputedStyle(element);
    const props = [
      'padding-top',
      'padding-bottom',
      'margin-top',
      'margin-bottom',
      'border-top-width',
      'border-bottom-width'
    ];
    props.forEach(prop => {
      const value = parseInt(style.getPropertyValue(prop), 0);
      if (!isNaN(value)) {
        total += value;
      }
    });
    return total;
  }

  function getTotalVerticalPadding(element: Element) {
    if (!element) {
      return 0;
    }
    return getVerticalPadding(0, element);
  }

  const handleResize = (entries: ResizeObserverEntry[]) => {
    if (!Array.isArray(entries)) {
      return;
    }
    // Schedule the state update to run in the next animation frame
    requestAnimationFrame(() => {
      const entry = entries[0];
      const totalVerticalPadding = calculatePadding ? getTotalVerticalPadding(ref?.current) ?? 0 : 0;
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height + totalVerticalPadding);
    });
  };

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    let observer = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries));

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
      observer = null;
    };
  }, [ref?.current]);

  return [width, height];
};
