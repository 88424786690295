import React from 'react';
import { BodyText, Flex, FlexItem, Grid, Separator, Size } from '@vaisala/rockhopper-components';
import { SiteNodeTypes } from '../../constants';
import { isSiteNode, isLocationNode, snakeCasetoCamelCase } from '../../utils';
import {
  HELPER_TEXT_TRANSLATION_KEYS,
  SITE_RIGHTS_LIST,
  RIGHTS_TRANSLATION_KEY,
  ZONE_RIGHTS_LIST,
  RightNames,
  SiteRightsTree
} from './constants';
import { useTranslation } from 'react-i18next';
import { MultiStateCheckbox } from '../MultiStateCheckbox/MultiStateCheckbox';
import { TEST_IDS } from '../../tests/testids';

interface Props {
  node: SiteRightsTree;
  onChange?: (checked: boolean, node: SiteRightsTree, rightName: RightNames) => void;
  readonly?: boolean;
}

export const RightCheckboxes: React.FC<Props> = ({ node, onChange, readonly }) => {
  const { t } = useTranslation();
  const nodes = [];

  if (isSiteNode(node)) {
    nodes.push(
      <React.Fragment key={node.node_id}>
        <BodyText className="grey-medium section-title ">
          {t(HELPER_TEXT_TRANSLATION_KEYS[SiteNodeTypes.SITE])}
        </BodyText>
        <Flex
          className="checkbox-container"
          alignContent="flex-start"
          justifyContent="space-around"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="flex-start"
        >
          {SITE_RIGHTS_LIST.map(rightName => {
            return (
              <FlexItem flexBasis={'100%'} key={rightName + node.node_id}>
                <MultiStateCheckbox
                  dataTa={snakeCasetoCamelCase(rightName)}
                  state={node.checkboxRightsStates[rightName]}
                  readOnly={readonly}
                  label={t(RIGHTS_TRANSLATION_KEY[rightName])}
                  onChange={event => onChange(event.target.checked, node, rightName)}
                />
              </FlexItem>
            );
          })}
        </Flex>
        <Separator />
      </React.Fragment>
    );
  }
  if (!isLocationNode(node)) {
    nodes.push(
      <React.Fragment key={node.node_id + node.type}>
        {isSiteNode(node) ? (
          <BodyText className="grey-medium section-title border-top">
            {t(HELPER_TEXT_TRANSLATION_KEYS[SiteNodeTypes.ZONE])}
          </BodyText>
        ) : null}
        <Flex
          className="checkbox-container"
          alignContent="flex-start"
          justifyContent="space-around"
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="flex-start"
        >
          {ZONE_RIGHTS_LIST.map(rightName => {
            return (
              <FlexItem flexBasis={'100%'} key={rightName + node.node_id}>
                <MultiStateCheckbox
                  dataTa={snakeCasetoCamelCase(rightName)}
                  state={node.checkboxRightsStates[rightName]}
                  readOnly={readonly}
                  label={t(RIGHTS_TRANSLATION_KEY[rightName])}
                  onChange={event => onChange(event.target.checked, node, rightName)}
                />
              </FlexItem>
            );
          })}
        </Flex>
      </React.Fragment>
    );
  }

  return (
    <Grid padding={Size.M}>
      <Grid.Row className="group-rights-row">
        <Grid.Col className="group-right--column-padding" xs={12} sm={12} md={12} lg={12} xl={12}>
          {nodes}
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};
