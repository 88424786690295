import { Icon, IconProps, Size, Popover, PopoverProps } from '@vaisala/rockhopper-components';
import React from 'react';
import cx from 'classnames';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import useHtmlId, { IUseHtmlIDProps } from '../../../hooks/useHtmlId';

import './infotip.scss';
import { TEST_IDS } from '../../../tests/testids';

export interface InfotipProps extends IUseHtmlIDProps {
  iconProps?: IconProps;
  popoverProps?: PopoverProps;
  children?: React.ReactNode;
}

const Infotip = ({ htmlId, iconProps = {}, popoverProps = {}, children }: InfotipProps) => {
  const { getId } = useHtmlId({ htmlId, separator: '__' });
  // Set some sane defaults for the icon
  const {
    color = VaiColor.AquaVaisala,
    name = VaiIcon.Help,
    size = Size.M,
    className = null,
    dataTa = TEST_IDS.infotip_icon,
    ...otherIconProps
  } = iconProps;
  const classes = cx('infotip', className);
  const iconId = getId('icon');
  const icon: JSX.Element = (
    <Icon
      htmlId={iconId}
      className={classes}
      color={color}
      name={name}
      size={size}
      dataTa={dataTa}
      {...otherIconProps}
    />
  );
  const {
    dataTa: dataTaPopover = TEST_IDS.infotip_popover,
    triggerElement = icon,
    showCloseIcon = false,
    ...otherPopoverProps
  } = popoverProps;
  const iconWrapperId = getId('icon-wrapper');
  const popoverId = getId('popover');

  return (
    <Popover
      id={popoverId}
      triggerElement={<div id={iconWrapperId}>{triggerElement}</div>}
      showCloseIcon={showCloseIcon}
      dataTa={dataTaPopover}
      {...otherPopoverProps}
      className={cx('infotip-popover', popoverProps.className)}
    >
      {/** NOTE: Use div instead of BodyText to avoid invalid dom nesting errors in console */}
      <div className="vai-body-text">{children}</div>
    </Popover>
  );
};

export default Infotip;
