import * as React from 'react';
import {
  Table,
  TableStyle,
  TableBody,
  TableRow,
  TableCell,
  Icon,
  Button,
  ButtonType,
  Flex,
  Separator,
  Modal,
  Size,
  Heading
} from '@vaisala/rockhopper-components';
import { UserInterface } from '../../../profile/user';
import EditProfileDialog from '../../profile/EditProfileDialog/index';
import './view-my-profile-dialog.scss';
import { LANGUAGE_TEXT } from '../../../utils/common';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { hideDialog, showDialog } from '../../../store/dialog';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';

interface ViewMyProfileDialogProps {
  getMyProfile: () => void;
  myProfile: UserInterface;
  userProfileLoading: boolean;
  editProfile: (userProfile: UserInterface) => void;
  editProfileSucceedFlag: boolean;
  editProfileError: any;
}

const ViewMyProfileDialog = (props: ViewMyProfileDialogProps) => {
  const { t } = useTranslation();
  const [pinVisibility, setPinVisibility] = React.useState(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    props.getMyProfile();
  }, []);

  const showEditProfileModal = () => {
    dispatch(
      showDialog(
        <EditProfileDialog disableUserInputs={false} userProfile={props.myProfile} editProfile={props.editProfile} />
      )
    );
  };

  const toggleVisbility = () => {
    setPinVisibility(!pinVisibility);
  };

  const onDismiss = () => dispatch(hideDialog());

  let groupListContent = <CenteredSpinner htmlId="view-my-profile-group-list-spinner" />;
  if (!props.userProfileLoading) {
    groupListContent = (
      <Table id="view-my-profile-table" tableStyle={TableStyle.Plain} className="my-profile-table vai-margin-left-s">
        <TableBody htmlId="view-my-profile-table-body">
          {props.myProfile.groups.map((row, index) => (
            <TableRow htmlId={`view-my-profile-table-row-${index}`} key={`profile-tabrow-${index}`}>
              <TableCell id={`view-my-profile-table-icon-cell-${index}`} className="aqua-medium">
                <Icon
                  id={`view-my-profile-table-group-icon-${index}`}
                  className="my-profile-user-group-icon"
                  name={VaiIcon.UserGroup}
                />
                {row.group_name}
              </TableCell>
              <TableCell className="view-my-profile-table-user-count" id={`view-my-profile-table-users-cell-${index}`}>
                {row.users.length} {row.users.length > 1 ? t('profile.users') : t('profile.user')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  let userProfileContent = <CenteredSpinner htmlId="view-my-profile-details-spinner" className="" />;
  if (!props.userProfileLoading) {
    userProfileContent = (
      <div id="view-my-profile-content">
        <Flex id="view-my-profile-name-flex-container">
          <Flex.Item id="view-my-profile-name-flex-first" alignSelf={'flex-start'} flexBasis={'40%'}></Flex.Item>
          <Flex.Item alignSelf={'flex-end'} flexBasis={'60%'}>
            <Heading id="view-my-profile-name-flex-second" className="my-profile-heading" level={2}>
              {t('profile.myProfile')}
            </Heading>
          </Flex.Item>
        </Flex>
        <Flex id="view-my-profile-picture-flex-container" justifyContent="flex-start" alignItems="center">
          <Flex.Item id="view-my-profile-picture-flex-first">
            {props.myProfile.picture ? (
              <img id="view-my-profile-picture-img" src={props.myProfile.picture} alt="Profile picture" />
            ) : (
              <Icon id="view-my-profile-picture-icon" className="my-profile-icon" name={VaiIcon.User} />
            )}
          </Flex.Item>
          <Flex.Item id="view-my-profile-full-name-flex-second" flexBasis="auto">
            <div id="view-my-profile-full-name-container" className="my-profile-full-name">
              {props.myProfile.first_name || props.myProfile.last_name
                ? (props.myProfile.first_name + ' ' + props.myProfile.last_name).trim()
                : props.myProfile.email.trim()}
            </div>
          </Flex.Item>
        </Flex>
        <Separator />
        <Flex id="my-profile-info-flex-container" className="my-profile-info-container">
          <Flex.Item alignSelf={'flex-start'} flexBasis={'49%'} style={{ height: '100%' }}>
            <Flex className="vai-margin-top-m vai-margin-bottom-s">
              <Flex.Item>
                <p id="my-profile-info-text" className="my-profile-header">
                  {t('profile.profileInformation')}
                </p>
              </Flex.Item>
              <Flex.Item className="my-profile-top-right-button-container aqua-medium">
                <Button
                  id="view-my-profile-dialog"
                  buttonType={ButtonType.Secondary}
                  className="my-profile-top-right-button"
                  onClick={showEditProfileModal}
                >
                  {t('profile.editProfile')}
                </Button>
              </Flex.Item>
            </Flex>
            <Table
              tableStyle={TableStyle.Plain}
              className="my-profile-table my-profile-borderless-table vai-margin-left-s"
              id="my-profile-info-table"
            >
              <TableBody htmlId="my-profile-info-table-body">
                <TableRow>
                  <TableCell id="my-profile-company-id-title">{t('profile.companyID')}</TableCell>
                  <TableCell id="my-profile-company-id-text" className="grey-medium">
                    {props.myProfile.company_alias}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="my-profile-username-title">{t('profile.unsernameEmail')}</TableCell>
                  <TableCell id="my-profile-username-text" className="grey-medium">
                    {props.myProfile.user_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="my-profile-language-title">{t('profile.language')}</TableCell>
                  <TableCell id="my-profile-language-text">{LANGUAGE_TEXT[props.myProfile.locale]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="my-profile-time-zone-title">{t('profile.timeZone')}</TableCell>
                  <TableCell id="my-profile-timezone-text">{props.myProfile.tz}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="my-profile-notification-email-title">{t('profile.notificationEmail')}</TableCell>
                  <TableCell id="my-profile-notification-email-text">{props.myProfile.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="my-profile-phone-title">{t('profile.phone')}</TableCell>
                  <TableCell id="my-profile-phone-text">{props.myProfile.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id="my-profile-pin-title">{t('profile.pin')}</TableCell>
                  <TableCell id="my-profile-pin-text">
                    <span>{pinVisibility ? props.myProfile.pin : '*****'}</span>
                  </TableCell>
                  <TableCell>
                    <Button
                      id="my-profile-visibility-icon-button"
                      buttonType={ButtonType.Secondary}
                      buttonSize={Size.S}
                      onClick={toggleVisbility}
                    >
                      <Icon
                        id="my-profile-visibility-icon-icon"
                        name={pinVisibility ? VaiIcon.VisibilityOn : VaiIcon.VisibilityOff}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Flex.Item>
          <Flex.Item alignSelf={'center'} flexBasis={'2%'} style={{ height: '100%' }}>
            <hr className="vertical-separator" />
          </Flex.Item>
          <Flex.Item alignSelf={'flex-end'} flexBasis={'49%'} style={{ height: '100%' }}>
            <Flex className="vai-margin-top-m vai-margin-bottom-s">
              <Flex.Item>
                <p id="my-profile-groups-header" className="my-profile-header">
                  {t('profile.groups')}
                </p>
              </Flex.Item>
            </Flex>
            <p id="my-profile-access-right-header" className="grey-medium vai-margin-top-m vai-margin-bottom-l">
              {t('profile.accessRight')}
            </p>
            {groupListContent}
          </Flex.Item>
        </Flex>
      </div>
    );
  }

  return (
    <Modal
      id="view-my-profile-dialog"
      isOpen
      htmlId="view-my-profile-dialog"
      showCloseIcon={true}
      onDismiss={onDismiss}
      width={Size.L}
      dismissOnOverlayClick={false}
      className="view-my-profile-modal"
    >
      <Flex>
        <Flex.Item alignSelf={'flex-start'} flexBasis={'100%'}>
          {userProfileContent}
        </Flex.Item>
      </Flex>
    </Modal>
  );
};
export default ViewMyProfileDialog;
