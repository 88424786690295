import { BodyText, Icon, IconProps, Size } from '@vaisala/rockhopper-components';
import React from 'react';
import {
  ALARM_PRIORITY,
  ALARM_STATUS_PRIORITY_TRANSLATION_KEYS,
  THRESHOLD_STATUS,
  THRESHOLD_STATUS_TRANSLATION_KEYS
} from '../../../constants';
import { Status } from './StatusItem';
import { useTranslation } from 'react-i18next';
import { AlarmPriorityIcons } from '../Alarms/shared/AlarmIcons';
import { VaiIcon, VaiColor } from '@vaisala/rockhopper-design-tokens';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedNodeId } from '../../../store/siteTree';
import { routes } from '../../../utils';
import { Link } from 'react-router-dom';

interface Props {
  status: Status;
  alarmCount?: number;
}

const ThresholdStatusIcons = {
  ...AlarmPriorityIcons,
  [THRESHOLD_STATUS.WITHIN]: (props: IconProps) => (
    <Icon name={VaiIcon.AlertOk} size={Size.M} color={VaiColor.LeafGreen} {...props} />
  )
};

export const ThresholdStatus: React.FC<Props> = ({ status, alarmCount }) => {
  const { t } = useTranslation();

  const ThresholdStatusIcon = ThresholdStatusIcons[status];
  const activeAlarmsTranslationKey =
    alarmCount > 0
      ? ALARM_STATUS_PRIORITY_TRANSLATION_KEYS[status as ALARM_PRIORITY]?.alarms
      : ALARM_STATUS_PRIORITY_TRANSLATION_KEYS[status as ALARM_PRIORITY]?.alarm;

  const isAlarmStatus = ALARM_STATUS_PRIORITY_TRANSLATION_KEYS[status];
  const selectedNodeId = useAppSelector(selectSelectedNodeId);

  return (
    <BodyText className="status-item__value alarm-status-value">
      {ThresholdStatusIcon && <ThresholdStatusIcon />}
      {isAlarmStatus ? (
        <Link to={`${routes.site.url}/${routes.site.alarms.url}/${selectedNodeId}`}>
          {alarmCount} {t(activeAlarmsTranslationKey)}
        </Link>
      ) : (
        t(THRESHOLD_STATUS_TRANSLATION_KEYS[status])
      )}
    </BodyText>
  );
};
