import * as React from 'react';
import { Size, Button, ButtonType, Modal, Heading, Flex, InputField, Icon } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { useTranslation } from 'react-i18next';

import './select-locations-dialog.scss';
import AccordionTree from '../AccordionTree/AccordionTree';
import { reportsDispatchActions, StoreState } from '../../../store';
import { connect } from 'react-redux';
import { LOCATION_KEY, MAX_LOCATIONS_SELECTED } from '../../../constants';
import { LocationTreeFormattedNode } from '../Locations';
import { useDebounce } from 'use-debounce';
import { filterLocationsTree, sortLocationsOnTopOfZones } from '../../../utils/common';
import { HasRights } from '../../HasRIghts/HasRights';

type SelectLocationsDialogProps = {
  isVisible: boolean;
  onDismiss: () => void;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const SelectLocationsDialog = (props: SelectLocationsDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const [searchVal, setSearchVal] = React.useState('');
  const [value] = useDebounce(searchVal, 600);
  //   Store the last selected locations version
  const [previouslocationsTree, setPreviousLocationsTree] = React.useState(props.selectedFormattedLocations);
  //   Stores new locations selected (Will not be saved if cancel is clicked)
  const [newlocationsTree, setLocationsTree] = React.useState(props.selectedFormattedLocations);
  const [locationsCount, setLocationsCount] = React.useState(props.selectedFormattedLocationsNum);
  React.useEffect(() => {
    const filteredLocations = filterLocationsTree(
      value.toLowerCase(),
      {} as LocationTreeFormattedNode,
      newlocationsTree
    );

    setPreviousLocationsTree(filteredLocations as { [key: string]: LocationTreeFormattedNode });
  }, [value]);

  React.useEffect(() => {
    if (!props.loadingAPI) setPreviousLocationsTree(props.selectedFormattedLocations);
  }, [props.selectedFormattedLocations]);

  const handleInputChange = event => {
    const search = event.target.value;

    setSearchVal(search);
  };

  const getSelectedLocationsSK = (
    data = newlocationsTree,
    selectedIDs = [],
    selectedCustomProps = {},
    selectedSKs = []
  ) => {
    sortLocationsOnTopOfZones(data).forEach(n => {
      if (Object.values(n.children).length > 0) {
        getSelectedLocationsSK(n.children, selectedIDs, selectedCustomProps, selectedSKs);
      } else if (n.type === LOCATION_KEY && n.selected) {
        selectedIDs.push(n.node_id);
        selectedSKs.push(n.sk);
        selectedCustomProps[n.node_id] = {
          measurement_points: [],
          showThreshold: false,
          visibleOnGraph: true,
          selected: true,
          symbol_id: n.symbol_id,
          name: n.name,
          hierarchy: n.hierarchy,
          decimal_places: n.decimal_places
        };
      }
    });

    return { selectedIDs, selectedCustomProps, selectedSKs };
  };

  const addSelectedLocations = () => {
    const { selectedIDs, selectedCustomProps, selectedSKs } = getSelectedLocationsSK();

    setPreviousLocationsTree(newlocationsTree);
    props.setReportSelectedFormattedLocations(newlocationsTree);
    props.setReportSelectedLocationsIDs(selectedIDs);
    props.setReportSelectedLocationsSKs(selectedSKs);
    props.setReportSelectedLocationsCustomProps(selectedCustomProps);
    props.setReportSelectedLocationsNum(locationsCount);
    props.onDismiss();
  };
  return (
    <Modal
      id="select-locations-dialog-id"
      htmlId="select-locations-dialog"
      isOpen={props.isVisible}
      showCloseIcon={true}
      onDismiss={props.onDismiss}
      width={Size.L}
      dismissOnOverlayClick={false}
    >
      <HasRights.ViewZone>
        <Heading className="vai-margin-top-none select-locations-header">
          {t('reports.selectLocationToAddToReport')}
        </Heading>

        <Flex flexDirection="column">
          <span className="select-locations-number">
            {locationsCount} {t('reports.locationsSelected')}
          </span>

          <span className="select-locations-info-text vai-font-size-xs">
            {locationsCount > MAX_LOCATIONS_SELECTED
              ? t('reports.greaterThanMaxLocationsInfoText', { max: MAX_LOCATIONS_SELECTED })
              : t('reports.lowerThanMaxLocationsInfoText', { max: MAX_LOCATIONS_SELECTED })}
          </span>

          <InputField
            data-ta="select-locations-search"
            className="vai-margin-vertical-s"
            value={searchVal}
            onChange={handleInputChange}
            name="search"
            placeholder={t('events.filterBar.search')}
            endIcon={<Icon name={VaiIcon.Search} size={Size.M} />}
            width={Size.Container}
          />

          <div data-ta="select-locations-accordion-wrapper" className="select-locations-accordion-wrapper">
            <AccordionTree
              locationsTree={previouslocationsTree}
              setLocationsTree={setLocationsTree}
              setLocationsCount={setLocationsCount}
              locationsCount={locationsCount}
            />
          </div>
        </Flex>

        <Flex className="vai-margin-top-m" justifyContent="center">
          <Button
            id="select-locations-confirm"
            disabled={JSON.stringify(newlocationsTree) === JSON.stringify(previouslocationsTree)}
            htmlId="select-locations-confirm"
            onClick={addSelectedLocations}
          >
            {t('reports.select')}
          </Button>
          <Button
            id="select-locations-cancel"
            htmlId="select-locations-cancel"
            onClick={props.onDismiss}
            buttonType={ButtonType.Secondary}
          >
            {t('general.cancel')}
          </Button>
        </Flex>
      </HasRights.ViewZone>
    </Modal>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedFormattedLocationsNum: reports.selectedLocationsNum,
  intervalDate: reports.intervalDate,
  loadingAPI: reports.isApiLoading,
  selectedLocationsCustomProps: reports.selectedLocationsCustomProps
});

const mapDispatchToProps = (dispatch: any) => ({
  setReportSelectedFormattedLocations: locations =>
    dispatch(reportsDispatchActions.setReportSelectedFormattedLocations(locations)),
  setReportSelectedLocationsCustomProps: locations =>
    dispatch(reportsDispatchActions.setReportSelectedLocationsCustomProps(locations)),
  setReportSelectedLocationsNum: number => dispatch(reportsDispatchActions.setReportSelectedLocationsNum(number)),
  setReportSelectedLocationsIDs: IDs => dispatch(reportsDispatchActions.setReportSelectedLocationsIDs(IDs)),
  setReportSelectedLocationsSKs: (SKs: string[]) => dispatch(reportsDispatchActions.setReportSelectedLocationsSK(SKs))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocationsDialog);
