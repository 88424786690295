import React from 'react';
import { BodyText, Button, ButtonType, Checkbox, Size, Stack } from '@vaisala/rockhopper-components';
import { useTranslation } from 'react-i18next';
import useHtmlId from '../../../hooks/useHtmlId';
import { LocationNode } from '../../../siteTree/site';
import { hideDialog } from '../../../store/dialog';
import { useAppDispatch } from '../../../store/hooks';
import { TEST_IDS } from '../../../tests/testids';
import { ModalContainer } from '../../Modals/ModalContainer';
import { TranslationKey } from '../../../react-i18next';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import { ToggleNodeActiveMutation } from '../../../store/services/siteApi';
import { ButtonWithLoader } from '../../ButtonWithLoader/ButtonWithLoader';

// import './index.scss';

interface Props {
  location: LocationNode | null;
  toggleNodeActive: ToggleNodeActiveMutation;
}

const htmlId = 'toggle-activation-dialog';

const ToggleActivationDialog: React.FC<Props> = ({ location, toggleNodeActive }) => {
  const { t } = useTranslation();
  const [saving, setSaving] = React.useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState<boolean>(true);
  const { getId } = useHtmlId({ htmlId });
  const dispatch = useAppDispatch();
  const onDismiss = () => {
    dispatch(hideDialog());
  };

  const onConfirm = async () => {
    setSaving(true);
    // Logic for activate and deactivate depending on the location's active status.
    if (location.active) {
      // Deactivation logic
      await toggleNodeActive({ node: location, active: false, unlink: location.device ? isChecked : false });
    } else {
      // Activation logic
      await toggleNodeActive({ node: location, active: true });
    }
    onDismiss();
  };
  const cancelId = getId('cancel-button');
  const confirmId = getId('confirm-button');
  const confirmButtonText = saving ? (
    <CenteredSpinner htmlId={getId('save-spinner')} className="" />
  ) : location.active ? (
    t('general.deactivate')
  ) : (
    t('general.activate')
  );
  const ModalButtons = (
    <>
      <ButtonWithLoader id={confirmId} className="vai-space-inline-s" disabled={saving} onClick={onConfirm}>
        {confirmButtonText}
      </ButtonWithLoader>
      <Button id={cancelId} htmlId={cancelId} buttonType={ButtonType.Secondary} onClick={onDismiss}>
        {t('general.cancel')}
      </Button>
    </>
  );

  const titleTranslation: TranslationKey = location.active
    ? 'toggleLocationActiveModal.deactivateTitle'
    : 'toggleLocationActiveModal.activateTitle';

  const blurbTranslation: TranslationKey = location.active
    ? 'toggleLocationActiveModal.deactivateBlurb'
    : 'toggleLocationActiveModal.activateBlurb';

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <ModalContainer
      id={getId()}
      isOpen
      role="alert-dialog"
      showCloseIcon={true}
      dismissOnOverlayClick={false}
      title={titleTranslation}
      onDismiss={onDismiss}
      buttons={ModalButtons}
    >
      <div className="vai-margin-m">
        <Stack spacing={Size.M}>
          <BodyText id={getId('blurb')} data-ta={TEST_IDS.toggle_location_active_dialog_blurb}>
            {t(blurbTranslation)}
          </BodyText>
          {location.active && location.device && (
            /* eslint-disable enforce-ids-in-jsx/missing-ids */
            <Checkbox
              checked={isChecked}
              htmlId={getId('checkbox')}
              onChange={onCheckboxChange}
              label={t('toggleLocationActiveModal.checkboxLabel')}
            />
          )}
        </Stack>
      </div>
    </ModalContainer>
  );
};

export default ToggleActivationDialog;
