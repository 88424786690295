import { BodyText, Flex, FlexItem, Heading, Toggle } from '@vaisala/rockhopper-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ALARM_PRIORITY, DEVICE_ALARM_STATUS } from '../../../constants';
import { LocationNode } from '../../../siteTree/site';
import LocationIcon from './LocationIcon';
import './locationHeader.scss';
import { TEST_IDS } from '../../../tests/testids';

interface ILocationHeader {
  location: LocationNode;
  toggle?: boolean;
  toggleActiveDisplay?: () => void;
  deviceStatus: DEVICE_ALARM_STATUS | ALARM_PRIORITY;
  measurementType: LocationNode['meas_id'];
}

const LocationHeader = ({
  location,
  toggle = false,
  toggleActiveDisplay,
  deviceStatus,
  measurementType
}: ILocationHeader) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="row" flexWrap="nowrap" className="location-header">
      <FlexItem className="location-header__icon-flex-item">
        <LocationIcon status={deviceStatus} />
      </FlexItem>
      <FlexItem flexGrow={1} flexShrink={0} className="location-header__title-flex-item">
        <Heading
          level={1}
          htmlId={TEST_IDS.location_header_title}
          dataTa={TEST_IDS.location_header_title}
          className="location-header__title"
        >
          {location.name}
        </Heading>
        <BodyText
          htmlId={TEST_IDS.location_header_desc}
          dataTa={TEST_IDS.location_header_desc}
          className="location-header__description"
        >
          {location.description ? location.description : t(`general.${measurementType}`)}
        </BodyText>
      </FlexItem>
      {toggle && (
        <Flex flexDirection="column" className="vai-margin-left-m">
          {toggle && (
            <Toggle
              htmlId={TEST_IDS.location_toggle}
              dataTa={TEST_IDS.location_toggle}
              text={t('site.active')}
              uncheckedText={t('site.inactive')}
              checked={location.active}
              onChange={toggleActiveDisplay}
              className="location-header__toggle"
            />
          )}
          {!location.active && (
            <FlexItem
              id={TEST_IDS.location_deactivated_label}
              dataTa={TEST_IDS.location_deactivated_label}
              alignSelf="flex-end"
              className="alert-red vai-margin-vertical-s"
            >
              {t('general.deactivated')}
            </FlexItem>
          )}
        </Flex>
      )}
    </Flex>
  );
};
export default LocationHeader;
