import { useTranslation } from 'react-i18next';
import { SiteInterface } from '../../siteTree/site';
import { AccordionTree } from '../AccordionTree/AccordionTree';
import { ModalContainer, ModalContainerProps } from '../Modals/ModalContainer';
import React, { useEffect, useState } from 'react';
import { BodyText, Button, ButtonType, Flex, FlexItem, Icon, InputField, Size } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { isEqual } from 'lodash';
import CenteredSpinner from '../BaseComponents/CenteredSpinner';
import { TEST_IDS } from '../../tests/testids';
import './styles.scss';

export const MAX_SELECTION_LIMIT = 50;
export const DATA_COUNT_ATTR = 'count';

export interface Props extends Omit<ModalContainerProps, 'title' | 'id' | 'children'> {
  site?: SiteInterface;
  onNodeSelect: (ids: string[]) => void;
  selectedNodes: string[];
  onApply: () => void;
}

export const EventFilterByZoneModal: React.FC<Props> = ({
  site,
  onNodeSelect,
  onDismiss,
  isOpen,
  selectedNodes,
  onApply
}) => {
  const [appliedSelection, setAppliedSelection] = useState(selectedNodes);
  const [keyword, setKeyword] = useState(null);
  const { t } = useTranslation();

  const isSelectionInvalid = selectedNodes?.length > MAX_SELECTION_LIMIT;

  useEffect(() => {
    if (isOpen) {
      setAppliedSelection(selectedNodes);
    }
  }, [isOpen]);

  const countDetailForTesting = { [`data-${DATA_COUNT_ATTR}`]: selectedNodes?.length };

  return (
    <ModalContainer
      width={Size.L}
      isOpen={isOpen}
      onDismiss={onDismiss}
      showCloseIcon
      dismissOnOverlayClick={false}
      title={'events.filterBar.byzone.title'}
      dataTa={TEST_IDS.events_filter_bar_filterByZone_modal}
      htmlId={TEST_IDS.events_filter_bar_filterByZone_modal}
      id={TEST_IDS.events_filter_bar_filterByZone_modal}
      footerChildren={
        <>
          <Button
            disabled={isEqual(appliedSelection, selectedNodes) || isSelectionInvalid}
            dataTa={TEST_IDS.events_filter_bar_filterByZone_apply}
            onClick={() => {
              setAppliedSelection(selectedNodes);
              setKeyword(null);
              onApply();
            }}
          >
            {t('events.filterBar.apply')}
          </Button>
          <Button
            buttonType={ButtonType.Secondary}
            dataTa={TEST_IDS.events_filter_bar_filterByZone_cancel}
            onClick={() => {
              onNodeSelect(appliedSelection);
              setKeyword(null);
              onDismiss();
            }}
          >
            {t('general.cancel')}
          </Button>
        </>
      }
    >
      <BodyText {...countDetailForTesting} dataTa={TEST_IDS.events_filter_bar_filterByZone_selected_count}>
        <strong>{t('events.filterBar.byzone.selectedCount', { count: selectedNodes?.length })}</strong>
      </BodyText>
      <BodyText className="grey-medium">
        {t('events.filterBar.byzone.selectedCount.constraintText', { max_limit: MAX_SELECTION_LIMIT })}
      </BodyText>
      <Flex alignItems="center">
        <FlexItem className="input-flex-item" flexGrow={4}>
          <InputField
            width={Size.Container}
            placeholder={t('general.search')}
            endIcon={<Icon name={VaiIcon.Search} />}
            onChange={event => setKeyword(event.target.value)}
            data-ta={TEST_IDS.events_filter_bar_filterByZone_input}
          />
        </FlexItem>
        <FlexItem flexGrow={1}>
          <Button
            startIcon={<Icon name={VaiIcon.Cross} />}
            buttonType={ButtonType.Link}
            onClick={() => onNodeSelect([])}
            dataTa={TEST_IDS.events_filter_bar_filterByZone_clear_filters}
          >
            {t('events.filterBar.clearFiltres')}
          </Button>
        </FlexItem>
      </Flex>
      <div className="accordion-wrapper">
        {site ? (
          <AccordionTree
            site={site}
            options={{
              selectedNodes,
              nonSelectableNodes: [site?.node_id],
              match: keyword,
              expandedNodes: [site.node_id]
            }}
            onChange={onNodeSelect}
            AccordionSummary={({ node }) => <>{t('general.countLocations', { count: node.locationsCount })}</>}
          />
        ) : (
          <CenteredSpinner />
        )}
      </div>
      <div className="error-text">
        {isSelectionInvalid && (
          <BodyText dataTa={TEST_IDS.events_filter_bar_filterByZone_error_message} className="alert-red">
            {t('events.filterBar.byzone.selectedCount.errorMessage')}
          </BodyText>
        )}
      </div>
    </ModalContainer>
  );
};
