import { Flex, Modal, ModalProps } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import './modal.scss';
import { TranslationKey } from '../../react-i18next';

export interface ModalContainerProps extends ModalProps {
  id: string;
  title: TranslationKey;
  titleParams?: { [key: string]: string };
  iconName?: VaiIcon;
  /**
   * JSX for buttons for the footer
   */
  buttons?: JSX.Element;
  /**
   * Extra stuff in footer below buttons. e.g separate delete button.
   */
  footerChildren?: JSX.Element;
}

export const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
  id,
  title,
  titleParams,
  role,
  buttons,
  iconName,
  footerChildren,
  ...rest
}) => {
  return (
    <Modal key={id} id={id} role={role} {...rest}>
      <Flex flexDirection="column" className="modal-container">
        <ModalHeader title={title} titleParams={titleParams} type={role} iconName={iconName} />
        <div className="modal-content">{children}</div>
        <ModalFooter buttons={buttons}>{footerChildren}</ModalFooter>
      </Flex>
    </Modal>
  );
};
