import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React, { useEffect, useState } from 'react';
import { LOCATION_KEY, LS_DATA_RETENTION_MONTHS, SITE_KEY, ZONE_KEY } from '../../constants';
import { DeleteTreeNodeInterface, TreeMenuSiteInterface } from '../../siteTree/site';
import { LocationFormDialog } from './Location/LocationFormDialog';
import { ZoneFormDialog } from './Zone/ZoneFormDialog';
import { FormActionType } from './shared';
import { NodeActions, getChildList, getNodeObjectById } from './SiteTree';
import { useAppSelector } from '../../store/hooks';
import { selectSelectedNodeId } from '../../store/siteTree';
import { getConstantFromLocalStorage } from '../../utils';
import DeleteZoneAndLocationDialog from './DeleteZoneAndLocationDialog';
import { DeleteNodeSection } from './Form/DeleteNodeSection';
import { SiteFormDialog } from './Site/SiteFormDialog';
import { useTranslation } from 'react-i18next';
import { EditNodeArgs } from '../../store/services/siteApi';

interface Props {
  currentAction: NodeActions;
  treeData: TreeMenuSiteInterface[][];
  onDismiss: () => void;
  editTreeNodeApiDetails: {
    editTreeNode: (args: EditNodeArgs) => void;
    result: { isLoading?: boolean; isQueryEnded?: boolean };
  };
  deleteTreeNodeApiDetails: {
    deleteTreeNode: (args: DeleteTreeNodeInterface) => void;
    result: { isLoading?: boolean; isQueryEnded?: boolean };
  };
}

export const EditActionDialogs: React.FC<Props> = ({
  currentAction,
  treeData,
  onDismiss,
  deleteTreeNodeApiDetails,
  editTreeNodeApiDetails
}) => {
  const { editTreeNode, result: editResult } = editTreeNodeApiDetails;
  const { deleteTreeNode, result: deleteResult } = deleteTreeNodeApiDetails;

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const { t } = useTranslation();
  let selectedNodeId = useAppSelector(selectSelectedNodeId);

  if (selectedNodeId === undefined) {
    selectedNodeId = treeData[0][0].id.toString();
  }

  useEffect(() => {
    if (editResult && editResult?.isQueryEnded) {
      onDismiss && onDismiss();
    }
  }, [editResult.isQueryEnded]);

  useEffect(() => {
    if (deleteResult && deleteResult.isQueryEnded && currentAction) {
      onDismiss && onDismiss();
      setDeleteDialogVisible(false);
    }
  }, [deleteResult.isQueryEnded, currentAction]);

  if (currentAction === null || currentAction !== NodeActions.EDIT_NODE) return null;

  const selectedItemObject = getNodeObjectById(selectedNodeId, treeData[0]);
  const selectedItemParentObject = getNodeObjectById(selectedItemObject.parent, treeData[0]);
  const siblingNames = getChildList(selectedItemObject.parent, treeData[0]).filter(
    item => item !== selectedItemObject.text.toLocaleLowerCase()
  );
  const childNames = getChildList(selectedNodeId, treeData[0]);

  let removable = false;
  if (selectedItemObject.type === ZONE_KEY) {
    removable = selectedItemObject.children.length < 1;
  } else if (selectedItemObject.type === LOCATION_KEY) {
    removable = true;
    const dataRetentionMonths = getConstantFromLocalStorage(LS_DATA_RETENTION_MONTHS);
    if (selectedItemObject.timestamp && dataRetentionMonths) {
      const currentDate = Date.now();
      const timeStamp = new Date(selectedItemObject.timestamp);
      const expiryDate = timeStamp.setMonth(timeStamp.getMonth() + Number(dataRetentionMonths));
      removable = expiryDate < currentDate;
    }
  }

  const iconName = selectedItemObject.parentType === SITE_KEY ? VaiIcon.MapMarkerHexagon : VaiIcon.FolderOpen;

  switch (selectedItemObject.type) {
    case LOCATION_KEY:
      return (
        <>
          {!deleteDialogVisible ? (
            <LocationFormDialog
              icon={iconName}
              loading={editResult?.isLoading}
              formAction={FormActionType.EDIT}
              onDismiss={onDismiss}
              onSave={node =>
                editTreeNode({
                  editedNode: node,
                  name: node.name,
                  node_id: selectedItemObject.id,
                  node_type: LOCATION_KEY
                })
              }
              selectedItemObject={selectedItemObject}
              parentName={selectedItemParentObject ? selectedItemParentObject.text : ''}
              siblingNames={siblingNames}
              Footer={
                <>
                  <DeleteNodeSection
                    deleteButtonDisabled={editResult?.isLoading}
                    onDelete={() => setDeleteDialogVisible(true)}
                    constraintText={t('site.deleteLocationMsg')}
                    label={t('site.deleteLocation')}
                    canBeDeleted={removable}
                  />
                </>
              }
            />
          ) : (
            <DeleteZoneAndLocationDialog
              onDismiss={onDismiss}
              node_id={selectedItemObject.id}
              parent_id={selectedItemObject.parent}
              name={selectedItemObject.text}
              deleteTreeNode={deleteTreeNode}
              type={selectedItemObject.type}
              loading={deleteResult.isLoading}
            />
          )}
        </>
      );
    case ZONE_KEY:
      return (
        <>
          {!deleteDialogVisible ? (
            <ZoneFormDialog
              icon={iconName}
              onDismiss={onDismiss}
              formAction={FormActionType.EDIT}
              parentName={selectedItemParentObject ? selectedItemParentObject.text : ''}
              selectedItemObject={selectedItemObject}
              onSave={zoneNode =>
                editTreeNode({
                  editedNode: zoneNode,
                  name: zoneNode.name,
                  node_id: selectedItemObject.id,
                  node_type: ZONE_KEY
                })
              }
              loading={editResult?.isLoading}
              childNames={childNames}
              siblingNames={siblingNames}
              Footer={
                <DeleteNodeSection
                  deleteButtonDisabled={editResult?.isLoading}
                  onDelete={() => setDeleteDialogVisible(true)}
                  constraintText={t('site.deleteZoneMsg')}
                  label={t('site.deleteZone')}
                  canBeDeleted={removable}
                />
              }
            />
          ) : (
            <DeleteZoneAndLocationDialog
              onDismiss={onDismiss}
              node_id={selectedItemObject.id}
              parent_id={selectedItemObject.parent}
              name={selectedItemObject.text}
              deleteTreeNode={deleteTreeNode}
              type={selectedItemObject.type}
              loading={deleteResult.isLoading}
            />
          )}
        </>
      );
    default:
      return (
        <SiteFormDialog
          onDismiss={onDismiss}
          loading={editResult?.isLoading}
          formAction={FormActionType.EDIT}
          selectedItemObject={selectedItemObject}
          childNames={childNames}
          onSave={zoneNode =>
            editTreeNode({
              editedNode: zoneNode,
              name: zoneNode.name,
              node_id: selectedItemObject.id,
              node_type: SITE_KEY
            })
          }
        />
      );
  }
};
