import React from 'react';

import { FlexItem, ListItem, RadioButton, Icon, Size } from '@vaisala/rockhopper-components';
import useHtmlId, { IUseHtmlIDProps } from '../../../hooks/useHtmlId';
import { TEST_IDS } from '../../../tests/testids';
import { DataSourceListItemModel } from '../../../types';
import { useTranslation } from 'react-i18next';
import { buildDeviceId, getDeviceImage } from '../../../utils';
import cx from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { SITES_SYMBOL_ID } from '../../../siteTree/site';
import { showMeasurementSymbol } from '../../../utils/site';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import './index.scss';
import StatusMeasurement from '../Status/StatusMeasurement';
import { QA_TEST_IDS } from '../../../tests/qaTestIds';
import { DEFAULT_DECIMAL_PLACES } from '../../../constants';

interface Props extends IUseHtmlIDProps {
  dataSource: DataSourceListItemModel;
  decimalPlaces: number | null;
  onSelect: () => void;
  checked: boolean;
  initial: boolean;
  symbolId: SITES_SYMBOL_ID;
}

const DataSourceListItem: React.FC<Props> = ({
  dataSource,
  checked = false,
  decimalPlaces = DEFAULT_DECIMAL_PLACES,
  initial,
  onSelect,
  symbolId,
  htmlId = nanoid()
}) => {
  const className = cx('cursor-pointer', { 'user-selected-row': checked });
  const { t } = useTranslation();
  const { getId } = useHtmlId({ htmlId });
  const deviceId = buildDeviceId(dataSource.device_model, dataSource.device_sn);
  const probeId = buildDeviceId(dataSource.probe_model, dataSource.probe_sn);
  let messageString;
  let iconName;
  if (initial && checked) {
    messageString = 'linkDataSourceDialog.initialDataSourceMessage';
    iconName = VaiIcon.Link;
  } else if (initial && !checked) {
    messageString = 'linkDataSourceDialog.unlinkDataSourceMessage';
    iconName = VaiIcon.Unlink;
  } else if (!initial && checked) {
    messageString = 'linkDataSourceDialog.linkDataSourceMessage';
    iconName = VaiIcon.Link;
  }

  return (
    <ListItem
      className={className}
      onClick={onSelect}
      dataTa={TEST_IDS.data_source_list_item_container}
      id={getId(`${TEST_IDS.data_source_list_item_container}-${probeId}-${dataSource.meas_group}`)}
    >
      <RadioButton
        className="vai-margin-left-m vai-margin-vertical-m vai-margin-right-none"
        dataTa={TEST_IDS.data_source_list_item_radio}
        value={''}
        checked={checked}
      />
      <FlexItem className="vai-margin-right-m">
        <img data-ta={TEST_IDS.device_icon} src={getDeviceImage(dataSource.device_model)} className="icon-width" />
      </FlexItem>
      <FlexItem flexGrow={1}>
        <span id={getId(TEST_IDS.device_id)} data-ta={TEST_IDS.device_id}>
          {deviceId}
        </span>
        <br />
        <span id={getId(TEST_IDS.probe_id)} data-ta={TEST_IDS.probe_id} className="grey-medium">
          {probeId}
        </span>
        <br />
        <span id={getId(TEST_IDS.status_msg_icon)} className="grey-medium">
          <Icon className="vai-margin-right-s status-msg-icon" size={Size.M} name={iconName} />
          {t(messageString)}
        </span>
      </FlexItem>
      <FlexItem
        dataTa={TEST_IDS.data_source_list_item_latest_meas_container}
        flexBasis={'50px'}
        className="vai-margin-right-m"
      >
        <StatusMeasurement
          id={getId(QA_TEST_IDS.data_source_list_item_latest_meas)}
          dataTa={TEST_IDS.data_source_list_item_latest_meas}
          value={dataSource.latest_measurement?.value}
          className="strong"
          decimals={decimalPlaces}
        />
        <span
          className="vai-margin-left-s"
          id={getId(QA_TEST_IDS.data_source_list_item_symbol)}
          data-ta={TEST_IDS.data_source_list_item_symbol}
        >
          {showMeasurementSymbol(symbolId)}
        </span>
      </FlexItem>
    </ListItem>
  );
};

export default DataSourceListItem;
