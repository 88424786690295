import { Accordion, AccordionList, Checkbox, Flex, Icon, Size, Spinner } from '@vaisala/rockhopper-components';
import React, { useContext, useEffect, useState } from 'react';
import { DEVICES_MEAS_ID, LOCATION_KEY, SITE_KEY, ZONE_KEY } from '../../constants';
import { LocationNode, SiteChildrenInterface, SiteInterface } from '../../siteTree/site';
import TruncatedText from '../Utils/TruncatedText/TruncatedText';
import { VaiIcon, VaiColor } from '@vaisala/rockhopper-design-tokens';
import { isLocationNode, isSiteNode, isZoneNode } from '../../utils';
import { cloneDeep } from 'lodash';
import {
  SelectionOptions,
  applyNodeSelection,
  applyLocationsCount,
  DEFAULT_SELECTION_OPTIONS,
  applyNodeVisibility
} from './helpers';
import { TEST_IDS } from '../../tests/testids';
import './accordion-tree.scss';

export type AccordionTreeNode =
  | SiteNode
  | SubParentNode
  | (LocationNode & { selected: SelectedHelper; visible: boolean });

type SiteNode = Omit<SiteInterface, 'children'> & HelperProperties;
type SubParentNode = Omit<SiteChildrenInterface, 'children'> & HelperProperties;
type HelperProperties = {
  locationsCount: number;
  selected: SelectedHelper;
  children: SubParentNode[];
  visible: boolean;
};
type SelectedHelper = { partially: boolean; full: boolean };

interface Props {
  node: AccordionTreeNode;
  isNodeExpanded?: boolean;
}

interface AccordionTreeWrapperProps<T> {
  site: T;
  onChange?: (selectedNodes: string[]) => void;
  AccordionContent?: ({ node }: { node: T & HelperProperties }) => React.ReactElement;
  AccordionSummary?: ({ node }: { node: T & HelperProperties }) => React.ReactElement;
  options?: {
    selectedNodes?: SelectionOptions['selectedNodes'];
    expandedNodes?: SelectionOptions['expandedNodes'];
    match?: string;
    nonSelectableNodes?: SelectionOptions['nonSelectableNodes'];
    filterResultBy?: { types: (typeof LOCATION_KEY | typeof ZONE_KEY | typeof SITE_KEY)[] };
    showLocations?: boolean;
  };
}

interface ContextType {
  site?: SiteNode;
  AccordionContent?: AccordionTreeWrapperProps<any>['AccordionContent'];
  AccordionSummary?: AccordionTreeWrapperProps<any>['AccordionSummary'];
  toggleNodeSelection: (targetId: string) => void;
  clearSelections: () => void;
  nonSelectableNodes: SelectionOptions['nonSelectableNodes'];
  expandedNodes: SelectionOptions['expandedNodes'];
}

const Context = React.createContext<ContextType>({
  site: undefined,
  toggleNodeSelection: () => {
    return;
  },
  clearSelections: () => {
    return;
  },
  nonSelectableNodes: [],
  expandedNodes: []
});

const useAccordionTreeContext = () => useContext(Context);

const DEFAULT_OPTIONS = {
  ...DEFAULT_SELECTION_OPTIONS,
  filterResultBy: { types: [] },
  match: null,
  showLocations: true
};

function AccordionTreeWrapper<T>({
  site,
  onChange,
  options,
  AccordionContent,
  AccordionSummary
}: AccordionTreeWrapperProps<T>) {
  const [processedSite, setProcessedSite] = useState(applyLocationsCount(site));
  const _options = { ...DEFAULT_OPTIONS, ...options };

  useEffect(() => {
    setProcessedSite(applyLocationsCount(site));
  }, [site]);

  useEffect(() => {
    setProcessedSite(site => {
      const siteClone = cloneDeep(site);
      applyNodeVisibility(siteClone, _options.match, {
        forcedVisibility: _options.match === null || null,
        showLocations: _options.showLocations
      });
      return siteClone;
    });
  }, [_options?.match, site]);

  useEffect(() => {
    setProcessedSite(site => {
      const siteClone = cloneDeep(site);
      const selectionOptions = {
        forcedSelection: _options.selectedNodes.includes('*'),
        nonSelectableNodes: _options.nonSelectableNodes,
        selectedNodes: _options.selectedNodes,
        expandedNodes: _options.expandedNodes
      };
      applyNodeSelection(siteClone, '', selectionOptions);
      return siteClone;
    });
  }, [_options.selectedNodes, site]);

  const toggleNodeSelection = targetId => {
    setProcessedSite(site => {
      const siteClone = cloneDeep(site);
      const selectionOptions = {
        forcedSelection: null,
        nonSelectableNodes: _options.nonSelectableNodes,
        selectedNodes: [],
        expandedNodes: _options.expandedNodes
      };
      const { selectedNodes } = applyNodeSelection(siteClone, targetId, selectionOptions);

      onChange &&
        onChange(
          selectedNodes
            .filter(node => !_options.filterResultBy.types?.includes(node.type))
            .filter(node => node.visible)
            .map(node => node.id)
        );
      return siteClone;
    });
  };

  const clearSelections = () => {
    setProcessedSite(site => {
      const siteClone = cloneDeep(site);
      const selectionOptions = {
        forcedSelection: false,
        nonSelectableNodes: _options.nonSelectableNodes,
        selectedNodes: [],
        expandedNodes: _options.expandedNodes
      };
      const { selectedNodes } = applyNodeSelection(siteClone, site.node_id, selectionOptions);
      onChange && onChange(selectedNodes.map(node => node.id));
      return siteClone;
    });
  };
  return (
    <Context.Provider
      value={{
        site: processedSite,
        toggleNodeSelection,
        clearSelections,
        AccordionContent,
        AccordionSummary,
        nonSelectableNodes: _options.nonSelectableNodes || [],
        expandedNodes: _options.expandedNodes || []
      }}
    >
      <AccordionTree node={processedSite} />
    </Context.Provider>
  );
}

const AccordionTree: React.FC<Props> = ({ node }) => {
  const { AccordionContent, AccordionSummary, expandedNodes } = useAccordionTreeContext();
  const [isExpanded, setIsExpanded] = useState(node.type === SITE_KEY || false);

  if (!node) {
    return <Spinner />;
  }
  if (!node.visible) {
    return null;
  }
  if (isLocationNode(node)) {
    return <NodeTitle node={node} />;
  }
  const Summary = ({ node }) => {
    switch (true) {
      case !!AccordionSummary:
        return <AccordionSummary node={node} />;
      default:
        return null;
    }
  };

  return (
    <AccordionList
      key={node.node_id}
      defaultKeys={expandedNodes}
      exclusive={false}
      onChange={(_activeKeys, activeKey, isExpanded) => {
        if (activeKey === node.node_id) {
          setIsExpanded(isExpanded);
        }
      }}
      htmlId="accordion-tree"
    >
      {node.children && node.visible ? (
        <Accordion
          key={node.node_id}
          dataTa={`${TEST_IDS.accordion_tree_accordion_$id}${node.node_id}`}
          accordionKey={node.node_id}
          title={<NodeTitle node={node} isNodeExpanded={isExpanded} />}
          summary={<Summary node={node} />}
          className={node.type === SITE_KEY ? 'site-header' : 'parent-node'}
        >
          {AccordionContent && <AccordionContent node={node} />}
          {isExpanded ? node.children.map(node => <AccordionTree key={node.node_id} node={node} />) : null}
        </Accordion>
      ) : (
        node.visible && <NodeTitle node={node} />
      )}
    </AccordionList>
  );
};

const NodeTitle: React.FC<Props> = ({ node, isNodeExpanded }) => {
  const renderIcon = () => {
    if (isSiteNode(node)) {
      return <Icon name={VaiIcon.MapMarkerHexagon} color={VaiColor.BlueDark} size={Size.M} />;
    } else if (isZoneNode(node)) {
      return (
        <Icon name={isNodeExpanded ? VaiIcon.FolderOpen : VaiIcon.Folder} color={VaiColor.BlueDark} size={Size.M} />
      );
    } else if (isLocationNode(node)) {
      switch (node.meas_id) {
        case DEVICES_MEAS_ID.TEMPERATURE:
          return <Icon name={VaiIcon.Thermometer} color={VaiColor.BlueDark} size={Size.M} />;
        case DEVICES_MEAS_ID.HUMIDITY:
          return <Icon name={VaiIcon.Humidity} color={VaiColor.BlueDark} size={Size.M} />;
        case DEVICES_MEAS_ID.CO2:
          return <Icon name={VaiIcon.Dust} color={VaiColor.BlueDark} size={Size.M} />;
        default:
          return;
      }
    }

    return <></>;
  };

  const { toggleNodeSelection, nonSelectableNodes } = useAccordionTreeContext();
  const isSelectable = !nonSelectableNodes.includes(node.node_id) && !nonSelectableNodes.includes('*');
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className={`${isLocationNode(node) ? 'last-child-wrapper' : ''}`}
      style={{ width: '100%' }}
    >
      <div
        onClick={e => e.stopPropagation()}
        data-ta={`${TEST_IDS.accordion_tree_checkbox_wrapper_$id}${node.node_id}`}
        className={`checkbox-wrapper ${node.selected?.partially ? 'partially-checked' : ''}`}
      >
        {isSelectable && (
          <Checkbox
            dataTa={`${TEST_IDS.accordion_tree_checkbox_$id}${node.node_id}`}
            name={node.name}
            checked={node.selected?.full}
            onChange={() => toggleNodeSelection(node.node_id)}
          />
        )}
        <Flex alignItems="center" className={`node-title ${node.type === SITE_KEY && 'site-node'}`}>
          {renderIcon()}
          <TruncatedText
            dataTa={`${TEST_IDS.accordion_tree_checkbox_title_$id}${node.node_id}`}
            text={node.name}
            className="node-text"
          />
        </Flex>
      </div>
    </Flex>
  );
};

export { AccordionTreeWrapper as AccordionTree };
