import React, { useContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DeviceAlarmSettings, DeviceAlarmTemplateRule } from '../../../../../store/services/alarmsApi';
import { LocationNode } from '../../../../../siteTree/site';
import { GetGroupsResponse, Group } from '../../../../../store/services/userApi';
import { DELAY_VALIDATION_TRANSLATION_KEYS, DelayError } from '../../../Alarms/shared/HoursMinutesFields';
import { ALARM_PRIORITY, DEVICE_ALARM_RULE_NAMES } from '../../../../../constants';
import { DEFAULT_NOTIFICATION_SETTINGS } from './shared';
import { checkCanAcknowledgeAlarmsCurried } from '../../../Alarms/shared/GroupsSelect';
import { DEFAULT_WARNING_STATE, WarningStateType } from '../shared';

interface DeviceModalContext {
  rules: DeviceAlarmSettings['template']['rules'];
  notificationSettings: DeviceAlarmSettings['settings']['notifications'];
  errors: Errors;
  warnings: WarningStateType;
  settingsHasChanged: boolean;
  notificationGroups?: GetGroupsResponse['groups'];
  isFetchingGroups: boolean;
  isSaving: boolean;
  updateSettings: (handler: (settings: DeviceAlarmSettings) => DeviceAlarmSettings) => void;
  updateWarnings: (handler: (warnings: WarningStateType) => WarningStateType) => void;
  handleSave: () => void;
  saveErrors: (validator: (errors: Errors) => Errors) => void;
  checkCanAcknowledgeAlarms: (group: Group) => boolean;
}

export const DEFAULT_RULE: DeviceAlarmTemplateRule = {
  ack_required: false,
  category: '',
  color: '',
  delay_sec: null,
  enabled: false,
  priority: ALARM_PRIORITY.CRITICAL
};

export const DEFAULT_RULES = {
  [DEVICE_ALARM_RULE_NAMES.CALIBRATION]: DEFAULT_RULE,
  [DEVICE_ALARM_RULE_NAMES.COMMUNICATION]: DEFAULT_RULE,
  [DEVICE_ALARM_RULE_NAMES.ERROR]: DEFAULT_RULE,
  [DEVICE_ALARM_RULE_NAMES.CONFIGURATION]: DEFAULT_RULE,
  [DEVICE_ALARM_RULE_NAMES.BATTERY]: DEFAULT_RULE
};
const Context = React.createContext<DeviceModalContext>({
  rules: DEFAULT_RULES,
  errors: getDefaultErrorsState(key => key),
  warnings: DEFAULT_WARNING_STATE,
  settingsHasChanged: false,
  isFetchingGroups: false,
  isSaving: false,
  notificationSettings: DEFAULT_NOTIFICATION_SETTINGS,
  updateSettings: () => {
    return;
  },
  updateWarnings: () => {
    return;
  },
  handleSave: () => {
    return;
  },
  saveErrors: () => getDefaultErrorsState(key => key),
  checkCanAcknowledgeAlarms: () => false
});

interface Props {
  initialSettings: DeviceAlarmSettings;
  location: LocationNode;
  onSave: (settings: DeviceAlarmSettings) => void;
  notificationGroupsDetails: { groups: Group[]; isFetching: boolean };
  isSaving: boolean;
}

export const DeviceAlarmModalProvider: React.FC<Props> = ({
  children,
  initialSettings,
  location,
  onSave,
  notificationGroupsDetails,
  isSaving
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState(getDefaultErrorsState(t));
  const [warnings, setWarnings] = useState<DeviceModalContext['warnings']>(DEFAULT_WARNING_STATE);
  const [settings, setSettings] = useState<DeviceAlarmSettings>(initialSettings);
  const [settingsHasChanged, setSettingsHasChanged] = useState(false);

  const checkCanAcknowledgeAlarms: DeviceModalContext['checkCanAcknowledgeAlarms'] = checkCanAcknowledgeAlarmsCurried(
    location.parent_id
  );

  useEffect(() => {
    setSettingsHasChanged(!isEqual(initialSettings, settings));
  }, [settings]);

  useEffect(() => {
    setSettings(initialSettings);
  }, [initialSettings]);

  const updateSettings: DeviceModalContext['updateSettings'] = handleUpdate => {
    setSettings(handleUpdate);
  };
  const updateWarnings: DeviceModalContext['updateWarnings'] = handleUpdate => {
    setWarnings(handleUpdate);
  };
  const handleSave = () => {
    onSave(settings);
  };

  const saveErrors: DeviceModalContext['saveErrors'] = validator => {
    setErrors(errors => {
      const updatedErrors = validator(errors);
      updatedErrors.collectedErrors = collectSetOfErrors(updatedErrors);
      return updatedErrors;
    });
  };

  return (
    <Context.Provider
      value={{
        notificationGroups: notificationGroupsDetails.groups,
        notificationSettings: settings.settings.notifications,
        isFetchingGroups: notificationGroupsDetails.isFetching,
        isSaving,
        settingsHasChanged,
        rules: settings.template.rules,
        errors,
        warnings,
        checkCanAcknowledgeAlarms,
        saveErrors,
        updateSettings,
        updateWarnings,
        handleSave
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useDeviceAlarmModalContext = () => useContext(Context);

/**
 *
 * @param translate - For translating error labels
 * @returns
 */
function getDefaultErrorsState(translate: (str: string) => string): Errors {
  const error = {
    cannotBeEmptyValue: {
      label: translate(DELAY_VALIDATION_TRANSLATION_KEYS.cannotBeEmptyValue),
      hasError: false
    },
    hours: { max: { label: translate(DELAY_VALIDATION_TRANSLATION_KEYS.hours.max), hasError: false } },
    minutes: { max: { label: translate(DELAY_VALIDATION_TRANSLATION_KEYS.minutes.max), hasError: false } }
  };
  return {
    [DEVICE_ALARM_RULE_NAMES.CALIBRATION]: { delay: error, hasError: false },
    [DEVICE_ALARM_RULE_NAMES.COMMUNICATION]: { delay: error, hasError: false },
    [DEVICE_ALARM_RULE_NAMES.ERROR]: { delay: error, hasError: false },
    [DEVICE_ALARM_RULE_NAMES.CONFIGURATION]: { delay: error, hasError: false },
    [DEVICE_ALARM_RULE_NAMES.BATTERY]: { delay: error, hasError: false },
    collectedErrors: []
  };
}

type Errors = Record<DEVICE_ALARM_RULE_NAMES, DelayError> & { collectedErrors: string[] };

export function collectSetOfErrors(errors: Errors) {
  const errorsSet = new Set<string>();
  for (const ruleName of Object.keys(DEVICE_ALARM_RULE_NAMES)) {
    const delay = errors[ruleName as DEVICE_ALARM_RULE_NAMES].delay;
    delay.cannotBeEmptyValue.hasError && errorsSet.add(delay.cannotBeEmptyValue.label);
    delay.hours.max.hasError && errorsSet.add(delay.hours.max.label);
    delay.minutes.max.hasError && errorsSet.add(delay.minutes.max.label);
  }

  const collectedErrors = Array.from(errorsSet);

  return collectedErrors;
}
